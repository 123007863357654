import { Tab, Table, TableCell, TableRow, Tabs } from '@material-ui/core';
import React, { useState } from 'react';
import ImageOptions from '../../components/ImageOptions';
import TabPanel from '../../components/TabPanel';
import TextOptions from '../../components/TextOptions';
import { TriviaOption, TriviaQuestion } from '../../service/Dto';

type TriviaQuestionDisplayData = {
    text: string;
    options: Omit<TriviaOption, 'id'>[];
    correctOption: Omit<TriviaOption, 'id'>;
};

const filterDisplayData = (
    question: TriviaQuestion,
    correctOptionIndex: number,
): TriviaQuestionDisplayData => {
    return {
        correctOption: question.options[correctOptionIndex],
        options: question.options,
        text: question.text,
    };
};

type Props = {
    question: TriviaQuestion;
};

const TriviaQuestionDetails = ({ question }: Props) => {
    const [tabIndex, setTabIndex] = useState(0);

    const correctOptionIndex = question.options.findIndex(
        (option) => option.id === question.correctOptionId,
    );

    const tabs: {
        key: string;
        value: TriviaQuestionDisplayData;
    }[] = question.languageInfo
        ? [
              {
                  key: question.languageInfo.defaultLanguageId,
                  value: filterDisplayData(question, correctOptionIndex),
              },
              ...(question.languageInfo.entityTranslations
                  ? question.languageInfo.entityTranslations.map((et) => ({
                        key: et.languageId,
                        value: {
                            correctOption: et.options[correctOptionIndex],
                            options: et.options,
                            text: et.text,
                        },
                    }))
                  : []),
          ]
        : [
              {
                  key: 'Unspecified Language',
                  value: filterDisplayData(question, correctOptionIndex),
              },
          ];

    return (
        <>
            <Tabs
                indicatorColor="primary"
                onChange={(_, newTabIndex) => setTabIndex(newTabIndex)}
                textColor="primary"
                value={tabIndex}
            >
                {tabs.map(({ key }, index) => {
                    return <Tab key={index} label={<div>{key}</div>} />;
                })}
            </Tabs>
            {tabs.map((tab, index) => {
                return (
                    <TabPanel index={index} key={index} value={tabIndex}>
                        <Table>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>{question.id}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Text</TableCell>
                                <TableCell>{tab.value.text}</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell>Options</TableCell>
                                <TableCell>
                                    {tab.value.correctOption.text ? (
                                        <TextOptions
                                            correctOption={
                                                tab.value.correctOption.text
                                            }
                                            options={tab.value.options.map(
                                                (option) => option.text!,
                                            )}
                                        />
                                    ) : (
                                        <ImageOptions
                                            correctOption={
                                                tab.value.correctOption
                                                    .imageUrl!
                                            }
                                            options={tab.value.options.map(
                                                (option) => option.imageUrl!,
                                            )}
                                        />
                                    )}
                                </TableCell>
                            </TableRow>
                        </Table>
                    </TabPanel>
                );
            })}
        </>
    );
};

export default TriviaQuestionDetails;
