import moment from 'moment-timezone';
import React, { Component } from 'react';
import CrudPage from '../components/CrudPage';
import config from '../config';
import { PAGE_LABEL } from '../service/Constants';
import {
    CategoryDto,
    IterationDto,
    LocaleIds,
    PartnerDto,
} from '../service/Dto';
import {
    GROUP_MODE_TYPE,
    groupModeOptions,
    GroupModeType,
} from '../utils/GroupMode';
import {
    FORM_INPUT_WIDTH,
    localizeDate,
    parseDateString,
    renderPrizeDescription,
    slugify,
} from '../utils/util';

interface BreadcrumbData {
    link?: string;
    text: string;
}

interface Props {
    createRecord: (postBody: any) => void;
    updateRecord: (iterationId: string, body: any) => void;
    deleteRecord: (params: any) => void;
    category: CategoryDto;
    partner: PartnerDto;
    iterations: IterationDto[];
    refreshData: (callback: () => void) => void;
}

const { predictionsWebRoot } = config;

const getGroupModeKeys = (
    groupMode: GroupModeType,
): Record<string, boolean> => {
    const generalLeaderboardFields = {
        generalLeaderboardName: false,
        generalLeaderboardImageUrl: true,
    };
    switch (groupMode) {
        case GROUP_MODE_TYPE['User Group']:
            return { ...generalLeaderboardFields, userGroupImageUrl: true };
        case GROUP_MODE_TYPE['Team and User Group']:
            return {
                ...generalLeaderboardFields,
                interGroupLeaderboardName: true,
                interGroupLeaderboardImageUrl: true,

                userGroupImageUrl: true,
            };
        case GROUP_MODE_TYPE.Venue:
            return generalLeaderboardFields;
        case GROUP_MODE_TYPE.Team:
            return {
                ...generalLeaderboardFields,
                interGroupLeaderboardName: true,
                interGroupLeaderboardImageUrl: true,
            };
        case GROUP_MODE_TYPE['Groups disabled']:
            return generalLeaderboardFields;
        default:
            return {};
    }
};

const validateGroupModeProperties =
    ({ omitRequirementCheck = false }: { omitRequirementCheck?: boolean }) =>
    ({
        fieldErrors,
        formValues,
        name,
        newValue,
    }: {
        name: string;
        newValue: string;
        formValues: any;
        fieldErrors: { [key: string]: string };
    }) => {
        if (!newValue) {
            return;
        }

        const groupModeKeys = getGroupModeKeys(formValues.groupMode);

        try {
            const groupModeProperties = JSON.parse(newValue);
            if (groupModeProperties === null) {
                throw new Error(`field ${fieldErrors[name]} can't be null`);
            }

            if (
                Object.keys(groupModeProperties).some(
                    (key) => groupModeKeys[key] === undefined,
                )
            ) {
                fieldErrors[name] = `only ${Object.keys(groupModeKeys).join(
                    ', ',
                )} fields are allowed`;
                return;
            }

            if (
                !omitRequirementCheck &&
                Object.keys(groupModeKeys)
                    .filter((key) => Boolean(groupModeKeys[key]))
                    .some((key) => !groupModeProperties[key])
            ) {
                fieldErrors[name] = `fields ${Object.keys(groupModeKeys)
                    .filter((key) => Boolean(groupModeKeys[key]))
                    .join(', ')} are required`;
                return;
            }

            if (
                Object.keys(groupModeKeys).some(
                    (key) =>
                        typeof groupModeProperties[key] !== 'string' &&
                        !(
                            typeof groupModeProperties[key] === 'undefined' &&
                            !groupModeKeys[key]
                        ),
                )
            ) {
                fieldErrors[name] = `fields ${Object.keys(groupModeKeys).join(
                    ', ',
                )} must be strings`;
                return;
            }

            if (
                Object.keys(groupModeKeys).some(
                    (key) =>
                        groupModeProperties[key] &&
                        !groupModeProperties[key].length,
                )
            ) {
                fieldErrors[name] = `fields ${Object.keys(groupModeKeys).join(
                    ', ',
                )} must be non-empty strings`;
            }
        } catch (error) {
            fieldErrors[name] = 'Invalid JSON syntax';
        }
    };

class Iterations extends Component<Props> {
    defaultIterationDates = () => {
        const { category } = this.props;
        const { iterations } = this.props;
        if (iterations.length > 0) {
            // iterations are sorted by date upstream of us
            const { endDate } = iterations[iterations.length - 1];
            if (endDate) {
                return {
                    endDate: moment(endDate).add(1, 'weeks').toISOString(),
                    startDate: endDate,
                };
            }
            // Last iteration had no endDate, don't default
            return { endDate: null, startDate: null };
        }

        const { endDate, startDate } = category;
        return { endDate, startDate };
    };

    updateSlug = ({
        formValues,
        newValue,
    }: {
        newValue: string;
        formValues: { [key: string]: string | boolean | object };
    }) => {
        // only auto update if iteration is not yet live
        if (
            !formValues.id ||
            !formValues.startDate ||
            parseDateString(formValues.startDate).isAfter(moment())
        ) {
            formValues.slug = slugify(newValue);
        }
    };

    inputFields() {
        const { category, partner } = this.props;
        const { endDate, startDate } = this.defaultIterationDates();

        return [
            {
                name: 'DEFAULT LANGUAGE',
                type: 'heading',
            },
            {
                defaultValue:
                    (partner &&
                        partner.properties &&
                        partner.properties.supportedLanguages &&
                        partner.properties.supportedLanguages[0]) ||
                    LocaleIds[0],
                label: 'Default Language',
                name: 'defaultLanguageId',
                options:
                    partner &&
                    partner.properties &&
                    partner.properties.supportedLanguages
                        ? partner.properties.supportedLanguages.map((sl) => ({
                              label: sl,
                              value: sl,
                          }))
                        : [
                              {
                                  label: LocaleIds[0],
                                  value: LocaleIds[0],
                              },
                          ],
                props: {
                    formwidthpercent: FORM_INPUT_WIDTH.third,
                },
                required: true,
                type: 'languageSelect',
            },
            {
                name: 'id',
                type: 'hidden',
            },
            {
                defaultValue: category.id,
                label: 'Category Id',
                name: 'categoryId',
                required: true,
                type: 'hidden',
            },
            {
                label: 'Name',
                name: 'name',
                onChange: this.updateSlug,
                props: {
                    autoFocus: true,
                },
                required: true,
                type: 'text',
            },
            {
                helperTextFunc: (name: string, value: string) => {
                    if (value) {
                        // prettier-ignore
                        return `ex. ${predictionsWebRoot}/${category.slug}/${slugify(value)}`;
                    }
                },
                label: 'Slug',
                name: 'slug',
                required: true,
                type: 'text',
            },
            {
                defaultValue: startDate,
                label: 'Visible Date',
                name: 'startDate',
                props: {
                    InputLabelProps: { shrink: true },
                },
                required: false,
                type: 'datetime-local',
            },
            {
                defaultValue: endDate,
                label: 'Hidden Date',
                name: 'endDate',
                props: {
                    InputLabelProps: { shrink: true },
                },
                required: false,
                type: 'datetime-local',
            },
            {
                label: 'How to Play Modal Header',
                name: 'stayTunedModalHeader',
                type: 'text',
            },
            {
                label: 'How To Play Modal Body',
                name: 'stayTunedModalBody',
                props: {
                    multiline: true,
                },
                type: 'text',
            },
            {
                defaultValue: false,
                label: 'Tournament',
                name: 'tournamentEnabled',
                props: {
                    color: 'primary',
                },
                type: 'boolean',
            },
            {
                helperTextFunc: (name: string, value: string) => {
                    if (value) {
                        if (value.trim() === '') {
                            return '1 per line';
                        }
                        return value ? renderPrizeDescription(value) : '';
                    } else {
                        return '';
                    }
                },
                label: 'Prizes',
                name: 'prizes',
                props: {
                    multiline: true,
                },
                required: (obj: any) => obj.tournamentEnabled === true,
                shouldDisplay: (obj: any) => obj.tournamentEnabled === true,
                type: 'text',
            },
            {
                label: 'Tournament Headline',
                name: 'tournamentHeadline',
                required: (obj: any) => obj.tournamentEnabled === true,
                shouldDisplay: (obj: any) => obj.tournamentEnabled === true,
                type: 'text',
            },
            {
                label: 'Tournament Sub Headline',
                name: 'tournamentSubheadline',
                required: (obj: any) => obj.tournamentEnabled === true,
                shouldDisplay: (obj: any) => obj.tournamentEnabled === true,
                type: 'text',
            },
            {
                label: 'Tournament Info Modal Body',
                name: 'infoModalBody',
                props: {
                    multiline: true,
                },
                required: (obj: any) => obj.tournamentEnabled === true,
                shouldDisplay: (obj: any) => obj.tournamentEnabled === true,
                type: 'text',
            },
            {
                label: 'Tournament Editorial',
                name: 'tournamentEditorial',
                props: {
                    multiline: true,
                },
                required: (obj: any) => obj.tournamentEnabled === true,
                shouldDisplay: (obj: any) => obj.tournamentEnabled === true,
                type: 'text',
            },
            {
                helperTextFunc: () =>
                    `{
                    "title": "Play Prediction Games",
                    "description": "Official free-to-play game. Predict the action for a chance to win prizes!",
                    "imageUrl": "https://www.example.com/images/image1.jpg"
                }`,
                label: 'Social Share',
                name: 'socialShare',
                required: false,
                type: 'json',
            },
            {
                name: 'Groups',
                type: 'heading',
            },
            {
                defaultValue: null,
                helperTextFunc: () =>
                    'Groups are disabled if no option selected',
                label: 'Group Mode',
                name: 'groupMode',
                options: groupModeOptions,
                type: 'select',
            },
            {
                helperTextFunc: (
                    name: string,
                    value: string,
                    formState: any,
                ) => {
                    const { groupMode } = formState;
                    switch (groupMode) {
                        case GROUP_MODE_TYPE['Team and User Group']:
                            return `{
                                "generalLeaderboardName": "General Leaderboard",
                                "generalLeaderboardImageUrl": "https://assets.playtally.com/tally/tally-square.png",
                                "interGroupLeaderboardName": "Inter Group Leaderboard",
                                "interGroupLeaderboardImageUrl": "https://assets.playtally.com/tally/tally-square.png",
                                "userGroupImageUrl": "https://assets.playtally.com/tally/tally-square.png"
                            }`;
                        case GROUP_MODE_TYPE['User Group']:
                            return `{
                                "generalLeaderboardName": "General Leaderboard",
                                "generalLeaderboardImageUrl": "https://assets.playtally.com/tally/tally-square.png",
                                "userGroupImageUrl": "https://assets.playtally.com/tally/tally-square.png"                    
                            }`;
                        case GROUP_MODE_TYPE.Venue:
                            return `{
                                "generalLeaderboardName": "General Leaderboard",
                                "generalLeaderboardImageUrl": "https://assets.playtally.com/tally/tally-square.png"
                            }`;
                        case GROUP_MODE_TYPE.Team:
                            return `{
                                "generalLeaderboardName": "General Leaderboard",
                                "generalLeaderboardImageUrl": "https://assets.playtally.com/tally/tally-square.png",
                                "interGroupLeaderboardName": "Inter Group Leaderboard",
                                "interGroupLeaderboardImageUrl": "https://assets.playtally.com/tally/tally-square.png"
                            }`;
                        case GROUP_MODE_TYPE['Groups disabled']:
                            return `{
                                "generalLeaderboardName": "General Leaderboard",
                                "generalLeaderboardImageUrl": "https://assets.playtally.com/tally/tally-square.png"                   
                            }`;
                        default:
                            return;
                    }
                },
                label: 'Group Mode properties',
                name: 'groupModeProperties',
                onChange: validateGroupModeProperties({
                    omitRequirementCheck: false,
                }),
                required: true,
                type: 'json',
            },
        ];
    }

    translationInputFields() {
        const { partner } = this.props;
        return [
            {
                defaultValue: false,
                label: 'Language',
                name: 'translation.languageCodeId',
                options:
                    partner &&
                    partner.properties &&
                    partner.properties.supportedLanguages
                        ? partner.properties.supportedLanguages.map((sl) => ({
                              label: sl,
                              value: sl,
                          }))
                        : [],
                props: {
                    formwidthpercent: FORM_INPUT_WIDTH.third,
                },
                required: true,
                type: 'languageSelect',
            },
            {
                label: 'How to Play Modal Header',
                name: 'translation.stayTunedModalHeader',
                type: 'text',
            },
            {
                label: 'How To Play Modal Body',
                name: 'translation.stayTunedModalBody',
                props: {
                    multiline: true,
                },
                type: 'text',
            },
            {
                helperTextFunc: (name: string, value: string) => {
                    if (value) {
                        if (value.trim() === '') {
                            return '1 per line';
                        }
                        return value ? renderPrizeDescription(value) : '';
                    } else {
                        return '';
                    }
                },
                label: 'Prizes',
                name: 'translation.prizes',
                props: {
                    multiline: true,
                },
                required: false,
                shouldDisplay: (obj: any) => obj.tournamentEnabled === true,
                type: 'text',
            },
            {
                label: 'Tournament Headline',
                name: 'translation.tournamentHeadline',
                required: false,
                shouldDisplay: (obj: any) => obj.tournamentEnabled === true,
                type: 'text',
            },
            {
                label: 'Tournament Sub Headline',
                name: 'translation.tournamentSubheadline',
                required: false,
                shouldDisplay: (obj: any) => obj.tournamentEnabled === true,
                type: 'text',
            },
            {
                label: 'Tournament Info Modal Body',
                name: 'translation.infoModalBody',
                props: {
                    multiline: true,
                },
                required: false,
                shouldDisplay: (obj: any) => obj.tournamentEnabled === true,
                type: 'text',
            },
            {
                label: 'Tournament Editorial',
                name: 'translation.tournamentEditorial',
                props: {
                    multiline: true,
                },
                required: false,
                shouldDisplay: (obj: any) => obj.tournamentEnabled === true,
                type: 'text',
            },
            {
                helperTextFunc: () =>
                    `{
                    "title": "Play Prediction Games",
                    "description": "Official free-to-play game. Predict the action for a chance to win prizes!",
                    "imageUrl": "https://www.example.com/images/image1.jpg"
                }`,
                label: 'Social Share',
                name: 'translation.socialShare',
                required: false,
                type: 'json',
            },
            {
                helperTextFunc: (
                    name: string,
                    value: string,
                    formState: any,
                ) => {
                    const { groupMode } = formState;
                    switch (groupMode) {
                        case GROUP_MODE_TYPE['Team and User Group']:
                            return `{
                                "generalLeaderboardName": "General Leaderboard",
                                "generalLeaderboardImageUrl": "https://assets.playtally.com/tally/tally-square.png",
                                "interGroupLeaderboardName": "Inter Group Leaderboard",
                                "interGroupLeaderboardImageUrl": "https://assets.playtally.com/tally/tally-square.png",
                                "userGroupImageUrl": "https://assets.playtally.com/tally/tally-square.png"
                            }`;
                        case GROUP_MODE_TYPE['User Group']:
                            return `{
                                "generalLeaderboardName": "General Leaderboard",
                                "generalLeaderboardImageUrl": "https://assets.playtally.com/tally/tally-square.png",
                                "userGroupImageUrl": "https://assets.playtally.com/tally/tally-square.png"                    
                            }`;
                        case GROUP_MODE_TYPE.Venue:
                            return `{
                                "generalLeaderboardName": "General Leaderboard",
                                "generalLeaderboardImageUrl": "https://assets.playtally.com/tally/tally-square.png"                   
                            }`;
                        case GROUP_MODE_TYPE.Team:
                            return `{
                                "generalLeaderboardName": "General Leaderboard",
                                "generalLeaderboardImageUrl": "https://assets.playtally.com/tally/tally-square.png",
                                "interGroupLeaderboardName": "Inter Group Leaderboard",
                                "interGroupLeaderboardImageUrl": "https://assets.playtally.com/tally/tally-square.png"
                            }`;
                        case GROUP_MODE_TYPE['Groups disabled']:
                            return `{
                                "generalLeaderboardName": "General Leaderboard",
                                "generalLeaderboardImageUrl": "https://assets.playtally.com/tally/tally-square.png"                   
                            }`;
                        default:
                            return;
                    }
                },
                label: 'Group Mode properties',
                name: 'translation.groupModeProperties',
                onChange: validateGroupModeProperties({
                    omitRequirementCheck: true,
                }),
                required: false,
                type: 'json',
            },
        ];
    }

    static tableColumns = [
        {
            label: 'Iteration ID',
            name: 'id',
            options: {
                display: 'false',
            },
        },
        {
            label: 'Firestore Location',
            name: 'firestoreLocation',
            options: {
                display: 'false',
                filter: false,
                sort: false,
            },
        },
        {
            label: 'Name',
            name: 'name',
        },
        {
            label: 'Visible Date',
            name: 'startDate',
            options: {
                customBodyRender: localizeDate,
                hint: 'When iteration starts and is visible',
            },
        },
        {
            label: 'Hidden Date',
            name: 'endDate',
            options: {
                customBodyRender: localizeDate,
                hint: 'When iteration ends and is hidden',
            },
        },
        {
            label: 'Group Mode',
            name: 'groupMode',
        },
        {
            label: 'Tournament',
            name: 'tournamentEnabled',
            options: {
                customBodyRender: (value: boolean) => {
                    return value === true ? 'true' : 'false';
                },
                hint: 'When tournament is selected, we will generate iteration leaderboards tournament style',
            },
        },
    ];

    presubmitTransform(formState: any) {
        const transformedState = { ...formState };
        transformedState.groupModeProperties = JSON.parse(
            transformedState.groupModeProperties,
        );
        if (formState.tournamentEnabled && formState.prizes) {
            // filter out empty lines
            transformedState.prizes = (formState.prizes || '')
                .split('\n')
                .map((prize: string) => prize.trim())
                .filter((prize: string) => prize);
        }

        const uuidLength = 36;

        transformedState.socialShare =
            typeof transformedState.socialShare === 'string'
                ? JSON.parse(transformedState.socialShare)
                : transformedState.socialShare;

        const translationEntities = Object.keys(formState).filter((key) =>
            key.startsWith('translation.languageCodeId.'),
        );
        if (translationEntities && translationEntities.length) {
            transformedState.entityTranslations = translationEntities.map(
                (te) => {
                    return {
                        languageCodeId: formState[te],
                        languageIdentity: te.slice(-uuidLength),
                    };
                },
            );

            const translationPrizesMultiline = Object.keys(formState).filter(
                (key) => key.startsWith('translation.prizes'),
            );
            if (
                translationPrizesMultiline &&
                translationPrizesMultiline.length
            ) {
                translationPrizesMultiline.forEach((tpmlKey) => {
                    const translationPrizeMultiline = formState[tpmlKey];
                    const translationEntityIndex =
                        transformedState.entityTranslations.findIndex(
                            (et: any) =>
                                et.languageIdentity ===
                                tpmlKey.slice(-uuidLength),
                        );
                    transformedState.entityTranslations[
                        translationEntityIndex
                    ] = {
                        ...transformedState.entityTranslations[
                            translationEntityIndex
                        ],
                        prizes: (translationPrizeMultiline || '')
                            .split('\n')
                            .map((prize: string) => prize.trim())
                            .filter((prize: string) => prize),
                    };
                    delete formState[tpmlKey];
                    delete transformedState[tpmlKey];
                });
            }
        }

        const translationGroupModeProperties = Object.keys(formState).filter(
            (key) => key.startsWith('translation.groupModeProperties'),
        );

        if (
            translationGroupModeProperties &&
            translationGroupModeProperties.length
        ) {
            translationGroupModeProperties.forEach((tgmpKey) => {
                const translationGroupModeProperties = formState[tgmpKey];
                const translationEntityIndex =
                    transformedState.entityTranslations.findIndex(
                        (et: any) =>
                            et.languageIdentity === tgmpKey.slice(-uuidLength),
                    );

                if (translationGroupModeProperties) {
                    const groupModeProperties = JSON.parse(
                        translationGroupModeProperties,
                    );

                    transformedState.entityTranslations[
                        translationEntityIndex
                    ] = {
                        ...transformedState.entityTranslations[
                            translationEntityIndex
                        ],
                        groupModeProperties,
                    };
                }
                delete formState[tgmpKey];
                delete transformedState[tgmpKey];
            });
        }

        const translationSocialShareKeys = Object.keys(formState).filter(
            (key) => key.startsWith('translation.socialShare'),
        );

        if (translationSocialShareKeys && translationSocialShareKeys.length) {
            translationSocialShareKeys.forEach((translationSocialShareKey) => {
                const translationSocialShare =
                    formState[translationSocialShareKey];
                const translationEntityIndex =
                    transformedState.entityTranslations.findIndex(
                        (et: any) =>
                            et.languageIdentity ===
                            translationSocialShareKey.slice(-uuidLength),
                    );

                const socialShare =
                    typeof translationSocialShare === 'string'
                        ? JSON.parse(translationSocialShare)
                        : translationSocialShare;

                transformedState.entityTranslations[translationEntityIndex] = {
                    ...transformedState.entityTranslations[
                        translationEntityIndex
                    ],
                    ...(socialShare
                        ? {
                              socialShare,
                          }
                        : {}),
                };
                delete formState[translationSocialShareKey];
                delete transformedState[translationSocialShareKey];
            });
        }

        if (formState.slug) {
            transformedState.slug = slugify(formState.slug);
        }

        return transformedState;
    }

    preformRenderTransform(iteration: any) {
        const transformedState = { ...iteration };

        // remove decimals and $ for amounts so the user doesn't think they need
        // to enter them in the form
        if (iteration.tournamentEnabled) {
            transformedState.prizes = (iteration.prizes || []).join('\n');
        }

        if (iteration.groupModeProperties) {
            transformedState.groupModeProperties = JSON.stringify(
                iteration.groupModeProperties,
            );
        }

        if (iteration.entityTranslations) {
            transformedState.entityTranslations.forEach((et: any) => {
                if (et.groupModeProperties) {
                    et.groupModeProperties = JSON.stringify(
                        et.groupModeProperties,
                    );
                }
            });
        }

        return transformedState;
    }

    buildEventLink = (iterationId: string) =>
        `/events?iteration=${iterationId}`;

    render() {
        const { category, iterations, partner, refreshData } = this.props;
        const { firebaseProjectId } = category;
        const breadCrumbs: BreadcrumbData[] = [
            { link: '/partners', text: 'Partners' },
            {
                link: `/categories?partner=${category.partnerId}`,
                text: `${category.partnerId}'s Categories`,
            },
            { link: '', text: `${category.name}'s Iterations` },
        ];

        const crudProps = {
            ...this.props,
            breadCrumbs: breadCrumbs,
            buildChildLink: this.buildEventLink,
            data: iterations,
            firebaseProjectId: firebaseProjectId,
            headline: `${category.name}: Iterations`,
            inputFields: this.inputFields(),
            label: PAGE_LABEL.iteration,
            muiDataTableOptions: {
                sortOrder: {
                    direction: 'desc',
                    name: 'startDate',
                },
            },
            parentId: category.id,
            preformRenderTransform: this.preformRenderTransform,
            presubmitTransform: this.presubmitTransform,
            refreshData: refreshData,
            supportedLanguages: partner.properties.supportedLanguages || [],
            tableColumns: Iterations.tableColumns,
            translationInputFields: this.translationInputFields(),
        };

        return <CrudPage {...crudProps} />;
    }
}

export default Iterations;
