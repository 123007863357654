export const GROUP_MODE_TYPE = {
    'Groups disabled': null,
    'User Group': 'USER_GROUP',
    'Team and User Group': 'TEAM,USER_GROUP',
    Team: 'TEAM',
    Venue: 'VENUE',
};

export type GroupModeType =
    (typeof GROUP_MODE_TYPE)[keyof typeof GROUP_MODE_TYPE];
export type GroupModeLabel = keyof typeof GROUP_MODE_TYPE;

export const groupModeOptions: Array<{
    value: GroupModeType;
    label: GroupModeLabel;
}> = (
    Object.entries(GROUP_MODE_TYPE) as Array<[GroupModeLabel, GroupModeType]>
).map(([key, value]) => ({ label: key, value }));
