import {
    LocaleId,
    LocaleIds,
    LOCK_TYPE,
    LOCK_TYPES,
    OptionCreateDto,
    PredictionDto,
    PredictionType,
    PredictionTypes,
    SheetsPrediction,
    SheetsPredictionTranslation,
} from '../../service/Dto';
import { AD_SIZING_POLICY, AdSizingPolicy } from '../ads/adSizingPolicy';
import {
    PREDICTION_AD_PLACEMENT,
    PredictionAdPlacement,
} from '../ads/predictionAdPlacement';
import {
    PREDICTION_AD_RENDER_TIME,
    PredictionAdRenderTime,
} from '../ads/predictionAdRenderTime';
import { AD_CUTOFF_HEIGHT } from '../constants';
import {
    EntityTableExport,
    EntityTableImport,
    EntityTableTransformer,
} from './EntityTableTransformer';
import { parseDecimalInt } from './util';

type PredictionParseConfig<T> = {
    [P in keyof T]?: {
        column: number;
        optional?: boolean;
        fromCell?: (value: string) => T[P];
    };
};

function makeParseEnumEntity<T>(entity: { [key: string]: T }, defaultValue: T) {
    return function (value: string): T {
        return entity[value] === undefined ? defaultValue : entity[value];
    };
}

// Handles both hh:mm:ss format and a string of total seconds
export const parseTimeIntervalSeconds = (
    timeIntervalString: string,
): number => {
    const timeStringArray = timeIntervalString.split(':');
    let seconds = 0;
    let multiplier = 1;

    while (timeStringArray.length > 0) {
        const nextString = timeStringArray.pop();
        if (nextString) {
            seconds += multiplier * parseDecimalInt(nextString);
            multiplier *= 60;
        }
    }
    return seconds;
};

const parsePredictionType = (value: string): PredictionType =>
    Object.values(PredictionTypes).find((type) => type === value) ||
    PredictionTypes.multipleChoice;

const translationParseConfig: PredictionParseConfig<SheetsPredictionTranslation> =
    {
        adDisclaimer: {
            column: 8,
            optional: true,
        },
        adHeadline: {
            column: 9,
            optional: true,
        },
        adIframeHtml: {
            column: 10,
            optional: true,
        },
        answerTime: { column: 5, optional: true },
        detailsText: { column: 4 },
        lockDescription: { column: 6, optional: true },
        number: {
            column: 0,
            fromCell: parseDecimalInt,
        },
        releaseTime: { column: 7, optional: true },
        subText: { column: 3 },
        text: { column: 1 },
    } as const;

const predictionParseConfig: PredictionParseConfig<SheetsPrediction> = {
    adCutOffHeight: {
        column: 21,
        fromCell: (value: string) => parseDecimalInt(value) || AD_CUTOFF_HEIGHT,
        optional: true,
    },
    adDisclaimer: {
        column: 23,
        optional: true,
    },
    allOrNothing: {
        column: 24,
        optional: true,
        fromCell: (value: string): boolean => value === 'TRUE',
    },
    hideOptionMetrics: {
        column: 25,
        optional: true,
        fromCell: (value: string): boolean => value === 'TRUE',
    },
    adEnabled: {
        column: 16,
        fromCell: (value: string): boolean => value === 'TRUE',
    },
    adHeadline: {
        column: 22,
        optional: true,
    },
    adIframeHtml: {
        column: 19,
        optional: true,
    },
    adPlacement: {
        column: 17,
        fromCell: makeParseEnumEntity<PredictionAdPlacement>(
            PREDICTION_AD_PLACEMENT,
            PREDICTION_AD_PLACEMENT.Inline,
        ),
        optional: true,
    },
    adRenderTime: {
        column: 18,
        fromCell: makeParseEnumEntity<PredictionAdRenderTime>(
            PREDICTION_AD_RENDER_TIME,
            PREDICTION_AD_RENDER_TIME['Right Away'],
        ),
        optional: true,
    },
    adSizingPolicy: {
        column: 20,
        fromCell: makeParseEnumEntity<AdSizingPolicy>(
            AD_SIZING_POLICY,
            AD_SIZING_POLICY['Other Content, fixed height'],
        ),
        optional: true,
    },
    answerMilestone: { column: 11, optional: true },
    answerTime: { column: 12, optional: true },
    detailsText: { column: 8 },
    lockDescription: { column: 2, optional: true },
    lockType: {
        column: 1,
        fromCell: makeParseEnumEntity<LOCK_TYPE>(LOCK_TYPES, LOCK_TYPES.MANUAL),
    },
    notes: { column: 3, optional: true },
    number: {
        column: 0,
        fromCell: parseDecimalInt,
    },
    releaseMilestone: { column: 9, optional: true },
    releaseTime: { column: 10, optional: true },
    sponsorshipUnitName: {
        column: 15,
        optional: true,
    },
    sponsorSlug: {
        column: 14,
        optional: true,
    },
    text: { column: 5 },
    timeIntervalSeconds: { column: 13, fromCell: parseTimeIntervalSeconds },
    totalPointValue: {
        column: 4,
        fromCell: parseDecimalInt,
    },
    type: {
        column: 7,
        fromCell: parsePredictionType,
    },
} as const;

const translationTabHeadline = [
    'number',
    'text',
    'options',
    'subText',
    'detailsText',
    'answerTime',
    'lockDescription',
    'releaseTime',
    'adDisclaimer',
    'adHeadline',
    'adIframeHtml',
];

const predictionTabHeadline = [
    'number',
    'lockType',
    'lockDescription',
    'notes',
    'totalPointValue',
    'text',
    'options',
    'type',
    'detailsText',
    'releaseMilestone',
    'releaseTime',
    'answerMilestone',
    'answerTime',
    'timeIntervalSeconds',
    'sponsorSlug',
    'sponsorshipUnitName',
    'adEnabled',
    'adPlacement',
    'adRenderTime',
    'adIframeHtml',
    'adSizingPolicy',
    'adCutOffHeight',
    'adHeadline',
    'adDisclaimer',
    'allOrNothing',
    'hideOptionMetrics',
];

const QUESTION_OPTION_INDEX = 6;
const TRANSLATION_QUESTION_OPTION_INDEX = 2;
const HEADER_ROWS_COUNT = 1;

const getTheLastRequiredColumnIndex = (
    config: PredictionParseConfig<SheetsPrediction>,
): number =>
    Math.max(
        ...Object.values(config)
            .filter((cell) => !cell.optional)
            .map((cell) => cell.column),
    );

const MIN_PREDICTION_COLUMNS_COUNT =
    getTheLastRequiredColumnIndex(predictionParseConfig) + 1;

const MIN_TRANSLATION_COLUMNS_COUNT =
    getTheLastRequiredColumnIndex(translationParseConfig) + 1;

const getPredictionOptionArray = (
    prediction: PredictionDto,
): string[][] | undefined => {
    prediction.options.shift();
    if (prediction.options.length <= 0) {
        return undefined;
    }
    const optionRows: string[][] = [];
    for (const option of prediction.options) {
        optionRows.push([
            ...new Array(QUESTION_OPTION_INDEX).fill(''),
            option.text,
        ]);
    }
    return optionRows;
};

const rowIsNotQuestionOption = (index: number) => {
    return (row: string[]) => {
        const trimmedRow = row.slice(0, index);
        return !trimmedRow.every((cell: string) => cell === '');
    };
};

const parsePredictionFromRow = (row: string[]): SheetsPrediction => {
    const prediction = {} as any;

    for (const [key, config] of Object.entries(predictionParseConfig)) {
        if (config.optional && !row[config.column]) {
            continue;
        }
        if (config.fromCell) {
            prediction[key] = config.fromCell(row[config.column]);
        } else {
            prediction[key] = row[config.column];
        }
    }

    return prediction;
};
const parsePredictionTranslationFromRow = (
    row: string[],
): SheetsPredictionTranslation => {
    const prediction = {} as any;

    for (const [key, config] of Object.entries(translationParseConfig)) {
        if (config.optional && !row[config.column]) {
            continue;
        }
        if (config.fromCell) {
            prediction[key] = config.fromCell(row[config.column]);
        } else {
            prediction[key] = row[config.column];
        }
    }

    return prediction;
};

const parsePredictionOptions = (
    firstOptionText: string,
    optionRows: string[][],
    optionIndex: number,
): OptionCreateDto[] => {
    const options = [
        {
            answeringText: firstOptionText,
            number: 1,
            text: firstOptionText,
        },
        ...optionRows.map((row, i) => ({
            answeringText: row[optionIndex],
            number: i + 2,
            text: row[optionIndex],
        })),
    ];

    return options;
};

const parsePrediction = ([firstRow, ...rest]: string[][]): {
    prediction: SheetsPrediction;
    nextRowsToParse: string[][];
} => {
    const prediction = parsePredictionFromRow(firstRow);
    const nextRowsIndex = rest.findIndex(
        rowIsNotQuestionOption(QUESTION_OPTION_INDEX),
    );
    const optionRows =
        nextRowsIndex === -1 ? rest : rest.slice(0, nextRowsIndex);

    const options = parsePredictionOptions(
        firstRow[QUESTION_OPTION_INDEX],
        optionRows,
        QUESTION_OPTION_INDEX,
    );

    return {
        nextRowsToParse: nextRowsIndex === -1 ? [] : rest.slice(nextRowsIndex),
        prediction: { ...prediction, options },
    };
};
const parsePredictionTranslation = ([firstRow, ...rest]: string[][]): {
    predictionTranslation: SheetsPredictionTranslation;
    nextRowsToParse: string[][];
} => {
    const predictionTranslation = parsePredictionTranslationFromRow(firstRow);
    const nextRowsIndex = rest.findIndex(
        rowIsNotQuestionOption(TRANSLATION_QUESTION_OPTION_INDEX),
    );
    const optionRows =
        nextRowsIndex === -1 ? rest : rest.slice(0, nextRowsIndex);

    const options = parsePredictionOptions(
        firstRow[TRANSLATION_QUESTION_OPTION_INDEX],
        optionRows,
        TRANSLATION_QUESTION_OPTION_INDEX,
    );

    return {
        nextRowsToParse: nextRowsIndex === -1 ? [] : rest.slice(nextRowsIndex),
        predictionTranslation: { ...predictionTranslation, options },
    };
};

const isRowEmpty = (row: string[]) =>
    row.length === 0 || row.every((cell) => cell === '');

const getTranslation = ({
    prediction,
    translation,
}: {
    prediction: PredictionDto;
    translation: any;
}): string[][] => {
    const chunk: string[] = [prediction.number.toString()];
    for (const [key, config] of Object.entries(translationParseConfig)) {
        const propValue = (translation as any)[key];
        if (propValue !== null && propValue !== undefined) {
            chunk[config.column] = `${propValue}`;
        }
    }
    chunk[TRANSLATION_QUESTION_OPTION_INDEX] = translation
        ? translation.options[0].text
        : '';
    const restOfOptions: string[][] = [];
    translation.options.forEach((option: any, i: number) => {
        i !== 0 &&
            restOfOptions.push([
                ...new Array(TRANSLATION_QUESTION_OPTION_INDEX).fill(''),
                option.text,
            ]);
    });
    return [chunk, ...restOfOptions];
};
const getFilledRows = (rows: string[][]) => {
    return rows.slice(HEADER_ROWS_COUNT).filter((row) => !isRowEmpty(row));
};

const isRowsOfRequiredLength = ({
    minColumnLength,
    optionIndex,
    rows,
}: {
    rows: string[][];
    optionIndex: number;
    minColumnLength: number;
}) => {
    const selectedRows = rows.filter(rowIsNotQuestionOption(optionIndex));
    return selectedRows.every((row) => row.length >= minColumnLength);
};

const predictionTableTransformer: EntityTableTransformer<
    SheetsPrediction,
    PredictionDto
> = {
    fromTable: (input: EntityTableImport): SheetsPrediction[] => {
        const predictions: SheetsPrediction[] = [];

        input.forEach((element, i) => {
            if (i === 0) {
                let rowsToParse = getFilledRows(element.values);

                if (
                    !isRowsOfRequiredLength({
                        minColumnLength: MIN_PREDICTION_COLUMNS_COUNT,
                        optionIndex: QUESTION_OPTION_INDEX,
                        rows: rowsToParse,
                    })
                ) {
                    return predictions;
                }

                while (rowsToParse.length > 0) {
                    const { nextRowsToParse, prediction } =
                        parsePrediction(rowsToParse);
                    predictions.push({
                        ...prediction,
                        defaultLanguageId: element.languageId,
                    });
                    rowsToParse = nextRowsToParse;
                }
            } else {
                let rowsToParse = getFilledRows(element.values);

                if (
                    !isRowsOfRequiredLength({
                        minColumnLength: MIN_TRANSLATION_COLUMNS_COUNT,
                        optionIndex: TRANSLATION_QUESTION_OPTION_INDEX,
                        rows: rowsToParse,
                    })
                ) {
                    return predictions;
                }

                while (rowsToParse.length > 0) {
                    const { nextRowsToParse, predictionTranslation } =
                        parsePredictionTranslation(rowsToParse);
                    const predictionBaseIndex = predictions.findIndex(
                        ({ number }) => number === predictionTranslation.number,
                    );
                    if (predictionBaseIndex !== -1) {
                        if (
                            predictions[predictionBaseIndex]
                                .entityTranslations !== undefined
                        ) {
                            predictions[
                                predictionBaseIndex
                            ].entityTranslations!.push({
                                ...predictionTranslation,
                                languageCodeId: element.languageId,
                            });
                        } else {
                            predictions[
                                predictionBaseIndex
                            ].entityTranslations = [
                                {
                                    ...predictionTranslation,
                                    languageCodeId: element.languageId,
                                },
                            ];
                        }
                    }
                    rowsToParse = nextRowsToParse;
                }
            }
        });

        return predictions;
    },

    toTable: (predictions: PredictionDto[]) => {
        // Make a deep copy of predictions so that the algorithm below doesn't modify
        // its argument
        const predictionsCopy = predictions.map((prediction) => ({
            ...prediction,
            options: [...prediction.options],
        }));
        const resultTabs: EntityTableExport = [
            {
                languageId:
                    predictionsCopy[0].defaultLanguageId || LocaleIds[0],
                values: [predictionTabHeadline],
            },
        ];
        const uniqueTranslationLanguages: Set<LocaleId> = new Set();
        predictionsCopy.forEach(({ entityTranslations }) => {
            if (entityTranslations) {
                entityTranslations.forEach((et: any) => {
                    uniqueTranslationLanguages.add(et.languageCodeId);
                });
            }
        });
        for (const prediction of predictionsCopy) {
            const predictionAsArray = [];
            for (const [key, config] of Object.entries(predictionParseConfig)) {
                const propValue = (prediction as any)[key];
                if (propValue !== null && propValue !== undefined) {
                    predictionAsArray[config.column] = `${propValue}`;
                }
            }
            if (
                prediction.entityTranslations &&
                prediction.entityTranslations.length
            ) {
                uniqueTranslationLanguages.forEach((lang) => {
                    const translation =
                        prediction.entityTranslations &&
                        prediction.entityTranslations.length &&
                        prediction.entityTranslations.find(
                            (e) => e.languageCodeId === lang,
                        );
                    if (translation) {
                        const chunk: string[][] = getTranslation({
                            prediction,
                            translation,
                        });
                        const tabIndex = resultTabs.findIndex(
                            (el) => el.languageId === lang,
                        );
                        if (tabIndex !== -1) {
                            resultTabs[tabIndex].values.push(...chunk);
                        } else {
                            resultTabs.push({
                                languageId: lang,
                                values: [translationTabHeadline, ...chunk],
                            });
                        }
                    }
                });
            }
            predictionAsArray[QUESTION_OPTION_INDEX] =
                prediction.options[0].text;

            resultTabs[0].values = [...resultTabs[0].values, predictionAsArray];
            const remainingPredictionOptions =
                getPredictionOptionArray(prediction);
            if (!remainingPredictionOptions) {
                continue;
            }
            for (const optionRow of remainingPredictionOptions) {
                resultTabs[0].values = [...resultTabs[0].values, optionRow];
            }
        }
        return resultTabs;
    },
};

export default predictionTableTransformer;
