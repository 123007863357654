export const TRIVIA_MODE = {
    'Points Countdown': 'pointsCountdown',
    'Countdown Full Points': 'countdownFullPoints',
    'No Countdown Full Points': 'noCountdownFullPoints',
    'Countdown No Points': 'countdownNoPoints',
    'No Countdown No Points': 'noCountdownNoPoints',
} as const;

export type TriviaMode = (typeof TRIVIA_MODE)[keyof typeof TRIVIA_MODE];
export type TriviaModeLabel = keyof typeof TRIVIA_MODE;

export const triviaModeOptions: Array<{
    value: TriviaMode;
    label: TriviaModeLabel;
}> = (Object.entries(TRIVIA_MODE) as Array<[TriviaModeLabel, TriviaMode]>).map(
    ([key, value]) => ({ label: key, value }),
);

export function isNoPointsTriviaMode(triviaMode: TriviaMode): boolean {
    return (
        triviaMode === TRIVIA_MODE['Countdown No Points'] ||
        triviaMode === TRIVIA_MODE['No Countdown No Points']
    );
}

export function isNoCountdownTriviaMode(triviaMode: TriviaMode): boolean {
    return (
        triviaMode === 'noCountdownFullPoints' ||
        triviaMode === 'noCountdownNoPoints'
    );
}
