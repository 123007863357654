import {
    Button,
    Checkbox,
    DialogActions,
    FormControlLabel,
    LinearProgress,
    TextField,
    Typography,
} from '@material-ui/core';
import { StyleRules, withStyles } from '@material-ui/core/styles';
import Duration from 'duration-js';
import React, { PureComponent } from 'react';
import { unlockTimeSecondsToNiceString } from '../utils/util';
import AuthoringModal from './AuthoringModal';

const NO_LOCK_DELAY = 'noLockDelay';

interface Props {
    batchManualLockPredictions: (
        eventId: string,
        bodyData: any,
    ) => Promise<void>;
    classes: any;
    eventId: string;
    handleCloseLockModal: () => void;
    handleManualLockResponse: (success: boolean, error: any | null) => void;
    predictionIdsToLock: string[];
}

interface State {
    delayTimeSeconds: number;
    manualLockInProgress: boolean;
    noLockDelay: boolean;
}

class ManualLockModal extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            delayTimeSeconds: 30,
            manualLockInProgress: false,
            noLockDelay: false,
        };
    }

    private handleSubmit = async () => {
        const {
            batchManualLockPredictions,
            eventId,
            handleManualLockResponse,
            predictionIdsToLock,
        } = this.props;
        const { delayTimeSeconds, noLockDelay } = this.state;
        const delayTimeToUse = noLockDelay ? 5 : delayTimeSeconds;
        const bodyData = {
            delayTimeSeconds: delayTimeToUse,
            predictionIds: predictionIdsToLock,
        };

        this.setState({ manualLockInProgress: true });

        try {
            await batchManualLockPredictions(eventId, bodyData);
            handleManualLockResponse(true, null);
        } catch (error) {
            handleManualLockResponse(false, error);
        }
    };

    private handleDelayTimeChange = () => (event: any) => {
        const inputValue: string = event.target.value;
        try {
            const timeString = new Duration(inputValue);
            const timeInSeconds = timeString.seconds();
            this.setState({ delayTimeSeconds: timeInSeconds });
        } catch (error) {
            if (inputValue[inputValue.length - 1] !== 's') {
                try {
                    const timeString = new Duration(`${inputValue}s`);
                    const timeInSeconds = timeString.seconds();
                    this.setState({ delayTimeSeconds: timeInSeconds });
                } catch (error) {
                    console.warn(`duration error: ${error}`);
                }
            }
        }
    };

    private handleNoDelayCheck = () => (event: any) => {
        this.setState({ noLockDelay: event.target.checked });
    };

    public render() {
        const { classes, handleCloseLockModal, predictionIdsToLock } =
            this.props;
        const { delayTimeSeconds, manualLockInProgress, noLockDelay } =
            this.state;

        const numberToLock = predictionIdsToLock
            ? predictionIdsToLock.length
            : 0;

        const subtitle = (
            <Typography variant="subtitle1">
                Are you sure you want to lock <b>{numberToLock}</b>{' '}
                {numberToLock > 1 ? 'questions' : 'question'}
            </Typography>
        );
        return (
            <AuthoringModal
                onClose={handleCloseLockModal}
                subtitle={subtitle}
                title="Manual Lock"
            >
                <DialogActions>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={noLockDelay}
                                onChange={this.handleNoDelayCheck()}
                                value={NO_LOCK_DELAY}
                            />
                        }
                        label="No Lock Delay"
                        labelPlacement="start"
                    />

                    <TextField
                        className={classes.textField}
                        defaultValue={delayTimeSeconds}
                        disabled={noLockDelay}
                        helperText={unlockTimeSecondsToNiceString(
                            delayTimeSeconds,
                        )}
                        id="delay-time"
                        label="Delay Time"
                        margin="normal"
                        onChange={this.handleDelayTimeChange()}
                        variant="outlined"
                    />
                </DialogActions>
                <DialogActions>
                    <Button
                        color="secondary"
                        disabled={manualLockInProgress}
                        onClick={handleCloseLockModal}
                        variant="contained"
                    >
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        disabled={manualLockInProgress}
                        onClick={this.handleSubmit}
                        variant="contained"
                    >
                        {`Lock ${numberToLock} questions`}
                    </Button>
                </DialogActions>
                {manualLockInProgress && <LinearProgress />}
            </AuthoringModal>
        );
    }
}

const styles = () => ({}) as StyleRules;

export default withStyles(styles)(ManualLockModal);
