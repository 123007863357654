import styled from '@emotion/styled';
import {
    Checkbox,
    Collapse,
    FormControlLabel,
    FormGroup,
    ListItem,
    Paper,
    Typography,
} from '@material-ui/core';
import { StyleRules, withStyles } from '@material-ui/core/styles';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import React, { Component } from 'react';
import Highlighter from 'react-highlight-words';
import SearchInput, { createFilter } from 'react-search-input';
import { FixedSizeList } from 'react-window';
import { NBAGameStatistics, NBAPlayByPlay } from '../../service/AuthoringApi';

interface Props {
    classes: any;
    gameStats: NBAGameStatistics;
}

interface State {
    openLists: { [periodName: string]: boolean };
    periods: Period[];
    refreshing: boolean;
    searchTerm: string;
}

interface Period {
    isChecked: boolean;
    name: string;
    quarterType: string;
    value: number;
}

interface PlayByPlayPeriod {
    periodName: string;
    plays: NBAPlayByPlay[];
}

const PBP_TYPES = {
    freeThrowMade: 'freethrowmade',
    freeThrowMissed: 'freethrowmiss',
    rebound: 'rebound',
    threePointMade: 'threepointmade',
    threePointMiss: 'threepointmiss',
    turnover: 'turnover',
    tvTimeout: 'tvtimeout',
    twoPointMade: 'twopointmade',
    twoPointMiss: 'twopointmiss',
};

class PlayByPlay extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const { gameStats } = props;
        const { quarter } = gameStats;

        const periods = this.initializePeriods(quarter);

        this.state = {
            openLists: {},
            periods,
            refreshing: false,
            searchTerm: '',
        };
    }

    private initializePeriods = (quarter: number): Period[] => {
        const periods: Period[] = [
            {
                isChecked: true,
                name: 'Q1',
                quarterType: 'quarter',
                value: 1,
            },
            {
                isChecked: false,
                name: 'Q2',
                quarterType: 'quarter',
                value: 2,
            },
            {
                isChecked: false,
                name: 'Q3',
                quarterType: 'quarter',
                value: 3,
            },
            {
                isChecked: false,
                name: 'Q4',
                quarterType: 'quarter',
                value: 4,
            },
        ];

        // Handle OT
        if (quarter > 4) {
            for (let i = 0; i < quarter - 4; i++) {
                periods.push({
                    isChecked: false,
                    name: `OT${i + 1}`,
                    quarterType: 'overtime',
                    value: i + 1,
                });
            }
        }

        return periods;
    };

    private getPlayByPlayByPeriod = (): PlayByPlayPeriod[] | undefined => {
        const { gameStats } = this.props;
        const { periods } = this.state;
        const { playByPlayMessages } = gameStats;

        if (!playByPlayMessages) {
            return undefined;
        }

        const checkedPeriods = periods.filter((period) => period.isChecked);

        const filteredPbps: PlayByPlayPeriod[] | any[] = [];

        for (const period of checkedPeriods) {
            const filteredQuarterPlays = playByPlayMessages.filter(
                (play) =>
                    play.quarter === period.value &&
                    play.quarterType === period.quarterType,
            );
            const periodName = period.name as any;
            const filteredQuarter: PlayByPlayPeriod = {
                periodName,
                plays: filteredQuarterPlays.reverse(),
            };
            filteredPbps.push(filteredQuarter);
        }

        return filteredPbps as PlayByPlayPeriod[];
    };

    private isScoringPlay = (data: NBAPlayByPlay): boolean => {
        const { type } = data;
        return (
            type === PBP_TYPES.freeThrowMade ||
            type === PBP_TYPES.threePointMade ||
            type === PBP_TYPES.twoPointMade
        );
    };

    private handleCheckboxChange = (event: any) => {
        const { periods } = this.state;
        const checked: boolean = event.target.checked;

        for (const period of periods) {
            if (period.name === event.target.value) {
                period.isChecked = checked;
            }
        }

        this.setState({ periods });
    };

    private searchUpdated = (term: string) => {
        this.setState({
            searchTerm: term,
        });
    };

    private handlePeriodClick = (periodName: string) => () => {
        this.setState((state) => ({
            openLists: {
                ...this.state.openLists,
                [periodName]: !state.openLists[periodName],
            },
        }));
    };

    // TODO: Calculate width by width of parent
    private renderPeriodPlayByPlay = (periodData: PlayByPlayPeriod) => {
        const { searchTerm } = this.state;
        const { periodName, plays } = periodData;
        const searchWords = searchTerm.split(' ');

        let filteredPlays = plays;
        if (searchTerm !== '') {
            filteredPlays = plays.filter(createFilter(searchTerm, 'message'));
        }
        const totalListHeight = filteredPlays.length * 58;
        const listHeight = totalListHeight > 800 ? 800 : totalListHeight;
        return (
            <PeriodContainer key={periodName}>
                <PeriodHeader onClick={this.handlePeriodClick(periodName)}>
                    <Typography style={{ color: '#d4af37' }} variant="h6">
                        {periodName}
                    </Typography>
                    {this.state.openLists[periodName] ? (
                        <ExpandLess />
                    ) : (
                        <ExpandMore />
                    )}
                </PeriodHeader>
                <Collapse
                    in={this.state.openLists[periodName]}
                    timeout="auto"
                    unmountOnExit={true}
                >
                    <FixedSizeList
                        height={listHeight}
                        itemCount={filteredPlays.length}
                        itemData={[filteredPlays, searchWords]}
                        itemSize={58}
                        overscanCount={4}
                        width={776}
                    >
                        {this.renderRow}
                    </FixedSizeList>
                </Collapse>
            </PeriodContainer>
        );
    };

    private renderRow = (params: {
        index: number;
        data: any[];
        style: any;
    }) => {
        const { classes } = this.props;
        const { data, index, style } = params;
        const play = data[0][params.index];
        const searchWords = data[1];
        return (
            <ListItem
                className={classes.rowContainer}
                key={index}
                style={{
                    ...style,
                    backgroundColor:
                        index % 2 === 0 ? 'rgba(150, 150, 150, 0.1)' : '#fff',
                }}
            >
                <PlayByPlayRowContainer>
                    <Typography className={classes.rowTime} variant="caption">
                        {play.gameTime}:&nbsp;
                    </Typography>
                    <Typography
                        className={classes.rowMessage}
                        variant={
                            this.isScoringPlay(play) ? 'subtitle2' : 'body2'
                        }
                    >
                        <Highlighter
                            autoEscape={true}
                            searchWords={searchWords}
                            textToHighlight={play.message}
                        />
                    </Typography>
                    <Typography className={classes.rowScore} variant="button">
                        ({play.score})
                    </Typography>
                </PlayByPlayRowContainer>
            </ListItem>
        );
    };

    public render() {
        const { classes } = this.props;
        const { periods } = this.state;

        const playByPlayPeriodData = this.getPlayByPlayByPeriod();

        return (
            <Paper className={classes.mainContainer}>
                <SearchInput
                    className={`${classes.searchInputContainer} pbp-search-input`}
                    onChange={this.searchUpdated}
                    placeholder="Filter"
                    throttle={500}
                />
                <FormGroup row={true}>
                    {periods.map((period) => (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={period.isChecked}
                                    onChange={this.handleCheckboxChange}
                                    value={period.name}
                                />
                            }
                            key={period.name}
                            label={period.name}
                        />
                    ))}
                </FormGroup>
                {playByPlayPeriodData &&
                    playByPlayPeriodData.map((period: PlayByPlayPeriod) =>
                        this.renderPeriodPlayByPlay(period),
                    )}
            </Paper>
        );
    }
}

const PeriodContainer = styled.div``;

const PeriodHeader = styled.div`
    align-items: center;
    background-color: #4b2e83;
    color: #d4af37;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    margin: 15px 0px;
    padding: 0px 10px;
    &:hover {
        opacity: 0.7;
    }
`;

const PlayByPlayRowContainer = styled.div`
    display: flex;
    width: 100%;
`;

const styles = () =>
    ({
        mainContainer: {
            padding: '10px',
        },
        rowContainer: {
            padding: '0px 10px',
        },
        rowMessage: {
            flex: 8,
            padding: '0px 5px',
        },
        rowScore: {
            alignSelf: 'center',
            color: '#565656',
            display: 'flex',
            flex: 1,
            justifyContent: 'flex-end',
            whiteSpace: 'nowrap',
        },
        rowTime: {
            alignSelf: 'center',
            flex: 1,
            whiteSpace: 'nowrap',
        },
        searchInputContainer: {
            display: 'inline-block',
            height: '30px',
            marginBottom: '10px',
            marginRight: '8px',
        },
    }) as StyleRules;

export default withStyles(styles)(PlayByPlay);
