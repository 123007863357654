export const PREDICTION_AD_RENDER_TIME = {
    'After Prediction': 'after prediction',
    'Right Away': 'right away',
} as const;

export type PredictionAdRenderTime =
    (typeof PREDICTION_AD_RENDER_TIME)[keyof typeof PREDICTION_AD_RENDER_TIME];
export type PredictionAdRenderTimeLabel =
    keyof typeof PREDICTION_AD_RENDER_TIME;

export const predictionAdRenderTimeOptions: Array<{
    value: PredictionAdRenderTime;
    label: PredictionAdRenderTimeLabel;
}> = (
    Object.entries(PREDICTION_AD_RENDER_TIME) as Array<
        [PredictionAdRenderTimeLabel, PredictionAdRenderTime]
    >
).map(([key, value]) => ({ label: key, value }));
