import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import green from '@material-ui/core/colors/green';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
// import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import AddIcon from '@material-ui/icons/Add';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
    SelectValidator,
    TextValidator,
    ValidatorForm,
} from 'react-material-ui-form-validator';
import { v4 as uuid } from 'uuid';
import { NBA_GAME_TIME_REGEX_STRING } from '../service/Constants';
import { LocaleIds } from '../service/Dto';
import { FORM_INPUT_WIDTH } from '../utils/util';
import IGameIframeUrlQueryParamsInput from './IGameIframeUrlQueryParams';
import TabPanel from './TabPanel';
import ValidatedDateTimePicker from './ValidatedDateTimePicker';
import ValidatedTimePicker from './ValidatedTimePicker';

const styles = (theme) => ({
    buttonProgress: {
        color: green[500],
        left: '50%',
        marginLeft: -12,
        marginTop: -12,
        position: 'absolute',
        top: '50%',
    },
    buttonSuccess: {
        '&:hover': {
            backgroundColor: green[700],
        },
        backgroundColor: green[500],
    },
    formHeading: {
        marginTop: '15px',
    },
    inputHalfWidth: {
        '@media (max-width:620px)': {
            marginRight: '0px',
            width: '100%',
        },
        marginRight: '5px',
        width: '49%',
    },
    inputThirdWidth: {
        '@media (max-width:620px)': {
            marginRight: '0px',
            width: '100%',
        },
        marginRight: '5px',
        width: '31%',
    },
    wrapper: {
        margin: theme.spacing(),
        position: 'relative',
    },
});

function cleanObjectValues(obj) {
    const newObject = {};
    Object.keys(obj).forEach((key) => {
        if (obj[key] !== undefined && obj[key] !== null) {
            if (obj[key].trim && obj[key].trim() === '') {
                newObject[key] = null;
            } else if (obj[key].trim) {
                newObject[key] = obj[key].trim();
            } else {
                newObject[key] = obj[key];
            }
        }
        // date objects want to be null for unset. Undefined makes the date
        // today and empty string is invalid.
        else if (obj[key] === null) {
            newObject[key] = obj[key];
        }
    });
    return newObject;
}

function checkMultilineTranslationsEquality(
    originalMultiline,
    translatedMultiline,
    key,
    errorMessage,
) {
    if (
        (originalMultiline && !translatedMultiline) ||
        (!originalMultiline && translatedMultiline) ||
        (originalMultiline &&
            translatedMultiline &&
            originalMultiline.split('\n').length !==
                translatedMultiline.split('\n').length)
    ) {
        const err = new Error(errorMessage);
        err.field = key;
        throw err;
    }
}

class FormBuilder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.initialFormState(),
        };
        this.renderInputField = this.renderInputField.bind(this);
    }

    static propTypes = {
        fields: PropTypes.array.isRequired,
        label: PropTypes.string.isRequired,
        notifyError: PropTypes.func.isRequired,
        notifySuccess: PropTypes.func.isRequired,
        onClose: PropTypes.func,
        onSubmit: PropTypes.func.isRequired,
        startingValue: PropTypes.object,
        supportedLanguages: PropTypes.array.isRequired,
        translationFields: PropTypes.array,
    };

    static defaultProps = {
        translationFields: [],
    };

    assignFormRef = (el) => (this.form = el);

    buildArtificialEventHandleChange = (name) => (value) =>
        this.handleChange({ target: { name, value } });

    handleCheckboxChange = (event) => {
        const target = event.target;
        const { formValues } = this.state;
        formValues[target.name] = target.checked;
        this.setState({ formValues });
    };

    handleChange = (event) => {
        const { fieldErrors, formValues, tabs } = this.state;
        const { name, value } = event.target;
        const fieldsMeta = [];
        tabs.forEach(({ fields }) => {
            fieldsMeta.push(...fields);
        });
        const fieldMeta = fieldsMeta.find((f) => f.name === name);
        const previousValue = formValues[name];

        formValues[name] = value;
        fieldErrors[name] = undefined;

        if (fieldMeta.onChange) {
            // note: can modify the formValues, fieldErrors objects
            fieldMeta.onChange({
                fieldErrors,
                formValues,
                name,
                newValue: value,
                previousValue,
            });
        }
        this.setState({
            fieldErrors,
            formValues,
        });
    };

    handleIGameIframeUrlQueryParamsInputChange = (event) => {
        const { formValues } = this.state;
        const { name, value } = event;

        formValues[name] = value;

        this.setState({
            formValues,
        });
    };

    handleLanguageChange = (event) => {
        const { fieldErrors, formValues, tabs } = this.state;
        const { name, value } = event.target;
        const fieldsMeta = [];
        tabs.forEach(({ fields }) => {
            fieldsMeta.push(...fields);
        });
        const fieldMeta = fieldsMeta.find((f) => f.name === name);
        const previousValue = formValues[name];

        formValues[name] = value;
        fieldErrors[name] = undefined;

        if (fieldMeta.onChange) {
            // note: can modify the formValues object
            fieldMeta.onChange({
                formValues,
                name,
                newValue: value,
                previousValue,
            });
        }
        const uuidLength = 36;
        const tabsUpdate = tabs.map((tab, i) => {
            if (
                name.slice(-uuidLength) === tab.tabIdentity ||
                (name === 'defaultLanguageId' && i === 0)
            ) {
                return {
                    ...tab,
                    label: `${tab.label.split(':')[0]}: ${value}`,
                    selectedLanguage: value,
                };
            }
            return tab;
        });

        this.setState({
            fieldErrors,
            formValues,
            tabs: tabsUpdate,
        });
    };

    handleClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return;
        }
        const { onClose } = this.props;
        this.setState({ ...this.initialFormState() });
        onClose();
    };

    static removeHeadingFields(cleanedFormState, fields) {
        const result = { ...cleanedFormState };

        const fieldNamesOfTypeHeading = fields
            .filter(({ type }) => type === 'heading')
            .map((field) => field.name);

        Object.keys(cleanedFormState).forEach((key) => {
            if (fieldNamesOfTypeHeading.includes(key)) {
                delete result[key];
            }
        });

        return result;
    }

    handleSubmit = async () => {
        const {
            fields: inputFields,
            notifyError,
            notifySuccess,
            onSubmit,
            presubmitTransform,
        } = this.props;
        const fields = this.state.formValues;
        this.setState({ lastSavedTime: Date.now(), saving: true }, async () => {
            try {
                const cleanedFormState = cleanObjectValues(
                    this.state.formValues,
                );

                const formStateWithoutHeadings =
                    FormBuilder.removeHeadingFields(
                        cleanedFormState,
                        inputFields,
                    );
                const transformedFormState = presubmitTransform
                    ? presubmitTransform(formStateWithoutHeadings)
                    : formStateWithoutHeadings;
                const translationOptionsMultiline = Object.keys(
                    transformedFormState,
                ).filter((key) =>
                    key.startsWith('translationOptionsMultiline'),
                );
                translationOptionsMultiline &&
                    translationOptionsMultiline.length &&
                    translationOptionsMultiline.forEach((tom) =>
                        checkMultilineTranslationsEquality(
                            transformedFormState.optionsMultiline,
                            transformedFormState[tom],
                            tom,
                            'Options length in translations and in default language should be the same.',
                        ),
                    );
                const translationPrizes = Object.keys(
                    transformedFormState,
                ).filter((key) => key.startsWith('translation.prizes.'));
                translationPrizes &&
                    translationPrizes.length &&
                    translationPrizes.forEach((tp) =>
                        checkMultilineTranslationsEquality(
                            transformedFormState.prizes,
                            transformedFormState[tp],
                            tp,
                            'Prizes length in translations and in default language should be the same.',
                        ),
                    );
                const translationEventMilestones = Object.keys(
                    transformedFormState,
                ).filter((key) =>
                    key.startsWith('translation.eventMilestones.'),
                );
                translationEventMilestones &&
                    translationEventMilestones.length &&
                    translationEventMilestones.forEach((tem) =>
                        checkMultilineTranslationsEquality(
                            transformedFormState.eventMilestones,
                            transformedFormState[tem],
                            tem,
                            'Milestones length in translations and in default language should be the same.',
                        ),
                    );
                const { fieldErrors } = this.state;

                if (Object.keys(fieldErrors).some((key) => fieldErrors[key])) {
                    throw new Error(
                        'Please check all fields again. There are mistakes there!',
                    );
                }

                await onSubmit(transformedFormState);

                notifySuccess('Submit successful');

                this.setState({
                    saving: false,
                    success: true,
                });
                // this.handleClose();
            } catch (error) {
                console.error('got error: ', JSON.stringify(error));
                if (
                    error.field &&
                    Object.keys(fields).find((f) => f === error.field)
                ) {
                    const { fieldErrors } = this.state;
                    fieldErrors[error.field] = error.message;
                    this.setState({
                        fieldErrors,
                        saving: false,
                        success: false,
                    });
                } else {
                    const errorString = error.message || JSON.stringify(error);
                    notifyError(errorString);
                    this.setState({
                        error,
                        saving: false,
                        success: false,
                    });
                }
            }
        });
    };

    initialFormState() {
        const { fields, startingValue, translationFields } = this.props;
        const { lockDescription, lockType } = startingValue;
        // Handles case of editing a locked MANUAL question.
        if (lockType === 'AUTOMATIC' && lockDescription && startingValue) {
            startingValue.lockType = 'MANUAL';
        }
        const executedStartingValues = {};
        Object.keys(startingValue).forEach((key) => {
            if (typeof startingValue[key] === 'function') {
                executedStartingValues[key] = startingValue[key](startingValue);
            } else {
                executedStartingValues[key] = startingValue[key];
            }
        });
        const formValues = executedStartingValues
            ? {
                  ...startingValue,
                  defaultLanguageId:
                      startingValue.defaultLanguageId || LocaleIds[0],
              }
            : this.defaultStartingValue();
        let translationTabs = [];
        if (
            startingValue.entityTranslations &&
            startingValue.entityTranslations.length
        ) {
            translationTabs = startingValue.entityTranslations.map(
                (element) => {
                    const ident = element.languageIdentity || uuid();
                    const fields = translationFields.map((field) => ({
                        ...field,
                        name:
                            field.type === 'heading'
                                ? field.name
                                : `${field.name}.${ident}`,
                    }));
                    if (element.options && element.options.length) {
                        element.options.forEach((opt, i) => {
                            element[`option${i + 1}`] = opt.answeringText;
                        });
                    }
                    // TODO: why is it here but not in the preformRenderTransform ?
                    if (Array.isArray(element.prizes)) {
                        element.prizes = element.prizes.join('\n');
                    }
                    if (
                        Array.isArray(element.eventMilestones) &&
                        element.eventMilestones.length
                    ) {
                        element.eventMilestones =
                            element.eventMilestones.join('\n');
                    }
                    for (const [key, value] of Object.entries(element)) {
                        formValues[`translation.${key}.${ident}`] = value;
                    }
                    return {
                        fields,
                        label: `Translation: ${element.languageCodeId}`,
                        selectedLanguage: element.languageCodeId,
                        tabIdentity: ident,
                    };
                },
            );
        }

        const tabIdentity = uuid();
        const tabs = [
            {
                fields,
                label: `Default language: ${formValues.defaultLanguageId}`,
                selectedLanguage: formValues.defaultLanguageId,
                tabIdentity,
            },
            ...translationTabs,
        ];

        return {
            fieldErrors: {},
            formValues,
            tabIndex: 0,
            tabs,
        };
    }

    defaultStartingValue = () => {
        const { fields } = this.props;
        const object = {};
        fields.forEach((field) => {
            object[field.name] =
                field.defaultValue === undefined ? '' : field.defaultValue;
        });
        return object;
    };

    handleTabChange = (event, newValue) => {
        this.setState({
            tabIndex: newValue,
        });
    };

    handleAddTranslation = () => {
        const { supportedLanguages, translationFields } = this.props;
        if (this.state.tabs.length < supportedLanguages.length) {
            const ident = uuid();
            const fields = translationFields.map((field) => ({
                ...field,
                name:
                    field.type === 'heading'
                        ? field.name
                        : `${field.name}.${ident}`,
            }));
            const formValues = { ...this.state.formValues };
            fields.forEach((field) => {
                formValues[field.name] = field.defaultValue;
            });
            this.setState({
                formValues,
                tabs: [
                    ...this.state.tabs,
                    {
                        fields,
                        label: 'Translation',
                        tabIdentity: ident,
                    },
                ],
            });
        }
    };

    handleTranslationDeletion(event) {
        console.log(event, 'deleted!');
    }

    renderInputField({
        helperTextFunc,
        label: labelFuncOrString,
        name,
        options,
        props = {},
        required,
        shouldDisplay,
        tabIdentity,
        type,
        validators,
    }) {
        const { classes } = this.props;
        const { fieldErrors, formValues } = this.state;

        if (shouldDisplay && !shouldDisplay(formValues)) {
            return;
        }

        if (type === 'heading') {
            return (
                <Typography
                    className={classes.formHeading}
                    color="primary"
                    key={name}
                    variant="h6"
                >
                    {name}
                </Typography>
            );
        }

        let label;
        if (typeof labelFuncOrString === 'function') {
            label = labelFuncOrString(formValues);
        } else if (typeof labelFuncOrString === 'string') {
            label = labelFuncOrString;
        }

        if (type === 'hidden') {
            const val = formValues[name] === null ? '' : formValues[name];
            return <input key={name} name={name} type="hidden" value={val} />;
        }

        const widthClass = props.formwidthpercent
            ? props.formwidthpercent === FORM_INPUT_WIDTH.third
                ? classes.inputThirdWidth
                : classes.inputHalfWidth
            : '';

        const materialUiCommonProps = {
            ...(required && {
                errorMessages: ['this field is required'],
                required: true,
                validators: ['required'],
            }),
            ...props,
            ...(helperTextFunc && {
                helperText: helperTextFunc(
                    name,
                    formValues[name],
                    formValues,
                    tabIdentity,
                ),
            }),
            ...(fieldErrors[name] && {
                error: !!fieldErrors[name],
                helperText: fieldErrors[name],
            }),
            disabled:
                typeof props.disabled === 'function'
                    ? props.disabled(formValues)
                    : props.disabled,
            fullWidth: !props.formwidthpercent,
            key: name,
            label,
            margin: 'dense',
            name,
            value: [null, undefined].includes(formValues[name])
                ? ''
                : formValues[name],
        };

        if (type === 'datetime-local') {
            return (
                <ValidatedDateTimePicker
                    {...materialUiCommonProps}
                    clearable
                    onChange={this.buildArtificialEventHandleChange(name)}
                    value={formValues[name]}
                />
            );
        }

        if (type === 'time-local') {
            return (
                <ValidatedTimePicker
                    {...materialUiCommonProps}
                    clearable
                    onChange={this.buildArtificialEventHandleChange(name)}
                    value={formValues[name]}
                />
            );
        }

        if (type === 'select') {
            return (
                <SelectValidator
                    {...materialUiCommonProps}
                    className={widthClass}
                    onChange={this.handleChange}
                >
                    {(typeof options === 'function'
                        ? options(formValues)
                        : options
                    ).map((option) => (
                        <MenuItem
                            key={`${name}-${option.value}`}
                            value={option.value}
                        >
                            {option.label}
                        </MenuItem>
                    ))}
                </SelectValidator>
            );
        }

        if (type === 'languageSelect') {
            const selectedLanguages = this.state.tabs
                .map(({ selectedLanguage }) => selectedLanguage)
                .filter(
                    (sl) =>
                        sl !==
                        this.state.tabs[this.state.tabIndex].selectedLanguage,
                );
            const opts = options.filter(
                (opt) => !selectedLanguages.some((sl) => sl === opt.value),
            );
            return (
                <SelectValidator
                    {...materialUiCommonProps}
                    className={widthClass}
                    onChange={this.handleLanguageChange}
                    value={
                        this.state.tabs[this.state.tabIndex].selectedLanguage
                    }
                >
                    {opts.map((option) => (
                        <MenuItem
                            key={`${name}-${option.value}`}
                            value={option.value}
                        >
                            {option.label}
                        </MenuItem>
                    ))}
                </SelectValidator>
            );
        }

        if (type === 'boolean') {
            return (
                <FormControl component="fieldset" key={name}>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Switch
                                    {...props}
                                    checked={formValues[name]}
                                    margin="dense"
                                    name={name}
                                    onChange={this.handleCheckboxChange}
                                    value={name}
                                />
                            }
                            label={label}
                        />
                    </FormGroup>
                </FormControl>
            );
        }

        if (type === 'json') {
            return (
                <TextValidator
                    {...materialUiCommonProps}
                    InputProps={{
                        classes: { inputAdornedStart: 'adorned-start' },
                    }}
                    id={name}
                    multiline={true}
                    onChange={this.handleChange}
                    value={
                        typeof formValues[name] === 'object'
                            ? JSON.stringify(formValues[name], null, 4)
                            : formValues[name]
                    }
                    variant="filled"
                />
            );
        }

        if (name === 'codes') {
            return (
                <TextValidator
                    {...materialUiCommonProps}
                    autoComplete="off"
                    className={widthClass}
                    errorMessages={['Match pattern: 12 digit codes']}
                    onChange={this.handleChange}
                    type={type}
                    // use custom validators for codes
                    validators={validators}
                />
            );
        }

        if (name === 'autoLockTime') {
            return (
                <TextValidator
                    {...materialUiCommonProps}
                    autoComplete="off"
                    className={widthClass}
                    errorMessages={['Match pattern: 05:45, 10:30, 03:00, etc']}
                    onChange={this.handleChange}
                    type={type}
                    validators={[`matchRegexp:${NBA_GAME_TIME_REGEX_STRING}`]}
                />
            );
        }

        if (type === 'igameIframeUrlQueryParamsMapping') {
            return (
                <IGameIframeUrlQueryParamsInput
                    {...materialUiCommonProps}
                    onChange={this.handleIGameIframeUrlQueryParamsInputChange}
                />
            );
        }

        return (
            <TextValidator
                {...materialUiCommonProps}
                autoComplete="off"
                className={widthClass}
                onChange={this.handleChange}
                type={type}
            />
        );
    }

    submitForm = () => {
        this.form.submit();
    };

    render() {
        const { saving, success } = this.state;
        const {
            classes,
            label,
            startingValue,
            supportedLanguages,
            translationFields,
        } = this.props;
        const buttonClassname = classNames({
            [classes.buttonSuccess]: success,
        });
        return (
            <Dialog
                aria-labelledby="form-dialog-title"
                onClose={this.handleClose}
                open={true}
            >
                <DialogTitle id="form-dialog-title">{label}</DialogTitle>
                <DialogContent>
                    {supportedLanguages.length > 0 &&
                        translationFields.length > 0 && (
                            <Box display="flex" flexDirection="row">
                                <Tabs
                                    indicatorColor="primary"
                                    onChange={this.handleTabChange}
                                    textColor="primary"
                                    value={this.state.tabIndex}
                                >
                                    {this.state.tabs.map((el, i) => {
                                        return (
                                            <Tab
                                                key={i}
                                                label={<div>{el.label}</div>}
                                            />
                                        );
                                    })}
                                </Tabs>
                                {!startingValue.id && (
                                    <IconButton
                                        aria-label="add translation"
                                        disabled={
                                            this.state.tabs.length ===
                                            supportedLanguages.length
                                        }
                                        onClick={this.handleAddTranslation}
                                    >
                                        <AddIcon fontSize="medium" />
                                    </IconButton>
                                )}
                            </Box>
                        )}

                    {this.state.tabs.map((tab, i) => {
                        return (
                            <ValidatorForm
                                key={i}
                                onSubmit={this.handleSubmit}
                                ref={this.assignFormRef}
                            >
                                <TabPanel
                                    index={i}
                                    key={i}
                                    value={this.state.tabIndex}
                                >
                                    {tab.fields.map((field) =>
                                        this.renderInputField({
                                            ...field,
                                            tabIdentity: tab.tabIdentity,
                                        }),
                                    )}
                                </TabPanel>
                            </ValidatorForm>
                        );
                    })}
                </DialogContent>
                <DialogActions>
                    <Button
                        color="secondary"
                        disabled={saving}
                        onClick={this.handleClose}
                        variant="contained"
                    >
                        Cancel
                    </Button>
                    <div className={classes.wrapper}>
                        <Button
                            className={buttonClassname}
                            color="primary"
                            disabled={saving}
                            onClick={this.submitForm}
                            variant="contained"
                        >
                            Submit
                        </Button>
                        {saving && (
                            <CircularProgress
                                className={classes.buttonProgress}
                                size={24}
                            />
                        )}
                    </div>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(FormBuilder);
