import {
    LanguageInfo,
    LocaleId,
    LocaleIds,
    ProgressivePoll,
    ProgressivePollCreate,
} from '../../service/Dto';
import { allCellsBeforeColumnAreEmpty, attachArrays } from '../shared';
import { WithNumber, WithRequiredProperty } from '../utilityTypes';
import {
    EntityTableExport,
    EntityTableImport,
    EntityTableItem,
    EntityTableTransformer,
} from './EntityTableTransformer';
import parseEntity, { ParseConfig } from './parseEntity';

export type ProgressivePollImportBase = {
    detailsText?: string;
    notes?: string;
    options: string[];
    text: string;
};

export type ProgressivePollImportTranslation = {
    detailsText?: string;
    options: string[];
    text: string;
};

export type ProgressivePollImport = ProgressivePollImportBase &
    LanguageInfo<ProgressivePollImportTranslation>;

const progressivePollParseConfig: ParseConfig<
    WithNumber<ProgressivePollImportBase>
> = {
    number: {
        column: 0,
    },
    text: {
        column: 1,
    },
    detailsText: {
        column: 2,
    },
    options: {
        column: 3,
        shouldParseNextRow: allCellsBeforeColumnAreEmpty(3),
    },
    notes: {
        column: 4,
    },
} as const;

const progressivePollTranslationParseConfig: ParseConfig<
    WithNumber<ProgressivePollImportTranslation>
> = {
    number: {
        column: 0,
    },
    text: {
        column: 1,
    },
    detailsText: {
        column: 2,
    },
    options: {
        column: 3,
        shouldParseNextRow: allCellsBeforeColumnAreEmpty(3),
    },
};

const isRowEmpty = (row: string[]) =>
    row.length === 0 || row.every((cell) => cell === '');

const HEADER_ROWS_COUNT = 1;

const firstTabHeadlineRow = [
    'Poll #',
    'Text',
    'Details Text',
    'Option Texts',
    'Notes',
];

const restTabsHeadlineRow = ['Poll #', 'Text', 'Details Text', 'Option Texts'];

const progressivePollTableTransformer: EntityTableTransformer<
    Omit<ProgressivePollCreate, 'iterationId'>,
    ProgressivePoll
> = {
    fromTable: (
        input: EntityTableImport,
    ): Omit<ProgressivePollCreate, 'iterationId'>[] => {
        const polls: WithNumber<ProgressivePollImport>[] = [];
        const [firstTab, ...restTabs] = input;
        let rowsToParse = firstTab.values
            .slice(HEADER_ROWS_COUNT)
            .filter((row) => !isRowEmpty(row));

        while (rowsToParse.length > 0) {
            const { nextRowsToParse, parsedEntity: poll } = parseEntity<
                WithNumber<ProgressivePollImportBase>
            >(progressivePollParseConfig, rowsToParse);
            polls.push({
                ...poll,
                defaultLanguageId: firstTab.languageId,
            });
            rowsToParse = nextRowsToParse;
        }

        const pollTranslations: WithNumber<
            ProgressivePollImportTranslation & {
                languageCodeId: LocaleId;
            }
        >[] = [];

        restTabs.forEach((element) => {
            let rowsToParse = element.values
                .slice(HEADER_ROWS_COUNT)
                .filter((row) => !isRowEmpty(row));

            while (rowsToParse.length > 0) {
                const { nextRowsToParse, parsedEntity: pollTranslation } =
                    parseEntity<WithNumber<ProgressivePollImportTranslation>>(
                        progressivePollTranslationParseConfig,
                        rowsToParse,
                    );

                pollTranslations.push({
                    ...pollTranslation,
                    languageCodeId: element.languageId,
                });
                rowsToParse = nextRowsToParse;
            }
        });

        const resultWithNumber = attachArrays<
            WithNumber<ProgressivePollImport>,
            WithNumber<
                ProgressivePollImportTranslation & { languageCodeId: LocaleId }
            >
        >(
            polls,
            pollTranslations,
            (
                poll: WithNumber<ProgressivePollImport>,
                translation: WithNumber<
                    ProgressivePollImportTranslation & {
                        languageCodeId: LocaleId;
                    }
                >,
            ): WithNumber<ProgressivePollImport> => {
                return {
                    number: poll.number,
                    options: poll.options,
                    text: poll.text,
                    detailsText: poll.detailsText,
                    defaultLanguageId: poll.defaultLanguageId,
                    entityTranslations: [
                        ...(poll.entityTranslations
                            ? poll.entityTranslations
                            : []),
                        {
                            detailsText: translation.detailsText,
                            options: translation.options,
                            text: translation.text,
                            languageId: translation.languageCodeId,
                        },
                    ],
                };
            },
            (a: { number: number }, b: { number: number }) =>
                a.number === b.number,
        );

        // eslint-disable-next-line no-unused-vars
        return resultWithNumber.map(({ number, ...rest }) => ({
            ...rest,
            entityTranslations: rest.entityTranslations
                ? rest.entityTranslations.map((e) => ({
                      ...e,
                      languageCodeId: e.languageId,
                  }))
                : [],
        }));
    },

    toTable: (polls: ProgressivePoll[]) => {
        const pollWithLanguageInfo = polls.find(
            (
                poll: ProgressivePoll,
            ): poll is WithRequiredProperty<
                ProgressivePoll,
                'entityTranslations'
            > => poll.entityTranslations !== undefined,
        );
        const result: EntityTableExport = [
            {
                languageId: pollWithLanguageInfo
                    ? pollWithLanguageInfo.defaultLanguageId
                    : LocaleIds[0],
                values: [firstTabHeadlineRow],
            },
        ];

        polls.forEach((poll, index) => {
            const number = index + 1;
            const { detailsText, notes, options, text } = poll;
            const [firstOption, ...restOptions] = options;

            result[0].values.push([
                number,
                text,
                detailsText || '',
                firstOption.text,
                notes || '',
            ]);

            restOptions.forEach((option) =>
                result[0].values.push(['', '', '', option.text]),
            );

            if (poll.entityTranslations) {
                poll.entityTranslations.forEach((et) => {
                    let correspondingTab: EntityTableItem<string | number>;

                    const existingCorrespondingTab = result.find(
                        ({ languageId }) => languageId === et.languageCodeId,
                    );

                    if (!existingCorrespondingTab) {
                        correspondingTab = {
                            languageId: et.languageCodeId,
                            values: [restTabsHeadlineRow],
                        };
                        result.push(correspondingTab);
                    } else {
                        correspondingTab = existingCorrespondingTab;
                    }

                    const [firstEtOption, ...restEtOptions] = et.options;
                    correspondingTab.values.push([
                        number,
                        et.text,
                        et.detailsText || '',
                        firstEtOption.text,
                    ]);
                    restEtOptions.forEach((option: any) => {
                        correspondingTab.values.push(['', '', '', option.text]);
                    });
                });
            }
        });
        return result;
    },
};

export default progressivePollTableTransformer;
