import Paper from '@material-ui/core/Paper';
import {
    createTheme,
    MuiThemeProvider,
    withStyles,
} from '@material-ui/core/styles';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import MuiDataTable from 'mui-datatables';
import React, { useState } from 'react';
import ActionButton from '../../components/ActionButton';
import BreadcrumbBar from '../../components/BreadcrumbBar';
import { PageHeadline } from '../../components/CrudPage';
import SheetImportExportDialog from '../../components/SheetImportExportDialog';
import { PartnerDto, Venue, VenueCreate } from '../../service/Dto';
import GoogleSheetsApi, {
    GoogleSheetsApiError,
} from '../../service/GoogleSheetsApi';
import { shouldIgnoreMuiTableRowClick } from '../../utils/tables/util';
import venueTableTransformer from '../../utils/tables/venueTableTransformer';

const styles = (theme: any) => ({
    paper: {
        padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
    },
});

type Props = {
    partner: PartnerDto;
    venues: Venue[];
    batchCreateVenues: ({
        partnerId,
        venues,
    }: {
        partnerId: string;
        venues: VenueCreate[];
    }) => Promise<void>;
    refreshData: () => Promise<void>;
    notifyError: (message: string) => void;
    notifySuccess: (message: string) => void;

    classes: any;
};

const tableColumns = [
    {
        label: 'Venue ID',
        name: 'id',
        options: {
            display: false,
            filter: false,
        },
    },
    {
        label: 'Name',
        name: 'name',
        options: {
            display: true,
            filter: false,
            sort: true,
        },
    },
    {
        label: 'Latitude',
        name: 'lat',
        options: {
            display: true,
            filter: false,
            sort: true,
        },
    },
    {
        label: 'Longitude',
        name: 'long',
        options: {
            display: true,
            filter: false,
            sort: true,
        },
    },
    {
        label: 'Address',
        name: 'address',
        options: {
            display: true,
            filter: false,
            sort: true,
        },
    },
    {
        label: 'Zip Code',
        name: 'zipCode',
        options: {
            display: true,
            filter: false,
            sort: true,
        },
    },
    {
        label: 'City',
        name: 'city',
        options: {
            display: true,
            filter: true,
            sort: true,
        },
    },
    {
        label: 'State',
        name: 'state',
        options: {
            display: true,
            filter: true,
            sort: true,
        },
    },
    {
        label: 'Country',
        name: 'country',
        options: {
            display: true,
            filter: true,
            sort: true,
        },
    },
    {
        label: 'Partner',
        name: 'partnerId',
        options: {
            display: false,
            filter: false,
            sort: false,
        },
    },
];

const getMuiTheme = () => {
    return createTheme({
        overrides: {
            MuiSvgIcon: {
                fontSizeSmall: {
                    fontSize: '13px',
                    verticalAlign: 'middle',
                },
            },
        },
    });
};

const Venues = ({
    batchCreateVenues,
    classes,
    notifyError,
    notifySuccess,
    partner,
    refreshData,
    venues,
}: Props) => {
    const [showImportExportModal, setShowImportExportModal] = useState(false);
    const [isImportingExporting, setIsImportingExporting] = useState(false);

    const handleExport = async (googleSheetId: string) => {
        setIsImportingExporting(true);

        const gapi = new GoogleSheetsApi();
        await gapi.initialize();

        const table = venueTableTransformer.toTable(venues);

        try {
            await gapi.writeToSheet(googleSheetId, table);
            notifySuccess('Succesfully exported venues');
        } catch (error) {
            if (error instanceof GoogleSheetsApiError) {
                notifyError(`Error exporting venues: ${error.status}`);
            } else {
                notifyError(`Error venues: ${error}`);
            }
        }

        setIsImportingExporting(false);
        setShowImportExportModal(false);
    };

    const handleImport = async (googleSheetId: string) => {
        setIsImportingExporting(true);

        const gapi = new GoogleSheetsApi();
        await gapi.initialize();

        try {
            const response = await gapi.readSheet(googleSheetId);

            const venuesToCreate = venueTableTransformer.fromTable(response);

            await batchCreateVenues({
                partnerId: partner.id,
                venues: venuesToCreate,
            });
            notifySuccess('Succesfully imported venues');
        } catch (error) {
            if (error instanceof GoogleSheetsApiError) {
                notifyError(`Error exporting venues: ${error.status}`);
            } else if (error instanceof Error) {
                const message = `Error importing venues: ${error.message}`;
                notifyError(message);
            }
        }
        await refreshData();
        setIsImportingExporting(false);
        setShowImportExportModal(false);
    };

    const breadCrumbs = [
        { link: '/partners', text: 'Partners' },
        { link: '', text: `${partner.id}'s venues` },
    ];

    const options = {
        filterType: 'dropdown',
        onRowClick: (__: string[], _: any, event: any) => {
            if (shouldIgnoreMuiTableRowClick(event)) {
                return;
            }
        },
        resizableColumns: true,
        responsive: 'simple',
        rowsPerPage: 50,
        rowsPerPageOptions: [10, 20, 30, 40, 50, 75, 100],
    };

    return (
        <div>
            <div>
                <Paper className={classes.paper}>
                    <BreadcrumbBar breadcrumbs={breadCrumbs} />
                    <PageHeadline headline={`${partner.id}: Venues`} />
                </Paper>
            </div>
            <MuiThemeProvider theme={getMuiTheme()}>
                <MuiDataTable
                    columns={tableColumns}
                    data={venues}
                    options={options}
                />
            </MuiThemeProvider>
            <ActionButton
                actions={[
                    {
                        icon: <KeyboardArrowUp />,
                        name: 'Import / Export venues',
                        onClick: () => {
                            setShowImportExportModal(true);
                        },
                    },
                ]}
            />
            {showImportExportModal && (
                <SheetImportExportDialog
                    helperText="Make sure you do not import the venues you already imported!"
                    isImportingExporting={isImportingExporting}
                    onClose={() => setShowImportExportModal(false)}
                    onExport={handleExport}
                    onImport={handleImport}
                />
            )}
        </div>
    );
};

export default withStyles(styles)(Venues);
