import { makeStyles } from '@material-ui/core/styles';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import React, { ReactNode, useCallback, useState } from 'react';

export interface SpeedDialActionInfo {
    icon: ReactNode;
    onClick: () => void;
    tooltipTitle: string;
}
interface Props {
    actions: SpeedDialActionInfo[];
}

const useStyles = makeStyles((theme: any) => ({
    speedDial: {
        bottom: theme.spacing(2),
        margin: theme.spacing(),
        position: 'fixed',
        right: theme.spacing(2),
    },
}));

export default function DefaultSpeedDial(props: Props) {
    const classes = useStyles();
    const { actions } = props;
    const [isOpened, setIsOpened] = useState(false);

    const onDialClick = useCallback(() => {
        setIsOpened((prevIsOpened) => !prevIsOpened);
    }, []);
    function open() {
        setIsOpened(true);
    }
    function close() {
        setIsOpened(false);
    }

    return (
        <SpeedDial
            ariaLabel="action-list"
            className={classes.speedDial}
            icon={<SpeedDialIcon />}
            onBlur={close}
            onClick={onDialClick}
            onFocus={open}
            onMouseEnter={open}
            onMouseLeave={close}
            open={isOpened}
        >
            {actions.map((action) => (
                <SpeedDialAction
                    icon={action.icon}
                    key={action.tooltipTitle}
                    onClick={action.onClick}
                    tooltipTitle={action.tooltipTitle}
                />
            ))}
        </SpeedDial>
    );
}
