import {
    LanguageInfo,
    LocaleId,
    LocaleIds,
    TriviaOption,
    TriviaQuestion,
} from '../../service/Dto';
import { allCellsBeforeColumnAreEmpty, attachArrays } from '../shared';
import { WithNumber, WithRequiredProperty } from '../utilityTypes';
import {
    EntityTableExport,
    EntityTableImport,
    EntityTableItem,
    EntityTableTransformer,
} from './EntityTableTransformer';
import parseEntity, { ParseConfig } from './parseEntity';
import { parseDecimalInt } from './util';

export type TriviaQuestionImportBase = {
    text: string;
    correctOption: string;
    optionTexts: string[];
    optionImageUrls: string[];

    adIframeHtml?: string;
    adHeadline?: string;
    adDisclaimer?: string;
};

export type TriviaQuestionImportTranslation = {
    text: string;

    optionTexts: string[];
    optionImageUrls: string[];

    adIframeHtml?: string;
    adHeadline?: string;
    adDisclaimer?: string;
};

export type TriviaQuestionImport = TriviaQuestionImportBase & {
    languageInfo?: LanguageInfo<TriviaQuestionImportTranslation>;
};

const triviaQuestionParseConfig: ParseConfig<WithNumber<TriviaQuestionImport>> =
    {
        correctOption: { column: 2 },
        number: {
            column: 0,
            fromCell: parseDecimalInt,
        },
        optionTexts: {
            column: 3,
            shouldParseNextRow: allCellsBeforeColumnAreEmpty(3),
        },
        optionImageUrls: {
            column: 4,
            shouldParseNextRow: allCellsBeforeColumnAreEmpty(3),
        },
        text: {
            column: 1,
        },
        adIframeHtml: {
            column: 5,
            optional: true,
        },
        adHeadline: {
            column: 6,
            optional: true,
        },
        adDisclaimer: {
            column: 7,
            optional: true,
        },
    } as const;

const triviaQuestionTranslationParseConfig: ParseConfig<
    WithNumber<TriviaQuestionImportTranslation>
> = {
    number: {
        column: 0,
        fromCell: parseDecimalInt,
    },
    optionTexts: {
        column: 2,
        shouldParseNextRow: allCellsBeforeColumnAreEmpty(2),
    },
    optionImageUrls: {
        column: 3,
        shouldParseNextRow: allCellsBeforeColumnAreEmpty(2),
    },
    text: {
        column: 1,
    },
    adIframeHtml: {
        column: 4,
        optional: true,
    },
    adHeadline: {
        column: 5,
        optional: true,
    },
    adDisclaimer: {
        column: 6,
        optional: true,
    },
};

const isRowEmpty = (row: string[]) =>
    row.length === 0 || row.every((cell) => cell === '');

const HEADER_ROWS_COUNT = 1;

const firstTabHeadlineRow = [
    'Question #',
    'Text',
    'Correct option',
    'Option Texts',
    'Option Images',
    'Ad Iframe HTML',
    'Ad Headline',
    'Ad Disclaimer',
];

const restTabsHeadlineRow = [
    'Question #',
    'Text',
    'Option Texts',
    'Option Images',
    'Ad Iframe HTML',
    'Ad Headline',
    'Ad Disclaimer',
];

const triviaQuestionTableTransformer: EntityTableTransformer<
    TriviaQuestionImport,
    TriviaQuestion
> = {
    fromTable: (input: EntityTableImport): TriviaQuestionImport[] => {
        const questions: WithNumber<TriviaQuestionImport>[] = [];
        const [firstTab, ...restTabs] = input;
        let rowsToParse = firstTab.values
            .slice(HEADER_ROWS_COUNT)
            .filter((row) => !isRowEmpty(row));

        while (rowsToParse.length > 0) {
            const { nextRowsToParse, parsedEntity: question } = parseEntity<
                WithNumber<TriviaQuestionImport>
            >(triviaQuestionParseConfig, rowsToParse);
            questions.push({
                ...question,
                languageInfo: {
                    defaultLanguageId: firstTab.languageId,
                },
            });
            rowsToParse = nextRowsToParse;
        }

        const questionTranslations: WithNumber<
            TriviaQuestionImportTranslation & { languageId: LocaleId }
        >[] = [];

        restTabs.forEach((element) => {
            let rowsToParse = element.values
                .slice(HEADER_ROWS_COUNT)
                .filter((row) => !isRowEmpty(row));

            while (rowsToParse.length > 0) {
                const { nextRowsToParse, parsedEntity: questionTranslation } =
                    parseEntity<WithNumber<TriviaQuestionImportTranslation>>(
                        triviaQuestionTranslationParseConfig,
                        rowsToParse,
                    );

                questionTranslations.push({
                    ...questionTranslation,
                    languageId: element.languageId,
                });
                rowsToParse = nextRowsToParse;
            }
        });

        const resultWithNumber = attachArrays<
            WithNumber<TriviaQuestionImport>,
            WithNumber<
                TriviaQuestionImportTranslation & { languageId: LocaleId }
            >
        >(
            questions,
            questionTranslations,
            (
                question: WithNumber<TriviaQuestionImport>,
                translation: WithNumber<
                    TriviaQuestionImportTranslation & { languageId: LocaleId }
                >,
            ): WithNumber<TriviaQuestionImport> => {
                if (question.languageInfo) {
                    return {
                        correctOption: question.correctOption,
                        languageInfo: {
                            ...question.languageInfo,
                            entityTranslations: [
                                ...(question.languageInfo.entityTranslations
                                    ? question.languageInfo.entityTranslations
                                    : []),
                                {
                                    languageId: translation.languageId,
                                    optionTexts: translation.optionTexts,
                                    optionImageUrls:
                                        translation.optionImageUrls,
                                    text: translation.text,
                                    adIframeHtml: translation.adIframeHtml,
                                    adDisclaimer: translation.adDisclaimer,
                                    adHeadline: translation.adHeadline,
                                },
                            ],
                        },
                        number: question.number,
                        optionTexts: question.optionTexts,
                        optionImageUrls: question.optionImageUrls,
                        text: question.text,
                        adIframeHtml: question.adIframeHtml,
                        adHeadline: question.adHeadline,
                        adDisclaimer: question.adDisclaimer,
                    };
                }

                return question;
            },
            (a: { number: number }, b: { number: number }) =>
                a.number === b.number,
        );

        // eslint-disable-next-line no-unused-vars
        return resultWithNumber.map(({ number, ...rest }) => rest);
    },
    toTable: (questions: TriviaQuestion[]) => {
        const questionWithLanguageInfo = questions.find(
            (
                question: TriviaQuestion,
            ): question is WithRequiredProperty<
                TriviaQuestion,
                'languageInfo'
            > => question.languageInfo !== undefined,
        );
        const result: EntityTableExport = [
            {
                languageId: questionWithLanguageInfo
                    ? questionWithLanguageInfo.languageInfo.defaultLanguageId
                    : LocaleIds[0],
                values: [firstTabHeadlineRow],
            },
        ];

        questions.forEach((question, index) => {
            const number = index + 1;
            const { ad, correctOptionId, options, text } = question;
            const [firstOption, ...restOptions] = options;

            const correctOption: TriviaOption = options.find(
                ({ id }) => id === correctOptionId,
            )!;

            result[0].values.push([
                number,
                text,
                correctOption.text || correctOption.imageUrl || '',
                firstOption.text || '',
                firstOption.imageUrl || '',
                ...(ad
                    ? [ad.iframeHtml, ad.headline || '', ad.disclaimer || '']
                    : []),
            ]);

            restOptions.forEach((option) =>
                result[0].values.push([
                    '',
                    '',
                    '',
                    option.text || '',
                    option.imageUrl || '',
                ]),
            );

            if (
                question.languageInfo &&
                question.languageInfo.entityTranslations
            ) {
                question.languageInfo.entityTranslations.forEach((et) => {
                    let correspondingTab: EntityTableItem<string | number>;

                    const existingCorrespondingTab = result.find(
                        ({ languageId }) => languageId === et.languageId,
                    );

                    if (!existingCorrespondingTab) {
                        correspondingTab = {
                            languageId: et.languageId,
                            values: [restTabsHeadlineRow],
                        };
                        result.push(correspondingTab);
                    } else {
                        correspondingTab = existingCorrespondingTab;
                    }

                    const [firstEtOption, ...restEtOptions] = et.options;
                    correspondingTab.values.push([
                        number,
                        et.text,
                        firstEtOption.text || '',
                        firstEtOption.imageUrl || '',
                        ...(ad
                            ? [
                                  ad.iframeHtml,
                                  ad.headline || '',
                                  ad.disclaimer || '',
                              ]
                            : []),
                    ]);
                    restEtOptions.forEach((option) => {
                        correspondingTab.values.push([
                            '',
                            '',
                            option.text || '',
                            option.imageUrl || '',
                        ]);
                    });
                });
            }
        });
        return result;
    },
};

export default triviaQuestionTableTransformer;
