import {
    Box,
    Button,
    DialogActions,
    FormControlLabel,
    LinearProgress,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from '@material-ui/core';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { ModalHandle } from 'src/types';
import { DeleteType, deleteTypes, UserDelete } from '../service/Dto';
import AuthoringModal from './AuthoringModal';

interface Props {
    deleteUser: (params: UserDelete & { partnerId: string }) => Promise<void>;
    onDeleteUserResponse: (success: boolean, error: Error | null) => void;
}

function DeleteUserModal(props: Props, forwardedRef: React.Ref<ModalHandle>) {
    const [partnerId, setPartnerId] = useState('');
    const [userNameOrId, setUserNameOrId] = useState('');
    const [inProgress, setInProgress] = useState(false);
    const [deleteType, setDeleteType] = useState<DeleteType>(deleteTypes[0]);

    const { deleteUser, onDeleteUserResponse: handleDeleteUserResponse } =
        props;

    const handleChangeDeleteType = (event: React.ChangeEvent<{}>) => {
        setDeleteType((event.target as HTMLInputElement).value as DeleteType);
    };

    const handleTextFieldChange = (event: any) => {
        const text = event.target.value;
        setUserNameOrId(text);
    };

    const handleSubmit = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        setInProgress(true);

        try {
            await deleteUser({
                partnerId,
                text: userNameOrId,
                type: deleteType,
            });
            handleDeleteUserResponse(true, null);
            closeModal();
        } catch (error) {
            handleDeleteUserResponse(false, error as Error);
            setInProgress(false);
        }
    };

    const closeModal = () => {
        setUserNameOrId('');
        setDeleteType('displayName');
        setInProgress(false);
        setPartnerId('');
    };

    const showModal = (partnerId: string) => {
        setPartnerId(partnerId);
    };

    useImperativeHandle(forwardedRef, () => ({
        closeModal,
        showModal,
    }));

    if (!partnerId) {
        return null;
    }
    const subtitle = (
        <Typography variant="subtitle1">
            Delete user via user ID or username.
        </Typography>
    );

    return (
        <AuthoringModal
            onClose={closeModal}
            subtitle={subtitle}
            title="Delete User"
        >
            <Box p="3">
                <form onSubmit={handleSubmit}>
                    <RadioGroup
                        onChange={handleChangeDeleteType}
                        value={deleteType}
                    >
                        <FormControlLabel
                            control={<Radio />}
                            label="Delete by display name"
                            value="displayName"
                        />
                        <FormControlLabel
                            control={<Radio />}
                            label="Delete by ID"
                            value="id"
                        />
                    </RadioGroup>
                    <TextField
                        label={
                            deleteType === 'id'
                                ? 'User ID'
                                : 'User display name'
                        }
                        margin="dense"
                        onChange={handleTextFieldChange}
                        required
                        value={userNameOrId}
                    />
                    <DialogActions>
                        <Button
                            color="secondary"
                            disabled={inProgress}
                            onClick={closeModal}
                            variant="contained"
                        >
                            Cancel
                        </Button>
                        <Button
                            color="primary"
                            disabled={inProgress}
                            type="submit"
                            variant="contained"
                        >
                            Delete
                        </Button>
                    </DialogActions>
                </form>
            </Box>
            {inProgress && <LinearProgress />}
        </AuthoringModal>
    );
}

export default forwardRef<ModalHandle, Props>(DeleteUserModal);
