import { Typography } from '@material-ui/core';
import React from 'react';
import {
    OfferDto,
    SponsorshipUnitDto,
    SponsorshipUnitType,
} from '../../service/Dto';
import AuthoringModal from '../AuthoringModal';
import CreateEditSponsorshipUnit, {
    ModeName,
} from './CreateEditSponsorshipUnit';

interface Props {
    offers: OfferDto[];
    onRequestClose: () => void;
    onSaveClick: (unit: Partial<SponsorshipUnitDto>) => void | Promise<void>;
    unitType: SponsorshipUnitType;
}

export default function CreateSponsorshipUnitModal(props: Props) {
    const { offers, onRequestClose, onSaveClick, unitType } = props;
    return (
        <AuthoringModal
            onClose={onRequestClose}
            subtitle={<Typography variant="subtitle1">{unitType}</Typography>}
            title="New sponsorship unit"
        >
            <CreateEditSponsorshipUnit
                mode={{ name: ModeName.create, unitType }}
                offers={offers}
                onSaveClick={onSaveClick}
            />
        </AuthoringModal>
    );
}
