import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { useGoogleLogin } from '@react-oauth/google';
import React, { useEffect, useRef, useState } from 'react';
import { AuthoringApi, ServerError } from '../../service/AuthoringApi';
import { isEmpty } from '../../utils/shared';

const G_API_SCOPES =
    'https://www.googleapis.com/auth/spreadsheets https://www.googleapis.com/auth/drive';

interface Props {
    api: AuthoringApi;
    setIsAuthenticated: (setIsAuthenticated: boolean) => void;
    notifyError: (message: string) => void;
}

export interface User {
    email: string;
    name: string;
    picture: string;
    given_name: string;
    family_name: string;
    locale: string;
}

function GoogleAuth({ api, notifyError, setIsAuthenticated }: Props) {
    const [basicProfile, setBasicProfile] = useState<User | null>(null);

    // eslint-disable-next-line no-undef
    const refreshIntervalId = useRef<ReturnType<typeof setInterval> | null>(
        null,
    );

    async function logout() {
        try {
            await api.logout();
            setBasicProfile(null);
            setIsAuthenticated(false);
            refreshIntervalId.current &&
                clearInterval(refreshIntervalId.current);
        } catch (err) {
            notifyError(String(err));
        }
    }

    function onAuthorize(user: User, expiryDate?: number) {
        setIsAuthenticated(true);
        setBasicProfile(user);
        setRefreshTimeout(expiryDate);
    }

    function setRefreshTimeout(expiryDate?: number) {
        const oneMin = 60 * 1000;
        const refreshMillis = oneMin * 45;
        const initialRefreshRequired =
            expiryDate && expiryDate < Date.now() + refreshMillis;

        if (initialRefreshRequired) {
            // awaiting reload auth tokens is not necessary here as the next iteration of it is always scheduled for the next 45mins
            reloadAuthTokens();
        }

        refreshIntervalId.current = setInterval(() => {
            reloadAuthTokens();
        }, refreshMillis);
    }

    async function reloadAuthTokens() {
        try {
            await api.refreshTokens();
        } catch (err) {
            notifyError(String(err));
        }
    }

    useEffect(() => {
        async function syncRequest() {
            try {
                const syncRes = await api.sync();

                if (!isEmpty(syncRes)) {
                    const { expiryDate, ...user } = syncRes;
                    onAuthorize(user, Number(expiryDate));
                }
            } catch (err) {
                notifyError(String(err));
            }
        }

        syncRequest();
    }, []);

    return basicProfile ? (
        <>
            <Button onClick={logout} style={{ color: 'white' }}>
                Logout
            </Button>
            <IconButton>
                {basicProfile && basicProfile.picture ? (
                    <Avatar src={basicProfile.picture} />
                ) : (
                    <AccountCircle />
                )}
            </IconButton>
        </>
    ) : (
        <Login api={api} notifyError={notifyError} onAuthorize={onAuthorize} />
    );
}

interface LoginProps {
    api: AuthoringApi;
    onAuthorize: (tokens: User) => void;
    notifyError: (message: string) => void;
}

function Login({ api, notifyError, onAuthorize }: LoginProps) {
    const googleLogin = useGoogleLogin({
        flow: 'auth-code',
        onError: (errorResponse) =>
            errorResponse.error_description &&
            notifyError(errorResponse.error_description),
        onSuccess: async (gAuthResponse) => {
            try {
                // Authenticate
                const user = await api.authenticate(gAuthResponse.code);
                onAuthorize(user);
            } catch (err) {
                if (err instanceof ServerError) {
                    notifyError(err.message);
                }
            }
        },
        scope: G_API_SCOPES,
    });

    return (
        <Button onClick={googleLogin} style={{ color: 'white' }}>
            Login
        </Button>
    );
}

export default GoogleAuth;
