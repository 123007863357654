export const GAME_TYPE = {
    'Prediction Game': 'PREDICTION',
    'Trivia Game': 'TRIVIA',
    IGame: 'IGAME',
    'Bingo Game': 'BINGO',
} as const;

export type GameType = (typeof GAME_TYPE)[keyof typeof GAME_TYPE];
export type GameTypeLabel = keyof typeof GAME_TYPE;

export const gameTypeOptions: Array<{
    value: GameType;
    label: GameTypeLabel;
}> = (Object.entries(GAME_TYPE) as Array<[GameTypeLabel, GameType]>).map(
    ([key, value]) => ({ label: key, value }),
);
