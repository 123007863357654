import { AdSizingPolicy } from 'src/utils/ads/adSizingPolicy';
import { PredictionAdPlacement } from 'src/utils/ads/predictionAdPlacement';
import { PredictionAdRenderTime } from 'src/utils/ads/predictionAdRenderTime';
import { GameType } from 'src/utils/event/GameType';

export const LocaleIds = [
    'EN', // English (the fallback for static messages)
    'DE', // German
    'PT', // Portuguese
    'ES', // Spanish, Castilian
    // addressing flavors that are inherently missing in ISO 639-1
    'EN-AU',
] as const;

export type LocaleId = (typeof LocaleIds)[number];

type PartnerProperties = {
    supportedLanguages?: LocaleId[];
    socialShare?: SocialShareConfig;
};

export interface PartnerDto {
    firebaseProjectId: string;
    firestoreLocation: string;
    id: string;
    name: string;
    collectEmail: boolean;
    properties: PartnerProperties;
}

export enum EventState {
    unpublished = 'unpublished',
    unlocked = 'unlocked',
    live = 'live',
    pending = 'pending',
    final = 'final',
}

export const LOCK_TYPES = {
    AUTOMATIC: 'AUTOMATIC',
    MANUAL: 'MANUAL',
} as const;

export type LOCK_TYPE = keyof typeof LOCK_TYPES;

export type IsoDateString = string;

// TODO: how do we share this with traceme shared?
// TODO: should we get rid of this?
export enum CategoryAnsweringType {
    MANUAL = 'MANUAL',
    NBA = 'NBA',
    NFL = 'NFL',
    CFB = 'CFB',
    MLB = 'MLB',
}

export type SocialShare = {
    title: string;
    description: string;
    imageUrl?: string;
};

export type SocialShareConfig = SocialShare & {
    languageInfo: LanguageInfo<SocialShare>;
};

export interface CategoryDto {
    currentEventCount: string; // TODO: string?
    description: string | null;
    disclaimerMarkdown: string | null;
    displayOrder: number;
    emoji: string | null;
    endDate: string | null;
    eventMilestones: string[] | null;
    eventStateDescriptions: { [eventState in EventState]: string } | null;
    firebaseProjectId: string;
    firestoreLocation: string;
    id: string;
    iterationNamesToShow: string[];
    name: string;
    partnerId: string;
    primaryEvent: string | null;
    reportingName: string;
    shortName: string;
    slug: string;
    startDate: IsoDateString | null;
    suppressDeadDayUi: boolean;
    type: CategoryAnsweringType;
}

export interface IterationDto {
    categoryId: string;
    categoryType: CategoryAnsweringType;
    endDate: IsoDateString | null;
    firestoreLocation: string;
    id: string;
    name: string;
    partnerId: string;
    slug: string;
    startDate: IsoDateString | null;
    socialShare: SocialShare | null;
}

export interface EventDto {
    categoryPrimary: boolean;
    description: string | null;
    endDate: IsoDateString | null;
    finalized: boolean;
    firestoreLocation: string;
    id: string;
    type: GameType;
    iterationId: string;
    multiTimeZone: boolean;
    name: string;
    partnerId: string;
    playerCount: number | null;
    prizeDetailsMarkdown: null;
    prizes: string[];
    shareName: string;
    shortName: string;
    slug: string;
    socialShare: SocialShare | null;
    startDate: IsoDateString | null;
    visible: boolean;
}

export interface OptionDto {
    answer: string | null;
    answeringId: string | null;
    answeringText: string | null;
    average: number | null;
    correct: boolean | null;
    count: number;
    id: string;
    text: string;
}

export const PredictionTypes = {
    betSim: 'BET_SIM',
    fillInTheBlanks: 'FILL_IN_THE_BLANK',
    multipleChoice: 'MULTIPLE_CHOICE',
    poll: 'POLL',
} as const;

export type PredictionType =
    (typeof PredictionTypes)[keyof typeof PredictionTypes];

export interface OptionCreateDto {
    answeringText: string;
    number: number;
    text: string;
}

export type UserDeleteByDisplayNameRequest = {
    displayName: string;
};

export type UserDeleteByIdRequest = {
    id: string;
};

export const deleteTypes = ['displayName', 'id'] as const;

export type DeleteType = (typeof deleteTypes)[number];

export type UserDelete = { type: DeleteType; text: string };

type TriviaOptionCreate = {
    text: string | null;
    imageUrl: string | null;
};

export type UntranslatableTriviaQuestion = {
    text: string;
    correctOption: string;
    options: TriviaOptionCreate[];
    ad?: TriviaQuestionAd;
};

export type TriviaQuestionEntityTranslationCreate =
    TriviaQuestionEntityTranslation;

export type TriviaQuestionCreate = UntranslatableTriviaQuestion & {
    languageInfo?: LanguageInfo<TriviaQuestionEntityTranslationCreate>;
};

type GroupBase = {
    id: string;
    name: string;
    partnerId: string;
    imageUrl: string | null;
};

export type Venue = GroupBase & {
    groupType: 'VENUE';
    lat: number;
    long: number;
    address: string;
    zipCode: string;
    city: string;
    state: string | null;
    country: string;
};

export type Team = GroupBase & {
    groupType: 'TEAM';
    iterationId: string;
};

type GroupCreateBase = {
    name: string;
    imageUrl?: string | null;
};

export type VenueCreate = GroupCreateBase & {
    address: string;
    zipCode: string;
    city: string;
    state: string | null;
    country: string;
    long: string | number;
    lat: string | number;
};

export type TeamCreate = GroupCreateBase;

export type SheetsPrediction = (
    | {}
    | {
          sponsorSlug: string;
          sponsorshipUnitName: string;
      }
) & {
    number: number;
    lockType: LOCK_TYPE;
    lockDescription?: string;
    notes?: string;
    totalPointValue: number;
    text: string;
    type: PredictionType;
    options: OptionCreateDto[];
    detailsText?: string;
    releaseMilestone?: string;
    releaseTime?: string;
    answerMilestone?: string;
    answerTime?: string;
    timeIntervalSeconds: number;
    defaultLanguageId?: LocaleId;
    allOrNothing?: boolean;
    hideOptionMetrics?: boolean;
    // ad
    adEnabled: boolean;
    adPlacement?: PredictionAdPlacement;
    adRenderTime?: PredictionAdRenderTime;
    adIframeHtml?: string;
    adSizingPolicy?: AdSizingPolicy;
    adCutOffHeight?: number;
    adHeadline?: string;
    adDisclaimer?: string;
    // translations
    entityTranslations?: SheetsPredictionTranslation[];
};
export type SheetsPredictionTranslation = {
    number: number;
    text: string;
    options: OptionCreateDto[];
    detailsText?: string;
    answerTime?: string;
    lockDescription?: string;
    releaseTime?: string;
    adHeadline?: string;
    adDisclaimer?: string;
    adIframeHtml?: string;
    subText?: string;
    languageCodeId?: LocaleId;
};

export type LanguageInfo<EntityTranslation> = {
    entityTranslations?: Array<EntityTranslation & { languageId: LocaleId }>;
    defaultLanguageId: LocaleId;
};

export type TriviaQuestionEntityTranslation<
    TriviaOptionType extends TriviaOption = TriviaOption,
> = {
    text: string;
    options: Omit<TriviaOptionType, 'id'>[];
    ad?: TriviaQuestionAd;
};

export type TriviaQuestionAd = {
    iframeHtml: string;
    headline: string | null;
    disclaimer: string | null;
};

type TextOnlyTriviaOption = {
    id: string;
    text: string;
    imageUrl: null;
};

type ImageOnlyTriviaOption = {
    id: string;
    text: null;
    imageUrl: string;
};

type TextAndImageTriviaOption = {
    id: string;
    text: string;
    imageUrl: string;
};

export type TriviaOption =
    | TextOnlyTriviaOption
    | ImageOnlyTriviaOption
    | TextAndImageTriviaOption;

export type TriviaQuestion<
    TriviaOptionType extends TriviaOption = TriviaOption,
> = {
    id: string;
    text: string;
    correctOptionId: string;
    options: TriviaOptionType[];
    bucketId: string;

    ad?: TriviaQuestionAd;
    languageInfo?: LanguageInfo<TriviaQuestionEntityTranslation>;
};

// we validate BingoSquareConfig on backend
type BingoSquareConfig = {
    interval: string;
    type: string;
    subject:
        | {
              type: 'team';
              value: string;
          }
        | {
              type: 'player';
              value: string;
          };
    number?: number;
};

export type SportRadarNBAGame = {
    id: string;
    status: 'closed' | 'scheduled' | 'inprogress';
    scheduled: IsoDateString;
    home: NBATeamInfo;
    away: NBATeamInfo;
};

type NBATeamInfo = {
    name: string;
    id: string;
};

export type BingoSquare = {
    id: string;
    eventId: string;

    text: string;
    imageUrl: string;
    occurredDate: IsoDateString | null;
    config: BingoSquareConfig;
};

export type BingoSquareCreate = Omit<
    BingoSquare,
    'id' | 'eventId' | 'occurredDate'
>;

export type BingoSquareUpdateRequest = {
    text?: string;
    imageUrl?: string;
    occurredDate?: IsoDateString;
};

export type BingoSquareSheetImport = Omit<
    BingoSquare,
    'id' | 'eventId' | 'occurredDate' | 'config'
> & {
    gameInterval: string;
    gameEventType: string;
    team: string;
    player: string;
    configNumber: string;
};

export type ProgressivePollOption = {
    id: string;
    text: string;
    number: number;
    pollId: string;
};

type ProgressivePollBase = {
    iterationId: string;
    text: string;
    detailsText?: string;
    notes?: string;
    defaultLanguageId: LocaleId;
};

export type ProgressivePoll = ProgressivePollBase & {
    id: string;
    number: number;

    options: ProgressivePollOption[];

    entityTranslations: {
        languageCodeId: LocaleId;
        detailsText?: string;
        options: Pick<ProgressivePollOption, 'id' | 'text'>[];
        text: string;
    }[];
};

export type ProgressivePollCreate = ProgressivePollBase & {
    options: string[];

    entityTranslations?: {
        detailsText?: string;
        options: string[];
        text: string;
        languageCodeId: LocaleId;
    }[];
};

export type BatchProgressivePollCreate = {
    iterationId: string;
    progressivePolls: Omit<ProgressivePollCreate, 'iterationId'>[];
};

export interface EntityTranslationOptionModel {
    text: string;
}

export type PredictionEntityTranslation = {
    languageCodeId: LocaleId;
    // if undefined, the default language one is reused
    adIframeHtml?: string;
    adDisclaimer?: string | undefined;
    adHeadline?: string | undefined;
    lockDescription?: string;
    options: EntityTranslationOptionModel[];

    // detailsText and answerExplanation are stored in Firestore to calculate subText
    detailsText: string | undefined;
    answerExplanation?: string | undefined;

    subText?: string | undefined;

    text: string;
};

export interface PredictionDto {
    answerExplanation: string | null;
    answerMilestone: string | null;
    answerTime: IsoDateString | null;
    answeringReferences: string[] | null;
    detailsText: string | null;
    eventId: string;
    firestoreLocation: string;
    id: string;
    lockDate: IsoDateString | null;
    lockDescription?: string;
    lockType: LOCK_TYPE;
    notes: string | null;
    number: number; // TODO: can this be null?
    options: OptionDto[];
    originalText: string;
    realTimePublished: boolean;
    releaseMilestone: string | null;
    releaseTime: IsoDateString | null;
    reportingStatType: string | null;
    text: string;
    timeIntervalSeconds: number;
    totalPointValue: number;
    totalUsersCount: string; // e.g. "2"
    type: PredictionType;
    visible: boolean;
    entityTranslations?: PredictionEntityTranslation[];
    defaultLanguageId?: LocaleId;
    adEnabled: boolean;
}

export enum OfferType {
    GLOBAL = 'GLOBAL',
    ONE_TIME = 'ONE_TIME',
    NO_CODE = 'NO_CODE',
}

function enumToOptions<T extends Object>(
    enumObj: T,
): { value: keyof T; label: T[keyof T] }[] {
    const entries = Object.entries(enumObj) as Array<[keyof T, T[keyof T]]>;
    return entries.map(([value, label]) => ({ label, value }));
}

export const offerCodeTypes = {
    CODE_39: 'Code 31',
    CODE_128: 'Code 128',
    EAN_8: 'EAN-8',
    EAN_13: 'EAN-13',
    ITF_14: 'ITF-14',
    QR_CODE: 'QR code',
    TEXT_ONLY: 'Text only',
    UPC_A: 'UPC-A',
} as const;

export type OfferCodeType = keyof typeof offerCodeTypes;
export type OfferCodeTypeLabel =
    (typeof offerCodeTypes)[keyof typeof offerCodeTypes];

export const offerCodeTypeOptions = enumToOptions(offerCodeTypes);

export interface OfferDto {
    codeSummary: null | {
        assignable: number;
        total: number;
    };
    displayName: string;
    expirationDate: IsoDateString | null;
    id: string;
    legalMarkdown: string | null;
    name: string;
    redemptionCtaUrl: string;
    redemptionMarkdown: string | null;
    sponsorId: string;
    type: OfferType;
    codeType: OfferCodeType;
}
export interface OfferCodeDto {
    id: string;
    data: string;
    assignable: boolean;
}

export interface SponsorDto {
    id: string;
    logoUrl: string;
    name: string;
    offers: OfferDto[];
    partnerId: string;
    units?: SponsorshipUnitDto[];
    shortName: string;
    slug: string;
}

export type UserDto = {
    id: string;
    displayName: string;
    isPro: string;
    partnerId: string;
    avatarUrl: string;
    email: string;
    phoneNumber: string;
    firestoreLocation: string;
};

type TemplateProperties = unknown;
export enum SponsorshipUnitType {
    PREDICTION = 'PREDICTION',
    EVENT = 'EVENT',
}

export interface SponsorshipUnitDto {
    created: string;
    id: string;
    modified: string;
    name: string;
    type: SponsorshipUnitType;
    templateId: string;
    properties: TemplateProperties;
    offerId?: string | null;
    bonusDescription?: string | null;
}

export interface OfferCodeListDto {
    offerId: string;
    start: number;
    count: number;
    total: number;
    codes: OfferCodeDto[];
}
