import Checkbox from '@material-ui/core/Checkbox';
import DialogActions from '@material-ui/core/DialogActions';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

const styles = () => ({
    iframeContainer: {
        display: 'block',
        margin: '0 auto',
    },
    iframeGrid: {
        '@media (max-width:620px)': {
            display: 'none',
        },
        margin: '20px auto',
    },
    iframeUrlContainer: {
        justifyContent: 'center',
    },
});

class AnswerGamecast extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);

        const storedIFrameUrl = window.localStorage.getItem('iframeUrl');

        this.state = {
            checkedIframeInput: true,
            iFrameUrl: storedIFrameUrl,
        };
    }

    handleIframeUrlChange = (e) => {
        window.localStorage.setItem('iframeUrl', e.target.value);
        this.setState({
            iFrameUrl: e.target.value,
        });
    };

    handleIframeCheckboxChange = (name) => (event) => {
        this.setState({ [name]: event.target.checked });
    };

    render() {
        const { classes } = this.props;
        const { checkedIframeInput, iFrameUrl } = this.state;
        return (
            <Grid
                className={classes.iframeGrid}
                item
                md={5}
                sm={false}
                xs={false}
            >
                <iframe
                    className={classes.iframeContainer}
                    height="750px"
                    src={iFrameUrl}
                    title="gamecast"
                    width="540px"
                />
                <DialogActions className={classes.iframeUrlContainer}>
                    {checkedIframeInput && (
                        <TextField
                            className={classes.textField}
                            id="standard-with-placeholder"
                            label="Gamecast URL"
                            margin="normal"
                            onChange={this.handleIframeUrlChange}
                            placeholder="Gamecast URL"
                        />
                    )}
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.state.checkedIframeInput}
                                onChange={this.handleIframeCheckboxChange(
                                    'checkedIframeInput',
                                )}
                                value="checkedA"
                            />
                        }
                        label="Show URL Input"
                    />
                </DialogActions>
            </Grid>
        );
    }
}

export default withStyles(styles)(AnswerGamecast);
