import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import React, { useState } from 'react';

type Props = {
    helperText?: string;
    onClose: () => void;
    onImport: (googleSheetId: string) => void;
    onExport: (googleSheetId: string) => void;
    isImportingExporting: boolean;
    classes: any;
};

const SheetImportExportDialog = ({
    classes,
    helperText,
    isImportingExporting,
    onClose,
    onExport,
    onImport,
}: Props) => {
    const [googleSheetId, setGoogleSheetId] = useState('');

    const title = isImportingExporting
        ? 'Talking to Google Sheets...'
        : 'Import/Export';

    return (
        <Dialog open={true} onClose={onClose}>
            <DialogTitle id="import-export-dialog">{title}</DialogTitle>
            <CloseIcon
                className={classes.closeImportExport}
                onClick={onClose}
            />
            <DialogContent>
                <TextField
                    defaultValue={googleSheetId}
                    fullWidth={true}
                    // as there is no check on group duplicates
                    helperText={helperText}
                    label="Sheet ID"
                    margin="dense"
                    onChange={(event) => {
                        setGoogleSheetId(event.target.value);
                    }}
                    style={{ display: 'block', margin: '0 auto' }}
                />
                <DialogActions style={{ marginTop: '25px' }}>
                    <Button
                        color="primary"
                        disabled={isImportingExporting}
                        onClick={() => {
                            onExport(googleSheetId);
                        }}
                        variant="contained"
                    >
                        Export to Sheets
                    </Button>
                    <Button
                        color="primary"
                        disabled={isImportingExporting}
                        onClick={() => onImport(googleSheetId)}
                        variant="contained"
                    >
                        Import from Sheets
                    </Button>
                </DialogActions>
                {isImportingExporting && <LinearProgress />}
            </DialogContent>
        </Dialog>
    );
};

const styles = () => ({
    closeImportExport: {
        cursor: 'pointer',
        position: 'absolute' as 'absolute',
        right: '10px',
        top: '10px',
    },
});

export default withStyles(styles)(SheetImportExportDialog);
