import { BingoSquareCreate, BingoSquareSheetImport } from '../../service/Dto';

export function tranformBingoSquareForCreate(
    importedBingoSquare: BingoSquareSheetImport,
): BingoSquareCreate {
    // ATODO: test what's in configNubmer
    const {
        configNumber,
        gameEventType,
        gameInterval,
        imageUrl,
        player,
        team,
        text,
    } = importedBingoSquare;
    return {
        text: text,
        imageUrl: imageUrl,
        config: {
            interval: gameInterval,
            type: gameEventType,
            subject: player
                ? {
                      type: 'player',
                      value: player,
                  }
                : {
                      type: 'team',
                      value: team,
                  },
            number:
                configNumber === ''
                    ? undefined
                    : Number.parseFloat(configNumber),
        },
    };
}
