import { createContext } from 'react';
import { SponsorDto, SponsorshipUnitDto } from '../service/Dto';

interface ApiContextProps {
    createSponsor: (
        partnerId: string,
        sponsor: Partial<SponsorDto>,
    ) => Promise<SponsorDto>;
    updateSponsor: (
        partnerId: string,
        sponsorId: string,
        sponsor: Partial<SponsorDto>,
    ) => Promise<SponsorDto>;
    deleteSponsor: (partnerId: string, sponsorId: string) => Promise<void>;
    saveSponsorshipUnit: (
        partnerId: string,
        sponsorId: string,
        unit: Partial<SponsorshipUnitDto>, // if unit.id exists, then this will PATCH. otherwise it will POST.
    ) => Promise<SponsorshipUnitDto>;
    deleteSponsorshipUnit: (
        partnerId: string,
        sponsorId: string,
        unitId: string,
    ) => Promise<void>;
    createOfferCodes: (
        sponsorId: string,
        offerId: string,
        codes: string[],
    ) => Promise<void>;
}

const dummy = () => {
    throw new Error(
        'Should never be called. Is ApiContext.Provider in the component tree?',
    );
};

const ApiContext = createContext<ApiContextProps>({
    createOfferCodes: dummy,
    // createContext requires default values.
    // In practice, these should never actually be called as long as the provider
    // is correctly in the component tree.
    createSponsor: dummy,
    deleteSponsor: dummy,
    deleteSponsorshipUnit: dummy,
    saveSponsorshipUnit: dummy,
    updateSponsor: dummy,
});

export default ApiContext;
