export interface SponsorshipUnit<TemplateId = string, TemplateProps = unknown> {
    sponsorId: string;
    templateId: TemplateId;
    properties: TemplateProps;
    // offer: Offer
}

// #region Prediction sponsorship units

export enum EventSponsorshipUnitTemplateId {
    EVENT = 'event',
}

export enum PredictionSponsorshipUnitTemplateId {
    DOUBLE_POINT_POWERUP = 'double_pt_powerup',
    PRIZE_REDEMPTION = 'prize_redemption',
}

export interface DoublePointPowerupProps {
    foo: string;
}

export interface PrizeRedemptionProps {
    bar: number;
}

export type PredictionSponsorshipUnit =
    | SponsorshipUnit<
          PredictionSponsorshipUnitTemplateId.DOUBLE_POINT_POWERUP,
          DoublePointPowerupProps
      >
    | SponsorshipUnit<
          PredictionSponsorshipUnitTemplateId.PRIZE_REDEMPTION,
          PrizeRedemptionProps
      >;

// #endregion

// #region Event sponsorship units

interface EventSponsorshipUnitProps {
    updateMe: string;
}

export type EventSponsorshipUnit = SponsorshipUnit<
    'event',
    EventSponsorshipUnitProps
>;

// #endregion
