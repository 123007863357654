export const IGAME_POINTS_CALC_TYPE = {
    NO_POINTS: 'NO_POINTS',
    SUM_OF_POINTS: 'SUM_OF_POINTS',
    MAX_POINTS: 'MAX_POINTS',
    NUM_OF_GAMES_PLAYED: 'NUM_OF_GAMES_PLAYED',
};

export type IGamePointsCalcType =
    (typeof IGAME_POINTS_CALC_TYPE)[keyof typeof IGAME_POINTS_CALC_TYPE];
export type IGamePointsCalcTypeLabel = keyof typeof IGAME_POINTS_CALC_TYPE;

export const igamePointsCalcTypeTypeOptions: Array<{
    value: IGamePointsCalcType;
    label: IGamePointsCalcTypeLabel;
}> = (
    Object.entries(IGAME_POINTS_CALC_TYPE) as Array<
        [IGamePointsCalcTypeLabel, IGamePointsCalcType]
    >
).map(([key, value]) => ({ label: key, value }));
