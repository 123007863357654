import Fab from '@material-ui/core/Fab';
import { StyleRules, Theme, withStyles } from '@material-ui/core/styles';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import React, { Component } from 'react';

export interface Action {
    name: string;
    icon: any; // rendered element
    onClick: () => void; // TODO: make this optional
}

interface Props {
    actions: Action[];
    classes: any;
    relativePosition?: boolean;
    buttonText?: string;
}

interface State {
    open: boolean;
}

class UnstyledSpeedDial extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            open: false,
        };
    }

    private handleClickGenerator = (name?: any) => () => {
        const { actions } = this.props;

        this.setState(
            (prevState: State) => {
                return {
                    open: !prevState.open,
                };
            },
            () => {
                if (name) {
                    const action = actions.find((a) => a.name === name);
                    if (action) {
                        action.onClick();
                    }
                }
            },
        );
    };

    private handleClose = () => {
        this.setState({
            open: false,
        });
    };

    private renderLoneyButtonWithText = () => {
        const { actions, buttonText, classes } = this.props;
        return (
            <Fab
                aria-label={actions[0].name}
                className={classes.fabWithText}
                color="primary"
                onClick={this.handleClickGenerator(actions[0].name)}
                size="small"
                variant="extended"
            >
                {actions[0].icon}
                {buttonText}
            </Fab>
        );
    };

    private renderLonelyFab = () => {
        const { actions, classes } = this.props;

        return (
            <Fab
                aria-label={actions[0].name}
                className={classes.fab}
                color="primary"
                onClick={this.handleClickGenerator(actions[0].name)}
            >
                {actions[0].icon}
            </Fab>
        );
    };

    private renderSpeedDial = () => {
        const { actions, classes, relativePosition } = this.props;
        const { open } = this.state;

        return (
            <div className={classes.root}>
                <SpeedDial
                    ariaLabel="action-list"
                    className={relativePosition ? '' : classes.speedDial}
                    icon={<SpeedDialIcon />}
                    onClick={this.handleClickGenerator()}
                    onClose={this.handleClose}
                    open={open}
                >
                    {actions.map((action) => (
                        <SpeedDialAction
                            icon={action.icon}
                            key={action.name}
                            onClick={this.handleClickGenerator(action.name)}
                            tooltipTitle={action.name}
                        />
                    ))}
                </SpeedDial>
            </div>
        );
    };

    public render() {
        const { actions, buttonText } = this.props;

        if (actions.length === 1) {
            if (buttonText) {
                return this.renderLoneyButtonWithText();
            } else {
                return this.renderLonelyFab();
            }
        } else {
            return this.renderSpeedDial();
        }
    }
}

const styles = (theme: Theme) =>
    ({
        fab: {
            bottom: theme.spacing(2),
            margin: theme.spacing(),
            position: 'fixed',
            right: theme.spacing(2),
        },
        fabWithText: {
            bottom: 25,
            margin: theme.spacing(),
            position: 'fixed',
            right: theme.spacing(2),
            width: 105,
        },
        speedDial: {
            bottom: theme.spacing(2),
            margin: theme.spacing(),
            position: 'fixed',
            right: 130,
        },
    }) as StyleRules;

export default withStyles(styles)(UnstyledSpeedDial);
