import AddIcon from '@material-ui/icons/Add';
import React, { useCallback, useContext, useMemo } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import CrudPage from '../components/CrudPage';
import FormBuilder from '../components/FormBuilder';
import ApiContext from '../contexts/ApiContext';
import AppContext from '../contexts/AppContext';
import {
    CrudPageProps,
    CrudPageTableColumn,
    PAGE_LABEL,
} from '../service/Constants';
import {
    OfferCodeDto,
    OfferCodeListDto,
    OfferDto,
    OfferType,
    PartnerDto,
    SponsorDto,
} from '../service/Dto';
import { useModal } from '../utils/hooks';

interface Props {
    codes: OfferCodeListDto;
    offer: OfferDto;
    partner: PartnerDto;
    refreshData: (callback: () => void) => void;
    sponsor: SponsorDto;
}

const TABLE_COLUMNS: Array<CrudPageTableColumn<any>> = [
    {
        label: 'ID',
        name: 'id',
        options: {
            display: false,
        },
    },
    {
        label: 'Code',
        name: 'data',
    },
    {
        generateFunc: (assignable) => (assignable ? 'Yes' : 'No'),
        label: 'Assignable',
        name: 'assignable',
    },
];

const dummyPromise = () =>
    new Promise<void>((resolve, reject) => {
        reject('Unsupported action');
    });

interface UploadCodesFormState {
    codes: string;
}

export default function Codes(props: Props) {
    const { codes, offer, partner, refreshData, sponsor } = props;
    const offerCodesInputFields = [
        {
            label: 'Codes',
            name: 'codes',
            props: {
                multiline: true,
            },
            type: 'text',
        },
    ];
    const { type } = offer;

    const appContext = useContext(AppContext);
    const apiContext = useContext(ApiContext);

    const [
        isCreateOfferCodesModalOpen,
        openCreateOfferCodesModal,
        closeCreateOfferCodesModal,
    ] = useModal<string>();

    const onRequestOpenCreateOfferCodesModal = useCallback(() => {
        if (type === OfferType.NO_CODE) {
            confirmAlert({
                buttons: [
                    {
                        label: 'Got it',
                        onClick: () => {
                            /* no op */
                        },
                    },
                ],
                message:
                    'This is a "No Code" offer, so you cannot upload codes',
                title: 'Oops!',
            });
            return;
        }

        if (type === OfferType.GLOBAL && codes.codes.length >= 1) {
            confirmAlert({
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            openCreateOfferCodesModal();
                        },
                    },
                    {
                        label: 'Cancel',
                        onClick: () => {
                            /* no op */
                        },
                    },
                ],
                message:
                    'This is a "Global" code offer, and it already has a code. Are you sure you want to upload another code?',
                title: 'Whoa there cowboy!',
            });
            return;
        }

        openCreateOfferCodesModal();
    }, [openCreateOfferCodesModal]);

    const deleteCode = useCallback(
        () =>
            new Promise<void>((resolve, reject) => {
                confirmAlert({
                    buttons: [
                        {
                            label: 'Got it',
                            onClick: () => {
                                reject('Cannot delete offer codes');
                            },
                        },
                    ],
                    message: 'Deleting offer codes is not supported yet',
                    title: 'Oops!',
                });
            }),
        [],
    );

    const onSubmit = useCallback(
        async (formState: UploadCodesFormState) => {
            const codesToUpload = formState.codes
                .split('\n')
                .map((code) => code.trim())
                .filter((code) => code);
            await apiContext.createOfferCodes(
                sponsor.id,
                offer.id,
                codesToUpload,
            );
        },
        [apiContext, sponsor, offer],
    );

    const renderCreateOffersModal = useCallback(
        () => (
            <FormBuilder
                fields={offerCodesInputFields}
                label="Upload offer codes"
                notifyError={appContext.notifyError}
                notifySuccess={appContext.notifySuccess}
                onClose={closeCreateOfferCodesModal}
                onSubmit={onSubmit}
                startingValue={{ codes: '' } as UploadCodesFormState}
                supportedLanguages={[]}
            />
        ),
        [appContext, closeCreateOfferCodesModal, onSubmit],
    );

    const breadcrumbs = useMemo(
        () => [
            {
                link: '/partners',
                text: `${partner.name}`,
            },
            {
                link: `/sponsors?partner=${partner.id}`,
                text: 'sponsors',
            },
            {
                link: `/offers?partner=${partner.id}&sponsor=${sponsor.id}`,
                text: 'offers',
            },
            {
                text: offer.name,
            },
        ],
        [partner, offer],
    );

    const { notifyError, notifySuccess } = appContext;

    const crudProps: CrudPageProps<OfferCodeDto> = {
        breadCrumbs: breadcrumbs,
        data: codes.codes,
        deleteRecord: deleteCode,
        headline: 'Codes',
        inputFields: [],
        label: PAGE_LABEL.code,
        notifyError,
        notifySuccess,
        parentId: offer.id,
        refreshData,
        secondaryActions: [
            {
                icon: <AddIcon />,
                name: 'Upload codes',
                onClick: onRequestOpenCreateOfferCodesModal,
            },
        ],
        supportedLanguages: [],
        tableColumns: TABLE_COLUMNS,
        updateRecord: dummyPromise,
    };

    return (
        <div>
            <CrudPage {...crudProps} />
            {isCreateOfferCodesModalOpen && renderCreateOffersModal()}
        </div>
    );
}
