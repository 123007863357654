import { ChangeEvent, useEffect, useReducer, useState } from 'react';
import { UserDto } from 'src/service/Dto';

export type State = {
    isGetUsersLoading: boolean;
    isSetProUsersLoading: boolean;
    isFormDirty: boolean;
    displayNames?: string;
};

const INITIAL_STATE = {
    displayNames: undefined,
    isFormDirty: false,
    isGetUsersLoading: true,
    isSetProUsersLoading: false,
};

export enum ActionTypes {
    GET_USERS_LOADING,
    GET_USERS_SUCCESS,
    GET_USERS_FAILURE,

    SET_PRO_USERS_LOADING,
    SET_PRO_USERS_SUCCESS,
    SET_PRO_USERS_FAILURE,
    SET_DISPLAY_NAMES,
}

type Action =
    | {
          type: ActionTypes.GET_USERS_LOADING;
      }
    | {
          type: ActionTypes.GET_USERS_SUCCESS;
          payload: { displayNames: string[] };
      }
    | {
          type: ActionTypes.GET_USERS_FAILURE;
      }
    | {
          type: ActionTypes.SET_PRO_USERS_LOADING;
          payload: { displayNames: string[] };
      }
    | {
          type: ActionTypes.SET_PRO_USERS_SUCCESS;
          payload: { displayNames: string[] };
      }
    | {
          type: ActionTypes.SET_PRO_USERS_FAILURE;
      }
    | {
          type: ActionTypes.SET_DISPLAY_NAMES;
          payload: { displayNames: string };
      };

const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case ActionTypes.GET_USERS_LOADING:
            return { ...state, isGetUsersLoading: true };
        case ActionTypes.GET_USERS_SUCCESS:
            return {
                ...state,
                displayNames: action.payload.displayNames.sort().join('\n'),
                isFormDirty: false,
                isGetUsersLoading: false,
            };
        case ActionTypes.GET_USERS_FAILURE:
            return {
                ...state,
                displayNames: undefined,
                isGetUsersLoading: false,
            };
        case ActionTypes.SET_PRO_USERS_LOADING:
            return {
                ...state,
                displayNames: action.payload.displayNames.join('\n'),
                isSetProUsersLoading: true,
            };
        case ActionTypes.SET_PRO_USERS_SUCCESS:
            return {
                ...state,
                displayNames: action.payload.displayNames.sort().join('\n'),
                isFormDirty: false,
                isSetProUsersLoading: false,
            };
        case ActionTypes.SET_PRO_USERS_FAILURE:
            return {
                ...state,
                displayNames: undefined,
                isSetProUsersLoading: false,
            };
        case ActionTypes.SET_DISPLAY_NAMES:
            return {
                ...state,
                displayNames: action.payload.displayNames,
                isFormDirty: true,
            };
        default:
            return state;
    }
};

const useSetProUsersForm = ({
    getUsers,
    notifyError,
    setProUsers,
}: {
    setProUsers: (params: {
        partnerId: string;
        displayNames: string[];
    }) => Promise<UserDto[]>;
    getUsers: (params: {
        partnerId: string;
        isPro?: boolean;
    }) => Promise<UserDto[]>;
    notifyError: (message: string) => void;
}) => {
    const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
    const [partnerId, setPartnerId] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (partnerId) {
            getUsers({ isPro: true, partnerId })
                .then((response) => {
                    dispatch({
                        payload: {
                            displayNames: response.map(
                                (user) => user.displayName,
                            ),
                        },
                        type: ActionTypes.GET_USERS_SUCCESS,
                    });
                })
                .catch((e) => {
                    notifyError(e.message);
                    dispatch({ type: ActionTypes.GET_USERS_FAILURE });
                });
            dispatch({ type: ActionTypes.GET_USERS_LOADING });
        }
    }, [dispatch, notifyError, getUsers, partnerId]);

    const handleSubmit = partnerId
        ? () => {
              const displayNames = state.displayNames
                  ? state.displayNames
                        .split('\n')
                        .map((a) => a.trim())
                        .filter((a) => a !== '')
                        .sort()
                  : [];
              dispatch({
                  payload: { displayNames },
                  type: ActionTypes.SET_PRO_USERS_LOADING,
              });
              return setProUsers({
                  displayNames,
                  partnerId,
              })
                  .then((users) => {
                      dispatch({
                          payload: {
                              displayNames: users.map(
                                  (user) => user.displayName,
                              ),
                          },
                          type: ActionTypes.SET_PRO_USERS_SUCCESS,
                      });
                  })
                  .catch((error) => {
                      dispatch({ type: ActionTypes.SET_PRO_USERS_FAILURE });
                      throw error;
                  });
          }
        : undefined;

    const handleTextFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch({
            payload: { displayNames: e.target.value },
            type: ActionTypes.SET_DISPLAY_NAMES,
        });
    };
    return {
        ...state,
        handleSubmit,
        handleTextFieldChange,
        partnerId,
        setPartnerId,
    };
};

export default useSetProUsersForm;
