type EntryBase<T> = {
    column: number;
    optional?: boolean;
    fromCell?: (value: string) => T;
};

type TwoDimentionalEntry<T> = EntryBase<T> & {
    shouldParseNextRow: (row: string[]) => boolean;
};

type Entry<T> = EntryBase<T> | TwoDimentionalEntry<T>;

export type ParseConfig<T> = { [K in keyof T]: Entry<T[K]> };

function partition<A, B>(
    array: (A | B)[],
    isValid: (v: A | B) => v is A,
): [A[], B[]] {
    const result: [A[], B[]] = [[], []];
    return array.reduce(([pass, fail], elem) => {
        return isValid(elem)
            ? [[...pass, elem], fail]
            : [pass, [...fail, elem]];
    }, result);
}

function isTwoDimentionalEntry<T>(
    keyedEntry: [any, Entry<T>],
): keyedEntry is [any, TwoDimentionalEntry<T>] {
    return 'shouldParseNextRow' in keyedEntry[1];
}

function parseEntity<T>(
    config: ParseConfig<T>,
    [firstRow, ...rest]: string[][],
): {
    parsedEntity: T;
    nextRowsToParse: string[][];
} {
    const parsedEntity = {} as any;

    const entries = Object.entries<Entry<T>>(config as any);

    const [twoDimentionalConfigs, oneDimentionalConfigs] = partition<
        [string, TwoDimentionalEntry<any>],
        [string, EntryBase<any>]
    >(entries, isTwoDimentionalEntry);

    for (const [key, config] of oneDimentionalConfigs) {
        if (config.optional && !firstRow[config.column]) {
            continue;
        }
        if (config.fromCell) {
            parsedEntity[key] = config.fromCell(firstRow[config.column]);
        } else {
            parsedEntity[key] = firstRow[config.column];
        }
    }

    let nextRowsIndex = 0;

    if (twoDimentionalConfigs.length > 0) {
        for (const [key, config] of twoDimentionalConfigs) {
            if (config.optional && !firstRow[config.column]) {
                continue;
            }
            parsedEntity[key] = [
                config.fromCell
                    ? config.fromCell(firstRow[config.column])
                    : firstRow[config.column],
            ];

            for (
                let i = 0;
                rest[i] && config.shouldParseNextRow(rest[i]);
                ++i
            ) {
                parsedEntity[key].push(
                    config.fromCell
                        ? config.fromCell(rest[i][config.column])
                        : rest[i][config.column],
                );
                if (i > nextRowsIndex) {
                    nextRowsIndex = i;
                }
            }
        }
        nextRowsIndex += 1;
    }

    return {
        nextRowsToParse: nextRowsIndex === -1 ? [] : rest.slice(nextRowsIndex),
        parsedEntity,
    };
}

export default parseEntity;
