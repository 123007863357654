import {
    Box,
    Button,
    Dialog,
    IconButton,
    MenuItem,
    Select,
    Tab,
    Tabs,
} from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import LinearProgress from '@material-ui/core/LinearProgress';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import { LocaleId, LocaleIds, ProgressivePollCreate } from '../service/Dto';
import TabPanel from './TabPanel';

type Props = {
    onClose: () => void;
    onSubmit: (data: ProgressivePollCreate) => Promise<void>;
    isSubmitting: boolean;
    classes: any;
    iterationId: string;
    supportedLanguages?: LocaleId[];
};

const initialTranslationState = {
    detailsText: '',
    options: '',
    text: '',
} as const;

const initialState = {
    notes: '',
    ...initialTranslationState,
} as const;

type TextFieldConfig = {
    helperText?: string;
    label: string;
    extraOptions?: {
        multiline?: boolean;
    };
};

const fieldConfig: Record<FieldName, TextFieldConfig> = {
    detailsText: {
        label: 'Poll question details text',
    },
    notes: {
        label: 'Notes',
    },
    options: {
        extraOptions: {
            multiline: true,
        },
        helperText: 'Poll question break line separated options',
        label: 'Poll options',
    },
    text: {
        label: 'Poll question text',
    },
};

const translationFieldConfig: Record<keyof TranslationFields, TextFieldConfig> =
    {
        detailsText: {
            label: 'Poll question details text',
        },
        options: {
            extraOptions: {
                multiline: true,
            },
            helperText: 'Poll question break line separated options',
            label: 'Poll options',
        },
        text: {
            label: 'Poll question text',
        },
    };

type DefaultLanguageFields = {
    detailsText: string;
    notes: string;
    options: string;
    text: string;
};

type TranslationFields = {
    detailsText: string;
    options: string;
    text: string;
};

type FieldName = keyof TranslationFields | keyof DefaultLanguageFields;

type TranslationTabsDataState = {
    selectedLanguage: LocaleId;
    fields: TranslationFields;
}[];
type DefaultTabsDataState = {
    defaultLanguageId: LocaleId;
    fields: DefaultLanguageFields;
};

const presubmitTransform = ({
    defaultLanguageTab,
    entityTranslationsTabs,
    iterationId,
}: {
    defaultLanguageTab: DefaultTabsDataState;
    entityTranslationsTabs: TranslationTabsDataState;
    iterationId: string;
}) => {
    return {
        ...defaultLanguageTab.fields,
        defaultLanguageId: defaultLanguageTab.defaultLanguageId,
        options: defaultLanguageTab.fields.options.split('\n'),
        entityTranslations: entityTranslationsTabs.map((t) => {
            return {
                ...t.fields,
                languageCodeId: t.selectedLanguage,
                options: t.fields.options.split('\n'),
            };
        }),
        iterationId,
    };
};

const AddProgressivePollDialog = ({
    classes,
    isSubmitting,
    iterationId,
    onClose,
    onSubmit,
    supportedLanguages,
}: Props) => {
    const [defaultLanguageTab, setDefaultLanguageTab] =
        useState<DefaultTabsDataState>({
            defaultLanguageId:
                (supportedLanguages && supportedLanguages[0]) || LocaleIds[0],
            fields: initialState,
        });
    const [tabs, setTabs] = useState<TranslationTabsDataState>([]);
    const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

    const title = isSubmitting ? 'Submitting...' : '';

    const onTextFieldChange =
        (tabIndex: number, name: string) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const duplicate = [...tabs];
            duplicate[tabIndex] = {
                ...duplicate[tabIndex],
                fields: {
                    ...duplicate[tabIndex].fields,
                    [name]: event.target.value,
                },
            };
            setTabs(duplicate);
        };

    const getTabFields = (tabIndex: number) => {
        return (
            <TabPanel
                index={tabIndex + 1}
                key={tabIndex + 1}
                value={activeTabIndex}
            >
                <Select
                    required={true}
                    name={'Translation Language'}
                    value={tabs[tabIndex].selectedLanguage}
                    fullWidth={true}
                    onChange={(event) => {
                        setTabs((previous) => {
                            const duplicate = [...previous];
                            duplicate[activeTabIndex] = {
                                ...duplicate[activeTabIndex],
                                selectedLanguage: event.target
                                    .value as LocaleId,
                            };
                            return duplicate;
                        });
                    }}
                >
                    {supportedLanguages
                        ?.filter(
                            (sl) =>
                                (sl === tabs[tabIndex].selectedLanguage ||
                                    tabs.every(
                                        (tab) => tab.selectedLanguage !== sl,
                                    )) &&
                                sl !== defaultLanguageTab.defaultLanguageId,
                        )
                        .map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                </Select>
                {Object.keys(translationFieldConfig).map((fieldName) => {
                    const name = fieldName as keyof TranslationFields;
                    const { extraOptions, helperText, label } =
                        fieldConfig[name];
                    return (
                        <TextField
                            fullWidth={true}
                            helperText={helperText}
                            key={name}
                            label={label}
                            margin="dense"
                            multiline={extraOptions && extraOptions.multiline}
                            onChange={onTextFieldChange(tabIndex, name)}
                            style={{ display: 'block', margin: '0 auto' }}
                            value={tabs[tabIndex].fields[name]}
                        />
                    );
                })}
            </TabPanel>
        );
    };

    return (
        <Dialog open={true}>
            <DialogTitle id="form-dialog-title">{title}</DialogTitle>
            <CloseIcon
                className={classes.closeImportExport}
                onClick={onClose}
            />
            <DialogContent>
                {supportedLanguages && supportedLanguages.length > 0 && (
                    <Box display="flex" flexDirection="row">
                        <Tabs
                            indicatorColor="primary"
                            onChange={(event, newValue: number) => {
                                setActiveTabIndex(newValue);
                            }}
                            textColor="primary"
                            value={activeTabIndex}
                            variant="scrollable"
                            scrollButtons="auto"
                        >
                            {[
                                {
                                    selectedLanguage:
                                        defaultLanguageTab.defaultLanguageId,
                                },
                                ...tabs,
                            ].map((el, i) => (
                                <Tab
                                    wrapped
                                    key={i}
                                    label={
                                        <div>{`${
                                            i === 0
                                                ? `Default language: ${el.selectedLanguage}`
                                                : el.selectedLanguage
                                                  ? `Translation language: ${el.selectedLanguage}`
                                                  : 'Translation'
                                        }`}</div>
                                    }
                                />
                            ))}
                        </Tabs>
                        {
                            <IconButton
                                aria-label="add translation"
                                disabled={
                                    !supportedLanguages ||
                                    supportedLanguages.length ===
                                        tabs.length + 1
                                }
                                onClick={() => {
                                    setTabs([
                                        ...tabs,
                                        {
                                            selectedLanguage:
                                                supportedLanguages?.filter(
                                                    (sl) =>
                                                        tabs.every(
                                                            (tab) =>
                                                                tab.selectedLanguage !==
                                                                sl,
                                                        ) &&
                                                        sl !==
                                                            defaultLanguageTab.defaultLanguageId,
                                                )[0],
                                            fields: initialTranslationState,
                                        },
                                    ]);
                                }}
                            >
                                <AddIcon fontSize="medium" />
                            </IconButton>
                        }
                    </Box>
                )}

                {activeTabIndex === 0 ? (
                    <TabPanel
                        index={activeTabIndex}
                        key={activeTabIndex}
                        value={activeTabIndex}
                    >
                        {Object.keys(fieldConfig).map((fieldName) => {
                            const name =
                                fieldName as keyof DefaultLanguageFields;
                            const { extraOptions, helperText, label } =
                                fieldConfig[name];
                            return (
                                <TextField
                                    fullWidth={true}
                                    helperText={helperText}
                                    key={name}
                                    label={label}
                                    margin="dense"
                                    multiline={
                                        extraOptions && extraOptions.multiline
                                    }
                                    onChange={(event) => {
                                        setDefaultLanguageTab({
                                            ...defaultLanguageTab,
                                            fields: {
                                                ...defaultLanguageTab.fields,
                                                [name]: event.target.value,
                                            },
                                        });
                                    }}
                                    style={{
                                        display: 'block',
                                        margin: '0 auto',
                                    }}
                                    value={defaultLanguageTab.fields[name]}
                                />
                            );
                        })}
                    </TabPanel>
                ) : (
                    tabs.map((t, i) => getTabFields(i))
                )}
                <DialogActions style={{ marginTop: '25px' }}>
                    <Button
                        color="primary"
                        disabled={isSubmitting}
                        onClick={() => {
                            onSubmit(
                                presubmitTransform({
                                    defaultLanguageTab,
                                    entityTranslationsTabs: tabs,
                                    iterationId,
                                }),
                            );
                        }}
                        variant="contained"
                    >
                        Submit
                    </Button>
                </DialogActions>

                {isSubmitting && <LinearProgress />}
            </DialogContent>
        </Dialog>
    );
};

const styles = () => ({
    closeImportExport: {
        cursor: 'pointer',
        position: 'absolute' as 'absolute',
        right: '10px',
        top: '10px',
    },
});

export default withStyles(styles)(AddProgressivePollDialog);
