import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { CategoryDto, EventDto, IterationDto } from 'src/service/Dto';
import BreadcrumbBar from '../components/BreadcrumbBar';
import { PageHeadline } from '../components/CrudPage';

type Props = {
    category: CategoryDto;
    iteration: IterationDto;
    event: EventDto;
    finalizeEvent: (event: EventDto) => Promise<void>;
    refreshEventsForIteration: (iterationId: string) => void;
    notifyError: (message: string) => void;
    notifySuccess: (message: string) => void;
    classes: any;
};

const styles = (theme: any) => ({
    paper: {
        padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
    },
    finalizeButton: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
});

const NoChildEntriesEvent = ({
    category,
    classes,
    event,
    finalizeEvent,
    iteration,
    notifyError,
    notifySuccess,
    refreshEventsForIteration,
}: Props) => {
    const [finalizing, setFinalizing] = useState(false);

    const onFinalizeClick = () => {
        confirmAlert({
            buttons: [
                {
                    label: 'Finalize Event',
                    onClick: async () => {
                        setFinalizing(true);
                        try {
                            await finalizeEvent(event);
                        } catch (error) {
                            notifyError(`Error finalizing event: ${error}`);
                        }

                        setFinalizing(false);
                        notifySuccess('Event Finalized');
                        refreshEventsForIteration(iteration.id);
                    },
                },
                {
                    label: 'Cancel',
                    onClick: () => {},
                },
            ],
            message:
                'Do you want to finalize the event? This will end the event for all players and finalize the leaderboard.',
            title: 'Finalize Event',
        });
    };

    const breadCrumbs = [
        { link: '/partners', text: 'Partners' },
        {
            link: `/categories?partner=${category.partnerId}`,
            text: `${category.partnerId}'s Categories`,
        },
        {
            link: `/iterations?category=${category.id}`,
            text: `${category.name}'s Iterations`,
        },
        {
            link: `/events?iteration=${iteration.id}`,
            text: `${iteration.name}'s Events`,
        },
        { link: '', text: 'No Child Entities for' },
    ];

    return (
        <div>
            <Paper className={classes.paper}>
                <BreadcrumbBar breadcrumbs={breadCrumbs} />
                <PageHeadline headline={`${event.name}: No Child Entities`} />
                <div className={classes.finalizedContainer}>
                    {finalizing ? (
                        <Typography align="center" variant="body1">
                            Finalizing...
                        </Typography>
                    ) : event.finalized ? (
                        <Typography align="center" gutterBottom variant="body1">
                            Event is finalized
                        </Typography>
                    ) : (
                        <Button
                            className={classes.finalizeButton}
                            color="primary"
                            onClick={onFinalizeClick}
                            size="small"
                            variant="contained"
                        >
                            Finalize
                        </Button>
                    )}
                </div>
            </Paper>
            <Typography variant="body1" align="center">
                There are no child entities for event of type {event.type}
            </Typography>
        </div>
    );
};

export default withStyles(styles)(NoChildEntriesEvent);
