import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    TextField,
} from '@material-ui/core';
import React from 'react';

const keys = ['uid', 'displayName', 'email'] as const;

type DataParamName = (typeof keys)[number];

type PartialRecord<K extends keyof any, T> = {
    [P in K]?: T;
};

type IGameIframeUrlQueryParamsMapping = PartialRecord<DataParamName, string>;

type Props = {
    name: string;
    label: string;
    value: IGameIframeUrlQueryParamsMapping;
    onChange: ({
        name,
        value,
    }: {
        name: string;
        value: IGameIframeUrlQueryParamsMapping;
    }) => void;
};

function IGameIframeUrlQueryParamsInput({
    label,
    name,
    onChange,
    value,
}: Props) {
    const onTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange({
            name,
            value: { ...value, [event.target.name]: event.target.value },
        });
    };

    const onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked, name: checkboxName } = event.target;
        const newValue = { ...value };

        if (checked) {
            newValue[checkboxName as DataParamName] = checkboxName;
        } else {
            delete newValue[checkboxName as DataParamName];
        }
        onChange({
            name,
            value: newValue,
        });
    };

    return (
        <FormControl component="fieldset">
            <FormLabel>{label}</FormLabel>
            <FormGroup row>
                {keys.map((dataParamName, index) => {
                    const maybeQueryParamName = value[dataParamName];

                    return (
                        <div key={index}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={onCheckboxChange}
                                        name={dataParamName}
                                        checked={
                                            maybeQueryParamName !== undefined
                                        }
                                    />
                                }
                                label={dataParamName}
                            />
                            <TextField
                                name={dataParamName}
                                disabled={!maybeQueryParamName}
                                onChange={onTextFieldChange}
                                value={maybeQueryParamName || dataParamName}
                            />
                        </div>
                    );
                })}
            </FormGroup>
        </FormControl>
    );
}

export default IGameIframeUrlQueryParamsInput;
