import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Paper from '@material-ui/core/Paper';
import {
    createTheme,
    MuiThemeProvider,
    withStyles,
} from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import MuiDataTable from 'mui-datatables';
import React, { useState } from 'react';
import ActionButton from '../../components/ActionButton';
import BreadcrumbBar from '../../components/BreadcrumbBar';
import { PageHeadline, transformToArray } from '../../components/CrudPage';
import ImageOptions from '../../components/ImageOptions';
import TextOptions from '../../components/TextOptions';
import TriviaSheetImportExportDialog from '../../components/TriviaSheetImportExportDialog';
import {
    PartnerDto,
    TriviaOption,
    TriviaQuestion,
    TriviaQuestionCreate,
} from '../../service/Dto';
import GoogleSheetsApi, {
    GoogleSheetsApiError,
} from '../../service/GoogleSheetsApi';
import { combineArrays } from '../../utils/shared';
import triviaQuestionTableTransformer, {
    TriviaQuestionImport,
} from '../../utils/tables/triviaQuestionTableTransformer';
import { shouldIgnoreMuiTableRowClick } from '../../utils/tables/util';
import TriviaQuestionDetails from './TriviaQuestionDetails';

const styles = (theme: any) => ({
    paper: {
        padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
    },
});

type TriviaOptionWithCorrect = TriviaOption & { correct: boolean };

type Props = {
    partner: PartnerDto;
    questions: TriviaQuestion[];
    onRowsDelete: ({
        ids,
        partnerId,
    }: {
        partnerId: string;
        ids: string[];
    }) => Promise<void>;
    triviaBuckets: string[];
    batchCreateTriviaQuestions: ({
        bucketId,
        partnerId,
        questions,
    }: {
        partnerId: string;
        questions: TriviaQuestionCreate[];
        bucketId: string;
    }) => Promise<void>;
    refreshData: () => Promise<void>;
    notifyError: (message: string) => void;
    notifySuccess: (message: string) => void;

    classes: any;
};

const tableColumns = [
    {
        label: 'Question ID',
        name: 'id',
        options: {
            display: false,
            filter: false,
        },
    },
    {
        label: 'Text',
        name: 'text',
        options: {
            display: true,
            filter: false,
            sort: true,
        },
    },
    {
        label: 'Options',
        name: 'options',
        options: {
            customBodyRender: (options: TriviaOptionWithCorrect[]) => {
                const correctOption = options.find((option) => option.correct)!;
                return correctOption.text ? (
                    <TextOptions
                        correctOption={correctOption.text}
                        options={options.map((option) => option.text!)}
                    />
                ) : (
                    <ImageOptions
                        correctOption={correctOption.imageUrl!}
                        options={options.map((option) => option.imageUrl!)}
                    />
                );
            },
            display: true,
            filter: false,
            sort: false,
        },
    },
    {
        label: 'Bucket',
        name: 'bucketId',
        options: {
            display: true,
            filter: true,
            sort: true,
        },
    },
    {
        label: 'Partner',
        name: 'partnerId',
        options: {
            display: false,
            filter: false,
            sort: false,
        },
    },
];

const getMuiTheme = () => {
    return createTheme({
        overrides: {
            MUIDataTableBodyCell: {
                root: {
                    '&:hover': {
                        cursor: 'pointer !important',
                    },
                    color: 'inherit',
                },
            },
            MuiSvgIcon: {
                fontSizeSmall: {
                    fontSize: '13px',
                    verticalAlign: 'middle',
                },
            },
        },
    } as any);
};

const Trivia = ({
    batchCreateTriviaQuestions,
    classes,
    notifyError,
    notifySuccess,
    onRowsDelete,
    partner,
    questions,
    refreshData,
    triviaBuckets,
}: Props) => {
    const [selectedQuestionId, setSelectedQuestionId] = useState<string | null>(
        null,
    );
    const [showImportExportModal, setShowImportExportModal] = useState(false);
    const [isImportingExporting, setIsImportingExporting] = useState(false);

    const handleExportTriviaQuestions = async (
        googleSheetId: string,
        triviaBucket: string,
    ) => {
        const triviaQuestionsToExport = questions.filter(
            (question) => question.bucketId === triviaBucket,
        );

        if (triviaQuestionsToExport.length === 0) {
            notifyError('There are no triviaQuestions to export!');
            return;
        }

        setIsImportingExporting(true);

        const gapi = new GoogleSheetsApi();
        await gapi.initialize();

        const table = triviaQuestionTableTransformer.toTable(
            triviaQuestionsToExport,
        );

        try {
            await gapi.writeToSheet(googleSheetId, table);
            notifySuccess('Succesfully exported trivia questions');
        } catch (error) {
            if (error instanceof GoogleSheetsApiError) {
                notifyError(
                    `Error exporting trivia questions: ${error.status}`,
                );
            } else {
                notifyError(`Error exporting questions: ${error}`);
            }
        }

        setIsImportingExporting(false);
        setShowImportExportModal(false);
    };

    const handleImportTriviaQuestions = async (
        googleSheetId: string,
        bucketId: string,
    ) => {
        setIsImportingExporting(true);

        const gapi = new GoogleSheetsApi();
        await gapi.initialize();

        let sheet;
        try {
            sheet = await gapi.readSheet(googleSheetId);
        } catch (error) {
            if (error instanceof GoogleSheetsApiError) {
                notifyError(
                    `Error importing trivia questions: ${error.status}`,
                );
            } else if (error instanceof Error) {
                const message = `Error importing trivia questions: ${error.message}`;
                notifyError(message);
            } else {
                notifyError(`Unknown google sheet error ${error}`);
            }

            setIsImportingExporting(false);
            return;
        }

        const importedQuestions =
            triviaQuestionTableTransformer.fromTable(sheet);

        const questionsToCreate = importedQuestions.map(
            prepareImportedTriviaQuestionsForCreate,
        );

        try {
            await batchCreateTriviaQuestions({
                bucketId,
                partnerId: partner.id,
                questions: questionsToCreate,
            });
            notifySuccess('Succesfully imported trivia questions');
        } catch (error) {
            if (error instanceof GoogleSheetsApiError) {
                notifyError(
                    `Error importing trivia questions: ${error.status}`,
                );
            } else if (error instanceof Error) {
                const message = `Error importing trivia questions: ${error.message}`;
                notifyError(message);
            } else {
                notifyError(`Unknown api error ${error}`);
            }

            setIsImportingExporting(false);
            return;
        }
        await refreshData();
        setIsImportingExporting(false);
        setShowImportExportModal(false);
    };

    const handleDelete = async ({
        lookup,
    }: {
        lookup: { [key: number]: boolean };
    }) => {
        const rows = transformToArray(questions, tableColumns, true);
        const rowsToDelete = Object.keys(lookup).map(
            (index) => rows[parseInt(index, 10)],
        );

        const ids = rowsToDelete.map(
            (row) =>
                row[tableColumns.findIndex((column) => column.name === 'id')],
        );
        try {
            await onRowsDelete({ ids, partnerId: partner.id });
            notifySuccess('Successfully deleted trivia question(s)');
        } catch (error) {
            if (error instanceof Error) {
                notifyError(error.message);
            } else {
                notifyError(`Unknown error: ${JSON.stringify(error)}`);
            }
        }
    };

    const breadCrumbs = [
        { link: '/partners', text: 'Partners' },
        { link: '', text: `${partner.id}'s trivia questions` },
    ];

    const options = {
        customToolbarSelect: (selectedRows: {
            lookup: { [key: number]: boolean };
        }) => (
            <Button
                onClick={() => handleDelete(selectedRows)}
                size="small"
                variant="contained"
            >
                <DeleteIcon />
            </Button>
        ),
        filterType: 'dropdown',
        onRowClick: ([id]: string[], _: any, event: any) => {
            if (shouldIgnoreMuiTableRowClick(event)) {
                return;
            }
            setSelectedQuestionId(id);
        },
        resizableColumns: true,
        responsive: 'simple',
        rowsPerPage: 50,
        rowsPerPageOptions: [10, 20, 30, 40, 50, 75, 100],
    };

    const selectedQuestion = questions.find(
        (question) => question.id === selectedQuestionId,
    );

    return (
        <div>
            <div>
                <Paper className={classes.paper}>
                    <BreadcrumbBar breadcrumbs={breadCrumbs} />
                    <PageHeadline
                        headline={`${partner.id}: trivia questions`}
                    />
                </Paper>
            </div>
            <MuiThemeProvider theme={getMuiTheme()}>
                <MuiDataTable
                    columns={tableColumns}
                    data={transformToArray(
                        questions.map(
                            ({ correctOptionId, options, ...rest }) => {
                                return {
                                    options: options.map((option) => ({
                                        correct: option.id === correctOptionId,
                                        ...option,
                                    })),
                                    ...rest,
                                };
                            },
                        ),
                        tableColumns,
                    )}
                    options={options}
                />
            </MuiThemeProvider>
            <ActionButton
                actions={[
                    {
                        icon: <KeyboardArrowUp />,
                        name: 'Import / Export trivia',
                        onClick: () => {
                            setShowImportExportModal(true);
                        },
                    },
                ]}
            />
            {selectedQuestion && (
                <Dialog
                    onClose={() => setSelectedQuestionId(null)}
                    open={!!selectedQuestion}
                >
                    <TriviaQuestionDetails question={selectedQuestion} />
                </Dialog>
            )}
            {showImportExportModal && (
                <TriviaSheetImportExportDialog
                    isImportingExporting={isImportingExporting}
                    onClose={() => setShowImportExportModal(false)}
                    onExport={handleExportTriviaQuestions}
                    onImport={handleImportTriviaQuestions}
                    triviaBuckets={triviaBuckets}
                />
            )}
        </div>
    );
};

function prepareImportedTriviaQuestionsForCreate(
    importedQuestion: TriviaQuestionImport,
): TriviaQuestionCreate {
    return {
        text: importedQuestion.text,
        correctOption: importedQuestion.correctOption,
        options: combineArrays(
            importedQuestion.optionTexts,
            importedQuestion.optionImageUrls,
            (text, imageUrl) => ({
                text: text || null,
                imageUrl: imageUrl || null,
            }),
        ),
        ...(importedQuestion.adIframeHtml
            ? {
                  ad: {
                      iframeHtml: importedQuestion.adIframeHtml,
                      headline: importedQuestion.adHeadline || null,
                      disclaimer: importedQuestion.adDisclaimer || null,
                  },
              }
            : {}),

        ...(importedQuestion.languageInfo
            ? {
                  languageInfo: {
                      defaultLanguageId:
                          importedQuestion.languageInfo.defaultLanguageId,
                      ...(importedQuestion.languageInfo.entityTranslations
                          ? {
                                entityTranslations:
                                    importedQuestion.languageInfo.entityTranslations.map(
                                        (et) => ({
                                            text: et.text,
                                            options: combineArrays(
                                                et.optionTexts,
                                                et.optionImageUrls,
                                                (text, imageUrl) => ({
                                                    text: text || null,
                                                    imageUrl: imageUrl || null,
                                                }),
                                            ),
                                            ...(et.adIframeHtml
                                                ? {
                                                      ad: {
                                                          iframeHtml:
                                                              et.adIframeHtml,
                                                          headline:
                                                              et.adHeadline ||
                                                              null,
                                                          disclaimer:
                                                              et.adDisclaimer ||
                                                              null,
                                                      },
                                                  }
                                                : {}),
                                            languageId: et.languageId,
                                        }),
                                    ),
                            }
                          : {}),
                  },
              }
            : {}),
    };
}
export default withStyles(styles)(Trivia);
