import AccordionActions from '@material-ui/core/AccordionActions';
import AppBar from '@material-ui/core/AppBar';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import Zoom from '@material-ui/core/Zoom';
import AddIcon from '@material-ui/icons/Add';
import LockIcon from '@material-ui/icons/Lock';
import UnlockIcon from '@material-ui/icons/LockOpen';
import MessageIcon from '@material-ui/icons/Message';
import RefreshIcon from '@material-ui/icons/Refresh';
import SaveIcon from '@material-ui/icons/Save';
import classNames from 'classnames';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Redirect } from 'react-router-dom';
import titleCase from 'title-case';
import {
    PREDICTION_EVENT_STATES,
    PREDICTION_TYPES,
} from 'traceme-shared/lib/constants';
import { isMultipleChoice } from 'traceme-shared/lib/util';
import ActionButton from '../../components/ActionButton';
import BatchUnlockModal from '../../components/BatchUnlockModal';
import ManualLockModal from '../../components/ManualLockModal';
import { SponsorshipUnitType } from '../../service/Dto';
import { groupUnitsByTypeForSponsors } from '../../utils/sponsors';
import {
    determineEventState,
    determinePredictionLockState,
    predictionInputFields,
    predictionTranslationInputFields,
    preformPredictionRenderTransform,
    presubmitPredictionTransform,
} from '../../utils/util';
import AnswerGamecast from '../AnswerGamecast';
import BreadcrumbBar from '../BreadcrumbBar';
import FormBuilder from '../FormBuilder';
import { SendSmsModal } from '../SendSmsModal';
import NBAStatsTable from './NBAStatsTable';
import PlayByPlay from './PlayByPlay';
import PredictionReminder from './PredictionReminder';
import PredictionRow from './PredictionRow';
import SaveAnswerDialog from './SaveAnswerDialog';

export const AnswerState = {
    answeringFinalQuestions: 'answeringFinalQuestions',
    answeringNonFinalQuestions: 'answeringNonFinalQuestions',
    revisingFinalizedGame: 'revisingFinalizedGame',
    revisingNonFinalizedGame: 'revisingNonFinalizedGame',
};

const extractServerAnsweredOptionId = (prediction) => {
    const serverCorrectOption = prediction.options.find(
        (option) => option.correct,
    );
    if (serverCorrectOption) {
        return serverCorrectOption.id;
    }
};

export const localAnsweredOptionId = (pendingAnswer) => {
    if (pendingAnswer) {
        const pendingAnswerOptions = pendingAnswer.options;
        if (pendingAnswerOptions && Object.keys(pendingAnswerOptions).length) {
            return Object.keys(pendingAnswerOptions)[0];
        }
    }
};

export const extractFitbPendingAnswer = (pendingAnswer, optionId) => {
    if (pendingAnswer) {
        const pendingAnswerOptions = pendingAnswer.options;
        if (pendingAnswerOptions && pendingAnswerOptions[optionId]) {
            return pendingAnswerOptions[optionId].answer;
        }
    }
};

export const PREDICTION_LOCK_STATES = {
    HIDDEN: 'hidden',
    LOCKED: 'locked',
    UNLOCKED: 'unlocked',
};

const extractFitbPendingEdited = (pendingAnswer, optionId) => {
    if (pendingAnswer) {
        const pendingAnswerOptions = pendingAnswer.options;
        if (pendingAnswerOptions && pendingAnswerOptions[optionId]) {
            return pendingAnswerOptions[optionId].edited;
        }
    }
};

const multilineTrim = (text) => {
    return (text || '')
        .split('\n')
        .map((s) => s.trim())
        .join('\n');
};

const pendingAnswersConflicting = ({
    pendingAnswersAfter,
    pendingAnswersBefore,
    predictionsAfter,
    predictionsBefore,
}) => {
    // if a prediction answer changed on the server between refreshes, check
    // if its in conflict with our current answer
    for (const predictionId of Object.keys(pendingAnswersBefore)) {
        const predictionAfter = predictionsAfter.find(
            (p) => p.id === predictionId,
        );
        const predictionBefore = predictionsBefore.find(
            (p) => p.id === predictionId,
        );

        const answerAfter = JSON.stringify(
            AnswerPredictions.extractAnswer(predictionAfter),
        );
        const answerBefore = JSON.stringify(
            AnswerPredictions.extractAnswer(predictionBefore),
        );
        const answerExplanationAfter = predictionAfter.answerExplanation;
        const answerExplanationBefore = predictionBefore.answerExplanation;
        const pendingAnswerBefore = JSON.stringify(
            pendingAnswersBefore[predictionId],
        );
        const pendingAnswerExplanationBefore = (
            pendingAnswersBefore[predictionId] || {}
        ).answerExplanation;

        if (
            answerAfter !== answerBefore &&
            answerAfter !== pendingAnswerBefore
        ) {
            return true;
        }

        if (
            answerExplanationAfter !== answerExplanationBefore &&
            answerExplanationAfter !== pendingAnswerExplanationBefore
        ) {
            return true;
        }
    }

    // This is probably not necessary but double check to make sure the
    // pending answers after the server refresh doesn't include anything
    // that wasn't previously there.
    for (const predictionId of Object.keys(pendingAnswersAfter)) {
        if (!pendingAnswersBefore[predictionId]) {
            return true;
        }
    }

    return false;
};

const pendingAnswersDifferent = ({
    pendingAnswersAfter,
    pendingAnswersBefore,
}) => {
    for (const predictionId of Object.keys(pendingAnswersBefore)) {
        const answerAfter = JSON.stringify(pendingAnswersAfter[predictionId]);
        const answerBefore = JSON.stringify(pendingAnswersBefore[predictionId]);
        if (answerBefore !== answerAfter) {
            return true;
        }
    }
    for (const predictionId of Object.keys(pendingAnswersAfter)) {
        if (!pendingAnswersBefore[predictionId]) {
            return true;
        }
    }
    return false;
};

const UNSPECIFIED = '';
const LOCAL_STORAGE_KEY = 'megaphoneEventInfo';
const SHOW_IFRAME = 'showIframe';
const SHOW_STATS = 'statsEnabledOnEvent';
// const USE_REMINDERS = 'useReminders';

const DEFAULT_PREFERENCES = {
    showAllPredictions: false,
    showIframe: false,
    statsEnabledOnEvent: false,
    useReminders: false,
};

class AnswerPredictions extends Component {
    gameStatsIntervalId = 0;
    sponsorshipUnitsByType = {};

    static propTypes = {
        batchEditPredictions: PropTypes.func.isRequired,
        batchManualLockPredictions: PropTypes.func.isRequired,
        batchUnlockPredictions: PropTypes.func.isRequired,
        category: PropTypes.object.isRequired,
        classes: PropTypes.object.isRequired,
        deletePrediction: PropTypes.func.isRequired,
        enqueueSnackbar: PropTypes.func,
        event: PropTypes.object.isRequired,
        eventGameStats: PropTypes.object,
        finalizeEvent: PropTypes.func.isRequired,
        iteration: PropTypes.object.isRequired,
        notifyError: PropTypes.func.isRequired,
        notifySuccess: PropTypes.func.isRequired,
        notifyWarning: PropTypes.func.isRequired,
        partner: PropTypes.object,
        predictions: PropTypes.arrayOf(PropTypes.object).isRequired,
        refreshData: PropTypes.func.isRequired,
        sendSmsAlert: PropTypes.func,
        setAnswers: PropTypes.func.isRequired,
        updatePredictionWithEvent: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        const { eventMilestones } = props.category;

        const milestoneQueryParam = this.getMilestoneQueryParam();
        const currentMilestone = eventMilestones
            ? eventMilestones[milestoneQueryParam]
            : '';
        const userPreferences = this.getPreferencesFromStorage();
        const {
            showAllPredictions,
            showIframe,
            statsEnabledOnEvent,
            useReminders,
        } = userPreferences || DEFAULT_PREFERENCES;

        this.state = {
            checkedPredictions: {},
            checkedPredictionsState: undefined,
            currentMilestone,
            editingPrediction: false,
            eventMilestones,
            finalizing: false,
            gameStats: undefined,
            manuallyCollapse: false,
            pendingAnswers: {},
            predictionsToUnlock: [],
            selectedMilestoneIndex: milestoneQueryParam,
            showAllPredictions,
            showBatchUnlockModal: false,
            showIframe,
            showManualLockModal: false,
            showSmsModal: false,
            spinRefreshBtn: false,
            statsEnabledOnEvent,
            useReminders,
        };

        this.addToSetAnswerBody = this.addToSetAnswerBody.bind(this);
        this.addToSetAnswerTranslationBody =
            this.addToSetAnswerTranslationBody.bind(this);
        this.openPreviewModalAndRefreshData =
            this.openPreviewModalAndRefreshData.bind(this);
        this.finalize = this.finalize.bind(this);
    }

    componentWillUnmount() {
        clearInterval(this.gameStatsIntervalId);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.sponsors !== this.props.sponsors) {
            this.updateSponsorshipUnits(this.props.sponsors);
        }
    }

    updateSponsorshipUnits = (sponsors) => {
        this.sponsorshipUnitsByType = groupUnitsByTypeForSponsors(sponsors);
    };

    getPreferencesFromStorage = () => {
        const { event } = this.props;
        const eventInfoString = window.localStorage.getItem(LOCAL_STORAGE_KEY);
        const eventInfoObj = JSON.parse(eventInfoString);
        // If no info for this event in local storage, set it to the defaults.
        if (!eventInfoString || eventInfoObj.eventId !== event.id) {
            const defaultsObj = { ...DEFAULT_PREFERENCES, eventId: event.id };
            const defaultsString = JSON.stringify(defaultsObj);
            window.localStorage.setItem(LOCAL_STORAGE_KEY, defaultsString);
            return defaultsObj;
        }
        return eventInfoObj;
    };

    addToSetAnswerBody({
        answerExplanation,
        optionId,
        predictionId,
        reset,
        type,
        value,
    }) {
        const { pendingAnswers } = this.state;

        if (!pendingAnswers[predictionId]) {
            pendingAnswers[predictionId] = {};
        }

        const pendingAnswer = pendingAnswers[predictionId];

        if (!pendingAnswer.options) {
            pendingAnswer.options = {};
        }

        if (reset || answerExplanation !== undefined) {
            if (!answerExplanation || answerExplanation.trim() === '') {
                delete pendingAnswers[predictionId].answerExplanation;
            } else {
                pendingAnswers[predictionId].answerExplanation =
                    answerExplanation;
            }
            if (reset) {
                delete pendingAnswers[predictionId].answerExplanationEdited;
            } else {
                pendingAnswers[predictionId].answerExplanationEdited = true;
            }
        }

        if (type === PREDICTION_TYPES.fillInTheBlank) {
            if (optionId) {
                if (!pendingAnswer.options[optionId]) {
                    pendingAnswer.options[optionId] = {};
                }
                const option = pendingAnswer.options[optionId];
                if (reset) {
                    delete option.edited;
                } else {
                    option.edited = true;
                }
                const trimmedValue = value ? value.trim() : undefined;
                if (!trimmedValue) {
                    delete option.answer;
                } else {
                    option.answer = trimmedValue;
                }
                if (Object.keys(option).length === 0) {
                    delete pendingAnswer.options[optionId];
                }
            }
        } else if (isMultipleChoice({ type })) {
            if (optionId) {
                pendingAnswers[predictionId].options = {
                    [optionId]: {
                        correct: true,
                    },
                };
            } else if (reset) {
                delete pendingAnswer.options;
            }
        } else if (type === PREDICTION_TYPES.poll) {
            throw new Error('Not possible on polls');
        }

        this.forceUpdate();
    }

    addToSetAnswerTranslationBody({
        answerExplanationTranslation,
        optionId,
        predictionId,
        reset,
        type,
        value,
    }) {
        const { pendingAnswers } = this.state;

        if (!pendingAnswers[predictionId]) {
            pendingAnswers[predictionId] = {};
        }

        const pendingAnswer = pendingAnswers[predictionId];

        if (!pendingAnswer.options) {
            pendingAnswer.options = {};
        }
        const { answerExplanation, languageCodeId } =
            answerExplanationTranslation;

        if (reset || answerExplanation !== undefined) {
            if (!answerExplanation || answerExplanation.trim() === '') {
                pendingAnswers[predictionId].entityTranslations =
                    pendingAnswers[predictionId].entityTranslations.filter(
                        (et) => et.languageCodeId === languageCodeId,
                    );
            } else {
                const answerTranslationIndex =
                    pendingAnswers[predictionId].entityTranslations &&
                    pendingAnswers[predictionId].entityTranslations.findIndex(
                        (et) => et.languageCodeId === languageCodeId,
                    );
                if (
                    answerTranslationIndex !== undefined &&
                    answerTranslationIndex !== -1
                ) {
                    pendingAnswers[predictionId].entityTranslations[
                        answerTranslationIndex
                    ] = answerExplanationTranslation;
                } else {
                    pendingAnswers[predictionId].entityTranslations = [
                        answerExplanationTranslation,
                    ];
                }
            }
            if (reset) {
                pendingAnswers[predictionId].entityTranslations = [];
            } else {
                pendingAnswers[predictionId].answerExplanationEdited = true;
            }
        }

        if (type === PREDICTION_TYPES.fillInTheBlank) {
            if (optionId) {
                if (!pendingAnswer.options[optionId]) {
                    pendingAnswer.options[optionId] = {};
                }
                const option = pendingAnswer.options[optionId];
                if (reset) {
                    delete option.edited;
                } else {
                    option.edited = true;
                }
                const trimmedValue = value ? value.trim() : undefined;
                if (!trimmedValue) {
                    delete option.answer;
                } else {
                    option.answer = trimmedValue;
                }
                if (Object.keys(option).length === 0) {
                    delete pendingAnswer.options[optionId];
                }
            }
        } else if (isMultipleChoice({ type })) {
            if (optionId) {
                pendingAnswers[predictionId].options = {
                    [optionId]: {
                        correct: true,
                    },
                };
            } else if (reset) {
                delete pendingAnswer.options;
            }
        } else if (type === PREDICTION_TYPES.poll) {
            throw new Error('Not possible on polls');
        }

        this.forceUpdate();
    }

    // answer is either an option id for multipleChoice Types
    // or an Object containing optionId => value mapping for fill in the blank.
    // undefined is returned if prediction is unanswered.
    static extractAnswer(prediction) {
        if (isMultipleChoice(prediction)) {
            return extractServerAnsweredOptionId(prediction);
        } else if (prediction.type === PREDICTION_TYPES.fillInTheBlank) {
            const options = prediction.options;
            const answers = {};
            options.forEach((option) => {
                if (option.answer) {
                    answers[option.id] = option.answer;
                }
            });
            if (Object.keys(answers).length > 0) {
                return answers;
            }
        }
    }

    pendingAnswersDistinctFromServer() {
        const { predictions } = this.props;
        const { pendingAnswers } = this.state;

        const distinctAnswers = {};
        predictions.forEach((prediction) => {
            const predictionId = prediction.id;
            const pendingAnswer = pendingAnswers[predictionId] || {};
            const pendingAnswerExplanation = pendingAnswer.answerExplanation;

            if (!distinctAnswers[predictionId]) {
                distinctAnswers[predictionId] = {};
            }

            const distinctAnswer = distinctAnswers[predictionId];
            if (!distinctAnswer.options) {
                distinctAnswer.options = {};
            }

            if (
                pendingAnswer.answerExplanationEdited &&
                (pendingAnswerExplanation || '') !==
                    (prediction.answerExplanation || '')
            ) {
                distinctAnswer.answerExplanation =
                    pendingAnswerExplanation || '';
            }
            if (
                pendingAnswer.entityTranslations &&
                pendingAnswer.entityTranslations.length
            ) {
                pendingAnswer.entityTranslations.forEach(
                    ({ answerExplanation, languageCodeId }) => {
                        const predictionEt = prediction.entityTranslations.find(
                            (et) => et.languageCodeId === languageCodeId,
                        );
                        if (
                            (answerExplanation || '') !==
                            (predictionEt.answerExplanation || '')
                        ) {
                            const etIsArray = Boolean(
                                distinctAnswer.entityTranslations &&
                                    distinctAnswer.entityTranslations.length,
                            );
                            if (etIsArray) {
                                const EtAnswerIndex =
                                    distinctAnswer.entityTranslations.findIndex(
                                        (et) =>
                                            et.languageCodeId ===
                                            languageCodeId,
                                    );
                                if (EtAnswerIndex !== -1) {
                                    distinctAnswer.entityTranslations[
                                        EtAnswerIndex
                                    ] = {
                                        answerExplanation,
                                        languageCodeId,
                                    };
                                } else {
                                    distinctAnswer.entityTranslations = [
                                        {
                                            answerExplanation,
                                            languageCodeId,
                                        },
                                    ];
                                }
                            } else {
                                distinctAnswer.entityTranslations = [
                                    { answerExplanation, languageCodeId },
                                ];
                            }
                        }
                    },
                );
            }

            if (isMultipleChoice(prediction, true)) {
                const pendingAnswerOptionId =
                    localAnsweredOptionId(pendingAnswer);
                const serverAnsweredOptionId =
                    extractServerAnsweredOptionId(prediction);
                if (
                    pendingAnswerOptionId !== undefined &&
                    pendingAnswerOptionId !== serverAnsweredOptionId
                ) {
                    distinctAnswer.options = pendingAnswer.options;
                }

                if (!serverAnsweredOptionId && !pendingAnswerOptionId) {
                    delete distinctAnswer.answerExplanation;
                    delete distinctAnswer.answerExplanationEdited;
                }
            } else if (prediction.type === PREDICTION_TYPES.fillInTheBlank) {
                let somethingAnswered;
                prediction.options.forEach((option) => {
                    const pendingAnswerText = extractFitbPendingAnswer(
                        pendingAnswers[predictionId],
                        option.id,
                    );
                    const pendingAnswerEdited = extractFitbPendingEdited(
                        pendingAnswers[predictionId],
                        option.id,
                    );

                    if (option.answer || pendingAnswerText) {
                        somethingAnswered = true;
                    }

                    if (
                        pendingAnswerEdited &&
                        (pendingAnswerText || '') !== (option.answer || '')
                    ) {
                        distinctAnswer.options[option.id] = {
                            answer: pendingAnswerText || '',
                        };
                    }
                });

                // backfill all options for fill in the blank which aren't
                // specified because they're required to be submitted to the
                // server all at once
                if (Object.keys(distinctAnswer.options).length) {
                    prediction.options.forEach((option) => {
                        if (
                            distinctAnswer.options[option.id] === undefined &&
                            option.answer
                        ) {
                            distinctAnswer.options[option.id] = {
                                answer: option.answer,
                            };
                        }
                    });
                }

                // if nothing is answered answerExplanation is not allowed so
                // clear it out.
                if (!somethingAnswered) {
                    delete distinctAnswer.answerExplanation;
                    delete distinctAnswer.answerExplanationEdited;
                }
            } else {
                throw new Error(
                    'Unknown prediction type seen: ',
                    prediction.type,
                );
            }

            if (
                distinctAnswer.options &&
                Object.keys(distinctAnswer.options).length === 0
            ) {
                delete distinctAnswer.options;
            }

            if (Object.keys(distinctAnswer).length === 0) {
                delete distinctAnswers[predictionId];
            }
        });

        return distinctAnswers;
    }

    handleCloseAnswerDialog = () => {
        this.setState({ saveDialogOpen: false });
    };

    handleCollapseAll = () => {
        this.setState({ manuallyCollapse: true });
    };

    giveExpandControlToRow = () => {
        // There's some performance issue when this gets called.
        // To mitigate for now, only setState when the value actually changes.
        if (this.state.manuallyCollapse !== null) {
            this.setState({ manuallyCollapse: null });
        }
    };

    refreshDataAndVerifyState(
        pendingAnswersBefore,
        predictionsBefore,
        callback,
    ) {
        const { notifyWarning, refreshData } = this.props;
        // compare the refreshed data with the stale data and answers, warn user of any differences
        refreshData(() => {
            const { predictions: predictionsAfter } = this.props;
            const pendingAnswersAfter = this.trimAnswerExplanations(
                this.filterForValidAnswers(
                    this.pendingAnswersDistinctFromServer(),
                    predictionsAfter,
                ),
            );
            if (
                pendingAnswersConflicting({
                    pendingAnswersAfter,
                    pendingAnswersBefore,
                    predictionsAfter,
                    predictionsBefore,
                })
            ) {
                notifyWarning(
                    'Your original answers are in conflict with a change made on the server, please review your selections and submit again',
                );
                callback('conflicting');
                return;
            } else if (
                pendingAnswersDifferent({
                    pendingAnswersAfter,
                    pendingAnswersBefore,
                })
            ) {
                if (Object.keys(pendingAnswersAfter).length === 0) {
                    notifyWarning(
                        'After server refresh, predictions already in sync',
                    );
                    callback('insync');
                    return;
                } else {
                    notifyWarning(
                        'Changes were made on the server since the last refresh, Your changes are compatible but have been pared down to reflect already saved state.',
                    );
                    callback();
                    return;
                }
            } else {
                callback();
            }
        });
    }

    openPreviewModalAndRefreshData =
        (pendingAnswersBefore, predictionsBefore) => () => {
            this.setState(
                {
                    refreshingData: true,
                    saveDialogOpen: true,
                    winnerSummary: undefined,
                },
                () => {
                    this.refreshDataAndVerifyState(
                        pendingAnswersBefore,
                        predictionsBefore,
                        (error) => {
                            if (error) {
                                this.setState({ saveDialogOpen: false });
                            }
                            this.setState({ refreshingData: false });
                        },
                    );
                },
            );
        };

    refreshAndSetAnswers = (
        pendingAnswersBefore,
        predictionsBefore,
        answerMilestone,
    ) => {
        const { event, notifyError, notifySuccess, setAnswers } = this.props;
        this.setState({ saving: true }, () => {
            this.refreshDataAndVerifyState(
                pendingAnswersBefore,
                predictionsBefore,
                async (error) => {
                    if (error) {
                        this.setState({ saveDialogOpen: false, saving: false });
                        return;
                    }

                    const pendingAnswers = pendingAnswersBefore;

                    try {
                        await setAnswers({
                            answerMilestone,
                            answers: pendingAnswers,
                            eventId: event.id,
                        });
                        notifySuccess('Save Successful');

                        // delete any answers we just saved out of pending to start
                        // from a clean slate.
                        Object.keys(pendingAnswers).forEach((predictionId) => {
                            delete this.state.pendingAnswers[predictionId];
                        });

                        this.setState({ saveDialogOpen: false, saving: false });
                    } catch (error) {
                        this.setState({ saving: false });
                        notifyError(error.message || JSON.stringify(error));
                    }
                },
            );
        });
    };

    getAnswerStats(pendingAnswers) {
        const { predictions } = this.props;

        let allAnswered = true;
        let atLeastOnePendingRevised = false;
        let allPendingAreRevised = true;
        let serverAllAnswered = true;

        predictions.forEach((prediction) => {
            let serverAnswered;
            if (isMultipleChoice(prediction)) {
                serverAnswered = !!extractServerAnsweredOptionId(prediction);
            } else {
                serverAnswered = prediction.options.every(
                    (option) => !!option.answer,
                );
            }
            allAnswered =
                allAnswered &&
                (serverAnswered || !!pendingAnswers[prediction.id]);
            serverAllAnswered = serverAllAnswered && serverAnswered;

            if (pendingAnswers[prediction.id]) {
                const revised = serverAnswered;
                atLeastOnePendingRevised = atLeastOnePendingRevised || revised;
                allPendingAreRevised = allPendingAreRevised && revised;
            }
        });

        return {
            allAnswered,
            allPendingAreRevised,
            atLeastOnePendingRevised,
            serverAllAnswered,
        };
    }

    determineAnswerState(pendingAnswers) {
        const { allAnswered, allPendingAreRevised, serverAllAnswered } =
            this.getAnswerStats(pendingAnswers);

        if (serverAllAnswered) {
            return AnswerState.revisingFinalizedGame;
        } else {
            if (allAnswered) {
                return AnswerState.answeringFinalQuestions;
            } else {
                if (allPendingAreRevised) {
                    return AnswerState.revisingNonFinalizedGame;
                } else {
                    return AnswerState.answeringNonFinalQuestions;
                }
            }
        }
    }

    renderSaveAnswersDialog(pendingAnswersBefore, predictionsBefore) {
        const { notifyError, predictions } = this.props;
        const {
            currentMilestone,
            eventMilestones,
            refreshingData,
            saveDialogOpen,
            saving,
            winnerSummary,
        } = this.state;
        const answerStats = this.getAnswerStats(pendingAnswersBefore);
        const answerState = this.determineAnswerState(pendingAnswersBefore);

        return (
            <SaveAnswerDialog
                answerState={answerState}
                answerStats={answerStats}
                confirmAnswer={this.refreshAndSetAnswers}
                currentMilestone={currentMilestone}
                eventMilestones={eventMilestones}
                handleCloseAnswerDialog={this.handleCloseAnswerDialog}
                notifyError={notifyError}
                parentSaving={saving}
                pendingAnswersBefore={pendingAnswersBefore}
                predictions={predictions}
                predictionsBefore={predictionsBefore}
                refreshingData={refreshingData}
                shouldBeOpen={saveDialogOpen}
                winnerSummary={winnerSummary}
            />
        );
    }

    filterForValidAnswers = (pendingAnswers, predictions) => {
        const validAnswers = {};
        Object.keys(pendingAnswers).forEach((predictionId) => {
            const pendingAnswer = pendingAnswers[predictionId];
            const prediction = predictions.find((p) => p.id === predictionId);
            if (prediction.type === PREDICTION_TYPES.fillInTheBlank) {
                // answerExplanation only is okay.
                if (pendingAnswer.options === undefined) {
                    validAnswers[predictionId] = pendingAnswer;
                }
                // if options are passed, all must exist
                else if (
                    prediction.options.every(
                        (o) =>
                            pendingAnswer &&
                            pendingAnswer.options &&
                            pendingAnswer.options[o.id] &&
                            pendingAnswer.options[o.id].answer,
                    )
                ) {
                    validAnswers[predictionId] = pendingAnswer;
                }
            }
            // multiple choice have no invalid states currently
            else {
                validAnswers[predictionId] = pendingAnswer;
            }
        });
        return validAnswers;
    };

    trimAnswerExplanations(pendingAnswers) {
        const pendingAnswersCopy = JSON.parse(JSON.stringify(pendingAnswers));

        Object.keys(pendingAnswersCopy).forEach((predictionId) => {
            const { answerExplanation } = pendingAnswersCopy[predictionId];
            if (answerExplanation) {
                pendingAnswersCopy[predictionId].answerExplanation =
                    multilineTrim(answerExplanation);
            }
        });
        return pendingAnswersCopy;
    }

    async finalize() {
        const { event, finalizeEvent, notifySuccess, refreshData } = this.props;
        confirmAlert({
            buttons: [
                {
                    label: 'Finalize Event',
                    onClick: async () => {
                        this.setState({ finalizing: true });
                        await finalizeEvent(event);
                        await refreshData(() => {
                            this.setState({ finalizing: false });
                        });
                        notifySuccess('Event Finalized');
                    },
                },
                {
                    label: 'Cancel',
                    onClick: () => {},
                },
            ],
            message:
                'Do you want to finalize the event? This will end the event for all players and finalize the leaderboard.',
            title: 'Finalize Event',
        });
    }

    createPrediction = () => {
        this.setState({ createPredictionPressed: true });
    };

    // ======== MODAL HANDLERS ========

    // Edit

    showEditForm = (prediction) => {
        this.setState({
            editingPrediction: true,
            predictionToEdit: prediction,
        });
    };

    closeEditForm = () => {
        this.setState({
            editingPrediction: false,
            predictionToEdit: undefined,
        });
    };

    renderEditModal() {
        const {
            category,
            event,
            notifyError,
            notifySuccess,
            partner,
            predictions,
        } = this.props;
        const { predictionToEdit } = this.state;
        const inputFields = predictionInputFields({
            category,
            event,
            predictions,
            sponsorshipUnits:
                this.sponsorshipUnitsByType[SponsorshipUnitType.PREDICTION],
        });
        const translationInputFields = predictionTranslationInputFields();
        const label = 'EDIT';
        const formStartingValue =
            preformPredictionRenderTransform(predictionToEdit);

        return (
            <FormBuilder
                fields={inputFields}
                label={label}
                notifyError={notifyError}
                notifySuccess={notifySuccess}
                onClose={this.closeEditForm}
                onSubmit={this.handleSubmitEdit}
                startingValue={formStartingValue}
                supportedLanguages={partner.properties.supportedLanguages || []}
                translationFields={translationInputFields}
            />
        );
    }

    handleSubmitEdit = async (formState) => {
        const { event, updatePredictionWithEvent } = this.props;
        const { id: eventId } = event;
        const transformedFormState = presubmitPredictionTransform(formState);
        if (transformedFormState.id) {
            await updatePredictionWithEvent(
                eventId,
                transformedFormState.id,
                transformedFormState,
            );
        }
    };

    // Batch UNlock

    showBatchUnlock = () => {
        const { notifyWarning } = this.props;
        const predictionsToUnlock = this.getCheckedPredictions();
        if (predictionsToUnlock.length <= 0) {
            return;
        }

        if (this.checkedPredictionsHaveDifferentStates(predictionsToUnlock)) {
            notifyWarning(
                "Checked Predictions don't have the same state. Unchecking. Sorry",
            );
            this.setState({
                checkedPredictions: {},
                checkedPredictionsState: undefined,
            });
            return;
        }

        this.setState({ predictionsToUnlock, showBatchUnlockModal: true });
    };

    closeBatchUnlock = () => {
        this.setState({ showBatchUnlockModal: false });
    };

    renderBatchUnlockModal = () => {
        const { batchUnlockPredictions, event } = this.props;
        const { predictionsToUnlock } = this.state;

        return (
            <BatchUnlockModal
                batchUnlockPredictions={batchUnlockPredictions}
                eventId={event.id}
                handleBatchUnlockResponse={this.handleBatchUnlockResponse}
                handleCloseUnlockModal={this.closeBatchUnlock}
                predictions={predictionsToUnlock}
            />
        );
    };

    handleBatchUnlockResponse = (success, error) => {
        const { notifyError, notifySuccess, refreshData } = this.props;

        if (success) {
            notifySuccess('Successfully Unlocked Prediction(s)');
        } else {
            notifyError(`Failed to unlock ${error.message || error}`);
        }
        refreshData();
        this.setState({
            checkedPredictions: {},
            checkedPredictionsState: undefined,
            predictionsToUnlock: [],
            showBatchUnlockModal: false,
        });
    };

    // Batch LOCK

    showBatchLock = () => {
        this.setState({ showManualLockModal: true });
    };

    closeBatchLock = () => {
        this.setState({ showManualLockModal: false });
    };

    renderManualLockModal = () => {
        const { batchManualLockPredictions, event } = this.props;
        const predictionIdsToLock = this.getCheckedPredictionIds();

        return (
            <ManualLockModal
                batchManualLockPredictions={batchManualLockPredictions}
                eventId={event.id}
                handleCloseLockModal={this.closeBatchLock}
                handleManualLockResponse={this.handleManualLockResponse}
                predictionIdsToLock={predictionIdsToLock}
            />
        );
    };

    handleManualLockResponse = async (success, error) => {
        const { notifyError, notifySuccess, refreshData } = this.props;

        if (success) {
            notifySuccess('Successfully Locked');
        } else {
            notifyError(`Failed to manual lock ${error.message || error}`);
        }
        refreshData();

        this.setState({
            checkedPredictions: {},
            checkedPredictionsState: undefined,
            predictionsToUnlock: [],
            showManualLockModal: false,
        });
    };

    // Send SMS Alert

    showSmsAlert = () => {
        this.setState({ showSmsModal: true });
    };

    closeSmsAlert = () => {
        this.setState({ showSmsModal: false });
    };

    renderSmsModal = () => {
        const { classes, event, notifyError, partner, sendSmsAlert } =
            this.props;
        const { smsProperties } = partner.properties;
        return (
            <SendSmsModal
                classes={classes}
                eventId={event.id}
                handleCloseModal={this.closeSmsAlert}
                handleSmsAlertResponse={this.handleSmsAlertResponse}
                notifyError={notifyError}
                partner={partner}
                partnerId={partner.id}
                partnerSmsProperties={smsProperties}
                sendSmsAlert={sendSmsAlert}
            />
        );
    };

    handleSmsAlertResponse = (success, error) => {
        const { notifyError, notifySuccess, refreshData } = this.props;

        if (success) {
            notifySuccess('Successfully Sent SMS Alert');
        } else {
            notifyError(`Failed to send SMS alert ${error.message || error}`);
        }
        refreshData();

        this.setState({
            showSmsModal: false,
        });
    };

    forceDeletePrediction = ({ id, parentId }) => {
        const { deletePrediction } = this.props;
        deletePrediction({ id, parentId }, true);
    };

    sortPredictions = () => {
        const { eventMilestones, selectedMilestoneIndex, showAllPredictions } =
            this.state;
        const { predictions } = this.props;
        const sortedPredictions = {
            answeredPredictions: [],
            toBeAnsweredPredictions: [],
            toBeReleasedPredictions: [],
            unlockedPredictions: [],
            unscheduledPredictions: [],
        };

        const milestoneFilter = eventMilestones
            ? eventMilestones[selectedMilestoneIndex]
            : null;

        predictions.forEach((prediction) => {
            const state = determinePredictionLockState(prediction);
            switch (state) {
                case 'hidden':
                    if (
                        prediction.releaseMilestone === UNSPECIFIED &&
                        prediction.answerMilestone === UNSPECIFIED
                    ) {
                        sortedPredictions.unscheduledPredictions.push(
                            prediction,
                        );
                    } else if (
                        prediction.releaseMilestone === milestoneFilter ||
                        showAllPredictions
                    ) {
                        sortedPredictions.toBeReleasedPredictions.push(
                            prediction,
                        );
                    }
                    break;
                case 'locked': {
                    const predictionAnswered =
                        this.isPredictionAnswered(prediction);
                    if (
                        prediction.answerMilestone === UNSPECIFIED ||
                        prediction.answerMilestone === milestoneFilter ||
                        showAllPredictions
                    ) {
                        if (predictionAnswered) {
                            sortedPredictions.answeredPredictions.push(
                                prediction,
                            );
                        } else {
                            sortedPredictions.toBeAnsweredPredictions.push(
                                prediction,
                            );
                        }
                    }
                    break;
                }
                case 'unlocked':
                    if (
                        prediction.answerMilestone === UNSPECIFIED ||
                        prediction.releaseMilestone === UNSPECIFIED ||
                        prediction.releaseMilestone === milestoneFilter ||
                        prediction.answerMilestone === milestoneFilter ||
                        showAllPredictions
                    ) {
                        sortedPredictions.unlockedPredictions.push(prediction);
                    }
                    break;
                default:
                    break;
            }
        });
        return sortedPredictions;
    };

    isPredictionAnswered = (prediction) => {
        const serverCorrectOption = prediction.options.find(
            (option) => option.correct || option.answer,
        );
        return serverCorrectOption !== undefined;
    };

    handleChangeMilestone = (e, value) => {
        const { event, refreshData } = this.props;
        const { eventMilestones } = this.state;
        const stateObj = {
            selectedMilestoneIndex: `${value}`,
        };
        window.history.replaceState(
            stateObj,
            'milestone',
            `${window.location.pathname}?event=${event.id}&milestone=${value}`,
        );
        this.setState({
            currentMilestone: eventMilestones[value],
            selectedMilestoneIndex: value,
        });
        refreshData();
    };

    getMilestoneQueryParam = () => {
        const query = window.location.search;
        const parsedQuery = queryString.parse(query);
        const milestoneParam =
            parsedQuery['milestone'] !== undefined
                ? parsedQuery['milestone']
                : 0;
        return parseInt(milestoneParam, 10);
    };

    handleSwitchChange = (name) => (event) => {
        let { showIframe, statsEnabledOnEvent } = this.state;
        const checked = event.target.checked;
        const userPreferences = this.getPreferencesFromStorage();
        userPreferences[name] = checked;

        if (name === SHOW_IFRAME && checked) {
            userPreferences[SHOW_STATS] = false;
            statsEnabledOnEvent = false;
            this.setState({
                [name]: checked,
                statsEnabledOnEvent,
            });
        } else if (name === SHOW_STATS && checked) {
            userPreferences[SHOW_IFRAME] = false;
            showIframe = false;
            this.setState({
                [name]: checked,
                showIframe,
            });
        } else {
            this.setState({
                [name]: checked,
            });
        }
        const preferenceString = JSON.stringify(userPreferences);
        window.localStorage.setItem(LOCAL_STORAGE_KEY, preferenceString);
    };

    handleManualRefresh = async () => {
        const { refreshData } = this.props;
        this.setState({
            refreshingData: true,
        });
        refreshData();
        this.setState({
            refreshingData: false,
        });
    };

    // Return true if selected predictions are in different states
    checkedPredictionsHaveDifferentStates(checkedPredictions) {
        const firstState = determinePredictionLockState(checkedPredictions[0]);
        for (const prediction of checkedPredictions) {
            const state = determinePredictionLockState(prediction);
            if (state !== firstState) {
                return true;
            }
        }

        return false;
    }

    // return Prediction[]
    getCheckedPredictions = () => {
        const { predictions } = this.props;
        const checkedPredictionIds = this.getCheckedPredictionIds();
        const fullPredictions = [];
        for (const predictionId of checkedPredictionIds) {
            for (const prediction of predictions) {
                if (prediction.id === predictionId) {
                    fullPredictions.push(prediction);
                }
            }
        }
        return fullPredictions;
    };

    // return string[]
    getCheckedPredictionIds = () => {
        const { checkedPredictions } = this.state;
        const checkedPredictionIds = [];

        for (const predictionId in checkedPredictions) {
            if (checkedPredictions[predictionId]) {
                checkedPredictionIds.push(predictionId);
            }
        }

        return checkedPredictionIds;
    };

    renderSwitches = () => {
        return (
            <FormGroup row>
                <FormControlLabel
                    control={
                        <Switch
                            checked={this.state.showAllPredictions}
                            color="primary"
                            onChange={this.handleSwitchChange(
                                'showAllPredictions',
                            )}
                            value="showAllPredictions"
                        />
                    }
                    label="Show All Predictions"
                />
                <FormControlLabel
                    control={
                        <Switch
                            checked={this.state.statsEnabledOnEvent}
                            color="primary"
                            onChange={this.handleSwitchChange(SHOW_STATS)}
                            value={SHOW_STATS}
                        />
                    }
                    label="Enable Stats"
                />
                {/* Temporary hiding these as our current partners don't use them. */}
                {/* <FormControlLabel
                    control={
                        <Switch
                            checked={this.state.showIframe}
                            color="primary"
                            onChange={this.handleSwitchChange(SHOW_IFRAME)}
                            value={SHOW_IFRAME}
                        />
                    }
                    label="Show Gamecast"
                />
                <FormControlLabel
                    control={
                        <Switch
                            checked={this.state.useReminders}
                            color="primary"
                            onChange={this.handleSwitchChange(USE_REMINDERS)}
                            value={USE_REMINDERS}
                        />
                    }
                    label="Enable Reminders"
                /> */}
            </FormGroup>
        );
    };

    getPendingAnswersPerMilestone = () => {
        const { predictions } = this.props;
        const { eventMilestones } = this.state;

        const pendingPredictions = predictions.filter(
            (prediction) =>
                determinePredictionLockState(prediction) === 'locked' &&
                !this.isPredictionAnswered(prediction),
        );

        const pendingAnswersPerMilestone = [];

        for (let i = 0; i < eventMilestones.length; i++) {
            const numPending = pendingPredictions.filter(
                (prediction) =>
                    prediction.answerMilestone === eventMilestones[i],
            ).length;
            pendingAnswersPerMilestone.push(numPending);
        }

        return pendingAnswersPerMilestone;
    };

    // returns true if allowed to check or false if not
    handlePredictionCheck = (predictionId, predictionLockState, checked) => {
        const { checkedPredictions, checkedPredictionsState } = this.state;

        // When unchecking, update checkedPredictionsObject and state
        // Make sure to checkedPredictionState to undefined if unchecking this makes no others checked
        if (!checked) {
            delete checkedPredictions[predictionId];
            if (Object.keys(checkedPredictions).length === 0) {
                this.setState({ checkedPredictionsState: undefined });
            }
            this.setState({ checkedPredictions });
            return true;
        }

        // When checking, don't allow checking of predictions in different states.
        if (
            Object.keys(checkedPredictions).length === 0 ||
            predictionLockState === checkedPredictionsState
        ) {
            checkedPredictions[predictionId] = checked;
            this.setState({
                checkedPredictions,
                checkedPredictionsState: predictionLockState,
            });
            return true;
        } else {
            return false;
        }
    };

    renderFilterBar = () => {
        const { classes } = this.props;
        const { eventMilestones, selectedMilestoneIndex, showAllPredictions } =
            this.state;

        if (!eventMilestones || showAllPredictions) {
            return null;
        }

        const badgeCount = this.getPendingAnswersPerMilestone();

        return (
            <AppBar color="default" position="static">
                <Tabs
                    indicatorColor="primary"
                    onChange={this.handleChangeMilestone}
                    scrollButtons="auto"
                    textColor="primary"
                    value={selectedMilestoneIndex}
                    variant="scrollable"
                >
                    {eventMilestones.map((milestone, index) => (
                        <Tab
                            key={milestone}
                            label={
                                <Badge
                                    badgeContent={badgeCount[index]}
                                    className={classes.badgePadding}
                                    color="secondary"
                                    invisible={
                                        badgeCount[index] === 0 ||
                                        index > selectedMilestoneIndex - 1
                                    }
                                    overlap="rectangular"
                                >
                                    {milestone}
                                </Badge>
                            }
                        />
                    ))}
                </Tabs>
            </AppBar>
        );
    };

    renderPredictions = (predictions) => {
        const {
            batchEditPredictions,
            batchManualLockPredictions,
            batchUnlockPredictions,
            event,
            notifyError,
            notifySuccess,
            refreshData,
            updatePredictionWithEvent,
        } = this.props;
        const {
            checkedPredictions,
            eventMilestones,
            manuallyCollapse,
            selectedMilestoneIndex,
        } = this.state;

        const pendingAnswers = this.pendingAnswersDistinctFromServer();
        let currentMilestone;
        if (eventMilestones) {
            currentMilestone = eventMilestones[selectedMilestoneIndex];
        } else {
            currentMilestone = 'NA';
        }
        return predictions.map((prediction) => (
            <PredictionRow
                addToSetAnswerBody={this.addToSetAnswerBody}
                addToSetAnswerTranslationBody={
                    this.addToSetAnswerTranslationBody
                }
                batchEditPredictions={batchEditPredictions}
                batchManualLockPredictions={batchManualLockPredictions}
                batchUnlockPredictions={batchUnlockPredictions}
                currentMilestone={currentMilestone}
                deletePrediction={this.forceDeletePrediction}
                event={event}
                eventMilestones={eventMilestones}
                giveExpandControlToRow={this.giveExpandControlToRow}
                handleBatchUnlockResponse={this.handleBatchUnlockResponse}
                handlePredictionCheck={this.handlePredictionCheck}
                key={prediction.id}
                manuallyCollapse={manuallyCollapse}
                notifyError={notifyError}
                notifySuccess={notifySuccess}
                pendingAnswer={pendingAnswers[prediction.id]}
                prediction={prediction}
                predictionIsChecked={checkedPredictions[prediction.id]}
                refreshData={refreshData}
                showEditForm={this.showEditForm}
                updatePredictionWithEvent={updatePredictionWithEvent}
            />
        ));
    };

    renderEmptyText = () => {
        const { classes } = this.props;
        return (
            <Typography
                className={classes.emptySection}
                component="p"
                variant="caption"
            >
                No Predictions
            </Typography>
        );
    };

    handleToastBatchUnlock = (predictionsToUnlock) => () => {
        this.setState({ predictionsToUnlock, showBatchUnlockModal: true });
    };

    addReleaseReminders = (releaseBatches) => {
        const { gameStats } = this.state;
        const { clock, quarter } = gameStats;
        const quarterString = quarter >= 5 ? 'OT' : `Q${quarter}`;

        for (const batch in releaseBatches) {
            const predictions = releaseBatches[batch];
            const message = `${predictions.length} Predictions to release @ ${predictions[0].releaseMilestone} ${predictions[0].releaseTime} (current: ${quarterString} ${clock})`;
            this.props.enqueueSnackbar(message, {
                action: (
                    <Button
                        onClick={this.handleToastBatchUnlock(predictions)}
                        size="small"
                        style={{ color: '#fff' }}
                    >
                        Release
                    </Button>
                ),
                autoHideDuration: 10000,
                variant: 'info',
            });
        }
    };

    renderFabs = () => {
        const { classes } = this.props;
        const { checkedPredictionsState } = this.state;
        if (!checkedPredictionsState) {
            const actions = [
                {
                    icon: <AddIcon />,
                    name: 'New Prediction',
                    onClick: this.createPrediction,
                },
                {
                    icon: <MessageIcon />,
                    name: 'Send SMS',
                    onClick: this.showSmsAlert,
                },
            ];
            return (
                <>
                    <Fab
                        className={classes.fabBtn}
                        color="secondary"
                        onClick={this.handleManualRefresh}
                    >
                        <RefreshIcon fontSize="inherit" />
                    </Fab>

                    <ActionButton
                        actions={actions}
                        className={classes.fabBtn}
                        relativePosition={true}
                    />
                </>
            );
        } else {
            return (
                <>
                    {checkedPredictionsState ===
                        PREDICTION_LOCK_STATES.HIDDEN && (
                        <>
                            <Fab
                                className={classes.fabBtn}
                                color="primary"
                                onClick={this.showBatchUnlock}
                            >
                                <UnlockIcon fontSize="inherit" />
                            </Fab>
                        </>
                    )}
                    {checkedPredictionsState ===
                        PREDICTION_LOCK_STATES.UNLOCKED && (
                        <Fab
                            className={classes.fabBtn}
                            color="secondary"
                            onClick={this.showBatchLock}
                        >
                            <LockIcon />
                        </Fab>
                    )}
                </>
            );
        }
    };

    render() {
        const { category, classes, event, iteration, predictions } = this.props;
        const {
            createPredictionPressed,
            editingPrediction,
            finalizing,
            gameStats,
            saveDialogOpen,
            selectedMilestoneIndex,
            showBatchUnlockModal,
            showIframe,
            showManualLockModal,
            showSmsModal,
            spinRefreshBtn,
            statsEnabledOnEvent,
            useReminders,
        } = this.state;

        if (createPredictionPressed) {
            return (
                <Redirect
                    to={`/predictions?event=${event.id}&create=true&milestone=${selectedMilestoneIndex}`}
                />
            );
        }

        const breadcrumbs = [
            { link: '/partners', text: 'Partners' },
            {
                link: `/iterations?category=${category.id}`,
                text: `${category.name}'s Iterations`,
            },
            {
                link: `/events?iteration=${iteration.id}`,
                text: `${iteration.name}'s Events`,
            },
            {
                link: `/predictions?event=${event.id}`,
                text: `${event.name}'s Predictions`,
            },
            { text: `${event.name} Validation` },
        ];

        const predictionsCopy = JSON.parse(JSON.stringify(predictions));
        const pendingAnswers = this.pendingAnswersDistinctFromServer();
        const trimmedPendingAnswers =
            this.trimAnswerExplanations(pendingAnswers);
        const validTrimmedPendingAnswers = this.filterForValidAnswers(
            trimmedPendingAnswers,
            predictions,
        );

        const pendingCount = Object.keys(validTrimmedPendingAnswers).length;

        let state = determineEventState(event);
        let stateColor = 'textPrimary';

        if (state === 'Hidden') {
            state = 'Event is hidden from players';
            stateColor = 'error';
        } else if (state === PREDICTION_EVENT_STATES.final) {
            state = 'Event is finalized';
            stateColor = 'error';
        }

        const title = `${event.name}`;

        const sortedPredictions = this.sortPredictions();
        const {
            answeredPredictions,
            toBeAnsweredPredictions,
            toBeReleasedPredictions,
            unlockedPredictions,
            unscheduledPredictions,
        } = sortedPredictions;

        const statTableClasses = [classes.statsTableWrapper];
        if (spinRefreshBtn) {
            statTableClasses.push(classes.translucent);
        }

        return (
            <div className={classes.root}>
                <Paper
                    className={classes.paper}
                    style={{ borderRadius: '0px' }}
                >
                    <BreadcrumbBar breadcrumbs={breadcrumbs} />
                    <Typography component="span" variant="h6">
                        <Typography component="span" variant="h6">
                            {title}
                        </Typography>
                        <Typography
                            color={stateColor}
                            gutterBottom
                            variant="body1"
                        >
                            {titleCase(state)}
                        </Typography>
                    </Typography>
                    <AccordionActions
                        style={{ justifyContent: 'space-between' }}
                    >
                        {this.renderSwitches()}
                        {!finalizing && !event.finalized && (
                            <Button
                                color="primary"
                                key={`finalizeEventAnswers-${event.id}`}
                                onClick={this.finalize}
                                size="small"
                                variant="contained"
                            >
                                Finalize
                            </Button>
                        )}
                        <Button
                            className={classes.visibleButton}
                            color="secondary"
                            onClick={this.handleCollapseAll}
                            size="small"
                        >
                            Collapse All
                        </Button>
                    </AccordionActions>
                </Paper>
                {this.renderFilterBar()}
                <Grid container justifyContent="center">
                    <Grid item md={6} sm={12} xs={12}>
                        <Typography
                            className={classes.sectionHeader}
                            component="p"
                            variant="h6"
                        >
                            Locked: Awaiting Validation
                        </Typography>
                        {toBeAnsweredPredictions.length === 0 &&
                            this.renderEmptyText()}
                        {toBeAnsweredPredictions.length > 0 &&
                            this.renderPredictions(toBeAnsweredPredictions)}
                        <Typography
                            className={classes.sectionHeader}
                            component="p"
                            variant="h6"
                        >
                            Live
                        </Typography>
                        {unlockedPredictions.length === 0 &&
                            this.renderEmptyText()}
                        {unlockedPredictions.length > 0 &&
                            this.renderPredictions(unlockedPredictions)}
                        <Typography
                            className={classes.sectionHeader}
                            component="p"
                            variant="h6"
                        >
                            Not Live Yet
                        </Typography>
                        {toBeReleasedPredictions.length === 0 &&
                            this.renderEmptyText()}
                        {toBeReleasedPredictions.length > 0 &&
                            this.renderPredictions(toBeReleasedPredictions)}
                        <Typography
                            className={classes.sectionHeader}
                            component="p"
                            variant="h6"
                        >
                            Unscheduled
                        </Typography>
                        {unscheduledPredictions.length === 0 &&
                            this.renderEmptyText()}
                        {unscheduledPredictions.length > 0 &&
                            this.renderPredictions(unscheduledPredictions)}
                        <Typography
                            className={classes.sectionHeader}
                            component="p"
                            variant="h6"
                        >
                            Answered
                        </Typography>
                        {answeredPredictions.length === 0 &&
                            this.renderEmptyText()}
                        {answeredPredictions.length > 0 &&
                            this.renderPredictions(answeredPredictions)}
                    </Grid>
                    {!showIframe && gameStats && statsEnabledOnEvent && (
                        <Grid
                            className={statTableClasses.join(' ')}
                            item
                            md={6}
                            sm={12}
                            style={{ opacity: spinRefreshBtn ? 0.5 : '' }}
                            xs={12}
                        >
                            <PlayByPlay gameStats={gameStats} />
                            <NBAStatsTable gameStats={gameStats} />
                        </Grid>
                    )}
                    {showIframe && <AnswerGamecast />}
                </Grid>
                <Zoom
                    in={pendingCount > 0}
                    key="save"
                    style={{
                        transitionDelay: `${pendingCount > 0 ? 195 : 0}ms`,
                    }}
                    timeout={195}
                    unmountOnExit
                >
                    <Button
                        className={classes.saveButton}
                        key={`saveEventAnswers-${event.id}`}
                        onClick={this.openPreviewModalAndRefreshData(
                            validTrimmedPendingAnswers,
                            predictionsCopy,
                        )}
                        size="small"
                        variant="contained"
                    >
                        <SaveIcon
                            className={classNames(
                                classes.leftIcon,
                                classes.iconSmall,
                            )}
                        />
                        {`Save ${pendingCount}`}
                    </Button>
                </Zoom>
                <Zoom
                    in={pendingCount <= 0}
                    key="create"
                    style={{
                        transitionDelay: `${pendingCount <= 0 ? 195 : 0}ms`,
                    }}
                    timeout={195}
                    unmountOnExit
                >
                    <div className={classes.fabContainer}>
                        {this.renderFabs()}
                    </div>
                </Zoom>
                {gameStats && gameStats.clock && useReminders && (
                    <PredictionReminder
                        addReleaseReminders={this.addReleaseReminders}
                        categoryType="NBA"
                        gameStats={gameStats}
                        predictions={predictions}
                    />
                )}
                {saveDialogOpen &&
                    this.renderSaveAnswersDialog(
                        validTrimmedPendingAnswers,
                        predictionsCopy,
                    )}
                {editingPrediction && this.renderEditModal(predictions[0])}
                {showBatchUnlockModal && this.renderBatchUnlockModal()}
                {showManualLockModal && this.renderManualLockModal()}
                {showSmsModal && this.renderSmsModal()}
            </div>
        );
    }
}

const styles = (theme) => ({
    badgePadding: {
        padding: `0 ${theme.spacing(2)}px`,
    },
    emptySection: {
        marginLeft: '20px',
        opacity: 0.65,
    },
    fabBtn: {
        fontSize: '35px',
        marginRight: '8px',
    },
    fabContainer: {
        alignItems: 'flex-end',
        bottom: theme.spacing(3),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        margin: theme.spacing(),
        position: 'fixed',
        right: theme.spacing(11),
        width: '305px',
    },
    iconSmall: {
        fontSize: 20,
    },
    leftIcon: {
        marginRight: theme.spacing(),
    },
    paper: {
        padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
    },
    root: {
        margin: `${theme.spacing()}px auto 50px`,
        width: '100%',
    },
    saveButton: {
        bottom: theme.spacing(3),
        margin: theme.spacing(),
        position: 'fixed',
        right: theme.spacing(11),
    },
    sectionHeader: {
        margin: '20px 0px',
    },
    statsTableWrapper: {
        marginTop: '20px',
        opacity: 1,
        transition: '0.5s',
        transitionTimingFunction: 'ease-out',
    },
    translucent: {
        opacity: 0.5,
        transitionTimingFunction: 'ease-in',
    },
});

export default withSnackbar(withStyles(styles)(AnswerPredictions));
