import {
    BingoSquare,
    BingoSquareSheetImport,
    LocaleIds,
} from '../../service/Dto';
import { WithNumber } from '../utilityTypes';
import {
    EntityTableExport,
    EntityTableImport,
    EntityTableTransformer,
} from './EntityTableTransformer';
import parseEntity, { ParseConfig } from './parseEntity';
import { parseDecimalInt } from './util';

const parseConfig: ParseConfig<WithNumber<BingoSquareSheetImport>> = {
    number: {
        column: 0,
        fromCell: parseDecimalInt,
    },
    text: {
        column: 1,
    },
    imageUrl: {
        column: 2,
    },
    gameInterval: {
        column: 3,
    },
    gameEventType: {
        column: 4,
    },
    team: {
        column: 5,
    },
    player: {
        column: 6,
    },
    configNumber: {
        column: 7,
    },
} as const;

const isRowEmpty = (row: string[]) =>
    row.length === 0 || row.every((cell) => cell === '');

const firstTabHeadlineRows = [
    ['', 'Visual', '', 'Occurrence Validation Config', '', '', ''],
    [
        '#',
        'Text',
        'Image Url',
        'Game Interval',
        'Game Event Type',
        'Team',
        'Player ID',
        'Number',
    ],
];

const bingoSquareTableTransformer: EntityTableTransformer<
    BingoSquareSheetImport,
    BingoSquare
> = {
    fromTable: (input: EntityTableImport): BingoSquareSheetImport[] => {
        const entities: WithNumber<BingoSquareSheetImport>[] = [];
        const [firstTab] = input;
        let rowsToParse = firstTab.values
            .slice(firstTabHeadlineRows.length)
            .filter((row) => !isRowEmpty(row));

        while (rowsToParse.length > 0) {
            const { nextRowsToParse, parsedEntity } = parseEntity<
                WithNumber<BingoSquareSheetImport>
            >(parseConfig, rowsToParse);
            entities.push(parsedEntity);

            rowsToParse = nextRowsToParse;
        }

        const resultWithNumber = entities;

        // eslint-disable-next-line no-unused-vars
        return resultWithNumber.map(({ number, ...rest }) => rest);
    },
    toTable: (entities: BingoSquare[]) => {
        const result: EntityTableExport = [
            {
                languageId: LocaleIds[0],
                values: firstTabHeadlineRows,
            },
        ];

        entities.forEach((entity, index) => {
            const number = index + 1;
            const {
                config: { interval, number: configNumber, subject, type },
                imageUrl,
                text,
            } = entity;
            result[0].values.push([
                number,
                text,
                imageUrl,
                interval,
                type,
                subject.type === 'team' ? subject.value : '',
                subject.type === 'player' ? subject.value : '',
                configNumber || '',
            ]);
        });
        return result;
    },
};

export default bingoSquareTableTransformer;
