import styled from '@emotion/styled';
import {
    Button,
    DialogActions,
    FormHelperText,
    IconButton,
    LinearProgress,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@material-ui/core';
import { StyleRules, withStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import Duration from 'duration-js';
import React, { Component } from 'react';
import { Prediction } from '../../service/AuthoringApi';
import {
    predictionPointValues,
    unlockTimeSecondsToNiceString,
} from '../../utils/util';
import AuthoringModal from '../AuthoringModal';

interface Props {
    batchUnlockPredictions: (eventId: string, bodyData: any) => void;
    classes: any;
    currentMilestone: string;
    editPrediction: () => void;
    eventMilestones: string[];
    handleCloseModal: () => void;
    handleRevealPredictionResponse: (
        success: boolean,
        error: any | null,
    ) => void;
    prediction: any;
    updatePredictionWithEvent: (
        eventId: string,
        predictionId: string,
        prediction: Prediction,
    ) => void;
}

interface State {
    answerMilestone: string;
    answerTime: string;
    revealInProgress: boolean;
    releaseMilestone: string;
    releaseTime: string;
    totalPointValue: number;
    timeIntervalSeconds: number;
}

class RevealPredictionModal extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const { currentMilestone, prediction } = props;
        const {
            answerMilestone,
            answerTime,
            releaseMilestone,
            releaseTime,
            timeIntervalSeconds,
            totalPointValue,
        } = prediction;

        const startingAnswerMilestone =
            answerMilestone === '' ? currentMilestone : answerMilestone;
        const startingReleaseMilestone =
            releaseMilestone === '' ? currentMilestone : releaseMilestone;
        const startingAnswerTimeText = answerTime || '';
        const startingReleaseTimeText = releaseTime || '';

        this.state = {
            answerMilestone: startingAnswerMilestone,
            answerTime: startingAnswerTimeText,
            releaseMilestone: startingReleaseMilestone,
            releaseTime: startingReleaseTimeText,
            revealInProgress: false,
            timeIntervalSeconds,
            totalPointValue,
        };
    }

    private handleSubmit = async () => {
        const {
            batchUnlockPredictions,
            handleRevealPredictionResponse,
            prediction,
            updatePredictionWithEvent,
        } = this.props;
        const {
            answerMilestone,
            answerTime,
            releaseMilestone,
            releaseTime,
            timeIntervalSeconds,
            totalPointValue,
        } = this.state;
        const { eventId, id: predictionId } = prediction;
        const newFields = {
            answerMilestone,
            answerTime,
            id: predictionId,
            releaseMilestone,
            releaseTime,
            timeIntervalSeconds,
            totalPointValue,
        };
        const updatedPrediction = { ...prediction, ...newFields };

        this.setState({
            revealInProgress: true,
        });

        try {
            await updatePredictionWithEvent(
                eventId,
                predictionId,
                updatedPrediction,
            );
            const bodyData = { predictionIds: [predictionId] };
            await batchUnlockPredictions(eventId, bodyData);
            handleRevealPredictionResponse(true, null);
        } catch (error) {
            handleRevealPredictionResponse(false, error);
        }
    };

    private getPredictionOptions = (): JSX.Element | null => {
        const { prediction } = this.props;
        const { options } = prediction;
        return options.map((option: any) => (
            <Typography
                color="textSecondary"
                key={option.text}
                variant="caption"
            >
                &bull; {option.text}
                <br />
            </Typography>
        ));
    };

    private getSubtitleNode = (): JSX.Element => {
        const { prediction } = this.props;
        const { text } = prediction;
        return (
            <QuestionContainer>
                <Typography
                    color="textSecondary"
                    style={{ maxWidth: '400px' }}
                    variant="caption"
                >
                    {text}
                </Typography>
                {this.getPredictionOptions()}
            </QuestionContainer>
        );
    };

    private handlePointsChange = (event: any) => {
        this.setState({ totalPointValue: event.target.value });
    };

    private handleAnswerMilestoneChange = (event: any) => {
        this.setState({ answerMilestone: event.target.value });
    };

    private handleReleaseTimeChange = () => (event: any) => {
        this.setState({ releaseTime: event.target.value });
    };

    private handleAnswerTimeChange = () => (event: any) => {
        this.setState({ answerTime: event.target.value });
    };

    private handleUnlockTimeChange = () => (event: any) => {
        const inputValue: string = event.target.value;
        try {
            const timeString = new Duration(inputValue);
            const timeInSeconds = timeString.seconds();
            this.setState({ timeIntervalSeconds: timeInSeconds });
        } catch (error) {
            if (inputValue[inputValue.length - 1] !== 's') {
                try {
                    const timeString = new Duration(`${inputValue}s`);
                    const timeInSeconds = timeString.seconds();
                    this.setState({ timeIntervalSeconds: timeInSeconds });
                } catch (error) {
                    console.warn(`duration error: ${error}`);
                }
            }
        }
    };

    private handleEdit = () => {
        const { editPrediction } = this.props;
        editPrediction();
    };

    public render() {
        const { classes, eventMilestones, handleCloseModal, prediction } =
            this.props;
        const {
            answerMilestone,
            answerTime,
            releaseMilestone,
            releaseTime,
            revealInProgress,
            timeIntervalSeconds,
            totalPointValue,
        } = this.state;
        const { name } = prediction;

        const subtitle = this.getSubtitleNode();
        const pointOptions = predictionPointValues.map((value) => ({
            label: `${value}`,
            value,
        }));
        return (
            <AuthoringModal
                onClose={handleCloseModal}
                subtitle={subtitle}
                title="Reveal Prediction"
            >
                <FormContainer>
                    <FormRow style={{ alignItems: 'flex-start' }}>
                        <div style={{ flexDirection: 'column' }}>
                            <FormHelperText>Points</FormHelperText>
                            <Select
                                onChange={this.handlePointsChange}
                                value={totalPointValue}
                            >
                                {pointOptions.map((option) => (
                                    <MenuItem
                                        key={`${name}-${option.value}`}
                                        value={option.value}
                                    >
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                        <TextField
                            className={classes.textField}
                            defaultValue={timeIntervalSeconds}
                            helperText={unlockTimeSecondsToNiceString(
                                timeIntervalSeconds,
                            )}
                            id="unlock-time"
                            label="Unlock Time"
                            margin="normal"
                            onChange={this.handleUnlockTimeChange()}
                            variant="outlined"
                        />
                    </FormRow>
                    <FormRow>
                        <div style={{ flexDirection: 'column' }}>
                            <FormHelperText style={{ marginBottom: '6px' }}>
                                Release Milestone
                            </FormHelperText>
                            <span className={classes.marginRight}>
                                {releaseMilestone}
                            </span>
                        </div>
                        <TextField
                            className={classes.textField}
                            defaultValue={releaseTime}
                            id="release-time"
                            label="Release Time"
                            margin="normal"
                            onChange={this.handleReleaseTimeChange()}
                            variant="outlined"
                        />
                    </FormRow>
                    <FormRow>
                        <div style={{ flexDirection: 'column' }}>
                            <FormHelperText>Answer Milestone</FormHelperText>
                            <Select
                                className={classes.marginRight}
                                inputProps={{
                                    id: 'answer-simple',
                                    name: 'answerMilestone',
                                }}
                                onChange={this.handleAnswerMilestoneChange}
                                value={answerMilestone}
                                variant="outlined"
                            >
                                {eventMilestones.map((milestone) => (
                                    <MenuItem
                                        key={`${name}-${milestone}`}
                                        value={milestone}
                                    >
                                        {milestone}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                        <TextField
                            className={classes.textField}
                            defaultValue={answerTime}
                            id="answer-time"
                            label="Answer Time"
                            margin="normal"
                            onChange={this.handleAnswerTimeChange()}
                            variant="outlined"
                        />
                    </FormRow>
                </FormContainer>
                <DialogActions style={{ justifyContent: 'space-between' }}>
                    <LeftButtonGroup>
                        <IconButton
                            aria-label="Edit"
                            className={classes.editIcon}
                            onClick={this.handleEdit}
                        >
                            <EditIcon />
                        </IconButton>
                    </LeftButtonGroup>
                    <RightButtonGroup>
                        <Button
                            className={classes.marginRight}
                            color="secondary"
                            onClick={handleCloseModal}
                            variant="contained"
                        >
                            Cancel
                        </Button>
                        <Button
                            color="primary"
                            disabled={revealInProgress}
                            onClick={this.handleSubmit}
                            variant="contained"
                        >
                            Reveal
                        </Button>
                    </RightButtonGroup>
                </DialogActions>
                {revealInProgress && <LinearProgress />}
            </AuthoringModal>
        );
    }
}

const QuestionContainer = styled.div`
    border-left: 3px solid #ddd;
    padding: 10px 20px;
    margin: 15px 0px;
`;

const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    min-width: 360px;
`;

const FormRow = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    text-align: left;
    width: 100%;
`;

const LeftButtonGroup = styled.div``;

const RightButtonGroup = styled.div``;

const styles = () =>
    ({
        marginRight: {
            marginRight: '10px',
        },
    }) as StyleRules;

export default withStyles(styles)(RevealPredictionModal);
