import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import {
    createTheme,
    MuiThemeProvider,
    withStyles,
} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import LockIcon from '@material-ui/icons/Lock';
import UnlockIcon from '@material-ui/icons/LockOpen';
import MUIDataTable from 'mui-datatables';
import pLimit from 'p-limit';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import { PAGE_LABEL } from '../service/Constants';
import { GROUP_MODE_TYPE } from '../utils/GroupMode';
import { shouldIgnoreMuiTableRowClick } from '../utils/tables/util';
import ActionButton from './ActionButton';
import BreadcrumbBar from './BreadcrumbBar';
import DeleteUserModal from './DeleteUserModal';
import FirestoreIcon from './FirestoreIcon';
import FormBuilder from './FormBuilder';
import SetProUsersModal from './SetProUsersModal/SetProUsersModal';

const limitPromiseConcurrency = pLimit(3);

const styles = (theme) => ({
    actionButton: {
        '@media (max-width:959.95px)': {
            backgroundColor: 'gainsboro',
            marginTop: `${theme.spacing()}px`,
        },
    },
    actionColumn: {
        // on responsive view float the button up and hug right
        '@media (max-width:959.95px)': {
            display: 'inline-grid',
            float: 'right',
            position: 'relative',
            top: -116,
        },
        margin: theme.spacing(),
        whiteSpace: 'nowrap',
    },
    paper: {
        padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
    },
});

const customToolbarButtonContainerStyle = {
    alignContent: 'center',
    boxShadow: 'none',
    display: 'flex',
    paddingBottom: 10,
    paddingRight: 10,
    paddingTop: 10,
};

const transformRow = (object, tableColumns, addEditColumn) => {
    const row = tableColumns.map((field) => {
        const columnData = object[field.name];
        return field.generateFunc
            ? field.generateFunc(object)
            : columnData === undefined
              ? ''
              : columnData;
    });

    // add a row for the edit column
    if (addEditColumn) {
        row.push('');
    }

    return row;
};

export const transformToArray = (data = [], tableColumns, addEditColumn) =>
    data.map((obj) => transformRow(obj, tableColumns, addEditColumn));

class CrudPage extends Component {
    constructor(props) {
        super(props);
        this.handleSubmitCrudForm = this.handleSubmitCrudForm.bind(this);
        this.deleteModalRef = React.createRef();
        this.setProUsersModalRef = React.createRef();
        const { immediateCreate, immediateEdit, predictionIdToEdit } =
            this.props;

        if (immediateCreate) {
            this.state = {
                formStartingValue: this.emptyObject(),
                modalOpen: true,
            };
        } else {
            this.state = {
                modalOpen: false,
            };
        }

        if (immediateEdit) {
            this.showEditForm(predictionIdToEdit);
        }
    }

    static propTypes = {
        alternativeFabActions: PropTypes.array,
        breadCrumbs: PropTypes.arrayOf(PropTypes.object).isRequired,
        buildChildLink: PropTypes.func,
        classes: PropTypes.object.isRequired,
        createRecord: PropTypes.func,
        customDataView: PropTypes.element,
        customModal: PropTypes.node,
        data: PropTypes.arrayOf(PropTypes.object).isRequired,
        deleteRecord: PropTypes.func.isRequired,
        deleteRecords: PropTypes.func,
        deleteUser: PropTypes.func,
        displayBatchRevealConfirmation: PropTypes.func,
        displayManualLockModal: PropTypes.func,
        firebaseProjectId: PropTypes.string,
        formCloseCallback: PropTypes.func,
        getUsers: PropTypes.func,
        headline: PropTypes.string.isRequired,
        history: ReactRouterPropTypes.history.isRequired,
        immediateCreate: PropTypes.bool,
        immediateEdit: PropTypes.bool,
        inputFields: PropTypes.array.isRequired,
        label: PropTypes.string.isRequired,
        muiDataTableOptions: PropTypes.object.isRequired,
        notifyError: PropTypes.func.isRequired,
        notifySuccess: PropTypes.func.isRequired,
        notifyWarning: PropTypes.func,
        onRowClick: PropTypes.func,
        predictionIdToEdit: PropTypes.string,
        preformRenderTransform: PropTypes.func,
        presubmitTransform: PropTypes.func,
        refreshData: PropTypes.func.isRequired,
        removeForceFormOpenEmptyPreset: PropTypes.func,
        secondaryActions: PropTypes.array,
        setProUsers: PropTypes.func,
        setRowProps: PropTypes.func,
        subheadline: PropTypes.string,
        supportedLanguages: PropTypes.array.isRequired,
        tableColumns: PropTypes.array,
        updateRecord: PropTypes.func.isRequired,
    };

    static defaultProps = {
        muiDataTableOptions: {},
    };

    showEditForm = (id) => {
        const { preformRenderTransform, refreshData } = this.props;
        refreshData(() => {
            const { data, notifyError } = this.props;
            const record = data.find((r) => r.id === id);

            if (!record) {
                this.setState({ modalOpen: false });
                notifyError('Record no longer found');
                return;
            } else {
                const transformedRecord = preformRenderTransform
                    ? preformRenderTransform(record)
                    : record;

                this.setState({
                    formStartingValue: transformedRecord,
                    modalOpen: true,
                });
            }
        });
    };

    getMuiTheme = () => {
        const { buildChildLink, onRowClick } = this.props;
        return createTheme({
            overrides: {
                MUIDataTableBodyCell: {
                    root: {
                        '&:hover': {
                            ...((buildChildLink || onRowClick) && {
                                cursor: 'pointer !important',
                            }),
                        },
                        color: 'inherit',
                    },
                },
                MuiSvgIcon: {
                    fontSizeSmall: {
                        fontSize: '13px',
                        verticalAlign: 'middle',
                    },
                },
            },
            typography: {
                useNextVariants: true,
            },
        });
    };

    emptyObject = () => {
        const { inputFields } = this.props;
        const object = {};
        inputFields.forEach((field) => {
            object[field.name] =
                field.defaultValue === undefined ? '' : field.defaultValue;
        });
        return object;
    };

    handleAddClicked = () => {
        this.setState({
            formStartingValue: this.emptyObject(),
            modalOpen: true,
        });
    };

    handleCloseCrudForm = () => {
        const { formCloseCallback, removeForceFormOpenEmptyPreset } =
            this.props;
        removeForceFormOpenEmptyPreset && removeForceFormOpenEmptyPreset();
        this.setState({ modalOpen: false }, formCloseCallback);
    };

    remoteDeletes = async (rows) => {
        const {
            deleteRecords,
            notifyError,
            notifySuccess,
            parentId,
            refreshData,
            tableColumns,
        } = this.props;

        const ids = rows.map(
            (row) =>
                row[tableColumns.findIndex((column) => column.name === 'id')],
        );

        try {
            await deleteRecords({ ids, parentId });
            notifySuccess(`Successfully deleted: ${ids}`);
        } catch (error) {
            console.error('Error in delete', error);
            notifyError(`Failed to delete: ${error.message}`);

            // need to refresh the table here because the MUI table has removed the row
            refreshData();
        }
    };

    remoteDelete = async (row) => {
        const {
            deleteRecord,
            label,
            notifyError,
            notifySuccess,
            parentId,
            refreshData,
            tableColumns,
        } = this.props;
        const id =
            row[tableColumns.findIndex((column) => column.name === 'id')];
        const name =
            row[tableColumns.findIndex((column) => column.name === 'name')];
        try {
            await deleteRecord({ id, parentId });
            notifySuccess(`Successfully deleted ${label}: ${name || id}`);
        } catch (error) {
            notifyError(
                `Failed to delete ${label} "${name || id}": ${
                    error.message || error
                }`,
            );

            // need to refresh the table here because the MUI table has removed the row
            refreshData();
        }
    };

    remoteDuplicate = async (row) => {
        const {
            duplicateEvent,
            label,
            notifyError,
            notifySuccess,
            parentId,
            refreshData,
            tableColumns,
        } = this.props;
        const eventId =
            row[tableColumns.findIndex((column) => column.name === 'id')];
        const name =
            row[tableColumns.findIndex((column) => column.name === 'name')];
        try {
            await duplicateEvent({ eventId, parentId });
            notifySuccess(`Successfully duplicated ${label}: ${name}`);
        } catch (error) {
            console.error('Error in duplicate', error);
            notifyError(
                `Failed to duplicate ${label} "${name}": ${
                    error.message || error
                }`,
            );

            // need to refresh the table here because the MUI table has removed the row
            refreshData();
        }
    };

    handleDelete = (deletedRows) => {
        const { data, tableColumns } = this.props;
        const rows = transformToArray(data, tableColumns, true);
        const rowsToDelete = Object.keys(deletedRows.lookup).map(
            (index) => rows[index],
        );

        if (rowsToDelete.length === 1) {
            this.remoteDelete(rowsToDelete[0]);
        } else if (rowsToDelete.length > 1) {
            if (this.props.deleteRecords) {
                this.remoteDeletes(rowsToDelete);
            } else {
                rowsToDelete.map((row) =>
                    limitPromiseConcurrency(() => this.remoteDelete(row)),
                );
            }
        }
    };

    handleDuplicate = (rowsToDuplicate) => {
        const { data, tableColumns } = this.props;
        const rowIndexes = Object.keys(rowsToDuplicate.lookup);
        const rows = transformToArray(data, tableColumns, true);
        rowIndexes.map((index) =>
            limitPromiseConcurrency(() => this.remoteDuplicate(rows[index])),
        );
    };

    getPartnerIdFromPartnerRow = (partnerRow) => {
        const { data, tableColumns } = this.props;
        const rowIndex = Object.keys(partnerRow.lookup);
        const rows = transformToArray(data, tableColumns, true);
        const selectedPartnerRow = rows[rowIndex];
        const partnerId = selectedPartnerRow[0];
        return partnerId;
    };

    getIterationIdFromIterationRow = (iterationRow) => {
        const { data, tableColumns } = this.props;
        const rowIndex = Object.keys(iterationRow.lookup);
        const rows = transformToArray(data, tableColumns, true);
        const selectedIterationRow = rows[rowIndex];
        const iterationId = selectedIterationRow[0];
        return iterationId;
    };

    handleShowSponsors = (partnerRow) => {
        const { history } = this.props;
        const partnerId = this.getPartnerIdFromPartnerRow(partnerRow);
        history.push(`/sponsors?partner=${partnerId}`);
    };

    handleShowVenues = (partnerRow) => {
        const { history } = this.props;
        const partnerId = this.getPartnerIdFromPartnerRow(partnerRow);
        history.push(`/venues?partner=${partnerId}`);
    };

    handleShowTriviaQuestions = (partnerRow) => {
        const { history } = this.props;
        const partnerId = this.getPartnerIdFromPartnerRow(partnerRow);
        history.push(`/trivia?partner=${partnerId}`);
    };

    handleShowProgressivePolls = (iterationRow) => {
        const { history } = this.props;
        const iterationId = this.getIterationIdFromIterationRow(iterationRow);
        history.push(`/progressive-polls?iteration=${iterationId}`);
    };

    handleShowTeams = (iterationRow) => {
        const { history } = this.props;
        const iterationId = this.getIterationIdFromIterationRow(iterationRow);
        history.push(`/teams?iteration=${iterationId}`);
    };

    handleDeleteUser = (partnerRow) => {
        const partnerId = this.getPartnerIdFromPartnerRow(partnerRow);
        this.deleteModalRef.current.showModal(partnerId);
    };

    handleSetProUsers = (partnerRow) => {
        const partnerId = this.getPartnerIdFromPartnerRow(partnerRow);
        this.setProUsersModalRef.current.showModal(partnerId);
    };

    handleDeleteUserResponse = (success, error) => {
        const { notifyError, notifySuccess } = this.props;
        if (success) {
            notifySuccess('User is deleted.');
            this.deleteModalRef.current.closeModal();
        } else {
            notifyError(`Failed to delete user ${error}`);
        }
    };

    handleSetProUsersResponse = (success, error) => {
        const { notifyError, notifySuccess } = this.props;
        if (success) {
            notifySuccess('Pro users are set.');
        } else {
            notifyError(`Failed to set pro users. ${error}`);
        }
    };

    handleManualLock = (rowsToLock) => {
        const { data, displayManualLockModal, notifyWarning, tableColumns } =
            this.props;
        const indicesToLock = Object.keys(rowsToLock.lookup);
        const rows = transformToArray(data, tableColumns, true);

        const idsToLock = indicesToLock
            .filter((index) => rows[index][3] === 'Live')
            .map((index) => rows[index][0]);

        if (idsToLock.length > 0) {
            const predictionsToLock = data.filter(
                (data) => idsToLock.indexOf(data.id) !== -1,
            );
            displayManualLockModal(predictionsToLock);
        } else {
            notifyWarning(
                'None of your selected predictions are eligible for Manual Lock',
            );
        }
    };

    // Reveals a batch of selected predictions
    // Silently ignores predictions that are already visible
    // Warns user when none of selected predictions are able to be unlocked
    handleRevealBatch = (revealedRows) => {
        const {
            data,
            displayBatchRevealConfirmation,
            notifyWarning,
            tableColumns,
        } = this.props;
        const revealedIndices = Object.keys(revealedRows.lookup);
        const rows = transformToArray(data, tableColumns, true);

        const idsToReveal = revealedIndices
            .filter((index) => rows[index][3] === 'Hidden')
            .map((index) => rows[index][0]);

        if (idsToReveal.length > 0) {
            const predictionsToUnlock = data.filter(
                (data) => idsToReveal.indexOf(data.id) !== -1,
            );
            displayBatchRevealConfirmation(predictionsToUnlock);
        } else {
            notifyWarning(
                'None of your selected predictions are eligible for batched unlock',
            );
        }
    };

    handleRowClick = (row, lookup, event) => {
        const { buildChildLink, onRowClick } = this.props;
        if (!onRowClick && !buildChildLink) {
            return;
        }
        const { tableColumns } = this.props;
        const id =
            row[tableColumns.findIndex((column) => column.name === 'id')];

        if (shouldIgnoreMuiTableRowClick(event)) {
            return;
        }

        if (onRowClick) {
            onRowClick(id);
            return;
        }

        this.props.history.push(buildChildLink(id));
    };

    handleEdit = (id) => () => {
        const { preformRenderTransform, refreshData } = this.props;
        refreshData(() => {
            const { data, notifyError } = this.props;
            const record = data.find((r) => r.id === id);

            if (!record) {
                this.setState({ modalOpen: false });
                notifyError('Record no longer found');
                return;
            } else {
                const transformedRecord = preformRenderTransform
                    ? preformRenderTransform(record)
                    : record;

                this.setState({
                    formStartingValue: transformedRecord,
                    modalOpen: true,
                });
            }
        });
    };

    handleFirestoreClick = (path) => () => {
        const { firebaseProjectId } = this.props;
        const root = `https://console.firebase.google.com/project/${firebaseProjectId}/firestore/data/`;

        const uriEncodedPath = encodeURIComponent(path);
        const tildeReplacedEncodedPath = uriEncodedPath.replace(/%/g, '~');
        window.open(`${root}${tildeReplacedEncodedPath}`, '_blank');
    };

    addActionsColumn(tableColumns) {
        const { classes } = this.props;
        return [
            ...tableColumns,
            {
                name: 'Actions',
                options: {
                    customBodyRender: (_, tableMeta) => {
                        return (
                            <div className={classes.actionColumn}>
                                <IconButton
                                    aria-label="Firestore Link"
                                    className={classes.actionButton}
                                    key="firestore"
                                    onClick={this.handleFirestoreClick(
                                        tableMeta.rowData[1],
                                    )}
                                >
                                    <FirestoreIcon
                                        fontSize="small"
                                        name="firestore"
                                    />
                                </IconButton>
                                <IconButton
                                    aria-label="Edit"
                                    className={classes.actionButton}
                                    key="edit"
                                    onClick={this.handleEdit(
                                        tableMeta.rowData[0],
                                    )}
                                >
                                    <EditIcon fontSize="small" name="edit" />
                                </IconButton>
                            </div>
                        );
                    },
                    filter: false,
                },
            },
        ];
    }

    async handleSubmitCrudForm(formState) {
        const { createRecord, presubmitTransform, updateRecord } = this.props;

        const transformedFormState = presubmitTransform
            ? presubmitTransform(formState)
            : formState;

        transformedFormState.defaultLanguageId = formState.defaultLanguageId;

        transformedFormState.entityTranslations &&
            transformedFormState.entityTranslations.length &&
            transformedFormState.entityTranslations.forEach((et) => {
                const dataToMap = Object.entries(formState).filter(
                    ([key]) =>
                        key.startsWith('translation') &&
                        key.endsWith(et.languageIdentity),
                );
                dataToMap.forEach(([key, value]) => {
                    const keyProperty = key.split('.')[1];

                    et[keyProperty] = value;
                    delete et.key;
                });

                delete et.language;
                delete et.languageIdentity;
            });

        if (transformedFormState.id) {
            await updateRecord(transformedFormState.id, transformedFormState);
            this.setState({ modalOpen: false }, this.handleCloseCrudForm());
        } else if (createRecord) {
            await createRecord(transformedFormState);
            this.setState({ modalOpen: false }, this.handleCloseCrudForm());
        } else {
            throw new Error('No createRecord function available');
        }
    }

    setRowPropsWrapper = (row) => {
        const { setRowProps, tableColumns } = this.props;
        const transposedRow = {};
        tableColumns.forEach((c, i) => {
            transposedRow[c.name] = row[i];
        });
        return setRowProps(transposedRow);
    };

    handleManualLockGenerator = (selectedRows) => {
        return () => {
            this.handleManualLock(selectedRows);
        };
    };

    handleRevealGenerator = (selectedRows) => {
        return () => {
            this.handleRevealBatch(selectedRows);
        };
    };

    handleDeleteGenerator = (selectedRows) => {
        return () => {
            this.handleDelete(selectedRows);
        };
    };

    handleDuplicateGenerator = (selectedRows) => {
        return () => {
            this.handleDuplicate(selectedRows);
        };
    };

    handleDeleteUserGenerator = (selectedRows) => {
        return () => {
            this.handleDeleteUser(selectedRows);
        };
    };
    handleSetProUsersGenerator = (selectedRows) => {
        return () => {
            this.handleSetProUsers(selectedRows);
        };
    };

    handleShowSponsorsGenerator = (selectedRows) => {
        return () => {
            this.handleShowSponsors(selectedRows);
        };
    };

    handleShowVenuesGenerator = (selectedRows) => {
        return () => {
            this.handleShowVenues(selectedRows);
        };
    };

    handleShowTriviaQuestionsGenerator = (selectedRows) => {
        return () => {
            this.handleShowTriviaQuestions(selectedRows);
        };
    };

    handleShowProgressivePollsGenerator = (selectedRows) => {
        return () => {
            this.handleShowProgressivePolls(selectedRows);
        };
    };

    handleShowTeamsGenerator = (selectedRows) => {
        return () => {
            this.handleShowTeams(selectedRows);
        };
    };

    sendSMSGenerator = (selectedRows) => {
        return () => {
            this.showSMSModal(selectedRows);
        };
    };
    iterationTeamsAllowed = ({ data, selectedRows }) => {
        const iterationId = this.getIterationIdFromIterationRow(selectedRows);
        const iteration = data.find((el) => el.id === iterationId);
        return (
            iteration.groupMode === GROUP_MODE_TYPE['Team and User Group'] ||
            iteration.groupMode === GROUP_MODE_TYPE.Team
        );
    };

    renderCustomToolbar = (selectedRows) => {
        const { data, label } = this.props;

        return (
            <Paper style={customToolbarButtonContainerStyle}>
                {label === PAGE_LABEL.prediction && (
                    <>
                        <Button
                            onClick={this.handleManualLockGenerator(
                                selectedRows,
                            )}
                            size="small"
                            style={{ marginRight: '15px' }}
                            variant="contained"
                        >
                            <LockIcon />
                        </Button>
                        <Button
                            onClick={this.handleRevealGenerator(selectedRows)}
                            size="small"
                            style={{ marginRight: '15px' }}
                            variant="contained"
                        >
                            <UnlockIcon />
                        </Button>
                    </>
                )}
                {label === PAGE_LABEL.iteration &&
                    selectedRows.data.length === 1 && (
                        <>
                            <Button
                                onClick={this.handleShowProgressivePollsGenerator(
                                    selectedRows,
                                )}
                                size="small"
                                style={{ marginRight: '15px' }}
                                variant="contained"
                            >
                                Progressive polls
                            </Button>
                            {this.iterationTeamsAllowed({
                                data,
                                selectedRows,
                            }) && (
                                <Button
                                    onClick={this.handleShowTeamsGenerator(
                                        selectedRows,
                                    )}
                                    size="small"
                                    style={{ marginRight: '15px' }}
                                    variant="contained"
                                >
                                    Teams
                                </Button>
                            )}
                        </>
                    )}
                {label === PAGE_LABEL.event &&
                    selectedRows.data.length === 1 && (
                        <Button
                            onClick={this.handleDuplicateGenerator(
                                selectedRows,
                            )}
                            size="small"
                            style={{ marginRight: '15px' }}
                            variant="contained"
                        >
                            Duplicate
                        </Button>
                    )}
                {label === PAGE_LABEL.partner &&
                    selectedRows.data.length === 1 && (
                        <>
                            <Button
                                onClick={this.handleShowVenuesGenerator(
                                    selectedRows,
                                )}
                                size="small"
                                style={{ marginRight: '15px' }}
                                variant="contained"
                            >
                                Venues
                            </Button>
                            <Button
                                onClick={this.handleShowTriviaQuestionsGenerator(
                                    selectedRows,
                                )}
                                size="small"
                                style={{ marginRight: '15px' }}
                                variant="contained"
                            >
                                Trivia Questions
                            </Button>
                            <Button
                                onClick={this.handleShowSponsorsGenerator(
                                    selectedRows,
                                )}
                                size="small"
                                style={{ marginRight: '15px' }}
                                variant="contained"
                            >
                                Sponsors
                            </Button>
                            <Button
                                onClick={this.handleSetProUsersGenerator(
                                    selectedRows,
                                )}
                                size="small"
                                style={{ marginRight: '15px' }}
                                variant="contained"
                            >
                                Set Pro Users
                            </Button>
                            <Button
                                onClick={this.handleDeleteUserGenerator(
                                    selectedRows,
                                )}
                                size="small"
                                style={{ marginRight: '15px' }}
                                variant="contained"
                            >
                                Delete User
                            </Button>
                        </>
                    )}
                <Button
                    className={styles.deleteButton}
                    onClick={this.handleDeleteGenerator(selectedRows)}
                    size="small"
                    variant="contained"
                >
                    <DeleteIcon />
                </Button>
            </Paper>
        );
    };

    render() {
        const {
            alternativeFabActions,
            breadCrumbs,
            buildChildLink,
            classes,
            createRecord,
            customDataView,
            customModal,
            data,
            deleteUser,
            getUsers,
            headline,
            immediateCreate,
            inputFields,
            label,
            muiDataTableOptions,
            notifyError,
            notifySuccess,
            onRowClick,
            secondaryActions,
            setProUsers,
            setRowProps,
            subheadline,
            supportedLanguages,
            tableColumns,
            translationInputFields,
        } = this.props;
        const { formStartingValue, modalOpen } = this.state;

        if (immediateCreate && !modalOpen) {
            this.handleAddClicked();
        }

        const options = {
            ...muiDataTableOptions,
            filterType: 'dropdown',
            onRowClick: this.handleRowClick,
            onRowsDelete: this.handleDelete,
            resizableColumns: true,
            responsive: 'simple',
            rowHover: !!buildChildLink || !!onRowClick,
            rowsPerPage: 50,
            rowsPerPageOptions: [10, 20, 30, 40, 50, 75, 100],
            setRowProps: setRowProps ? this.setRowPropsWrapper : undefined,
        };

        if (
            label === PAGE_LABEL.prediction ||
            label === PAGE_LABEL.event ||
            label === PAGE_LABEL.partner ||
            label === PAGE_LABEL.iteration
        ) {
            options.customToolbarSelect = this.renderCustomToolbar;
        }

        const actions = [];
        if (createRecord) {
            actions.push({
                icon: <AddIcon />,
                name: `Add ${label}`,
                onClick: this.handleAddClicked,
            });
        }
        if (secondaryActions) {
            actions.push(...secondaryActions);
        }
        return (
            <div>
                <div>
                    <Paper className={classes.paper}>
                        <BreadcrumbBar breadcrumbs={breadCrumbs} />
                        <PageHeadline
                            headline={headline}
                            subheadline={subheadline}
                        />
                    </Paper>
                </div>
                {customDataView || (
                    <MuiThemeProvider theme={this.getMuiTheme()}>
                        <MUIDataTable
                            columns={this.addActionsColumn(tableColumns)}
                            data={transformToArray(data, tableColumns, true)}
                            options={options}
                        />
                    </MuiThemeProvider>
                )}
                {actions.length > 0 ? <ActionButton actions={actions} /> : null}
                {alternativeFabActions && (
                    <ActionButton
                        actions={alternativeFabActions}
                        buttonText={alternativeFabActions[0].name}
                    />
                )}
                {modalOpen && (
                    <FormBuilder
                        fields={inputFields}
                        label={label}
                        notifyError={notifyError}
                        notifySuccess={notifySuccess}
                        onClose={this.handleCloseCrudForm}
                        onSubmit={this.handleSubmitCrudForm}
                        startingValue={formStartingValue}
                        supportedLanguages={supportedLanguages}
                        translationFields={translationInputFields}
                    />
                )}
                {customModal}
                <>
                    <DeleteUserModal
                        deleteUser={deleteUser}
                        onDeleteUserResponse={this.handleDeleteUserResponse}
                        ref={this.deleteModalRef}
                    />
                    <SetProUsersModal
                        getUsers={getUsers}
                        notifyError={notifyError}
                        onSetProUsersResponse={this.handleSetProUsersResponse}
                        ref={this.setProUsersModalRef}
                        setProUsers={setProUsers}
                    />
                </>
            </div>
        );
    }
}

export const PageHeadline = ({ headline, subheadline }) => {
    if (!subheadline) {
        return (
            <Typography color="textPrimary" gutterBottom variant="h6">
                {headline}
            </Typography>
        );
    } else {
        return (
            <div>
                <Typography color="textPrimary" gutterBottom variant="h6">
                    {headline}
                </Typography>
                <Typography
                    color="textPrimary"
                    gutterBottom
                    variant="subtitle2"
                >
                    {subheadline}
                </Typography>
            </div>
        );
    }
};

PageHeadline.propTypes = {
    headline: PropTypes.string.isRequired,
    subheadline: PropTypes.string,
};

export default withRouter(withStyles(styles)(CrudPage));
