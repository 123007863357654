import React, { useContext, useMemo } from 'react';
import CrudPage from '../components/CrudPage';
import AppContext from '../contexts/AppContext';
import {
    CrudPageInputField,
    CrudPageProps,
    CrudPageTableColumn,
    PAGE_LABEL,
} from '../service/Constants';
import {
    OfferCodeType,
    offerCodeTypeOptions,
    OfferDto,
    OfferType,
    PartnerDto,
    SponsorDto,
} from '../service/Dto';
import { localizeDate } from '../utils/util';

interface Props {
    createRecord: (offer: Partial<OfferDto>) => Promise<any>;
    deleteRecord: (params: { id: string; parentId: string }) => Promise<any>;
    partner: PartnerDto;
    refreshData: (callback: () => void) => void;
    sponsor: SponsorDto;
    updateRecord: (offerId: string, offer: Partial<OfferDto>) => Promise<any>;
}

const TABLE_COLUMNS: Array<CrudPageTableColumn<any>> = [
    {
        label: 'Offer ID',
        name: 'id',
        options: {
            display: false,
            filter: false,
        },
    },
    {
        label: 'Display Name',
        name: 'displayName',
    },
    {
        label: 'Type',
        name: 'type',
    },
    {
        label: 'Expiration Date',
        name: 'expirationDate',
        options: {
            customBodyRender: localizeDate,
        },
    },
    {
        generateFunc: (offer: OfferDto) => {
            const { codeSummary } = offer;
            if (!codeSummary) {
                return 'N/A';
            }
            const { assignable, total } = codeSummary;
            return `${assignable} / ${total}`;
        },
        label: 'Codes (avail/total)',
        name: 'codesAvailable',
    } as CrudPageTableColumn<OfferDto>,
];

const defaultOfferCodeType: OfferCodeType = 'QR_CODE';

const offerInputFields = (): CrudPageInputField[] => [
    {
        name: 'id',
        type: 'hidden',
    },
    {
        label: 'Name',
        name: 'name',
        props: {
            autoFocus: true,
        },
        required: true,
        type: 'text',
    },
    {
        label: 'Display Name',
        name: 'displayName',
        required: true,
        type: 'text',
    },
    {
        label: 'Type',
        name: 'type',
        options: [
            {
                label: 'Global code',
                value: OfferType.GLOBAL,
            },
            {
                label: 'One-time code',
                value: OfferType.ONE_TIME,
            },
            {
                label: 'No code',
                value: OfferType.NO_CODE,
            },
        ],
        required: true,
        type: 'select',
    },

    {
        defaultValue: defaultOfferCodeType,
        label: 'Offer Code Type',
        name: 'codeType',
        options: offerCodeTypeOptions,
        required: true,
        shouldDisplay: (data: { type: OfferType }) =>
            data.type !== OfferType.NO_CODE,
        type: 'select',
    },
    {
        label: 'Expiration Date',
        name: 'expirationDate',
        type: 'datetime-local',
    },
    {
        label: 'Legal Markdown',
        name: 'legalMarkdown',
        props: {
            multiline: true,
        },
        type: 'text',
    },
    {
        label: 'Redemption CTA Url',
        name: 'redemptionCtaUrl',
        type: 'text',
    },
    {
        label: 'Redemption Markdown',
        name: 'redemptionMarkdown',
        props: {
            multiline: true,
        },
        type: 'text',
    },
];

export default function Offers(props: Props) {
    const {
        createRecord,
        deleteRecord,
        partner,
        refreshData,
        sponsor,
        updateRecord,
    } = props;
    const appContext = useContext(AppContext);

    const { notifyError, notifySuccess } = appContext;

    function presubmitTransform(offer: Partial<OfferDto>): Partial<OfferDto> {
        offer.sponsorId = sponsor.id;
        return offer;
    }

    function buildCodesLink(offerId: string) {
        return `/codes?partner=${partner.id}&sponsor=${sponsor.id}&offer=${offerId}`;
    }

    const breadcrumbs = useMemo(
        () => [
            {
                link: '/partners',
                text: `${partner.id}`,
            },
            {
                link: `/sponsors?partner=${partner.id}`,
                text: 'sponsors',
            },
            { text: `${sponsor.name}'s offers` },
        ],
        [partner, sponsor],
    );

    const crudProps: CrudPageProps<OfferDto> = {
        breadCrumbs: breadcrumbs,
        buildChildLink: buildCodesLink,
        createRecord,
        data: sponsor.offers,
        deleteRecord,
        headline: 'Offers',
        inputFields: offerInputFields(),
        label: PAGE_LABEL.offer,
        notifyError,
        notifySuccess,
        parentId: sponsor.id,
        presubmitTransform,
        refreshData,
        supportedLanguages: [],
        tableColumns: TABLE_COLUMNS,
        updateRecord,
    };

    return <CrudPage {...crudProps} />;
}
