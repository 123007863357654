import { LocaleIds, Team, TeamCreate } from '../../service/Dto';
import {
    EntityTableExport,
    EntityTableImport,
    EntityTableTransformer,
} from './EntityTableTransformer';
import parseEntity, { ParseConfig } from './parseEntity';

const teamParseConfig: ParseConfig<TeamCreate> = {
    imageUrl: {
        column: 1,
        fromCell: (cell: string) => (cell === '' ? null : cell),
    },
    name: {
        column: 0,
    },
} as const;

const isRowEmpty = (row: string[]) =>
    row.length === 0 || row.every((cell) => cell === '');

const HEADER_ROWS_COUNT = 1;

const firstTabHeadlineRow = ['Name', 'Image Url'];

const triviaQuestionTableTransformer: EntityTableTransformer<TeamCreate, Team> =
    {
        fromTable: (input: EntityTableImport): TeamCreate[] => {
            const teams: TeamCreate[] = [];
            const [firstTab] = input;
            let rowsToParse = firstTab.values
                .slice(HEADER_ROWS_COUNT)
                .filter((row) => !isRowEmpty(row));

            while (rowsToParse.length > 0) {
                const { nextRowsToParse, parsedEntity } =
                    parseEntity<TeamCreate>(teamParseConfig, rowsToParse);
                teams.push(parsedEntity);
                rowsToParse = nextRowsToParse;
            }

            return teams;
        },
        toTable: (teams: Team[]) => {
            const result: EntityTableExport = [
                {
                    languageId: LocaleIds[0],
                    values: [firstTabHeadlineRow],
                },
            ];

            teams.forEach((team) => {
                const { imageUrl, name } = team;
                result[0].values.push([name, imageUrl || '']);
            });
            return result;
        },
    };

export default triviaQuestionTableTransformer;
