import {
    FormControl,
    FormHelperText,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    Select,
    Typography,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import LinearProgress from '@material-ui/core/LinearProgress';
import { StyleRules, Theme, withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { PREDICTION_TYPES } from 'traceme-shared/lib/constants';
import { isMultipleChoice } from 'traceme-shared/lib/util';
import {
    AnswerState,
    extractFitbPendingAnswer,
    localAnsweredOptionId,
} from './AnswerPredictions';

interface Props {
    answerState: any;
    answerStats: any;
    classes: any;
    confirmAnswer: (
        pendingAnswersBefore: any,
        predictionsBefore: any,
        answerMilestone?: string,
    ) => void;
    handleCloseAnswerDialog: () => void;
    notifyError: (message: string) => void;
    parentSaving: boolean;
    pendingAnswersBefore: any;
    predictions: any;
    predictionsBefore: any;
    refreshingData: boolean;
    shouldBeOpen: boolean;
    currentMilestone?: string;
    eventMilestones?: string[];
}

interface State {
    answerMilestone: string;
}

class SaveAnswerDialog extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const answerMilestone = props.currentMilestone || 'NA';
        this.state = {
            answerMilestone,
        };
    }

    private renderMultipleChoiceAnswerSummary(answers: any, prediction: any) {
        const { classes } = this.props;
        const optionId = localAnsweredOptionId(answers[prediction.id]);
        const option = prediction.options.find((o: any) => o.id === optionId);
        if (option) {
            return <span className={classes.nextLine}>{option.text}</span>;
        } else {
            return null;
        }
    }

    private renderFillInTheBlankAnswerSummary(answers: any, prediction: any) {
        const { classes } = this.props;
        return prediction.options.map((option: any) => (
            <span
                className={classes.nextLine}
                key={`confirm-fitb-${option.id}`}
            >
                {option.text} -{' '}
                {extractFitbPendingAnswer(answers[prediction.id], option.id) ||
                    option.answer ||
                    ''}
            </span>
        ));
    }

    private renderAnswerSummary(prediction: any, answers: any) {
        const { classes } = this.props;

        const answerExplanation =
            answers[prediction.id].answerExplanation ||
            prediction.answerExplanation;

        // const answerExplanationTranslations =
        //     prediction.entityTranslations &&
        //     prediction.entityTranslations.length &&
        //     prediction.entityTranslations.map((et: any) => {
        //         const answerEt = answers[prediction.id].entityTranslations;
        //         const answerTranslation =
        //             answerEt &&
        //             answerEt.find(
        //                 (answerEt: any) =>
        //                     et.languageIdentity === answerEt.languageIdentity
        //             );
        //         return {
        //             languageCodeId: et.languageCodeId,
        //             answerExplanation:
        //                 (answerTranslation &&
        //                     answerTranslation.answerExplanation) ||
        //                 et.answerExplanation,
        //         };
        //     });

        return (
            <React.Fragment>
                <Typography
                    className={classes.inline}
                    color="textPrimary"
                    component="span"
                >
                    {prediction.text}
                </Typography>
                {answerExplanation && (
                    <Typography
                        className={classes.answerExplanation}
                        component="span"
                    >
                        {answerExplanation}
                    </Typography>
                )}
                {isMultipleChoice(prediction) &&
                    this.renderMultipleChoiceAnswerSummary(answers, prediction)}
                {prediction.type === PREDICTION_TYPES.fillInTheBlank &&
                    this.renderFillInTheBlankAnswerSummary(answers, prediction)}
            </React.Fragment>
        );
    }
    private renderConfirmedAnswerList(answers: any) {
        const { predictions } = this.props;

        return (
            <List key="confirmed-answers-list">
                {Object.keys(answers).map((predictionId) => {
                    const prediction = predictions.find(
                        (p: any) =>
                            p.id === predictionId &&
                            p.type !== PREDICTION_TYPES.poll,
                    );
                    return (
                        <ListItem
                            dense={true}
                            key={`confirm-${predictionId}`}
                            role={undefined}
                        >
                            <ListItemText
                                secondary={this.renderAnswerSummary(
                                    prediction,
                                    answers,
                                )}
                            />
                        </ListItem>
                    );
                })}
            </List>
        );
    }

    private handleConfirmAnswer = () => {
        const { confirmAnswer, pendingAnswersBefore, predictionsBefore } =
            this.props;
        const { answerMilestone } = this.state;
        confirmAnswer(pendingAnswersBefore, predictionsBefore, answerMilestone);
    };

    private handleAnswerMilestoneChange = (event: any) => {
        this.setState({ answerMilestone: event.target.value });
    };

    public render() {
        const {
            answerState,
            answerStats,
            classes,
            eventMilestones,
            handleCloseAnswerDialog,
            notifyError,
            parentSaving,
            pendingAnswersBefore,
            refreshingData,
            shouldBeOpen,
        } = this.props;
        const { answerMilestone } = this.state;
        const { allPendingAreRevised, atLeastOnePendingRevised } = answerStats;

        if (refreshingData) {
            return (
                <Dialog
                    aria-labelledby="form-dialog-title"
                    onClose={handleCloseAnswerDialog}
                    open={shouldBeOpen}
                >
                    <DialogTitle id="form-dialog-title">
                        Refreshing recent answers from server...
                    </DialogTitle>
                    <DialogContent>
                        <LinearProgress />
                    </DialogContent>
                </Dialog>
            );
        }

        const pendingAnswers = pendingAnswersBefore;

        if (atLeastOnePendingRevised && !allPendingAreRevised) {
            notifyError(
                'Revised answers cannot be submitted alongside new answers',
            );
            handleCloseAnswerDialog();
            return;
        }

        let dialogTitle;
        let dialogText;

        switch (answerState) {
            case AnswerState.answeringFinalQuestions:
                dialogTitle = 'Confirm Answer Submission';
                dialogText =
                    "Press 'Confirm' to answer these remaining questions" +
                    ', the event will not be finalized yet';
                break;

            case AnswerState.answeringNonFinalQuestions:
                dialogTitle = 'Confirm Answer Submission';
                dialogText =
                    "Press 'Confirm' to continue setting answers for the " +
                    'following questions:';
                break;

            case AnswerState.revisingFinalizedGame:
                dialogTitle = 'Confirm Answer Revision';
                dialogText =
                    'The following questions will have their answers modified ' +
                    'from their original answers.';
                break;

            case AnswerState.revisingNonFinalizedGame:
                dialogTitle = 'Confirm Answer Revision';
                dialogText =
                    'The following questions will have their answers modified ' +
                    "from their original answers. Press 'Confirm' to continue.";
                break;

            default:
        }

        return (
            <Dialog
                aria-labelledby="form-dialog-title"
                onClose={handleCloseAnswerDialog}
                open={shouldBeOpen}
            >
                <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{dialogText}</DialogContentText>
                    {this.renderConfirmedAnswerList(pendingAnswers)}
                </DialogContent>
                <DialogActions className={classes.bottomWrapper}>
                    <Button
                        color="secondary"
                        disabled={parentSaving}
                        onClick={handleCloseAnswerDialog}
                        variant="contained"
                    >
                        Cancel
                    </Button>
                    {eventMilestones && (
                        <FormControl>
                            <FormHelperText>Answer Milestone</FormHelperText>
                            <Select
                                onChange={this.handleAnswerMilestoneChange}
                                value={answerMilestone}
                            >
                                {eventMilestones.map((milestone) => (
                                    <MenuItem
                                        key={`${milestone}`}
                                        value={milestone}
                                    >
                                        {milestone}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                    <div className={classes.buttonWrapper}>
                        <Button
                            color="primary"
                            disabled={parentSaving}
                            onClick={this.handleConfirmAnswer}
                            variant="contained"
                        >
                            Confirm
                        </Button>
                        {parentSaving && (
                            <CircularProgress
                                className={classes.buttonProgress}
                                size={24}
                            />
                        )}
                    </div>
                </DialogActions>
            </Dialog>
        );
    }
}

const styles = (theme: Theme) =>
    ({
        answerExplanation: {
            color: '#808080',
            display: 'block',
            marginBottom: `${theme.spacing()}px`,
            whiteSpace: 'pre',
        },
        bottomWrapper: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: '0 10px',
        },
        buttonProgress: {
            color: green[500],
            left: '50%',
            marginLeft: -12,
            marginTop: -12,
            position: 'absolute',
            top: '50%',
        },
        buttonWrapper: {
            margin: theme.spacing(),
            position: 'relative',
        },
        inline: {
            display: 'inline',
        },
        nextLine: {
            display: 'block',
        },
        winnersList: {
            backgroundColor: theme.palette.background.paper,
        },
        winnersSummaryTitle: {
            marginBottom: theme.spacing(),
        },
    }) as StyleRules;

export default withStyles(styles)(SaveAnswerDialog);
