import { Button, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import {
    createTheme,
    MuiThemeProvider,
    withStyles,
} from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import MuiDataTable from 'mui-datatables';
import React, { useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import ActionButton from '../../components/ActionButton';
import BreadcrumbBar from '../../components/BreadcrumbBar';
import { PageHeadline } from '../../components/CrudPage';
import FirestoreIcon from '../../components/FirestoreIcon';
import SheetImportExportDialog from '../../components/SheetImportExportDialog';
import {
    BingoSquare,
    BingoSquareCreate,
    BingoSquareUpdateRequest,
    CategoryDto,
    EventDto,
    IterationDto,
} from '../../service/Dto';
import GoogleSheetsApi, {
    GoogleSheetsApiError,
} from '../../service/GoogleSheetsApi';
import bingoSquareTableTransformer from '../../utils/tables/bingoSquareTableTransformer';
import { shouldIgnoreMuiTableRowClick } from '../../utils/tables/util';
import { localizeDate } from '../../utils/util';
import BingoSquareDetails from './BingoSquareDetails';
import EditBingoSquareDialogForm from './EditBingoSquareDialogForm';
import { tranformBingoSquareForCreate } from './util';

const styles = (theme: any) => ({
    actionColumn: {
        margin: theme.spacing(),
    },
    paper: {
        padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
    },
});

type Props = {
    category: CategoryDto;
    iteration: IterationDto;
    event: EventDto;
    bingoSquares: BingoSquare[];
    refreshBingoSquares: (eventId: string) => void;
    updateBingoSquare: (params: {
        id: string;
        eventId: string;
        body: BingoSquareUpdateRequest;
    }) => Promise<void>;
    batchCreateBingoSquares: (params: {
        eventId: string;
        bingoSquares: BingoSquareCreate[];
    }) => Promise<void>;
    notifyError: (message: string) => void;
    notifySuccess: (message: string) => void;
    notifyWarning: (message: string) => void;
    finalizeEvent: (event: EventDto) => Promise<void>;
    refreshEventsForIteration: (iterationId: string) => void;

    classes: any;
};

const handleFirestoreClick =
    (firebaseProjectId: string, path: string) => () => {
        const root = `https://console.firebase.google.com/project/${firebaseProjectId}/firestore/data/`;

        const uriEncodedPath = encodeURIComponent(path);
        const tildeReplacedEncodedPath = uriEncodedPath.replace(/%/g, '~');
        window.open(`${root}${tildeReplacedEncodedPath}`, '_blank');
    };

const tableColumns = [
    {
        label: 'Bingo Square ID',
        name: 'id',
        options: {
            display: false,
            filter: false,
        },
    },
    {
        label: 'Text',
        name: 'text',
        options: {
            display: true,
            filter: false,
            sort: true,
        },
    },
    {
        label: 'Image Url',
        name: 'imageUrl',
        options: {
            customBodyRender: (value: string) => {
                return (
                    <Link
                        href={value}
                        target="_blank"
                        rel="noreferrer"
                        onClick={(e) => e.stopPropagation()}
                    >
                        {value}
                    </Link>
                );
            },
            filter: false,
        },
        required: true,
    },
    {
        label: 'Occurred Date',
        name: 'occurredDate',
        options: {
            customBodyRender: localizeDate,
            filter: false,
            hint: 'When event occurred',
        },
    },
    {
        label: 'Config',
        name: 'config',
        options: {
            customBodyRender: (config: unknown) => {
                return <pre>{JSON.stringify(config, null, 2)}</pre>;
            },
            display: true,
            filter: false,
            sort: false,
        },
    },
];

const getMuiTheme = () => {
    return createTheme({
        overrides: {
            MUIDataTableBodyCell: {
                root: {
                    '&:hover': {
                        cursor: 'pointer !important',
                    },
                    color: 'inherit',
                },
            },
            MuiSvgIcon: {
                fontSizeSmall: {
                    fontSize: '13px',
                    verticalAlign: 'middle',
                },
            },
        },
    } as any);
};

const BingoSquares = ({
    batchCreateBingoSquares,
    bingoSquares,
    category,
    classes,
    event,
    finalizeEvent,
    iteration,
    notifyError,
    notifySuccess,
    notifyWarning,
    refreshBingoSquares,
    refreshEventsForIteration,
    updateBingoSquare,
}: Props) => {
    const [selectedQuestionId, setSelectedQuestionId] = useState<string | null>(
        null,
    );
    const [showImportExportModal, setShowImportExportModal] = useState(false);
    const [editSquareId, setEditSquareId] = useState<null | string>(null);
    const [isImportingExporting, setIsImportingExporting] = useState(false);
    const [finalizing, setFinalizing] = useState(false);

    const onFinalizeClick = () => {
        confirmAlert({
            buttons: [
                {
                    label: 'Finalize Event',
                    onClick: async () => {
                        setFinalizing(true);
                        try {
                            await finalizeEvent(event);
                        } catch (error) {
                            notifyError(`Error finalizing event: ${error}`);
                        }

                        setFinalizing(false);
                        notifySuccess('Event Finalized');
                        refreshEventsForIteration(iteration.id);
                    },
                },
                {
                    label: 'Cancel',
                    onClick: () => {},
                },
            ],
            message:
                'Do you want to finalize the event? This will end the event for all players and finalize the leaderboard.',
            title: 'Finalize Event',
        });
    };

    const handleEdit = (id: string) => {
        setEditSquareId(id);
    };

    const handleExportBingoSquares = async (googleSheetId: string) => {
        if (bingoSquares.length === 0) {
            notifyError('There are no bingoSquares to export!');
            return;
        }

        setIsImportingExporting(true);

        const gapi = new GoogleSheetsApi();
        await gapi.initialize();

        const table = bingoSquareTableTransformer.toTable(bingoSquares);

        try {
            await gapi.writeToSheet(googleSheetId, table);
        } catch (error) {
            setIsImportingExporting(false);

            if (error instanceof GoogleSheetsApiError) {
                notifyError(`Error exporting bingo squares: ${error.status}`);
            } else {
                notifyError(`Error exporting bingo squares: ${error}`);
            }
            return;
        }

        notifySuccess('Succesfully exported bingo squares!');
        setIsImportingExporting(false);
        setShowImportExportModal(false);
    };

    const handleImportBingoSquares = async (googleSheetId: string) => {
        setIsImportingExporting(true);

        const gapi = new GoogleSheetsApi();
        await gapi.initialize();

        try {
            const response = await gapi.readSheet(googleSheetId);

            const importedBingoSquares =
                bingoSquareTableTransformer.fromTable(response);

            await batchCreateBingoSquares({
                eventId: event.id,
                bingoSquares: importedBingoSquares.map(
                    tranformBingoSquareForCreate,
                ),
            });
        } catch (error) {
            setIsImportingExporting(false);

            if (error instanceof GoogleSheetsApiError) {
                notifyError(
                    `Error getting bingo data from google ${error.status}`,
                );
            } else if (error instanceof Error) {
                const message = `Error importing bingo squares: ${error.message}`;
                notifyError(message);
            }
            return;
        }
        notifySuccess('Successfully imported bingo squares!');
        setIsImportingExporting(false);
        setShowImportExportModal(false);

        refreshBingoSquares(event.id);
    };

    const breadCrumbs = [
        { link: '/partners', text: 'Partners' },
        {
            link: `/categories?partner=${category.partnerId}`,
            text: `${category.partnerId}'s Categories`,
        },
        {
            link: `/iterations?category=${category.id}`,
            text: `${category.name}'s Iterations`,
        },
        {
            link: `/events?iteration=${iteration.id}`,
            text: `${iteration.name}'s Events`,
        },
        { link: '', text: `${event.name}'s Bingo Cards` },
    ];

    const options = {
        filterType: 'dropdown',
        onRowClick: ([id]: string[], _: any, event: any) => {
            if (shouldIgnoreMuiTableRowClick(event)) {
                return;
            }
            setSelectedQuestionId(id);
        },
        resizableColumns: true,
        responsive: 'simple',
        rowsPerPage: 50,
        rowsPerPageOptions: [10, 20, 30, 40, 50, 75, 100],
    };

    const selectedQuestion = bingoSquares.find(
        (question) => question.id === selectedQuestionId,
    );

    const addActionColumn = () => {
        return [
            ...tableColumns,
            {
                name: 'Actions',
                options: {
                    customBodyRender: (
                        _: unknown,
                        tableMeta: { rowData: string[] },
                    ) => {
                        return (
                            <div className={classes.actionColumn}>
                                <IconButton
                                    aria-label="Firestore Link"
                                    className={classes.actionButton}
                                    key="firestore"
                                    onClick={handleFirestoreClick(
                                        category.firebaseProjectId,
                                        `${event.firestoreLocation}/bingoSquares/${tableMeta.rowData[0]}`,
                                    )}
                                >
                                    <FirestoreIcon />
                                </IconButton>
                                <IconButton
                                    aria-label="Edit"
                                    key="edit"
                                    onClick={() =>
                                        handleEdit(tableMeta.rowData[0])
                                    }
                                >
                                    <EditIcon fontSize="small" name="edit" />
                                </IconButton>
                            </div>
                        );
                    },
                    filter: false,
                },
            },
        ];
    };

    const bingoSquareToEdit = bingoSquares.find(
        (bingoSquare) => bingoSquare.id === editSquareId,
    );

    return (
        <div>
            <div>
                <Paper className={classes.paper}>
                    <BreadcrumbBar breadcrumbs={breadCrumbs} />
                    <PageHeadline headline={`${event.name}: Bingo Squares`} />
                    <div className={classes.finalizedContainer}>
                        {finalizing ? (
                            <Typography align="center" variant="body1">
                                Finalizing...
                            </Typography>
                        ) : event.finalized ? (
                            <Typography
                                align="center"
                                gutterBottom
                                variant="body1"
                            >
                                Event is finalized
                            </Typography>
                        ) : (
                            <Button
                                className={classes.finalizeButton}
                                color="primary"
                                onClick={onFinalizeClick}
                                size="small"
                                variant="contained"
                            >
                                Finalize
                            </Button>
                        )}
                    </div>
                </Paper>
            </div>
            <MuiThemeProvider theme={getMuiTheme()}>
                <MuiDataTable
                    columns={addActionColumn()}
                    data={bingoSquares}
                    options={options}
                />
            </MuiThemeProvider>
            <ActionButton
                actions={[
                    {
                        icon: <KeyboardArrowUp />,
                        name: 'Import / Export bingo squares',
                        onClick: () => {
                            setShowImportExportModal(true);
                        },
                    },
                ]}
            />
            {selectedQuestion && (
                <Dialog
                    onClose={() => setSelectedQuestionId(null)}
                    open={!!selectedQuestion}
                >
                    <BingoSquareDetails bingoSquare={selectedQuestion} />
                </Dialog>
            )}
            {showImportExportModal && (
                <SheetImportExportDialog
                    isImportingExporting={isImportingExporting}
                    onClose={() => setShowImportExportModal(false)}
                    onExport={handleExportBingoSquares}
                    onImport={handleImportBingoSquares}
                />
            )}
            {bingoSquareToEdit && (
                <EditBingoSquareDialogForm
                    notifyError={notifyError}
                    notifySuccess={notifySuccess}
                    notifyWarning={notifyWarning}
                    bingoSquare={bingoSquareToEdit}
                    refreshBingoSquares={refreshBingoSquares}
                    onClose={() => setEditSquareId(null)}
                    updateBingoSquare={updateBingoSquare}
                />
            )}
        </div>
    );
};

export default withStyles(styles)(BingoSquares);
