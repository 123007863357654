import { Component } from 'react';
import { NBAGameStatistics, Prediction } from '../../service/AuthoringApi';
import { NBA_GAME_TIME_REGEX } from '../../service/Constants';
import { determinePredictionLockState } from '../../utils/util';

interface Props {
    addReleaseReminders: (predictions: { [key: string]: Prediction[] }) => void;
    categoryType: string;
    gameStats: NBAGameStatistics;
    predictions: Prediction[];
}

const PREDICTION_REMINDER_BUFFER_IN_SECONDS = 19;

class PredictionReminder extends Component<Props> {
    private remindedPredictions: { [key: string]: boolean } = {};

    constructor(props: Props) {
        super(props);
        this.checkForReleaseReminder();
    }

    public componentDidUpdate(prevProps: Props) {
        const { gameStats: prevGameStats } = prevProps;
        const { gameStats } = this.props;
        const { clock, quarter } = gameStats;
        const { clock: prevClock, quarter: prevQuarter } = prevGameStats;
        if (prevClock === clock && prevQuarter === quarter) {
            return;
        }

        this.checkForReleaseReminder();
    }

    private checkForReleaseReminder = () => {
        const { addReleaseReminders, gameStats, predictions } = this.props;
        const { clock, quarter } = gameStats;
        const periodString = quarter <= 4 ? `Q${quarter}` : 'OT';
        if (!clock) {
            return;
        }
        const parsedGameTime = this.getSecondsLeftInQuarter(clock);

        // Get hidden predictions in current release milestone
        const predictionsToCheck = this.getPredictionsToCheck(
            predictions,
            parsedGameTime,
            periodString,
        );

        predictionsToCheck.map(
            (prediction) => (this.remindedPredictions[prediction.text] = true),
        );

        const releaseBatches: { [key: string]: Prediction[] } = {};
        for (const prediction of predictionsToCheck) {
            const { releaseTime } = prediction;
            if (!releaseTime) {
                continue;
            }
            if (releaseBatches[releaseTime]) {
                releaseBatches[releaseTime].push(prediction);
            } else {
                releaseBatches[releaseTime] = [prediction];
            }
        }
        addReleaseReminders(releaseBatches);
    };

    private getPredictionsToCheck = (
        predictions: Prediction[],
        parsedGameTime: number,
        periodString: string,
    ): Prediction[] => {
        const predictionsToCheck = predictions.filter((prediction) => {
            // If the prediction doesn't have a release time or the release time isn't formatted properly, don't check it.
            if (
                !prediction.releaseTime ||
                !NBA_GAME_TIME_REGEX.test(prediction.releaseTime)
            ) {
                return false;
            }

            const predictionState = determinePredictionLockState(prediction);
            const parsedPredictionTime = this.getSecondsLeftInQuarter(
                prediction.releaseTime,
            );
            // Check: Prediction is not released, the release milestone is the current game milestone,
            // there hasn't already been a reminder for this prediction, and the release time is within X seconds of game clock.
            return (
                predictionState === 'hidden' &&
                prediction.releaseMilestone === periodString &&
                !this.remindedPredictions[prediction.text] &&
                Math.abs(parsedPredictionTime - parsedGameTime) <=
                    PREDICTION_REMINDER_BUFFER_IN_SECONDS
            );
        });
        return predictionsToCheck;
    };

    private getSecondsLeftInQuarter = (clock: string): number => {
        if (!clock) {
            return 10000;
        }
        const timeStringArray = clock.split(':');
        let seconds = 0;
        let multiplier = 1;

        while (timeStringArray.length > 0) {
            const nextString = timeStringArray.pop();
            if (nextString) {
                seconds += multiplier * parseInt(nextString, 10);
                multiplier *= 60;
            }
        }
        return seconds;
    };

    public render() {
        return null;
    }
}

export default PredictionReminder;
