import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import CrudPage from '../components/CrudPage';
import config from '../config';
import { PAGE_LABEL } from '../service/Constants';
import { localizeDate, parseDateString, slugify } from '../utils/util';

const { predictionsWebRoot } = config;

// Takes the multi-line event milestones input text, and formats the helper description text.
const renderEventMilestonesDescription = (eventMilestonesString) => {
    const eventMilestonesDescription = [];

    eventMilestonesString
        .split('\n')
        .map((milestone) => milestone.trim())
        .filter((milestone) => milestone) // filter out empty lines
        .forEach((milestone) => {
            eventMilestonesDescription.push(milestone);
        });

    return eventMilestonesDescription.join(', ');
};
class Categories extends Component {
    static propTypes = {
        categories: PropTypes.arrayOf(PropTypes.object).isRequired,
        createRecord: PropTypes.func.isRequired,
        deleteRecord: PropTypes.func.isRequired,
        partner: PropTypes.object.isRequired,
        refreshData: PropTypes.func.isRequired,
        updateRecord: PropTypes.func.isRequired,
    };

    updateReportingName = ({ formValues, newValue }) => {
        // only update automatically if this is a new category
        if (!formValues.id) {
            formValues.reportingName = newValue;
        }
    };

    updateSlug = ({ formValues, newValue }) => {
        // only auto update if category is not yet live
        if (
            !formValues.id ||
            !formValues.startDate ||
            parseDateString(formValues.startDate).isAfter(moment())
        ) {
            formValues.slug = slugify(newValue);
        }
    };

    inputFields() {
        const { partner } = this.props;
        return [
            {
                name: 'id',
                type: 'hidden',
            },
            {
                label: 'Name',
                name: 'name',
                onChange: this.updateReportingName,
                props: {
                    autoFocus: true,
                },
                required: true,
                type: 'text',
            },
            {
                defaultValue: partner.id,
                label: 'partner',
                name: 'partnerId',
                required: true,
                type: 'hidden',
            },
            {
                label: 'Short Name',
                name: 'shortName',
                onChange: this.updateSlug,
                required: true,
                type: 'text',
            },
            {
                helperTextFunc: (name, value) => {
                    if (value) {
                        return `ex. ${predictionsWebRoot}/${slugify(value)}`;
                    }
                },
                label: 'Slug',
                name: 'slug',
                required: true,
                type: 'text',
            },
            {
                label: 'Reporting Name',
                name: 'reportingName',
                props: {
                    helperText:
                        'Allow analytics to report this category as a different name. Only change this if you know what you’re doing.',
                },
                required: true,
                type: 'text',
            },
            {
                defaultValue: null,
                label: 'Visible Date',
                name: 'startDate',
                props: {
                    InputLabelProps: { shrink: true },
                },
                required: false,
                type: 'datetime-local',
            },
            {
                defaultValue: null,
                label: 'Hidden Date',
                name: 'endDate',
                props: {
                    InputLabelProps: { shrink: true },
                },
                required: false,
                type: 'datetime-local',
            },
            {
                defaultValue: '',
                helperTextFunc: (name, value) => {
                    if (!value || !value.length || value.trim() === '') {
                        return '1 per line';
                    } else {
                        return renderEventMilestonesDescription(value);
                    }
                },
                label: 'Event Milestones (All events)',
                name: 'eventMilestones',
                props: {
                    multiline: true,
                },
                required: false,
                type: 'text',
            },
        ];
    }

    translationInputFields() {
        return [];
    }

    static tableColumns = [
        {
            label: 'Category ID',
            name: 'id',
            options: {
                display: false,
                filter: false,
            },
        },
        {
            label: 'Firestore Location',
            name: 'firestoreLocation',
            options: {
                display: false,
                filter: false,
                sort: false,
            },
        },
        {
            label: 'Name',
            name: 'name',
            options: {
                filter: false,
            },
        },
        {
            label: 'Short Name',
            name: 'shortName',
            options: {
                display: false,
                filter: false,
            },
        },
        {
            label: 'Partner',
            name: 'partnerId',
            options: {
                display: true,
                filter: false,
            },
        },
        {
            label: 'Visible Date',
            name: 'startDate',
            options: {
                customBodyRender: localizeDate,
                filter: false,
                hint: 'When category is visible',
            },
        },
        {
            generateFunc: (category) =>
                `${Categories.categoryVisible(category)}`,
            label: 'Visible',
            name: 'visible',
            options: {
                display: false,
                filter: true,
            },
        },
        {
            label: 'Hidden Date',
            name: 'endDate',
            options: {
                customBodyRender: localizeDate,
                filter: false,
                hint: 'When category flips from visible to hidden',
            },
        },
    ];

    presubmitTransform(formState) {
        const transformedState = { ...formState };
        // filter out empty lines, and transform to array (via filter()) before submitting to server.
        transformedState.eventMilestones = (formState.eventMilestones || '')
            .split('\n')
            .map((milestone) => milestone.trim())
            .filter((milestone) => milestone);

        const eventStateDescriptions = {};
        ['unlocked', 'pending', 'live', 'final'].forEach((eventState) => {
            const fieldName = `${eventState}EventStateDescription`;
            if (transformedState[fieldName]) {
                eventStateDescriptions[eventState] =
                    transformedState[fieldName];
            }
            delete transformedState[fieldName];
        });
        if (Object.keys(eventStateDescriptions).length > 0) {
            transformedState.eventStateDescriptions = eventStateDescriptions;
        }
        const uuidLength = 36;
        const translationEntities = Object.keys(formState).filter((key) =>
            key.startsWith('translation.languageCodeId.'),
        );
        if (translationEntities && translationEntities.length) {
            transformedState.entityTranslations = translationEntities.map(
                (te) => {
                    return {
                        languageCodeId: formState[te],
                        languageIdentity: te.slice(-uuidLength),
                    };
                },
            );

            const translationEventMilestonesMultiline = Object.keys(
                formState,
            ).filter((key) => key.startsWith('translation.eventMilestones'));
            if (
                translationEventMilestonesMultiline &&
                translationEventMilestonesMultiline.length
            ) {
                translationEventMilestonesMultiline.forEach((temmKey) => {
                    const translationPrizeMultiline = formState[temmKey];
                    const translationEntityIndex =
                        transformedState.entityTranslations.findIndex(
                            (et) =>
                                et.languageIdentity ===
                                temmKey.slice(-uuidLength),
                        );
                    transformedState.entityTranslations[
                        translationEntityIndex
                    ] = {
                        ...transformedState.entityTranslations[
                            translationEntityIndex
                        ],
                        eventMilestones: (translationPrizeMultiline || '')
                            .split('\n')
                            .map((prize) => prize.trim())
                            .filter((prize) => prize),
                    };
                    delete formState[temmKey];
                    delete transformedState[temmKey];
                });
            }
        }
        if (formState.slug) {
            transformedState.slug = slugify(formState.slug);
        }

        return transformedState;
    }

    preformRenderTransform(category) {
        const transformedState = { ...category };
        const eventStateDescriptions = category.eventStateDescriptions || {};

        ['unlocked', 'pending', 'live', 'final'].forEach((state) => {
            const fieldName = `${state}EventStateDescription`;
            transformedState[fieldName] = eventStateDescriptions[state];
        });
        delete transformedState.eventStateDescriptions;

        // Take eventMilestones array, and transform into multi-line text value.
        transformedState.eventMilestones = (
            category.eventMilestones || []
        ).join('\n');

        return transformedState;
    }

    buildIterationLink = (categoryId) => `/iterations?category=${categoryId}`;

    static categoryVisible = (categoryOrRow) => {
        const now = moment();

        return (
            !!categoryOrRow.startDate &&
            parseDateString(categoryOrRow.startDate).isBefore(now) &&
            (!categoryOrRow.endDate ||
                parseDateString(categoryOrRow.endDate).isAfter(now))
        );
    };

    setRowProps = (row) => {
        if (
            row.visible.toLowerCase() === 'false' ||
            row.visible.toLowerCase() === 'hidden'
        ) {
            return {
                style: { color: '#808080' },
            };
        } else {
            return {
                style: {},
            };
        }
    };

    render() {
        const { categories, partner, refreshData } = this.props;
        const { firebaseProjectId } = partner;

        const breadCrumbs = [
            {
                link: '/partners',
                text: 'Partners',
            },
            { link: '', text: `${partner.id}'s Categories` },
        ];
        return (
            <CrudPage
                {...this.props}
                breadCrumbs={breadCrumbs}
                buildChildLink={this.buildIterationLink}
                data={categories}
                firebaseProjectId={firebaseProjectId}
                headline="Categories"
                inputFields={this.inputFields()}
                label={PAGE_LABEL.category}
                preformRenderTransform={this.preformRenderTransform}
                presubmitTransform={this.presubmitTransform}
                refreshData={refreshData}
                setRowProps={this.setRowProps}
                supportedLanguages={partner.properties.supportedLanguages || []}
                tableColumns={Categories.tableColumns}
                translationInputFields={this.translationInputFields()}
            />
        );
    }
}

export default Categories;
