import '../../styles/App.css';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import { SportFeedData } from './FeedPrediction';

interface Props {
    handleChange: (data: SportFeedData) => void;
    feedData: SportFeedData;
}

export enum SportRadarSportTypes {
    NCAAFBTrial = 'NCAAFB Trial',
    NHL = 'NHL',
    NFL = 'NFL',
    NBA = 'NBA',
}

export enum NCAAFBPredictionTemplates {
    gameWinner = 'Game winner',
    coinTossWinner = 'Coin toss winner',
}

export enum NHLPredictionTemplates {
    gameWinner = 'Game winner',
    gameHomePowerplayGoals = 'Game powerplay goals Home Team',
    gameAwayPowerplayGoals = 'Game powerplay goals Away Team',
    gameHomeIceTimeMoreThan20PlayerCount = 'Home Team players with ice time >20 min count',
    gameAwayIceTimeMoreThan20PlayerCount = 'Away Team players with ice time >20 min count',
    gameHomePeriodWithMoreGoals = 'Home Team period with more goals',
    gameAwayPeriodWithMoreGoals = 'Away Team period with more goals',
    totalBlockShotsHome = 'Total Block Shots Home Team',
    totalBlockShotsAway = 'Total Block Shots Away Team',
    totalWiningTeamGoals = 'Total Wining Team Goals',
    totalCombinedPowerplayGoals = 'Total Combined Powerplay Goals',
    faceOffsFirstPeriod = 'More faceoffs won in 1st period',
    faceOffsSecondPeriod = 'More faceoffs won in 2nd period',
    faceOffsThirdPeriod = 'More faceoffs won in 3rd period',
    hitsFirstPeriod = 'More hits done in 1st period',
    hitsSecondPeriod = 'More hits done in 2nd period',
    hitsThirdPeriod = 'More hits done in 3rd period',
    anyTeamShorthandedGoal = 'Any Team Shorthanded Goal',
    blockedShotsFirstPeriodHome = 'Blocked shots count in 1st period Home Team',
    blockedShotsSecondPeriodHome = 'Blocked shots count in 2nd period Home Team',
    blockedShotsThirdPeriodHome = 'Blocked shots count in 3rd period Home Team',
    blockedShotsFirstPeriodAway = 'Blocked shots count in 1st period Away Team',
    blockedShotsSecondPeriodAway = 'Blocked shots count in 2nd period Away Team',
    blockedShotsThirdPeriodAway = 'Blocked shots count in 3rd period Away Team',
    takeawaysFirstPeriod = 'More takeaways done in 1st period',
    takeawaysSecondPeriod = 'More takeaways done in 2nd period',
    takeawaysThirdPeriod = 'More takeaways done in 3rd period',

    twoPeriodsPointDifferential = 'Two periods points differential',

    goalsMadeFirstPeriodHome = 'Goals made count in 1st period Home Team',
    goalsMadeSecondPeriodHome = 'Goals made count in 2nd period Home Team',
    goalsMadeThirdPeriodHome = 'Goals made count in 3rd period Home Team',
    goalsMadeFirstPeriodAway = 'Goals made count in 1st period Away Team',
    goalsMadeSecondPeriodAway = 'Goals made count in 2nd period Away Team',
    goalsMadeThirdPeriodAway = 'Goals made count in 3rd period Away Team',

    hitsCombinedFirstPeriod = 'Combined hits done in 1st period',
    hitsCombinedSecondPeriod = 'Combined hits done in 2nd period',
    hitsCombinedThirdPeriod = 'Combined hits done in 3rd period',

    gameShotsLeader = 'Game shots done leader',

    goalsMadeLeaderFirstPeriodHome = 'Goals made leader in 1st period Home Team',
    goalsMadeLeaderSecondPeriodHome = 'Goals made leader in 2nd period Home Team',
    goalsMadeLeaderThirdPeriodHome = 'Goals made leader in 3rd period Home Team',
    goalsMadeLeaderFirstPeriodAway = 'Goals made leader in 1st period Away Team',
    goalsMadeLeaderSecondPeriodAway = 'Goals made leader in 2nd period Away Team',
    goalsMadeLeaderThirdPeriodAway = 'Goals made leader in 3rd period Away Team',

    goalMadeFirstPeriodHome = 'Goal made in 1st period Home Team',
    goalMadeSecondPeriodHome = 'Goal made in 2nd period Home Team',
    goalMadeThirdPeriodHome = 'Goal made in 3rd period Home Team',
    goalMadeFirstPeriodAway = 'Goal made in 1st period Away Team',
    goalMadeSecondPeriodAway = 'Goal made in 2nd period Away Team',
    goalMadeThirdPeriodAway = 'Goal made in 3rd period Away Team',

    goalMade3MinsLeftFirstPeriod = 'Goal made in 3 minutes till the end of the 1st period',
    goalMade3MinsLeftSecondPeriod = 'Goal made in 3 minutes till the end of the 2nd period',
    goalMade3MinsLeftThirdPeriod = 'Goal made in 3 minutes till the end of the 3rd period',

    gameFirstGoalMade = 'First goal made in the game',

    shotsMadeFirstPeriodHome = 'Shots made in 1st period Home Team',
    shotsMadeSecondPeriodHome = 'Shots made in 2nd period Home Team',
    shotsMadeThirdPeriodHome = 'Shots made in 3rd period Home Team',
    shotsMadeFirstPeriodAway = 'Shots made in 1st period Away Team',
    shotsMadeSecondPeriodAway = 'Shots made in 2nd period Away Team',
    shotsMadeThirdPeriodAway = 'Shots made in 3rd period Away Team',

    timeLeftLastGoalMadeFirstPeriod = 'Time left last goal made in 1st period',
    timeLeftLastGoalMadeSecondPeriod = 'Time left last goal made in 2nd period',
    timeLeftLastGoalMadeThirdPeriod = 'Time left last goal made in 3rd period',

    gameSecondGoalMade = 'Game second goal made',

    gamePlayerWithGoalAndAssistHome = 'Game player with goal and assist Home',
    gamePlayerWithGoalAndAssistAway = 'Game player with goal and assist Away',

    gameFirstShotOnGoal = 'Game first shot on goal',

    faceoffsCombinedIn2MinsFirstPeriod = 'Face-offs combined in 2 minutes after 1st period starts',
    faceoffsCombinedIn2MinsSecondPeriod = 'Face-offs combined in 2 minutes after 2nd period starts',
    faceoffsCombinedIn2MinsThirdPeriod = 'Face-offs combined in 2 minutes after 3rd period starts',

    gamePlayersWith2PlusGoalsHome = 'Game players count with 2 more goals Home',
    gamePlayersWith2PlusGoalsAway = 'Game players count with 2 more goals Away',

    penaltyCombinedFirstPeriod = 'Penalties both team made in 1st period',
    penaltyCombinedSecondPeriod = 'Penalties both team made in 2nd period',
    penaltyCombinedThirdPeriod = 'Penalties both team made in 3rd period',

    firstSuccessfulPenalty = 'First successful penalty made',

    shotsLeaderFirstPeriod = 'Shots leader made in 1st period',
    shotsLeaderSecondPeriod = 'Shots leader made in 2nd period',
    shotsLeaderThirdPeriod = 'Shots leader made in 3rd period',

    firstHitFirstPeriod = 'First hit made in 1st period',
    firstHitSecondPeriod = 'First hit made in 2nd period',
    firstHitThirdPeriod = 'First hit made in 3rd period',

    goalieWith30MoreSaves = 'Goalie with 30 or more saves made',

    powerPlayGoalsMadeFirstPeriod = 'Power play goals made in 1st period',
    powerPlayGoalsMadeSecondPeriod = 'Power play goals made in 2nd period',
    powerPlayGoalsMadeThirdPeriod = 'Power play goals made in 3rd period',

    teamPenaltyMadeFirstPeriod = 'First penalty by team made in 1st period',
    teamPenaltyMadeSecondPeriod = 'First penalty by team made in 2nd period',
    teamPenaltyMadeThirdPeriod = 'First penalty by team made in 3rd period',

    gameHitsMadeLeader = 'Game hits made leader',

    halfGameShotsLeaderHome = 'Half game shots leader Home Team',
    halfGameShotsLeaderAway = 'Half game shots leader Away Team',

    game10ShotSaveGoalie = 'First 10 shot saves goalie',

    teamPlay5on3FirstPeriod = 'Any team play 5 on 3 players in 1st period',
    teamPlay5on3SecondPeriod = 'Any team play 5 on 3 players in 2nd period',
    teamPlay5on3ThirdPeriod = 'Any team play 5 on 3 players in 3rd period',

    powerPlayOpportunitiesFirstPeriod = 'Power play opportunities in 1st period',
    powerPlayOpportunitiesSecondPeriod = 'Power play opportunities in 2nd period',
    powerPlayOpportunitiesThirdPeriod = 'Power play opportunities in 3rd period',

    icingStoppageFirstPeriod = 'Icing stoppage in 1st period',
    icingStoppageSecondPeriod = 'Icing stoppage in 2nd period',
    icingStoppageThirdPeriod = 'Icing stoppage in 3rd period',

    penaltyScorelessHomeTeamFirstPeriod = 'Penalty caused scoreless in 1st period Home',
    penaltyScorelessHomeTeamSecondPeriod = 'Penalty caused scoreless in 2nd period Home',
    penaltyScorelessHomeTeamThirdPeriod = 'Penalty caused scoreless in 3rd period Home',
    penaltyScorelessAwayTeamFirstPeriod = 'Penalty caused scoreless in 1st period Away',
    penaltyScorelessAwayTeamSecondPeriod = 'Penalty caused scoreless in 2nd period Away',
    penaltyScorelessAwayTeamThirdPeriod = 'Penalty caused scoreless in 3rd period Away',
}

export enum NBAPredictionTemplates {
    gameWinner = 'Game winner',
    gameLeaderFirstPeriod = 'Score more points in 1st period',
    gameLeaderSecondPeriod = 'Score more points in 2nd period',
    gameLeaderThirdPeriod = 'Score more points in 3rd period',
    gameLeaderFourthPeriod = 'Score more points in 4th period',
    gameTeamLeaderFirstPeriodHome = 'Game team leader Home in 1st period',
    gameTeamLeaderSecondPeriodHome = 'Game team leader Home in 2nd period',
    gameTeamLeaderThirdPeriodHome = 'Game team leader Home in 3rd period',
    gameTeamLeaderFourthPeriodHome = 'Game team leader Home in 4th period',
    gameTeamLeaderFirstPeriodAway = 'Game team leader Away in 1st period',
    gameTeamLeaderSecondPeriodAway = 'Game team leader Away in 2nd period',
    gameTeamLeaderThirdPeriodAway = 'Game team leader Away in 3rd period',
    gameTeamLeaderFourthPeriodAway = 'Game team leader Away in 4th period',
    gameHomePeriodWithMoreGoals = 'Home Team period with more goals',
    gameAwayPeriodWithMoreGoals = 'Away Team period with more goals',
    pointDifferenceFirstPeriod = 'Score difference in 1st period',
    pointDifferenceSecondPeriod = 'Score difference in 2nd period',
    pointDifferenceThirdPeriod = 'Score difference in 3rd period',
    pointDifferenceFourthPeriod = 'Score difference in 4th period',
    gamePointDifference = 'Game score difference',
    firstHalfGameLeaderHome = 'Home team 1st half leader',
    firstHalfGameLeaderAway = 'Away team 1st half leader',
    freeThrowsMadeFirstPeriod = 'Free throws made in 1st period',
    freeThrowsMadeSecondPeriod = 'Free throws made in 2nd period',
    freeThrowsMadeThirdPeriod = 'Free throws made in 3rd period',
    freeThrowsMadeFourthPeriod = 'Free throws made in 4th period',
    gameFreeThrowsMade = 'Combined free throws made in the game',
    firstHalfPointDifference = 'Score difference at the end of the 1st half',
    threePointsMadeGameLeader = 'Three point made game leader',
    firstHalfFreeTrowsLeaderHome = 'Home first half free throws leader',
    firstHalfFreeTrowsLeaderAway = 'Away first half free throws leader',
    theMostScorePeriodHome = 'Home most score period',
    theMostScorePeriodAway = 'Away most score period',
    gameReboundsMade = 'Combined rebounds made in the game', //check rebounds
    gamePointsLeaderHome = 'Home game points leader',
    gamePointsLeaderAway = 'Away game points leader',
    blocksMadeFirstPeriod = 'Blocks made in 1st period',
    blocksMadeSecondPeriod = 'Blocks made in 2nd period',
    blocksMadeThirdPeriod = 'Blocks made in 3rd period',
    blocksMadeFourthPeriod = 'Blocks made in 4th period',
    firstHalfBlocksMadeHome = 'Home first half blocks made',
    firstHalfBlocksMadeAway = 'Away first half blocks made',
    stealsMadeFirstPeriod = 'Steals made in 1st period',
    stealsMadeSecondPeriod = 'Steals made in 2nd period',
    stealsMadeThirdPeriod = 'Steals made in 3rd period',
    stealsMadeFourthPeriod = 'Steals made in 4th period',
    gameReboundsMadeLeader = 'Game rebounds made leader', // check rebounds
    firstHalfCombinedSRBMadeLeader = 'First half combined steals, rebounds and blocks made leader', // SRB - Steals, Rebounds, Blocks
    game3PointsMadePctLeader = '3 Points made percentage game leader',
    first3PointsMadeTeam = 'First 3points made team',
    periodOffensiveReboundsMadeHome = 'Home offensive rebounds made', // check offensive rebounds
    periodOffensiveReboundsMadeAway = 'Away offensive rebounds made', // check offensive rebounds
    gameTenPointsMadePlayersQtyHome = 'Ten points made Home players quantity in the game',
    gameTenPointsMadePlayersQtyAway = 'Ten points made Away players quantity in the game',
    tenPointsMadeFirstPeriodHomePlayer = 'Ten points made by Home player in 1st period',
    tenPointsMadeSecondPeriodHomePlayer = 'Ten points made by Home player in 2nd period',
    tenPointsMadeThirdPeriodHomePlayer = 'Ten points made by Home player in 3rd period',
    tenPointsMadeFourthPeriodHomePlayer = 'Ten points made by Home player in 4th period',
    tenPointsMadeFirstPeriodAwayPlayer = 'Ten points made by Away player in 1st period',
    tenPointsMadeSecondPeriodAwayPlayer = 'Ten points made by Away player in 2nd period',
    tenPointsMadeThirdPeriodAwayPlayer = 'Ten points made by Away player in 3rd period',
    tenPointsMadeFourthPeriodAwayPlayer = 'Ten points made by Away player in 4th period',
    tenPointsMadeAnyPlayerFirstPeriod = 'Ten points made by any team player in 1st period',
    tenPointsMadeAnyPlayerSecondPeriod = 'Ten points made by any team player in 2nd period',
    tenPointsMadeAnyPlayerThirdPeriod = 'Ten points made by any team player in 3rd period',
    tenPointsMadeAnyPlayerFourthPeriod = 'Ten points made by any team player in 4th period',
    gameFastBreakPointsHome = 'Game fast break points Home',
    gameFastBreakPointsAway = 'Game fast break points Away',
    threePointAttemptsLeaderFirstPeriod = 'Three point attempts leader in 1st period',
    threePointAttemptsLeaderSecondPeriod = 'Three point attempts leader in 2nd period',
    threePointAttemptsLeaderThirdPeriod = 'Three point attempts leader in 3rd period',
    threePointAttemptsLeaderFourthPeriod = 'Three point attempts leader in 4th period',
    gameLastTeamToScoreHome = 'Last team to score in the game Home',
    gameLastTeamToScoreAway = 'Last team to score in the game Away',
    lastTeamToScoreFirstPeriod = 'Last team to score in 1st period',
    lastTeamToScoreSecondPeriod = 'Last team to score in 2nd period',
    lastTeamToScoreThirdPeriod = 'Last team to score in 3rd period',
    lastTeamToScoreFourthPeriod = 'Last team to score in 4th period',
    fieldGoalsTeamLeaderFirstPeriod = 'Field goals team leader in 1st period',
    fieldGoalsTeamLeaderSecondPeriod = 'Field goals team leader in 2nd period',
    fieldGoalsTeamLeaderThirdPeriod = 'Field goals team leader in 3rd period',
    fieldGoalsTeamLeaderFourthPeriod = 'Field goals team leader in 4th period',
    benchScoreHome = 'Bench score more than 35 Home',
    benchScoreAway = 'Bench score more than 35 Away',
    benchScoreTeamLeaderFirstPeriod = 'Bench score team leader in 1st period',
    benchScoreTeamLeaderSecondPeriod = 'Bench score team leader in 2nd period',
    benchScoreTeamLeaderThirdPeriod = 'Bench score team leader in 3rd period',
    benchScoreTeamLeaderFourthPeriod = 'Bench score team leader in 4th period',
    firstFieldGoalsMadeHome = 'First 2 field goals made Home',
    firstFieldGoalsMadeAway = 'First 2 field goals made Away',
    firstHalfLastMinuteScoreByTeams = 'Both team score in the last minute of the first half',
}

export enum NFLPredictionTemplates {
    gameWinner = 'Game winner',
    totalTurnoversQtyHome = 'Total turnovers quantity Home',
    totalTurnoversQtyAway = 'Total turnovers quantity Away',
    scorePointsFirstPeriodHome = 'Score points count in 1st period Home Team',
    scorePointsSecondPeriodHome = 'Score points count in 2nd period Home Team',
    scorePointsThirdPeriodHome = 'Score points count in 3rd period Home Team',
    scorePointsFourthPeriodHome = 'Score points count in 4th period Home Team',
    scorePointsFirstPeriodAway = 'Score points count in 1st period Away Team',
    scorePointsSecondPeriodAway = 'Score points count in 2nd period Away Team',
    scorePointsThirdPeriodAway = 'Score points count in 3rd period Away Team',
    scorePointsFourthPeriodAway = 'Score points count in 4th period Away Team',
    defenseSacksQtyHome = 'Defense sacks quantity Home',
    defenseSacksQtyAway = 'Defense sacks quantity Away',
    gameTouchdownQty = 'Game touchdown quantity',
    gamePossessionTimeLeader = 'Longest possession time game leader',
    gamePenaltyLeader = 'Penalty game leader',
    gameTotalYardsHome = 'Total yards game ended Home',
    gameTotalYardsAway = 'Total yards game ended Away',
    gameTacklesForLossQtyHome = 'Game tackles for loss quantity Home',
    gameTacklesForLossQtyAway = 'Game tackles for loss quantity Away',
    fieldGoalAttemptsFirstPeriodHome = 'Field goal attempts in 1st period Home Team',
    fieldGoalAttemptsSecondPeriodHome = 'Field goal attempts in 2nd period Home Team',
    fieldGoalAttemptsThirdPeriodHome = 'Field goal attempts in 3rd period Home Team',
    fieldGoalAttemptsFourthPeriodHome = 'Field goal attempts in 4th period Home Team',
    fieldGoalAttemptsFirstPeriodAway = 'Field goal attempts in 1st period Away Team',
    fieldGoalAttemptsSecondPeriodAway = 'Field goal attempts in 2nd period Away Team',
    fieldGoalAttemptsThirdPeriodAway = 'Field goal attempts in 3rd period Away Team',
    fieldGoalAttemptsFourthPeriodAway = 'Field goal attempts in 4th period Away Team',
    puntsQtyFirstPeriodHome = 'Punts quantity in 1st period Home Team',
    puntsQtySecondPeriodHome = 'Punts quantity in 2nd period Home Team',
    puntsQtyThirdPeriodHome = 'Punts quantity in 3rd period Home Team',
    puntsQtyFourthPeriodHome = 'Punts quantity in 4th period Home Team',
    puntsQtyFirstPeriodAway = 'Punts quantity in 1st period Away Team',
    puntsQtySecondPeriodAway = 'Punts quantity in 2nd period Away Team',
    puntsQtyThirdPeriodAway = 'Punts quantity in 3rd period Away Team',
    puntsQtyFourthPeriodAway = 'Punts quantity in 4th period Away Team',
    rushingTouchdownQtyFirstPeriodHome = 'Rushing touchdown quantity in 1st period Home Team',
    rushingTouchdownQtySecondPeriodHome = 'Rushing touchdown quantity in 2nd period Home Team',
    rushingTouchdownQtyThirdPeriodHome = 'Rushing touchdown quantity in 3rd period Home Team',
    rushingTouchdownQtyFourthPeriodHome = 'Rushing touchdown quantity in 4th period Home Team',
    rushingTouchdownQtyFirstPeriodAway = 'Rushing touchdown quantity in 1st period Away Team',
    rushingTouchdownQtySecondPeriodAway = 'Rushing touchdown quantity in 2nd period Away Team',
    rushingTouchdownQtyThirdPeriodAway = 'Rushing touchdown quantity in 3rd period Away Team',
    rushingTouchdownQtyFourthPeriodAway = 'Rushing touchdown quantity in 4th period Away Team',
    rushingMoreThan10YardsFirstPeriodHome = 'Rushing plays more than 10 yards in 1st period Home Team',
    rushingMoreThan10YardsSecondPeriodHome = 'Rushing plays more than 10 yards in 2nd period Home Team',
    rushingMoreThan10YardsThirdPeriodHome = 'Rushing plays more than 10 yards in 3rd period Home Team',
    rushingMoreThan10YardsFourthPeriodHome = 'Rushing plays more than 10 yards in 4th period Home Team',
    rushingMoreThan10YardsFirstPeriodAway = 'Rushing plays more than 10 yards in 1st period Away Team',
    rushingMoreThan10YardsSecondPeriodAway = 'Rushing plays more than 10 yards in 2nd period Away Team',
    rushingMoreThan10YardsThirdPeriodAway = 'Rushing plays more than 10 yards in 3rd period Away Team',
    rushingMoreThan10YardsFourthPeriodAway = 'Rushing plays more than 10 yards in 4th period Away Team',
    longestTouchdownPlayerHome = 'Longest touchdown player Home',
    longestTouchdownPlayerAway = 'Longest touchdown player Away',
}

function SportRadar(props: Props) {
    const { feedData, handleChange } = props;

    const sportTypes: SportRadarSportTypes[] = [
        ...Object.values(SportRadarSportTypes),
    ];

    const NCAAFBPredictionPreset: NCAAFBPredictionTemplates[] = [
        ...Object.values(NCAAFBPredictionTemplates),
    ];
    const NHLPredictionPreset: Array<NHLPredictionTemplates> = [
        ...Object.values(NHLPredictionTemplates),
    ];

    const NBAPredictionPreset: Array<NBAPredictionTemplates> = [
        ...Object.values(NBAPredictionTemplates),
    ];

    const NFLPredictionPreset: Array<NFLPredictionTemplates> = [
        ...Object.values(NFLPredictionTemplates),
    ];

    let sportFields;
    switch (feedData.feedSport) {
        case SportRadarSportTypes.NCAAFBTrial:
            sportFields = (
                <>
                    <FormHelperText>Game Id</FormHelperText>
                    <TextField
                        fullWidth={true}
                        margin="dense"
                        onChange={(event) =>
                            handleChange({
                                ...feedData,
                                id: event.target.value,
                            })
                        }
                        placeholder="Game ID"
                        style={{
                            display: 'block',
                            margin: '0 auto',
                        }}
                        value={feedData.id}
                    />
                    <FormHelperText>Prediction template</FormHelperText>
                    <Select
                        fullWidth={true}
                        onChange={(event) =>
                            handleChange({
                                ...feedData,
                                template: event.target
                                    .value as NCAAFBPredictionTemplates,
                            })
                        }
                        value={feedData.template}
                    >
                        {NCAAFBPredictionPreset.map(
                            (template: NCAAFBPredictionTemplates) => (
                                <MenuItem key={template} value={template}>
                                    {template}
                                </MenuItem>
                            ),
                        )}
                    </Select>
                </>
            );
            break;
        case SportRadarSportTypes.NHL:
            sportFields = (
                <>
                    <FormHelperText>Game Id</FormHelperText>
                    <TextField
                        fullWidth={true}
                        margin="dense"
                        onChange={(event) =>
                            handleChange({
                                ...feedData,
                                id: event.target.value,
                            })
                        }
                        placeholder="Game ID"
                        style={{
                            display: 'block',
                            margin: '0 auto',
                        }}
                        value={feedData.id}
                    />
                    <FormHelperText>Prediction template</FormHelperText>
                    <Select
                        fullWidth={true}
                        onChange={(event) =>
                            handleChange({
                                ...feedData,
                                template: event.target
                                    .value as NHLPredictionTemplates,
                            })
                        }
                        value={feedData.template}
                    >
                        {NHLPredictionPreset.map((template) => (
                            <MenuItem key={template} value={template}>
                                {template}
                            </MenuItem>
                        ))}
                    </Select>
                </>
            );
            break;
        case SportRadarSportTypes.NBA:
            sportFields = (
                <>
                    <FormHelperText>Game Id</FormHelperText>
                    <TextField
                        fullWidth={true}
                        margin="dense"
                        onChange={(event) =>
                            handleChange({
                                ...feedData,
                                id: event.target.value,
                            })
                        }
                        placeholder="Game ID"
                        style={{
                            display: 'block',
                            margin: '0 auto',
                        }}
                        value={feedData.id}
                    />
                    <FormHelperText>Prediction template</FormHelperText>
                    <Select
                        fullWidth={true}
                        onChange={(event) =>
                            handleChange({
                                ...feedData,
                                template: event.target
                                    .value as NBAPredictionTemplates,
                            })
                        }
                        value={feedData.template}
                    >
                        {NBAPredictionPreset.map((template) => (
                            <MenuItem key={template} value={template}>
                                {template}
                            </MenuItem>
                        ))}
                    </Select>
                </>
            );
            break;
        case SportRadarSportTypes.NFL:
            sportFields = (
                <>
                    <FormHelperText>Game Id</FormHelperText>
                    <TextField
                        fullWidth={true}
                        margin="dense"
                        onChange={(event) =>
                            handleChange({
                                ...feedData,
                                id: event.target.value,
                            })
                        }
                        placeholder="Game ID"
                        style={{
                            display: 'block',
                            margin: '0 auto',
                        }}
                        value={feedData.id}
                    />
                    <FormHelperText>Prediction template</FormHelperText>
                    <Select
                        fullWidth={true}
                        onChange={(event) =>
                            handleChange({
                                ...feedData,
                                template: event.target
                                    .value as NFLPredictionTemplates,
                            })
                        }
                        value={feedData.template}
                    >
                        {NFLPredictionPreset.map((template) => (
                            <MenuItem key={template} value={template}>
                                {template}
                            </MenuItem>
                        ))}
                    </Select>
                </>
            );
            break;
        default:
            break;
    }

    return (
        <>
            <FormHelperText>Sports</FormHelperText>
            <Select
                fullWidth={true}
                onChange={(event) =>
                    handleChange({
                        ...feedData,
                        feedSport: event.target.value as SportRadarSportTypes,
                    })
                }
                value={feedData.feedSport}
            >
                {sportTypes.map((sport: SportRadarSportTypes) => (
                    <MenuItem key={sport} value={sport}>
                        {sport}
                    </MenuItem>
                ))}
            </Select>
            {sportFields}
        </>
    );
}

export default SportRadar;
