import {
    Button,
    DialogActions,
    FormControl,
    LinearProgress,
    TextField,
    Typography,
} from '@material-ui/core';
import React, { forwardRef, useImperativeHandle } from 'react';
import { UserDto } from 'src/service/Dto';
import { ModalHandle } from '../../types';
import AuthoringModal from '../AuthoringModal';
import useSetProUsersForm from './useSetProUsersForm';

interface Props {
    setProUsers: (params: {
        partnerId: string;
        displayNames: string[];
    }) => Promise<UserDto[]>;
    getUsers: (params: {
        partnerId: string;
        isPro?: boolean;
    }) => Promise<UserDto[]>;
    notifyError: (message: string) => void;
    onSetProUsersResponse: (success: boolean, error: Error | null) => void;
}

const SetProUsersModal = (
    props: Props,
    forwardedRef: React.Ref<ModalHandle>,
) => {
    const { getUsers, notifyError, setProUsers } = props;

    const {
        displayNames,
        handleSubmit,
        handleTextFieldChange,
        isFormDirty,

        isGetUsersLoading,
        isSetProUsersLoading,

        partnerId,
        setPartnerId,
    } = useSetProUsersForm({ getUsers, notifyError, setProUsers });

    const closeModal = () => {
        setPartnerId(undefined);
    };

    const showModal = (partnerId: string) => {
        setPartnerId(partnerId);
    };

    useImperativeHandle(forwardedRef, () => ({
        closeModal,
        showModal,
    }));

    if (!partnerId) {
        return null;
    }

    const isLoading = isGetUsersLoading || isSetProUsersLoading;
    const subtitle = (
        <Typography variant="subtitle1">
            Specify the pro users by their display names.
        </Typography>
    );

    return (
        <AuthoringModal
            onClose={closeModal}
            subtitle={subtitle}
            title="Set Pro Users"
        >
            <FormControl>
                <TextField
                    disabled={isLoading}
                    label="User Display Names"
                    margin="normal"
                    multiline
                    onChange={handleTextFieldChange}
                    required
                    value={displayNames || ''}
                />
            </FormControl>
            <DialogActions>
                <Button
                    color="secondary"
                    disabled={isLoading}
                    onClick={closeModal}
                    variant="contained"
                >
                    Close
                </Button>

                <Button
                    color="primary"
                    disabled={isLoading || !isFormDirty}
                    onClick={() => {
                        if (handleSubmit) {
                            handleSubmit()
                                .then(() => {
                                    props.onSetProUsersResponse(true, null);
                                })
                                .catch((error) => {
                                    props.onSetProUsersResponse(false, error);
                                });
                        }
                    }}
                    variant="contained"
                >
                    SET
                </Button>
            </DialogActions>
            {isLoading && <LinearProgress />}
        </AuthoringModal>
    );
};

export default forwardRef<ModalHandle, Props>(SetProUsersModal);
