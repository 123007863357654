import { Tab, Table, TableCell, TableRow, Tabs } from '@material-ui/core';
import React, { useState } from 'react';
import TabPanel from '../../components/TabPanel';
import { BingoSquare } from '../../service/Dto';
import { localizeDate } from '../../utils/util';

type Props = {
    bingoSquare: BingoSquare;
};

const BingoSquareDetails = ({ bingoSquare }: Props) => {
    const [tabIndex, setTabIndex] = useState(0);

    const tabs: {
        key: string;
        value: BingoSquare;
    }[] = [
        {
            key: 'Unspecified Language',
            value: bingoSquare,
        },
    ];

    return (
        <>
            <Tabs
                indicatorColor="primary"
                onChange={(_, newTabIndex) => setTabIndex(newTabIndex)}
                textColor="primary"
                value={tabIndex}
            >
                {tabs.map(({ key }, index) => {
                    return <Tab key={index} label={<div>{key}</div>} />;
                })}
            </Tabs>
            {tabs.map((tab, index) => {
                return (
                    <TabPanel index={index} key={index} value={tabIndex}>
                        <Table>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>{bingoSquare.id}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Text</TableCell>
                                <TableCell>{tab.value.text}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Occurred Date</TableCell>
                                <TableCell>
                                    {bingoSquare.occurredDate
                                        ? localizeDate(bingoSquare.occurredDate)
                                        : 'NULL'}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Image</TableCell>
                                <TableCell>
                                    <img
                                        height="100"
                                        width="100"
                                        src={tab.value.imageUrl}
                                        alt="image url"
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Config</TableCell>
                                <TableCell>
                                    <pre>
                                        {JSON.stringify(
                                            bingoSquare.config,
                                            null,
                                            2,
                                        )}
                                    </pre>
                                </TableCell>
                            </TableRow>
                        </Table>
                    </TabPanel>
                );
            })}
        </>
    );
};

export default BingoSquareDetails;
