import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { ReactNode } from 'react';

type Props = {
    options: string[];
    correctOption: string;
};

const useStyles = makeStyles({
    container: {
        display: 'flex',
        alignItems: 'center',
        gap: 10,
    },
    option: {
        height: 'auto',
        width: 30,
    },
    correctOption: {
        border: '#00e4c7 solid 4px',
        width: 50,
    },
});

const ImageOptions = ({ correctOption, options }: Props) => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            {options.map<ReactNode>((option) => (
                <img
                    className={classNames(
                        classes.option,
                        option === correctOption && classes.correctOption,
                    )}
                    key={option}
                    height={30}
                    width={30}
                    src={option}
                />
            ))}
        </div>
    );
};

export default ImageOptions;
