import { FeedProviders } from 'src/components/FeedPredictions/FeedPrediction';
import {
    NCAAFBPredictionTemplates,
    SportRadarSportTypes,
} from 'src/components/FeedPredictions/SportRadar';
import { User } from 'src/components/GoogleAuth/GoogleAuth';
import { SportRadarNBASeason } from 'src/utils/event/BingoSportRadarUrlConfig';
import config from '../config';
import {
    BatchProgressivePollCreate,
    BingoSquare,
    BingoSquareCreate,
    BingoSquareUpdateRequest,
    CategoryDto,
    EventDto,
    IterationDto,
    OfferCodeListDto,
    OfferDto,
    PartnerDto,
    PredictionDto,
    ProgressivePoll,
    ProgressivePollCreate,
    SponsorDto,
    SponsorshipUnitDto,
    SportRadarNBAGame,
    Team,
    TeamCreate,
    TriviaQuestion,
    TriviaQuestionCreate,
    UserDelete,
    UserDto,
    VenueCreate,
} from './Dto';

const { apiBaseUrl, sponsorBaseUrl } = config;

export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' | 'HEAD';

export interface NBAGameStatistics {
    away: NBATeamInfo;
    clock?: string;
    home: NBATeamInfo;
    leadChanges?: number;
    quarter: number;
    playByPlayMessages?: NBAPlayByPlay[];
}

export interface Prediction {
    id?: string;
    number: number;
    type: string;
    text: string;
    options: QuestionOption[];
    totalPointValue: number;
    lockType: string;
    eventId?: string;
    detailsText?: string;
    releaseMilestone?: string;
    releaseTime?: string;
    answerMilestone?: string;
    answerTime?: string;
    timeIntervalSeconds?: number;
    lockDescription?: string;
    notes?: string;
    entityTranslations?: any[];
    [key: string]: string | number | QuestionOption[] | undefined;
}

export interface QuestionOption {
    answeringText: string;
    number: number;
    text: string;
}

interface NBATeamInfo {
    alias: string;
    id: string;
    market: string;
    name: string;
    points: number;
    scoring: any;
    statistics: NBATeamStatistics;
}

interface NBATeamStatistics {
    assists: number;
    biggestLead: number;
    blocks: number;
    defensiveRebounds: number;
    fastBreakPts: number;
    fieldGoalsAttempted: number;
    fieldGoalsMade: number;
    fieldGoalsPct: number;
    foulouts?: number;
    freeThrowsMade: number;
    freeThrowsPct: number;
    freeThrowsAttempted: number;
    offensiveRebounds: number;
    points: number;
    pointsInPaint: number;
    rebounds: number;
    steals: number;
    threePointsMade: number;
    threePointsAttempted: number;
    threePointsPct: number;
    turnovers: number;
    largestScoringRun?: number;
    periods?: NBATeamStatistics[];
}

export interface NBAPlayByPlay {
    gameTime: string;
    message: string;
    score: string;
    quarter: number;
    quarterType: string;
    team: string;
    type: string;
}

export class ServerError extends Error {
    public code?: string;
    public status: number; // http status code
    public dataPath?: string;

    constructor({
        code,
        dataPath,
        message,
        status,
    }: {
        status: number;
        message?: string;
        code?: string;
        dataPath?: string;
    }) {
        super(message);
        this.code = code;
        this.status = status;
        this.dataPath = dataPath;
    }
}

export class AuthoringApi {
    private onError?: (error: Error) => void;
    private onStaleToken?: () => void;

    constructor(params: { onError?: () => void; onStaleToken?: () => void }) {
        const { onError, onStaleToken } = params;
        this.onError = onError;
        this.onStaleToken = onStaleToken;
    }

    private externalRequest = async (params: {
        url: string;
        method: HttpMethod;
        bodyData?: object;
    }): Promise<any> => {
        const { bodyData, method, url } = params;
        const response = await fetch(url, {
            ...(bodyData && { body: JSON.stringify(bodyData) }),
            headers: {
                Accept: '*/*',
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json; charset=UTF-8',
            },
            method,
        });

        if (!response.ok) {
            console.log('failed external response');
            console.log(response);
        } else {
            if (response.status === 204) {
                return {};
            } else {
                try {
                    return await response.json();
                } catch (error) {
                    console.error(
                        "response was okay but didn't parse as json",
                        await response.text(),
                    );
                    if (this.onError) {
                        this.onError(error as Error);
                    }
                }
            }
        }
    };

    private request = async (params: {
        baseUrl?: string;
        path: string;
        method: HttpMethod;
        body?: object;
    }): Promise<any> => {
        const { baseUrl = apiBaseUrl, body, method, path } = params;

        const response = await fetch(`${baseUrl}${path}`, {
            ...(body && { body: JSON.stringify(body) }),
            credentials: 'include',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            method,
        });

        if (!response.ok) {
            if (response.status === 401 || response.status === 403) {
                if (this.onStaleToken) {
                    this.onStaleToken();
                }
                throw new Error('Stale Token');
            } else {
                let errorResponse;
                try {
                    errorResponse = await response.json();
                } catch (error) {
                    console.warn(
                        'unable to parse response as json using text',
                        error,
                    );
                    const responseText = await response.text();
                    throw new ServerError({
                        message: responseText,
                        status: response.status,
                    });
                }
                // if response is parsed as json
                if (errorResponse) {
                    throw new ServerError({
                        code: errorResponse.code,
                        message:
                            errorResponse.message || 'Unknown Server Error',
                        status: response.status,
                        ...(errorResponse.dataPath && {
                            dataPath: errorResponse.dataPath,
                        }),
                    });
                } else {
                    throw new ServerError({
                        message: 'Unknown Server Error',
                        status: response.status,
                    });
                }
            }
        } else {
            if (response.status === 204) {
                return {};
            } else {
                try {
                    return await response.json();
                } catch (error) {
                    console.error(
                        "response was okay but didn't parse as json",
                        await response.text(),
                    );
                    if (this.onError) {
                        this.onError(error as Error);
                    }
                }
            }
        }
    };

    public async getPartners(): Promise<PartnerDto[]> {
        const response = await this.request({
            method: 'GET',
            path: '/partners',
        });
        return response as PartnerDto[];
    }

    public async getPartner(partnerId: string): Promise<PartnerDto> {
        const response = await this.request({
            method: 'GET',
            path: `/partners/${partnerId}`,
        });
        return response as PartnerDto;
    }

    public async getCategories(partnerId: string): Promise<CategoryDto[]> {
        const response = await this.request({
            method: 'GET',
            path: `/categories?partner-id=${partnerId}`,
        });
        return response as CategoryDto[];
    }

    public async getCategory(categoryId: string): Promise<CategoryDto> {
        const response = await this.request({
            method: 'GET',
            path: `/categories/${categoryId}`,
        });
        return response as CategoryDto;
    }

    public async getIterations(categoryId: string): Promise<IterationDto[]> {
        const response = await this.request({
            method: 'GET',
            path: `/categories/${categoryId}/iterations`,
        });
        return response as IterationDto[];
    }

    public async getIteration(iterationId: string): Promise<IterationDto> {
        const response = await this.request({
            method: 'GET',
            path: `/iterations/${iterationId}`,
        });
        return response as IterationDto;
    }

    public async getEventsForIteration(
        iterationId: string,
    ): Promise<EventDto[]> {
        const response = await this.request({
            method: 'GET',
            path: `/iterations/${iterationId}/events`,
        });
        return response as EventDto[];
    }

    public async getEvent(eventId: string): Promise<EventDto> {
        const response = await this.request({
            method: 'GET',
            path: `/events/${eventId}`,
        });
        return response as EventDto;
    }

    public async pullPredictionDataFromFeed(
        eventId: string,
        feedProvider: FeedProviders,
        id: string,
        feedSport: SportRadarSportTypes,
        template?: NCAAFBPredictionTemplates,
        groupId?: string,
    ): Promise<EventDto> {
        const response = await this.request({
            method: 'GET',
            path: `/events/${eventId}/predictions/pulldatafromfeed?feedprovider=${feedProvider}&id=${id}${
                template ? `&template=${template}` : ''
            }&feedsport=${feedSport}${groupId ? `&groupid=${groupId}` : ''}`,
        });
        return response;
    }

    public async createEvent(event: Partial<EventDto>): Promise<EventDto> {
        const response = await this.request({
            body: event,
            method: 'POST',
            path: '/events',
        });
        return response as EventDto;
    }

    public async updateEvent(
        eventId: string,
        event: Partial<EventDto>,
    ): Promise<EventDto> {
        const response = await this.request({
            body: event,
            method: 'PATCH',
            path: `/events/${eventId}`,
        });
        return response as EventDto;
    }

    public async finalizeEvent(
        eventId: string,
        event: Partial<EventDto>,
    ): Promise<EventDto> {
        const response = await this.request({
            body: event,
            method: 'POST',
            path: `/events/${eventId}/finalize`,
        });
        return response as EventDto;
    }

    public async getPredictionsForEvent(
        eventId: string,
    ): Promise<PredictionDto[]> {
        const response = await this.request({
            method: 'GET',
            path: `/events/${eventId}/predictions`,
        });
        return response as PredictionDto[];
    }

    public async getTriviaQuestions(
        partnerId: string,
    ): Promise<TriviaQuestion[]> {
        const response = await this.request({
            method: 'GET',
            path: `/partners/${partnerId}/trivia-questions`,
        });
        return response;
    }

    public async getBingoSquaresForEvent(
        eventId: string,
    ): Promise<BingoSquare[]> {
        const response = await this.request({
            method: 'GET',
            path: `/events/${eventId}/bingo-squares`,
        });
        return response as BingoSquare[];
    }

    public async batchCreateBingoSquares({
        bingoSquares,
        eventId,
    }: {
        eventId: string;
        bingoSquares: BingoSquareCreate[];
    }): Promise<BingoSquare[]> {
        const response = await this.request({
            body: { bingoSquares },
            method: 'POST',
            path: `/events/${eventId}/bingo-squares/batch`,
        });
        return response as BingoSquare[];
    }

    public async updateBingoSquare({
        body,
        eventId,
        id,
    }: {
        id: string;
        eventId: string;
        body: BingoSquareUpdateRequest;
    }): Promise<void> {
        const response = await this.request({
            body: body,
            method: 'PATCH',
            path: `/events/${eventId}/bingo-squares/${id}`,
        });
        return response;
    }

    public async getSportRadarGames({
        season,
    }: {
        season: SportRadarNBASeason | 'ALL';
    }): Promise<Record<SportRadarNBASeason, SportRadarNBAGame[]>> {
        const response = await this.request({
            method: 'GET',
            path: `/sportRadarGames?season=${season}`,
        });
        return response;
    }

    public async getProgressivePolls(
        iterationId: string,
    ): Promise<ProgressivePoll[]> {
        const response = await this.request({
            method: 'GET',
            path: `/iterations/${iterationId}/progressive-polls`,
        });
        return response;
    }

    public async deleteProgressivePolls({
        ids,
        iterationId,
    }: {
        ids: string[];
        iterationId: string;
    }): Promise<void> {
        const idsParams = new URLSearchParams(
            ids.map((id) => ['ids', id]),
        ).toString();

        await this.request({
            method: 'DELETE',
            path: `/iterations/${iterationId}/progressive-polls?${idsParams}`,
        });
    }

    public async createProgressivePoll(postBody: ProgressivePollCreate) {
        const { iterationId } = postBody;
        await this.request({
            method: 'POST',
            path: `/iterations/${iterationId}/progressive-polls`,
            body: postBody,
        });
    }

    public async batchCreateProgressivePolls(
        postBody: BatchProgressivePollCreate,
    ) {
        const { iterationId } = postBody;
        await this.request({
            method: 'POST',
            path: `/iterations/${iterationId}/progressive-polls/batch`,
            body: postBody,
        });
    }

    public async getTeams({
        iterationId,
        partnerId,
    }: {
        partnerId: string;
        iterationId: string;
    }): Promise<Team[]> {
        const response = await this.request({
            method: 'GET',
            path: `/partners/${partnerId}/iterations/${iterationId}/teams`,
        });
        return response;
    }

    public async batchCreateTeams({
        iterationId,
        partnerId,
        teams,
    }: {
        teams: TeamCreate[];
        partnerId: string;
        iterationId: string;
    }): Promise<string[]> {
        const response = await this.request({
            body: { teams },
            method: 'POST',
            path: `/partners/${partnerId}/iterations/${iterationId}/teams/batch`,
        });

        return response;
    }

    public async getTriviaBuckets(partnerId: string): Promise<TriviaQuestion> {
        const response = await this.request({
            method: 'GET',
            path: `/partners/${partnerId}/trivia-buckets`,
        });

        return response;
    }

    public async batchCreateTriviaQuestions({
        bucketId,
        partnerId,
        questions,
    }: {
        questions: TriviaQuestionCreate[];
        bucketId: string;
        partnerId: string;
    }): Promise<TriviaQuestion> {
        const response = await this.request({
            body: { bucketId, questions },
            method: 'POST',
            path: `/partners/${partnerId}/trivia-questions/batch`,
        });

        return response;
    }
    public async getVenues(partnerId: string): Promise<TriviaQuestion[]> {
        const response = await this.request({
            method: 'GET',
            path: `/partners/${partnerId}/venues`,
        });
        return response;
    }

    public async batchCreateVenues({
        partnerId,
        venues,
    }: {
        venues: VenueCreate[];
        partnerId: string;
    }): Promise<string[]> {
        const response = await this.request({
            body: { venues },
            method: 'POST',
            path: `/partners/${partnerId}/venues/batch`,
        });

        return response;
    }

    public async batchCreatePredictions(bodyData: any): Promise<any> {
        const { eventId } = bodyData;
        const response = await this.request({
            body: bodyData,
            method: 'POST',
            path: `/events/${eventId}/predictions/batch`,
        });
        return response;
    }

    public async manualLockPredictions(
        eventId: string,
        bodyData: any,
    ): Promise<any> {
        const response = await this.request({
            body: bodyData,
            method: 'POST',
            path: `/events/${eventId}/predictions/lock`,
        });
        return response;
    }

    public async recrawlEvent(params: {
        accessToken: string;
        eventUrl: string;
    }): Promise<any> {
        const { accessToken, eventUrl } = params;
        const url = 'https://graph.facebook.com/v3.3/';
        const bodyData = {
            access_token: accessToken,
            id: `https://${eventUrl}`,
            scrape: true,
        };
        const response = await this.externalRequest({
            bodyData,
            method: 'POST',
            url,
        });
        return response;
    }

    public setProUsers({
        displayNames,
        partnerId,
    }: {
        partnerId: string;
        displayNames: string[];
    }): Promise<UserDto[]> {
        return this.request({
            body: {
                displayNames,
                partnerId,
            },
            method: 'POST',
            path: '/users/setPro',
        });
    }

    public getUsers({
        isPro,
        partnerId,
    }: {
        partnerId: string;
        isPro?: boolean;
    }): Promise<UserDto[]> {
        return this.request({
            method: 'GET',
            path: `/users?partner-id=${partnerId}${
                isPro !== undefined ? `&is-pro=${isPro}` : ''
            }`,
        });
    }

    public deleteUser(
        params: {
            partnerId: string;
        } & UserDelete,
    ): Promise<any> {
        const { ...body } = params;
        return this.request({
            body,
            method: 'POST',
            path: '/users/delete',
        });
    }

    public async getSponsors(partnerId: string): Promise<SponsorDto[]> {
        const response = await this.request({
            baseUrl: sponsorBaseUrl,
            method: 'GET',
            path: `/sponsors?partner-id=${partnerId}`,
        });
        return response as SponsorDto[];
    }

    public async createSponsor(
        partnerId: string,
        sponsor: Partial<SponsorDto>,
    ): Promise<SponsorDto> {
        const response = await this.request({
            baseUrl: sponsorBaseUrl,
            body: sponsor,
            method: 'POST',
            path: `/sponsors?partner-id=${partnerId}`,
        });
        return response as SponsorDto;
    }

    public async updateSponsor(
        sponsorId: string,
        sponsor: Partial<SponsorDto>,
    ): Promise<SponsorDto> {
        const response = await this.request({
            baseUrl: sponsorBaseUrl,
            body: sponsor,
            method: 'PATCH',
            path: `/sponsors/${sponsorId}`,
        });
        return response as SponsorDto;
    }

    public deleteSponsor(sponsorId: string): Promise<void> {
        return this.request({
            baseUrl: sponsorBaseUrl,
            method: 'DELETE',
            path: `/sponsors/${sponsorId}`,
        });
    }

    public async saveSponsorshipUnit(
        sponsorId: string,
        unit: Partial<SponsorshipUnitDto>,
    ): Promise<SponsorshipUnitDto> {
        const create = !unit.id;
        const response = await this.request({
            baseUrl: sponsorBaseUrl,
            body: unit,
            method: create ? 'POST' : 'PATCH',
            path: `/sponsors/${sponsorId}/units${create ? '' : `/${unit.id}`}`,
        });
        return response as SponsorshipUnitDto;
    }

    public deleteSponsorshipUnit(
        sponsorId: string,
        unitId: string,
    ): Promise<void> {
        return this.request({
            baseUrl: sponsorBaseUrl,
            method: 'DELETE',
            path: `/sponsors/${sponsorId}/units/${unitId}`,
        });
    }

    public async createOffer(
        sponsorId: string,
        offer: Partial<OfferDto>,
    ): Promise<OfferDto> {
        const response = await this.request({
            baseUrl: sponsorBaseUrl,
            body: offer,
            method: 'POST',
            path: `/sponsors/${sponsorId}/offers`,
        });
        return response as OfferDto;
    }

    public async updateOffer(
        sponsorId: string,
        offerId: string,
        offer: Partial<OfferDto>,
    ): Promise<OfferDto> {
        return this.request({
            baseUrl: sponsorBaseUrl,
            body: offer,
            method: 'PATCH',
            path: `/sponsors/${sponsorId}/offers/${offerId}`,
        });
    }

    public deleteOffer(sponsorId: string, offerId: string): Promise<OfferDto> {
        return this.request({
            baseUrl: sponsorBaseUrl,
            method: 'DELETE',
            path: `/sponsors/${sponsorId}/offers/${offerId}`,
        });
    }

    public createOfferCodes(
        sponsorId: string,
        offerId: string,
        codes: string[],
    ): Promise<void> {
        return this.request({
            baseUrl: sponsorBaseUrl,
            body: {
                codes,
            },
            method: 'POST',
            path: `/sponsors/${sponsorId}/offers/${offerId}/codes`,
        });
    }

    public async getOfferCodes(
        sponsorId: string,
        offerId: string,
    ): Promise<OfferCodeListDto> {
        const response = await this.request({
            baseUrl: sponsorBaseUrl,
            method: 'GET',
            path: `/sponsors/${sponsorId}/offers/${offerId}/codes`,
        });
        return response as OfferCodeListDto;
    }

    public async authenticate(code: string): Promise<User> {
        const response = await this.request({
            body: {
                code,
            },
            method: 'POST',
            path: '/auth',
        });

        return response;
    }

    public async refreshTokens(): Promise<User | null> {
        const response = await this.request({
            method: 'GET',
            path: '/refresh-token',
        });

        return response;
    }

    public async sync(): Promise<User & { expiryDate: number }> {
        const response = await this.request({
            method: 'GET',
            path: '/sync',
        });

        return response;
    }

    public async logout(): Promise<void> {
        const response = await this.request({
            method: 'GET',
            path: '/logout',
        });

        return response;
    }
}
