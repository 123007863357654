import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Avatar,
    Button,
    IconButton,
    Paper,
    Tab,
    Tabs,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt';
import * as H from 'history';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import BreadcrumbBar from '../components/BreadcrumbBar';
import DefaultSpeedDial, {
    SpeedDialActionInfo,
} from '../components/DefaultSpeedDial';
import FormBuilder from '../components/FormBuilder';
import CreateEditSponsorshipUnit, {
    ModeName,
} from '../components/sponsorship/CreateEditSponsorshipUnit';
import CreateSponsorshipUnitModal from '../components/sponsorship/CreateSponsorshipUnitModal';
import ApiContext from '../contexts/ApiContext';
import AppContext from '../contexts/AppContext';
import {
    OfferDto,
    PartnerDto,
    SponsorDto,
    SponsorshipUnitDto,
    SponsorshipUnitType,
} from '../service/Dto';
import { useModal } from '../utils/hooks';
import {
    groupUnitsByTypeForSponsor,
    SponsorshipUnitsByType,
} from '../utils/sponsors';

interface Props {
    history: H.History;
    partner: PartnerDto;
    sponsors: SponsorDto[];
}

const UNIT_SECTIONS: Array<{
    unitType: SponsorshipUnitType;
    title: string;
}> = [
    {
        title: 'Prediction Sponsorship Units',
        unitType: SponsorshipUnitType.PREDICTION,
    },
    {
        title: 'Event Sponsorship Units',
        unitType: SponsorshipUnitType.EVENT,
    },
];

const useStyles = makeStyles((theme: any) => ({
    content: {
        padding: theme.spacing(4),
    },
    deadState: {
        alignSelf: 'center' as 'center',
        display: 'flex' as 'flex',
        justifyContent: 'center',
        marginTop: '100px',
    },
    root: {
        height: '100%',
    },
    sponsorActions: {
        alignSelf: 'center' as 'center',
    },
    sponsorDetails: {
        display: 'flex' as 'flex',
        flexDirection: 'column' as 'column',
        marginLeft: theme.spacing(2),
        marginRight: 'auto',
    },
    sponsorshipUnitContainer: {
        alignItems: 'center' as 'center',
        display: 'flex' as 'flex',
        flexDirection: 'row' as 'row',
        justifyContent: 'flex-start' as 'flex-start',
        width: '100%',
    },
    sponsorshipUnitGroup: {
        display: 'flex' as 'flex',
        flexDirection: 'column' as 'column',
    },
    sponsorshipUnitName: {
        marginRight: theme.spacing(),
    },
    sponsorshipUnitTemplateId: {
        marginRight: theme.spacing(4),
    },
    sponsorSummary: {
        display: 'flex' as 'flex',
        flexDirection: 'row' as 'row',
        padding: theme.spacing(4, 3),
    },
    sponsorSummaryName: {
        marginRight: 'auto',
    },
    tabs: {
        // margin: '0 50px',
    },
    topArea: {
        backgroundColor: '#ffffff',
        display: 'flex' as 'flex',
        flexDirection: 'column' as 'column',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(2),
        width: '100%',
    },
    topBar: {
        alignItems: 'center' as 'center',
        display: 'flex' as 'flex',
        flexDirection: 'row' as 'row',
    },
}));

enum InputFieldType {
    boolean = 'boolean',
    datetimeLocal = 'datetime-local',
    json = 'json',
    heading = 'heading',
    hidden = 'hidden',
    select = 'select',
    text = 'text',
    timeLocal = 'time-local',
}

interface InputField<T> {
    defaultValue?: T;
    name: string;
    required?: boolean;
    type: InputFieldType;
    label?: string | ((formValues: object) => string);
}

const sponsorInputFields: Array<InputField<any>> = [
    {
        name: 'id',
        type: InputFieldType.hidden,
    },
    {
        label: 'Name',
        name: 'name',
        required: true,
        type: InputFieldType.text,
    },
    {
        label: 'Slug',
        name: 'slug',
        required: true,
        type: InputFieldType.text,
    },
    {
        label: 'Short name',
        name: 'shortName',
        required: true,
        type: InputFieldType.text,
    },
    {
        label: 'Logo URL',
        name: 'logoUrl',
        required: true,
        type: InputFieldType.text,
    },
    {
        label: 'Wide Logo URL',
        name: 'wideLogoUrl',
        required: true,
        type: InputFieldType.text,
    },
    {
        label: 'Primary color',
        name: 'primaryColor',
        type: InputFieldType.text,
    },
];

function renderEditDeleteActions(onEdit: () => void, onDelete: () => void) {
    return (
        <>
            <IconButton aria-label="Edit Button" key="edit" onClick={onEdit}>
                <EditIcon />
            </IconButton>
            <IconButton
                aria-label="Delete Button"
                key="delete"
                onClick={onDelete}
            >
                <DeleteIcon />
            </IconButton>
        </>
    );
}

function SponsorshipUnit(props: {
    unit: SponsorshipUnitDto;
    offers: OfferDto[];
    onDeleteClick: (unit: SponsorshipUnitDto) => void;
    onSaveClick: (
        unit: Partial<SponsorshipUnitDto>,
        unitId?: string,
    ) => void | Promise<void>;
}) {
    const { offers, onDeleteClick, onSaveClick, unit } = props;

    const classes = useStyles();

    return (
        <Accordion>
            <AccordionSummary>
                <div
                    className={classes.sponsorshipUnitContainer}
                    key={unit.name}
                >
                    <Typography
                        className={classes.sponsorshipUnitName}
                        variant="subtitle2"
                    >
                        {unit.name}
                    </Typography>
                    <Typography
                        className={classes.sponsorshipUnitTemplateId}
                        variant="caption"
                    >
                        (templateId: {unit.templateId})
                    </Typography>
                    <Typography
                        className={classes.sponsorshipUnitTemplateId}
                        variant="caption"
                    >
                        (id: {unit.id})
                    </Typography>
                </div>
            </AccordionSummary>
            <AccordionDetails>
                <CreateEditSponsorshipUnit
                    mode={{
                        name: ModeName.edit,
                        onDeleteClick,
                        savedUnit: unit,
                    }}
                    offers={offers}
                    onSaveClick={onSaveClick}
                />
            </AccordionDetails>
        </Accordion>
    );
}

function DeadState(props: { partner: PartnerDto; hasSponsors: boolean }) {
    const classes = useStyles();
    return (
        <Typography className={classes.deadState} variant="body2">
            {!props.hasSponsors &&
                `No sponsors for partner ${props.partner.name}`}
            {props.hasSponsors && 'Select a sponsor above'}
        </Typography>
    );
}

interface GroupedSponsorshipUnits {
    [sponsorId: string]: SponsorshipUnitsByType;
}

function useGroupedSponsorshipUnits(sponsors: SponsorDto[]) {
    const [groupedUnits, setGroupedUnits] = useState<GroupedSponsorshipUnits>(
        {},
    );
    useEffect(() => {
        const updatedGroupedUnits: GroupedSponsorshipUnits = {};
        sponsors.forEach((sponsor) => {
            const unitsByType = groupUnitsByTypeForSponsor(sponsor);
            if (unitsByType) {
                updatedGroupedUnits[sponsor.id] = unitsByType;
            }
        });
        setGroupedUnits(updatedGroupedUnits);
    }, [sponsors]);
    return groupedUnits;
}

export default function Sponsors(props: Props) {
    const { history, partner, sponsors } = props;

    // #region Hook declarations
    const appContext = useContext(AppContext);
    const apiContext = useContext(ApiContext);
    const classes = useStyles();
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [
        isCreateSponsorModalOpen,
        openCreateSponsorModal,
        closeCreateSponsorModal,
    ] = useModal();
    const [
        isEditSponsorModalOpen,
        openEditSponsorModal,
        closeEditSponsorModal,
        editSponsorModalData,
    ] = useModal<SponsorDto>();
    const [
        isCreateUnitModalOpen,
        openCreateUnitModal,
        closeCreateUnitModal,
        createUnitModalType,
    ] = useModal<SponsorshipUnitType>();
    const groupedUnits = useGroupedSponsorshipUnits(sponsors);
    // #endregion

    const selectedSponsor = sponsors[selectedIndex];

    const createSponsor = useCallback(
        async (formValues: unknown) => {
            const sponsor = formValues as Partial<SponsorDto>;
            await apiContext.createSponsor(partner.id, sponsor);
        },
        [partner, apiContext],
    );

    const updateSponsor = useCallback(
        async (formValues: unknown) => {
            const sponsor = formValues as Partial<SponsorDto>;
            const sponsorId = sponsor.id!;
            await apiContext.updateSponsor(partner.id, sponsorId, sponsor);
        },
        [partner, apiContext],
    );

    const saveSponsorshipUnit = useCallback(
        async (sponsorId: string, unitToSave: Partial<SponsorshipUnitDto>) => {
            try {
                await apiContext.saveSponsorshipUnit(
                    partner.id,
                    sponsorId,
                    unitToSave,
                );
                appContext.notifySuccess('Successfully saved sponsorship unit');
            } catch (error) {
                appContext.notifyError((error as Error).message);
            }
        },
        [partner, appContext, apiContext],
    );

    const onCreateUnitSaveClick = useCallback(
        async (unit: Partial<SponsorshipUnitDto>) => {
            try {
                await apiContext.saveSponsorshipUnit(
                    partner.id,
                    selectedSponsor.id,
                    unit,
                );
                appContext.notifySuccess('Successfully saved sponsorship unit');
                closeCreateUnitModal();
            } catch (error) {
                appContext.notifyError((error as Error).message);
            }
        },
        [
            closeCreateUnitModal,
            saveSponsorshipUnit,
            partner,
            selectedSponsor,
            apiContext,
            appContext,
        ],
    );

    function onTabChange(_: any, newValue: any) {
        setSelectedIndex(newValue);
    }

    // #region Sponsor modal
    function renderSponsorModal() {
        let label: string;
        let onClose: () => void;
        let onSubmit: any;
        let startingValue: SponsorDto | undefined;
        if (isCreateSponsorModalOpen) {
            label = 'Create Sponsor';
            onClose = closeCreateSponsorModal;
            onSubmit = createSponsor;
        } else if (isEditSponsorModalOpen) {
            label = 'Edit Sponsor';
            onClose = closeEditSponsorModal;
            onSubmit = updateSponsor;
            startingValue = editSponsorModalData;
        } else {
            throw new Error('unexpectedly called');
        }
        return (
            <FormBuilder
                fields={sponsorInputFields}
                label={label}
                notifyError={appContext.notifyError}
                notifySuccess={appContext.notifySuccess}
                onClose={onClose}
                onSubmit={onSubmit}
                startingValue={startingValue || {}}
                supportedLanguages={partner.properties.supportedLanguages || []}
            />
        );
    }
    // #endregion

    // #region Sponsor
    function renderSponsor(sponsor: SponsorDto) {
        function editSponsor() {
            openEditSponsorModal(sponsor);
        }

        async function deleteSponsor() {
            confirmAlert({
                buttons: [
                    {
                        label: 'Delete',
                        onClick: () => {
                            apiContext.deleteSponsor(partner.id, sponsor.id);
                        },
                    },
                    {
                        label: 'Cancel',
                        onClick: () => {},
                    },
                ],
                message: `Sponsor: ${sponsor.name}`,
                title: 'Delete sponsor?',
            });
        }

        function onDeleteUnitClick(unit: SponsorshipUnitDto) {
            confirmAlert({
                buttons: [
                    {
                        label: 'Delete',
                        onClick: async () => {
                            try {
                                await apiContext.deleteSponsorshipUnit(
                                    partner.id,
                                    sponsor.id,
                                    unit.id,
                                );
                                appContext.notifySuccess(
                                    'Successfully deleted sponsorship unit',
                                );
                            } catch (error) {
                                appContext.notifyError(
                                    (error as Error).message,
                                );
                            }
                        },
                    },
                    {
                        label: 'Cancel',
                        onClick: () => {},
                    },
                ],
                message: `Sponsor: ${sponsor.name}\nUnit: ${unit.name}`,
                title: 'Delete sponsorship unit?',
            });
        }

        function onSaveUnitForSponsorClick(
            unitToSave: Partial<SponsorshipUnitDto>,
        ) {
            return saveSponsorshipUnit(sponsor.id, unitToSave);
        }

        function onOffersClick() {
            history.push(`/offers?partner=${partner.id}&sponsor=${sponsor.id}`);
        }

        return (
            <div className={classes.content}>
                <Paper className={classes.sponsorSummary}>
                    <Avatar alt={sponsor.name} src={sponsor.logoUrl} />
                    <div className={classes.sponsorDetails}>
                        <Typography variant="h6">{sponsor.name}</Typography>
                        <Typography variant="caption">
                            id: {sponsor.id}
                        </Typography>
                        <Typography variant="caption">
                            slug: {sponsor.slug}
                        </Typography>
                        <Typography variant="caption">
                            short name: {sponsor.shortName}
                        </Typography>
                    </div>
                    <div className={classes.sponsorActions}>
                        {renderEditDeleteActions(editSponsor, deleteSponsor)}
                    </div>
                </Paper>
                {UNIT_SECTIONS.map((section) => {
                    const units =
                        groupedUnits[sponsor.id] &&
                        groupedUnits[sponsor.id][section.unitType];
                    if (!units) {
                        return null;
                    }
                    return (
                        <Accordion
                            defaultExpanded={true}
                            key={section.unitType}
                        >
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="body1">
                                    {section.title}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails
                                className={classes.sponsorshipUnitGroup}
                            >
                                {units.map((unit: SponsorshipUnitDto) => (
                                    <SponsorshipUnit
                                        key={unit.id}
                                        offers={sponsor.offers}
                                        onDeleteClick={onDeleteUnitClick}
                                        onSaveClick={onSaveUnitForSponsorClick}
                                        unit={unit}
                                    />
                                ))}
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
                <Button onClick={onOffersClick} variant="contained">
                    See sponsor offers
                </Button>
            </div>
        );
    }
    // #endregion

    const speedDialActions: SpeedDialActionInfo[] = [];
    if (sponsors.length) {
        speedDialActions.push({
            icon: <OfflineBoltIcon />,
            onClick: () => {
                openCreateUnitModal(SponsorshipUnitType.PREDICTION);
            },
            tooltipTitle: 'New prediction unit',
        });

        speedDialActions.push({
            icon: <CalendarTodayIcon />,
            onClick: () => {
                openCreateUnitModal(SponsorshipUnitType.EVENT);
            },
            tooltipTitle: 'New event unit',
        });
    }

    speedDialActions.push({
        icon: <AttachMoneyIcon />,
        onClick: openCreateSponsorModal,
        tooltipTitle: 'New sponsor',
    });

    const breadcrumbs = [
        {
            link: '/partners',
            text: `${partner.name}`,
        },
        { text: 'sponsors' },
    ];

    return (
        <div className={classes.root}>
            <div className={classes.topArea}>
                <BreadcrumbBar breadcrumbs={breadcrumbs} />
                <div className={classes.topBar}>
                    <Tabs
                        className={classes.tabs}
                        onChange={onTabChange}
                        scrollButtons="auto"
                        value={selectedIndex}
                        variant="scrollable"
                    >
                        {sponsors.map((sponsor: SponsorDto) => (
                            <Tab
                                icon={
                                    <img
                                        alt="Sponsor logo"
                                        height="20px"
                                        src={sponsor.logoUrl}
                                    />
                                }
                                key={sponsor.id}
                                label={sponsor.name}
                            />
                        ))}
                    </Tabs>
                </div>
            </div>
            {selectedSponsor && renderSponsor(selectedSponsor)}
            {!selectedSponsor && (
                <DeadState
                    hasSponsors={sponsors.length > 0}
                    partner={partner}
                />
            )}
            <DefaultSpeedDial actions={speedDialActions} />
            {(isCreateSponsorModalOpen || isEditSponsorModalOpen) &&
                renderSponsorModal()}
            {isCreateUnitModalOpen && (
                <CreateSponsorshipUnitModal
                    offers={selectedSponsor.offers}
                    onRequestClose={closeCreateUnitModal}
                    onSaveClick={onCreateUnitSaveClick}
                    unitType={createUnitModalType!}
                />
            )}
        </div>
    );
}
