import { TableCell } from '@material-ui/core';

const oldRender = TableCell.render;

TableCell.render = function (...args) {
    const [props, ...otherArgs] = args;
    if (typeof props === 'object' && props && 'isEmpty' in props) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
        const { isEmpty, ...propsWithoutEmpty } = props;
        return oldRender.apply(this, [propsWithoutEmpty, ...otherArgs]);
    } else {
        return oldRender.apply(this, args);
    }
};
