import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import {
    createTheme,
    MuiThemeProvider,
    withStyles,
} from '@material-ui/core/styles';
import { KeyboardArrowUp } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import MuiDataTable from 'mui-datatables';
import React, { useState } from 'react';
import ActionButton from '../../components/ActionButton';
import AddProgressivePollDialog from '../../components/AddProgressivePollDialog';
import BreadcrumbBar from '../../components/BreadcrumbBar';
import { PageHeadline, transformToArray } from '../../components/CrudPage';
import PPollSheetImportExportDialog from '../../components/PPollSheetImportExportDialog';
import {
    BatchProgressivePollCreate,
    CategoryDto,
    IterationDto,
    PartnerDto,
    ProgressivePoll,
    ProgressivePollCreate,
} from '../../service/Dto';
import GoogleSheetsApi, {
    GoogleSheetsApiError,
} from '../../service/GoogleSheetsApi';
import progressivePollTableTransformer from '../../utils/tables/progressivePollTableTransformer';

const styles = (theme: any) => ({
    paper: {
        padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
    },
});

const tableColumns: {
    label: string;
    name: string;
    options?: any;
    generateFunc?: (prop: any) => string;
}[] = [
    {
        label: 'Progressive poll ID',
        name: 'id',
        options: {
            display: false,
            filter: false,
        },
    },
    {
        label: 'Sequence number',
        name: 'number',
        options: {
            display: true,
            filter: false,
            hint: 'Lower progressive poll  number will be shown earlier, than bigger',
            sort: true,
        },
    },
    {
        label: 'Question',
        name: 'text',
        options: {
            filter: false,
        },
    },

    {
        label: 'Sub-text',
        name: 'detailsText',
        options: {
            display: true,
            filter: false,
        },
    },
    {
        generateFunc: (prediction) => {
            let displayString = '';
            prediction.options.forEach((option: any) => {
                displayString += `<${option.text}> `;
            });
            return displayString;
        },
        label: 'Choices',

        name: 'options',
        options: {
            display: true,
            filter: true,
        },
    },

    {
        label: 'Notes',
        name: 'notes',
        options: {
            display: true,
            filter: false,
        },
    },
];

const getMuiTheme = () => {
    return createTheme({
        overrides: {
            MuiSvgIcon: {
                fontSizeSmall: {
                    fontSize: '13px',
                    verticalAlign: 'middle',
                },
            },
        },
    });
};

type Props = {
    partner: PartnerDto;
    iteration: IterationDto;
    category: CategoryDto;
    progressivePolls: ProgressivePoll[];
    onSubmit: (data: ProgressivePollCreate) => Promise<void>;
    onBatchSubmit: (data: BatchProgressivePollCreate) => Promise<void>;
    onRowsDelete: ({
        ids,
        iterationId,
    }: {
        iterationId: string;
        ids: string[];
    }) => Promise<void>;
    notifyError: (message: string) => void;
    notifySuccess: (message: string) => void;
    refreshData: () => Promise<void>;
    classes: any;
};

const ProgressivePolls = ({
    category,
    classes,
    iteration,
    notifyError,
    notifySuccess,
    onBatchSubmit,
    onRowsDelete,
    onSubmit,
    partner,
    progressivePolls,
    refreshData,
}: Props) => {
    const [showAddModal, setShowAddModal] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showImportExportModal, setShowImportExportModal] = useState(false);
    const [isImportingExporting, setIsImportingExporting] = useState(false);

    const iterationId = iteration.id;

    const handleSubmit = async (data: ProgressivePollCreate) => {
        setIsSubmitting(true);
        try {
            await onSubmit(data);
        } catch (error) {
            if (error instanceof Error) {
                notifyError(
                    `Error creating progressive poll: ${error.message}`,
                );
            } else {
                console.log(error);
                notifyError('Error creating progressive poll');
            }
            setIsSubmitting(false);
            return;
        }
        notifySuccess('Successfully created progressive poll');
        setIsSubmitting(false);
        setShowAddModal(false);
    };

    const handleDelete = async ({
        lookup,
    }: {
        lookup: { [key: number]: boolean };
    }) => {
        const rows = transformToArray(progressivePolls, tableColumns, true);
        const rowsToDelete = Object.keys(lookup).map(
            (index) => rows[parseInt(index, 10)],
        );

        const ids = rowsToDelete.map(
            (row) =>
                row[tableColumns.findIndex((column) => column.name === 'id')],
        );
        try {
            await onRowsDelete({ ids, iterationId: iterationId });
            notifySuccess('Successfully deleted progressive poll(s)');
        } catch (error) {
            if (error instanceof Error) {
                notifyError(error.message);
            } else {
                notifyError(`Unknown error: ${JSON.stringify(error)}`);
            }
        }
    };

    const handleImportProgressivePolls = async (googleSheetId: string) => {
        setIsImportingExporting(true);

        const gapi = new GoogleSheetsApi();
        await gapi.initialize();

        let sheet;
        try {
            sheet = await gapi.readSheet(googleSheetId);
        } catch (error) {
            if (error instanceof GoogleSheetsApiError) {
                notifyError(
                    `Error importing progressive polls: ${error.status}`,
                );
            } else if (error instanceof Error) {
                const message = `Error importing progressive polls: ${error.message}`;
                notifyError(message);
            } else {
                notifyError(`Unknown google sheet error ${error}`);
            }

            setIsImportingExporting(false);
            return;
        }

        const importedPolls = progressivePollTableTransformer.fromTable(sheet);

        try {
            await onBatchSubmit({
                iterationId: iterationId,
                progressivePolls: importedPolls,
            });
            notifySuccess('Succesfully imported progressive polls');
        } catch (error) {
            if (error instanceof GoogleSheetsApiError) {
                notifyError(
                    `Error importing progressive polls: ${error.status}`,
                );
            } else if (error instanceof Error) {
                const message = `Error importing progressive polls: ${error.message}`;
                notifyError(message);
            } else {
                notifyError(`Unknown api error ${error}`);
            }

            setIsImportingExporting(false);
            return;
        }
        await refreshData();
        setIsImportingExporting(false);
        setShowImportExportModal(false);
    };

    const handleExportProgressivePolls = async (googleSheetId: string) => {
        if (progressivePolls.length === 0) {
            notifyError('There are no progressivePolls to export!');
            return;
        }

        setIsImportingExporting(true);

        const gapi = new GoogleSheetsApi();
        await gapi.initialize();

        const table = progressivePollTableTransformer.toTable(progressivePolls);

        try {
            await gapi.writeToSheet(googleSheetId, table);
            notifySuccess('Succesfully exported progressive polls');
        } catch (error) {
            if (error instanceof GoogleSheetsApiError) {
                notifyError(
                    `Error exporting progressive polls: ${error.status}`,
                );
            } else {
                notifyError(`Error exporting questions: ${error}`);
            }
        }

        setIsImportingExporting(false);
        setShowImportExportModal(false);
    };

    const breadCrumbs = [
        { link: '/partners', text: 'Partners' },
        {
            link: `/categories?partner=${category.partnerId}`,
            text: `${category.partnerId}'s Categories`,
        },
        {
            link: `/iterations?category=${category.id}`,
            text: `${category.name}'s Iterations`,
        },
        { link: '', text: `${iteration.name}'s progressive polls` },
    ];

    const options = {
        customToolbarSelect: (selectedRows: {
            lookup: { [key: number]: boolean };
        }) => (
            <Button
                onClick={() => handleDelete(selectedRows)}
                size="small"
                variant="contained"
            >
                <DeleteIcon />
            </Button>
        ),
        filterType: 'dropdown',
        resizableColumns: true,
        responsive: 'simple',
        rowsPerPage: 50,
        rowsPerPageOptions: [10, 20, 30, 40, 50, 75, 100],
    };

    return (
        <div>
            <div>
                <Paper className={classes.paper}>
                    <BreadcrumbBar breadcrumbs={breadCrumbs} />
                    <PageHeadline
                        headline={`${iteration.name}: progressive polls`}
                    />
                </Paper>
            </div>
            <MuiThemeProvider theme={getMuiTheme()}>
                <MuiDataTable
                    columns={tableColumns}
                    data={
                        progressivePolls &&
                        progressivePolls.map((dataElement: any) => {
                            const resultArray: string[] = [];
                            tableColumns.forEach((el) => {
                                if (el.generateFunc) {
                                    resultArray.push(
                                        el.generateFunc(dataElement),
                                    );
                                } else {
                                    resultArray.push(
                                        dataElement[el.name]?.toString(),
                                    );
                                }
                            });
                            return resultArray;
                        })
                    }
                    options={options}
                />
            </MuiThemeProvider>
            <ActionButton
                actions={[
                    {
                        icon: <AddIcon />,
                        name: 'Add progressive Poll Question',
                        onClick: () => setShowAddModal(true),
                    },
                    {
                        icon: <KeyboardArrowUp />,
                        name: 'Import / Export progressive Poll',
                        onClick: () => {
                            setShowImportExportModal(true);
                        },
                    },
                ]}
            />
            {showAddModal && (
                <AddProgressivePollDialog
                    isSubmitting={isSubmitting}
                    iterationId={iterationId}
                    onClose={() => setShowAddModal(false)}
                    onSubmit={handleSubmit}
                    supportedLanguages={partner.properties.supportedLanguages}
                />
            )}
            {showImportExportModal && (
                <PPollSheetImportExportDialog
                    isImportingExporting={isImportingExporting}
                    onClose={() => setShowImportExportModal(false)}
                    onExport={handleExportProgressivePolls}
                    onImport={handleImportProgressivePolls}
                />
            )}
        </div>
    );
};

export default withStyles(styles)(ProgressivePolls);
