import '../../styles/App.css';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormHelperText from '@material-ui/core/FormHelperText';
import LinearProgress from '@material-ui/core/LinearProgress';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { StyleRules, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React, { useState } from 'react';
import Kindred from './Kindred';
import SportRadar, {
    NBAPredictionTemplates,
    NCAAFBPredictionTemplates,
    NFLPredictionTemplates,
    NHLPredictionTemplates,
    SportRadarSportTypes,
} from './SportRadar';

interface Props {
    handleClose: () => void;
    handleFeedDataPull: (
        props: SportRadarDataPullProps | KindredDataPullProps,
    ) => Promise<void>;
    eventId: string;
    classes: any;
}

export interface DataPullProps {
    eventId: string;
    id: string;
}

export interface SportRadarDataPullProps extends DataPullProps {
    template:
        | NCAAFBPredictionTemplates
        | NHLPredictionTemplates
        | NBAPredictionTemplates
        | NFLPredictionTemplates;
    feedSport: SportRadarSportTypes;
    feedProvider: FeedProviders.sportRadar;
}

export interface KindredDataPullProps extends DataPullProps {
    groupId: string;
    feedProvider: FeedProviders.kindred;
}

export type SportFeedData = {
    feedProvider: FeedProviders;
    id: string;
    groupId: string;
    template?:
        | NCAAFBPredictionTemplates
        | NHLPredictionTemplates
        | NBAPredictionTemplates
        | NFLPredictionTemplates;
    feedSport: SportRadarSportTypes;
};

export enum FeedProviders {
    sportRadar = 'sportradar',
    kindred = 'kindred',
}

function FeedPrediction(props: Props) {
    const [feedData, setFeedData] = useState<SportFeedData>({
        feedProvider: FeedProviders.sportRadar,
        feedSport: SportRadarSportTypes.NHL,
        groupId: '',
        id: '',
    });
    const [pullIsinProgress, setPullIsinProgress] = useState<boolean>(false);

    const feedProviderOptions = [
        {
            label: 'Sport Radar',
            value: FeedProviders.sportRadar,
        },
        {
            label: 'Kindred',
            value: FeedProviders.kindred,
        },
    ];

    const title = 'Prediction from a feed';

    let feedContent;
    switch (feedData.feedProvider) {
        case FeedProviders.sportRadar:
            feedContent = (
                <SportRadar feedData={feedData} handleChange={setFeedData} />
            );
            break;
        case FeedProviders.kindred:
            feedContent = (
                <Kindred feedData={feedData} handleChange={setFeedData} />
            );
            break;
        default:
            break;
    }

    const submitDisabled = (): boolean => {
        switch (feedData.feedProvider) {
            case FeedProviders.sportRadar:
                return (
                    !feedData.id || !feedData.template || !feedData.feedSport
                );
            case FeedProviders.kindred:
                return !feedData.id || !feedData.groupId;
            default:
                return true;
        }
    };

    return (
        <Dialog open={true}>
            <DialogTitle id="form-dialog-title">{title}</DialogTitle>
            <CloseIcon
                className={props.classes.closeImportExport}
                onClick={props.handleClose}
            />
            <DialogContent>
                <FormHelperText>Feed provider</FormHelperText>
                <Select
                    fullWidth={true}
                    onChange={(event: any) =>
                        setFeedData({
                            ...feedData,
                            feedProvider: event.target.value,
                        })
                    }
                    value={feedData.feedProvider}
                >
                    {feedProviderOptions.map((provider) => (
                        <MenuItem key={provider.value} value={provider.value}>
                            {provider.label}
                        </MenuItem>
                    ))}
                    ))
                </Select>
                {feedContent}
                <DialogActions style={{ marginTop: '25px' }}>
                    <Button
                        color="primary"
                        disabled={submitDisabled()}
                        onClick={async () => {
                            setPullIsinProgress(true);
                            const {
                                feedProvider,
                                feedSport,
                                groupId,
                                id,
                                template,
                            } = feedData;
                            await props.handleFeedDataPull({
                                eventId: props.eventId,
                                feedProvider: feedProvider,
                                feedSport: feedSport,
                                groupId: groupId,
                                id: id,
                                template: template!,
                            });
                        }}
                        variant="contained"
                    >
                        Pull feed data
                    </Button>
                </DialogActions>
                {pullIsinProgress && <LinearProgress />}
            </DialogContent>
        </Dialog>
    );
}

const styles = () =>
    ({
        closeImportExport: {
            cursor: 'pointer',
            position: 'absolute',
            right: '10px',
            top: '10px',
        },
    }) as StyleRules<string>;

export default withStyles(styles)(FeedPrediction);
