import { Link } from '@material-ui/core';
import React, { Component } from 'react';
import { UserDelete, UserDto } from 'src/service/Dto';
import CrudPage from '../components/CrudPage';
import { PAGE_LABEL } from '../service/Constants';
import { slugify } from '../utils/util';

const DEFAULT_SMS_ALERT_TITLE = 'Get SMS alerts for new picks';
const DEFAULT_SMS_ALERT_SUBTEXT = 'Up to 3 messages per game';

const MARKETING_SUPPORT = 'marketingSupport';
// Marketing Select Types
const EMAIL_AND_SMS = 'emailAndSMSMarketingEnabled';
const EMAIL_ONLY = 'emailMarketingEnabled';
const NONE = 'noMarketing';
// Marketing prop names
const SMS_MARKETING_ENABLED = 'smsMarketingEnabled';
const MARKETING_OPT_IN_TITLE = 'marketingOptInTitle';
const EMAIL_MARKETING_LABEL = 'emailMarketingLabel';
const SMS_MARKETING_LABEL = 'smsMarketingLabel';
// Alert Prop Names
const SMS_ALERT_CHECKBOX_TITLE = 'smsAlertCheckboxTitle';
const SMS_ALERT_CHECKBOX_SUBTITLE = 'smsAlertCheckboxSubtitle';
// const SMS_PROVIDER_PROPERTIES = 'SMSProviderProperties';
const EMAIL_OPT_IN_IS_MANDATORY = 'emailOptInIsMandatory';
const SMS_OPT_IN_IS_MANDATORY = 'smsOptInIsMandatory';
const OPTIONAL_OPT_IN_IS_MANDATORY = 'optionalOptInIsMandatory';

//Re-opt-in properties
const OPT_IN_VALID_SINCE = 'optInValidSince';
const EMAIL_RE_OPT_IN_IS_MANDATORY = 'emailReOptInIsMandatory';
const SMS_RE_OPT_IN_IS_MANDATORY = 'smsReOptInIsMandatory';
const OPTIONAL_RE_OPT_IN_IS_MANDATORY = 'optionalReOptInIsMandatory';

// Other Props
const THEME_PROPERTIES = 'themeProperties';
const URL_PROPERTIES = 'urlProperties';

interface Props {
    createRecord: (postBody: any) => void;
    deleteRecord: (params: any) => void;
    setProUsers: (params: {
        partnerId: string;
        displayNames: string[];
    }) => Promise<UserDto[]>;
    getUsers: (params: {
        partnerId: string;
        isPro?: boolean;
    }) => Promise<UserDto[]>;

    deleteUser: (params: UserDelete & { partnerId: string }) => Promise<void>;
    notifyError: (message: string) => void;
    notifySuccess: (message: string) => void;
    partners: any[];
    refreshData: (callback: any) => void;
    updateRecord: (partnerId: string, body: any) => void;
}

class Partners extends Component<Props> {
    private buildCategoryLink = (partnerId: string) =>
        `/categories?partner=${partnerId}`;

    private updateReportingName = (params: {
        newValue: string;
        formValues: any;
    }) => {
        const { formValues, newValue } = params;
        // only update automatically if this is a new category
        if (!formValues.id) {
            formValues.reportingName = newValue;
        }
    };

    private validateJson = ({
        fieldErrors,
        name,
        newValue,
    }: {
        name: string;
        newValue: string;
        previousValue: string;
        formValues: { [key: string]: string | boolean | object };
        fieldErrors: { [key: string]: string };
    }) => {
        try {
            JSON.parse(newValue);
        } catch (error) {
            fieldErrors[name] = 'Invalid JSON syntax';
        }
    };

    private validateHtmlSnippet = ({
        fieldErrors,
        name,
        newValue,
    }: {
        name: string;
        newValue: string;
        previousValue: string;
        formValues: { [key: string]: string | boolean | object };
        fieldErrors: { [key: string]: string };
    }) => {
        if (newValue) {
            const regexForHTML = /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/;
            const isValid = regexForHTML.test(newValue);

            !isValid && (fieldErrors[name] = 'Invalid HTML syntax');
        }
    };

    private inputFields() {
        return [
            {
                helperTextFunc: (name: string, value: any) => {
                    if (value && value !== slugify(value)) {
                        return `current Id not valid, valid Id example:${slugify(
                            value,
                        )}`;
                    }
                },
                label: 'Partner ID',
                name: 'partnerId',
                required: true,
                shouldDisplay: (obj: any) => typeof obj.id === 'undefined',
                type: 'text',
            },
            {
                label: 'Name',
                name: 'name',
                onChange: this.updateReportingName,
                props: {
                    autoFocus: true,
                },
                required: true,
                type: 'text',
            },
            {
                label: 'Short Name',
                name: 'shortName',
                required: true,
            },
            {
                label: 'Host Name',
                name: 'hostName',
                required: false,
            },
            {
                defaultValue: '{ANONYMOUS}',
                label: 'Authentication Method',
                name: 'authenticationMethod',
                options: [
                    {
                        label: 'Anonymous',
                        value: '{ANONYMOUS}',
                    },
                    {
                        label: 'Phone',
                        value: '{PHONE}',
                    },
                    {
                        label: 'Okta via user ID',
                        value: '{OKTA_UID}',
                    },
                ],
                props: {
                    formwidthpercent: 'third',
                },
                required: true,
                shouldDisplay: () => {
                    return true;
                },
                type: 'select',
            },
            {
                defaultValue: '{PLIVO}',
                label: 'SMS provider',
                name: 'SMSProvider',
                options: [
                    {
                        label: 'Plivo',
                        value: '{PLIVO}',
                    },
                    {
                        label: 'Vonage',
                        value: '{VONAGE}',
                    },
                    {
                        label: 'Twilio',
                        value: '{TWILIO}',
                    },
                    {
                        label: 'Bandwidth',
                        value: '{BANDWIDTH}',
                    },
                ],
                required: true,
                shouldDisplay: () => {
                    return true;
                },
                type: 'select',
            },
            //             {
            //                 defaultValue: `{
            // "plivo": {
            //     "authId": "",
            //     "authToken": "",
            // },
            // "vonage": {
            //     "apiKey": "",
            //     "apiSecret": "",
            // },
            //                 }`,
            //                 label: 'SMS Provider Properties',
            //                 name: SMS_PROVIDER_PROPERTIES,
            //                 required: false,
            //                 type: 'json',
            //             },
            {
                label: 'Primary SMS number',
                name: 'primarySmsPhoneNumber',
                required: false,
                type: 'text',
            },
            {
                label: 'Secondary SMS number',
                name: 'secondarySmsPhoneNumber',
                required: false,
                type: 'text',
            },
            {
                label: 'Facebook App Id',
                name: 'facebookAppId',
                required: false,
                type: 'text',
            },
            {
                name: 'Marketing Properties',
                type: 'heading',
            },
            {
                defaultValue: (obj: any) => obj.marketingSupport,
                label: 'Marketing Support',
                name: MARKETING_SUPPORT,
                onChange: ({
                    formValues,
                    newValue,
                }: {
                    newValue: string;
                    formValues: {
                        [key: string]: string | boolean | object;
                    };
                }): void => {
                    switch (newValue) {
                        case NONE:
                            formValues.collectEmail = false;
                            formValues.collectPhone = false;
                            break;
                        case EMAIL_ONLY:
                            formValues.collectEmail = true;
                            formValues.collectPhone = false;
                            break;
                        case EMAIL_AND_SMS:
                            formValues.collectEmail = true;
                            formValues.collectPhone = true;
                            break;

                        default:
                            break;
                    }
                },
                options: [
                    {
                        label: 'Email Only',
                        value: EMAIL_ONLY,
                    },
                    {
                        label: 'Email & SMS',
                        value: EMAIL_AND_SMS,
                    },
                    {
                        label: 'None',
                        value: NONE,
                    },
                ],
                required: true,
                type: 'select',
            },
            {
                helperTextFunc: () =>
                    'Displays above email and SMS checkboxes when Email & SMS marketing enabled. Use {"EN":"en text", "PT":"pt text", ...} notation',
                label: 'Marketing Opt In Title. { "EN": "en text" }',
                name: MARKETING_OPT_IN_TITLE,
                onChange: this.validateJson,
                required: false,
                shouldDisplay: (obj: any) => obj.marketingSupport !== NONE,
                type: 'json',
            },
            {
                helperTextFunc: () =>
                    'Displays to the right of email opt-in checkbox. Use {"EN":"en text", "PT":"pt text", ...} notation',
                label: 'Email Marketing Label. { "EN": "en text" }',
                name: EMAIL_MARKETING_LABEL,
                onChange: this.validateJson,
                required: false,
                shouldDisplay: (obj: any) =>
                    obj.marketingSupport === EMAIL_AND_SMS ||
                    obj.marketingSupport === EMAIL_ONLY,
                type: 'json',
            },
            {
                helperTextFunc: () =>
                    'Displays to the right of SMS opt-in checkbox. Use {"EN":"en text", "PT":"pt text", ...} notation',
                label: 'Phone Marketing Label. { "EN": "en text" }',
                name: SMS_MARKETING_LABEL,
                onChange: this.validateJson,
                required: false,
                shouldDisplay: (obj: any) =>
                    obj.marketingSupport === EMAIL_AND_SMS,
                type: 'json',
            },
            {
                defaultValue: false,
                helperTextFunc: () =>
                    'Users will be unable to proceed with re-opt-in if email opt-in is unchecked',
                label: 'Onboarding email opt-in is mandatory',
                name: EMAIL_OPT_IN_IS_MANDATORY,
                props: {
                    color: 'primary',
                },
                required: true,
                shouldDisplay: (obj: any) =>
                    obj.marketingSupport === EMAIL_AND_SMS ||
                    obj.marketingSupport === EMAIL_ONLY,
                type: 'boolean',
            },

            {
                defaultValue: false,
                helperTextFunc: () =>
                    'Users will be unable to proceed with re-opt-in if SMS opt-in is unchecked',
                label: 'Onboarding SMS opt-in is mandatory',
                name: SMS_OPT_IN_IS_MANDATORY,
                props: {
                    color: 'primary',
                },
                required: true,
                shouldDisplay: (obj: any) =>
                    obj.marketingSupport === EMAIL_AND_SMS,
                type: 'boolean',
            },
            {
                defaultValue: false,
                helperTextFunc: () =>
                    'Users will be unable to proceed with re-opt-in if SMS opt-in is unchecked',
                label: 'Onboarding additional opt-in is mandatory',
                name: OPTIONAL_OPT_IN_IS_MANDATORY,
                props: {
                    color: 'primary',
                },
                required: true,
                type: 'boolean',
            },
            {
                name: 'Re-Opt-In',
                shouldDisplay: (obj: any) => obj.marketingSupport !== NONE,
                type: 'heading',
            },
            {
                defaultValue: null,
                helperTextFunc: () =>
                    'Users, who done their opt-in before date given will be forced to see an new opt in screen on the next game visit',
                label: 'Force re-opt-in users joined before:',
                name: OPT_IN_VALID_SINCE,
                required: false,
                shouldDisplay: (obj: any) => obj.marketingSupport !== NONE,
                type: 'datetime-local',
            },
            {
                defaultValue: false,
                helperTextFunc: () =>
                    'Users will be unable to proceed with re-opt-in if email opt-in is unchecked',
                label: 'Email opt-in is mandatory',
                name: EMAIL_RE_OPT_IN_IS_MANDATORY,
                props: {
                    color: 'primary',
                },
                required: true,
                shouldDisplay: (obj: any) =>
                    obj.optInValidSince &&
                    (obj.marketingSupport === EMAIL_AND_SMS ||
                        obj.marketingSupport === EMAIL_ONLY),
                type: 'boolean',
            },

            {
                defaultValue: false,
                helperTextFunc: () =>
                    'Users will be unable to proceed with re-opt-in if SMS opt-in is unchecked',
                label: 'SMS opt-in is mandatory',
                name: SMS_RE_OPT_IN_IS_MANDATORY,
                props: {
                    color: 'primary',
                },
                required: true,
                shouldDisplay: (obj: any) =>
                    obj.optInValidSince &&
                    obj.marketingSupport === EMAIL_AND_SMS,
                type: 'boolean',
            },
            {
                defaultValue: false,
                helperTextFunc: () =>
                    'Users will be unable to proceed with re-opt-in if SMS opt-in is unchecked',
                label: 'Additional opt-in is mandatory',
                name: OPTIONAL_RE_OPT_IN_IS_MANDATORY,
                props: {
                    color: 'primary',
                },
                required: true,
                shouldDisplay: (obj: any) => obj.optInValidSince,
                type: 'boolean',
            },
            {
                name: 'SMS Alert Properties',
                type: 'heading',
            },
            {
                defaultValue: false,
                label: 'SMS Alerts Supported',
                name: 'smsAlertsEnabled',
                props: {
                    color: 'primary',
                },
                required: true,
                type: 'boolean',
            },
            {
                defaultValue: (obj: any) =>
                    obj[SMS_ALERT_CHECKBOX_TITLE] || DEFAULT_SMS_ALERT_TITLE,
                helperTextFunc: () =>
                    '(Bold) Displays to the right of SMS Alerts opt-in checkbox. Use {"EN":"en text", "PT":"pt text", ...} notation',
                label: 'Checkbox Title',
                name: SMS_ALERT_CHECKBOX_TITLE,
                onChange: this.validateJson,
                required: false,
                shouldDisplay: (obj: any) => obj.smsAlertsEnabled,
                type: 'json',
            },
            {
                defaultValue: (obj: any) =>
                    obj[SMS_ALERT_CHECKBOX_SUBTITLE] ||
                    DEFAULT_SMS_ALERT_SUBTEXT,
                helperTextFunc: () =>
                    'Displays under checkbox title. Use {"EN":"en text", "PT":"pt text", ...} notation',
                label: 'Checkbox Subtitle',
                name: SMS_ALERT_CHECKBOX_SUBTITLE,
                onChange: this.validateJson,
                required: false,
                shouldDisplay: (obj: any) => obj.smsAlertsEnabled,
                type: 'json',
            },
            {
                name: 'Theme Properties',
                type: 'heading',
            },
            {
                defaultValue: `{
                    "logoUrl": null,
                    "onboardingToastColor": null,
                    "prizeDialogTopLine": null,
                    "leaderboardCurrentUserTextColor": null,
                    "leaderboardPrizeTextColor": null,
                    "modalBackgroundColor": null,
                    "newPredictionBackgroundColor": null,
                    "seesawLottieColor": null,
                    "tournamentHeaderBackgroundColor": null,
                    "tournamentHeaderGraphicUrl": null
                }`,
                label: 'Theme Properties',
                name: THEME_PROPERTIES,
                onChange: this.validateJson,
                required: false,
                type: 'json',
            },
            {
                name: 'URL Properties',
                type: 'heading',
            },
            {
                defaultValue: `{
                    "privacyUrl": null,
                    "rulesUrl": null,
                    "surveyUrl": null,
                    "termsUrl": null
                }`,
                label: 'URL Properties',
                name: URL_PROPERTIES,
                onChange: this.validateJson,
                required: false,
                type: 'json',
            },
            {
                name: 'Other Properties',
                type: 'heading',
            },
            {
                defaultValue: `{
                    "messageBar": null,
                    "embeddedUtmValues": null,
                    "messageBarGameOver": null,
                    "prizeDialogTopLine": null
                }`,
                label: 'Other Properties',
                name: 'properties',
                onChange: this.validateJson,
                required: false,
                type: 'json',
            },
            {
                name: 'Advanced Config',
                type: 'heading',
            },
            {
                defaultValue: false,
                label: 'Show Advanced Config',
                name: 'showAdvancedConfig',
                props: {
                    color: 'primary',
                },
                required: false,
                type: 'boolean',
            },
            {
                label: 'Analytics Keys',
                name: 'analyticsKeys',
                onChange: this.validateJson,
                required: false,
                shouldDisplay: (obj: any) => obj.showAdvancedConfig,
                type: 'json',
            },

            {
                label: 'Firebase Config',
                name: 'firestoreConfig',
                onChange: this.validateJson,
                required: false,
                shouldDisplay: (obj: any) => obj.showAdvancedConfig,
                type: 'json',
            },
            {
                label: 'HTML Snippet',
                name: 'htmlSnippet',
                onChange: this.validateHtmlSnippet,
                props: {
                    multiline: true,
                },
                required: false,
                shouldDisplay: (obj: any) => obj.showAdvancedConfig,
                type: 'text',
            },
        ];
    }

    private static tableColumns = [
        {
            label: 'Partner ID',
            name: 'id',
            options: {
                display: false,
                filter: false,
            },
        },
        {
            label: 'Name',
            name: 'name',
            options: {
                display: false,
                filter: false,
            },
        },
        {
            label: 'Short Name',
            name: 'shortName',
            options: {
                filter: false,
            },
        },
        {
            label: 'Host Name',
            name: 'hostName',
            options: {
                customBodyRender: (value: string) => {
                    return (
                        <Link
                            href={`https://${value}`}
                            target="_blank"
                            rel="noreferrer"
                            onClick={(e) => e.stopPropagation()}
                        >
                            {value}
                        </Link>
                    );
                },
                filter: false,
            },
            required: true,
        },
        {
            label: 'Collect Email',
            name: 'collectEmail',
            options: {
                customBodyRender: (value: any) => {
                    return value === true ? 'Yes' : 'No';
                },
                filter: false,
            },
        },
        {
            label: 'Auth Method',
            name: 'authenticationMethod',
            options: {
                customBodyRender: (value: any) => {
                    let ret = '';
                    if (value.indexOf('ANONYMOUS') > 0) {
                        ret = 'Anonymous';
                    } else if (value.indexOf('PHONE') > 0) {
                        ret = 'Phone';
                    }
                    return ret;
                },
            },
        },
        {
            label: 'SMS provider',
            name: 'SMSProvider',
            options: {
                customBodyRender: (value: any) => {
                    switch (value) {
                        case '{PLIVO}':
                            return 'PLIVO';
                        case '{VONAGE}':
                            return 'VONAGE';
                        case '{TWILIO}':
                            return 'TWILIO';
                        case '{BANDWIDTH}':
                            return 'BANDWIDTH';
                        default:
                            return 'PLIVO';
                    }
                },
            },
        },
        {
            label: 'Primary SMS Phone',
            name: 'primarySmsPhoneNumber',
            options: {
                filter: false,
            },
        },
        {
            label: 'Secondary SMS Phone',
            name: 'secondarySmsPhoneNumber',
            options: {
                filter: false,
            },
        },
        {
            label: 'Facebook App Id',
            name: 'facebookAppId',
            options: {
                display: false,
                filter: false,
            },
        },
        {
            label: 'Firebase Config',
            name: 'firestoreConfig',
            options: {
                customBodyRender: (conf: any) => {
                    return `${JSON.stringify(conf, null, 4)}`;
                },
                display: false,
                filter: false,
            },
        },
        {
            label: 'Properties',
            name: 'properties',
            options: {
                customBodyRender: (prop: any) => {
                    return `${JSON.stringify(prop, null, 4)}`;
                },
                display: false,
                filter: false,
            },
        },
        {
            label: 'Analytics Keys',
            name: 'analyticsKeys',
            options: {
                customBodyRender: (prop: any) => {
                    return `${JSON.stringify(prop, null, 4)}`;
                },
                display: false,
                filter: false,
            },
        },
    ];

    // Combine the various property objects into the one object that is stored in the DB
    // Convert the custom prop names for this form to the names that megaphone expects.
    private presubmitTransform(formState: any) {
        const parseJsonOrReturn = (str: any) =>
            typeof str === 'string' ? JSON.parse(str) : str;
        const legacyFieldsParseJsonOrReturn = (str: any) => {
            try {
                return typeof str === 'string' ? JSON.parse(str) : str;
            } catch (error) {
                return str;
            }
        };

        const transformedState = {
            ...formState,
        };
        const {
            emailMarketingLabel,
            emailOptInIsMandatory,
            emailReOptInIsMandatory,
            marketingOptInTitle,
            marketingSupport,
            optInValidSince,
            optionalOptInIsMandatory,
            optionalReOptInIsMandatory,
            smsAlertCheckboxSubtitle,
            smsAlertCheckboxTitle,
            smsMarketingLabel,
            smsOptInIsMandatory,
            smsReOptInIsMandatory,
            ...trimmedState
        } = transformedState;

        trimmedState.properties = parseJsonOrReturn(trimmedState.properties);

        if (marketingSupport === EMAIL_AND_SMS) {
            trimmedState.collectEmail = true;
            trimmedState.properties[SMS_MARKETING_ENABLED] = true;
            trimmedState.properties[MARKETING_OPT_IN_TITLE] =
                legacyFieldsParseJsonOrReturn(marketingOptInTitle);
            trimmedState.properties[EMAIL_MARKETING_LABEL] =
                legacyFieldsParseJsonOrReturn(emailMarketingLabel);
            trimmedState.properties[SMS_MARKETING_LABEL] =
                legacyFieldsParseJsonOrReturn(smsMarketingLabel);
        }

        if (marketingSupport === EMAIL_ONLY) {
            trimmedState.properties[SMS_MARKETING_ENABLED] = false;
            trimmedState.collectEmail = true;
            trimmedState.properties[MARKETING_OPT_IN_TITLE] =
                legacyFieldsParseJsonOrReturn(marketingOptInTitle);
            trimmedState.properties[EMAIL_MARKETING_LABEL] =
                legacyFieldsParseJsonOrReturn(emailMarketingLabel);
        }

        if (trimmedState.smsAlertsEnabled) {
            trimmedState.properties[SMS_ALERT_CHECKBOX_TITLE] =
                legacyFieldsParseJsonOrReturn(smsAlertCheckboxTitle);

            trimmedState.properties[SMS_ALERT_CHECKBOX_SUBTITLE] =
                legacyFieldsParseJsonOrReturn(smsAlertCheckboxSubtitle);
        }

        trimmedState[THEME_PROPERTIES] = parseJsonOrReturn(
            trimmedState[THEME_PROPERTIES],
        );

        trimmedState[URL_PROPERTIES] = parseJsonOrReturn(
            trimmedState[URL_PROPERTIES],
        );
        trimmedState.properties = {
            ...trimmedState[THEME_PROPERTIES],
            ...trimmedState[URL_PROPERTIES],
            ...trimmedState.properties,
        };

        // Onboarding opt-in checkboxes
        if (marketingSupport !== NONE) {
            trimmedState.properties[EMAIL_OPT_IN_IS_MANDATORY] =
                emailOptInIsMandatory || false;
            trimmedState.properties[SMS_OPT_IN_IS_MANDATORY] =
                smsOptInIsMandatory || false;
        }
        trimmedState.properties[OPTIONAL_OPT_IN_IS_MANDATORY] =
            optionalOptInIsMandatory || false;

        // Re-opt-in checkboxes
        if (marketingSupport !== NONE) {
            trimmedState.properties[OPT_IN_VALID_SINCE] =
                optInValidSince || null;
            trimmedState.properties[EMAIL_RE_OPT_IN_IS_MANDATORY] =
                emailReOptInIsMandatory || false;
            trimmedState.properties[SMS_RE_OPT_IN_IS_MANDATORY] =
                smsReOptInIsMandatory || false;
        }
        trimmedState.properties[OPTIONAL_RE_OPT_IN_IS_MANDATORY] =
            optionalReOptInIsMandatory || false;

        delete trimmedState.showAdvancedConfig;
        return trimmedState;
    }

    // Split partner.properties into the different sub-categories for readability in the create/edit form.
    private preformRenderTransform(partner: any) {
        const { properties } = partner;

        // Deconstruct Marketing props
        const {
            emailMarketingLabel,
            marketingOptInTitle,
            smsMarketingLabel,
            ...lessMarketing
        } = partner.properties;

        // Email AND SMS marketing enabled
        if (properties[SMS_MARKETING_ENABLED] && partner.collectEmail) {
            partner[MARKETING_SUPPORT] = EMAIL_AND_SMS;
            partner[MARKETING_OPT_IN_TITLE] = marketingOptInTitle;
            partner[EMAIL_MARKETING_LABEL] = emailMarketingLabel;
            partner[SMS_MARKETING_LABEL] = smsMarketingLabel;
        } else if (partner.collectEmail) {
            partner[MARKETING_SUPPORT] = EMAIL_ONLY;
            partner[MARKETING_OPT_IN_TITLE] = marketingOptInTitle;
            partner[EMAIL_MARKETING_LABEL] = emailMarketingLabel;
        } else {
            partner[MARKETING_SUPPORT] = NONE;
        }

        // Deconstruct SMS Alert props
        const {
            smsAlertCheckboxSubtitle,
            smsAlertCheckboxTitle,
            ...lessAlerts
        } = lessMarketing;

        // SMS Alerts Enabled so pull out text for those fields.
        if (partner.smsAlertsEnabled) {
            partner[SMS_ALERT_CHECKBOX_TITLE] = smsAlertCheckboxTitle;
            partner[SMS_ALERT_CHECKBOX_SUBTITLE] = smsAlertCheckboxSubtitle;
        }

        // Onboarding opt-in checkboxes
        if (partner[MARKETING_SUPPORT] !== NONE) {
            partner[EMAIL_OPT_IN_IS_MANDATORY] =
                properties[EMAIL_OPT_IN_IS_MANDATORY] || false;
            partner[SMS_OPT_IN_IS_MANDATORY] =
                properties[SMS_OPT_IN_IS_MANDATORY] || false;
        }
        partner[OPTIONAL_OPT_IN_IS_MANDATORY] =
            properties[OPTIONAL_OPT_IN_IS_MANDATORY] || false;

        // Re-opt-in checkboxes
        if (partner[MARKETING_SUPPORT] !== NONE) {
            partner[OPT_IN_VALID_SINCE] =
                properties[OPT_IN_VALID_SINCE] || null;
            partner[EMAIL_RE_OPT_IN_IS_MANDATORY] =
                properties[EMAIL_RE_OPT_IN_IS_MANDATORY] || false;
            partner[SMS_RE_OPT_IN_IS_MANDATORY] =
                properties[SMS_RE_OPT_IN_IS_MANDATORY] || false;
        }
        partner[OPTIONAL_RE_OPT_IN_IS_MANDATORY] =
            properties[OPTIONAL_RE_OPT_IN_IS_MANDATORY] || false;

        // Deconstruct Theme props
        const {
            leaderboardCurrentUserTextColor,
            leaderboardHorizontalRuleColor,
            leaderboardPrizeTextColor,
            logoSizeWaitingRoom,
            logoUrl,
            modalBackgroundColor,
            navLogoUrl,
            newPredictionBackgroundColor,
            onboardingFooterImageUrl,
            onboardingToastColor,
            prizeDetailsModalBackgroundImageUrl,
            prizeDialogTopLine,
            seesawLottieColor,
            stayTunedTileLoadingDotsColor,
            tournamentHeaderBackgroundColor,
            tournamentHeaderGraphicUrl,
            waitingRoomSponsorLogoUrl,
            waitingRoomToastBackgroundColor,
            waitingRoomToastInvertTextColor,
            ...lessTheme
        } = lessAlerts;

        partner[THEME_PROPERTIES] = {
            leaderboardCurrentUserTextColor,
            leaderboardHorizontalRuleColor,
            leaderboardPrizeTextColor,
            logoSizeWaitingRoom,
            logoUrl,
            modalBackgroundColor,
            navLogoUrl,
            newPredictionBackgroundColor,
            onboardingFooterImageUrl,
            onboardingToastColor,
            prizeDetailsModalBackgroundImageUrl,
            prizeDialogTopLine,
            seesawLottieColor,
            stayTunedTileLoadingDotsColor,
            tournamentHeaderBackgroundColor,
            tournamentHeaderGraphicUrl,
            waitingRoomSponsorLogoUrl,
            waitingRoomToastBackgroundColor,
            waitingRoomToastInvertTextColor,
        };

        // Deconstruct URL props
        const { privacyUrl, rulesUrl, surveyUrl, termsUrl, ...otherProps } =
            lessTheme;

        partner[URL_PROPERTIES] = {
            privacyUrl,
            rulesUrl,
            surveyUrl,
            termsUrl,
        };

        partner.properties = otherProps;
        return partner;
    }

    private setRowProps = () => {
        return {
            style: {},
        };
    };

    public render() {
        const {
            createRecord,
            deleteUser,
            getUsers,
            partners,
            refreshData,
            setProUsers,
        } = this.props;

        const props = {
            ...this.props,
            breadCrumbs: [],
            buildChildLink: this.buildCategoryLink,
            createRecord,
            data: partners,
            deleteUser: deleteUser,
            getUsers: getUsers,
            headline: 'Partners',
            inputFields: this.inputFields(),
            label: PAGE_LABEL.partner,
            preformRenderTransform: this.preformRenderTransform,
            presubmitTransform: this.presubmitTransform,
            refreshData: refreshData,
            setProUsers: setProUsers,
            setRowProps: this.setRowProps,
            supportedLanguages: [],
            tableColumns: Partners.tableColumns,
        };

        return <CrudPage {...props} />;
    }
}

export default Partners;
