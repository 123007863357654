import { useState } from 'react';

export function useModal<T>(): [
    boolean,
    (modalData?: T) => void,
    () => void,
    T?,
] {
    const [modalOpen, setModalOpen] = useState(false);
    const [modalData, setModalData] = useState<T | undefined>(undefined);

    function openModal(data?: T) {
        setModalData(data);
        setModalOpen(true);
    }

    function closeModal() {
        setModalOpen(false);
        setModalData(undefined);
    }

    return [modalOpen, openModal, closeModal, modalData];
}
