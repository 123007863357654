import './fix/MuiDataTablesFix';
import './styles/App.css';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { GoogleOAuthProvider } from '@react-oauth/google';
import moment from 'moment-timezone';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { Component } from 'react';
import { BrowserRouter, Link, Redirect, Route } from 'react-router-dom';
import AnswerPredictions from './components/AnswerPredictions';
import GoogleAuth from './components/GoogleAuth';
import config from './config';
import ApiContext from './contexts/ApiContext';
import AppContext from './contexts/AppContext';
import BingoSquares from './pages/BingoSquares';
import Categories from './pages/Categories';
import Codes from './pages/Codes';
import Events from './pages/Events';
import Iterations from './pages/Iterations';
import NoChildEntriesEvent from './pages/NoChildEntriesEvent';
import Offers from './pages/Offers';
import Partners from './pages/Partners';
import Predictions from './pages/Predictions';
import ProgressivePolls from './pages/ProgressivePolls';
import Sponsors from './pages/Sponsors';
import Teams from './pages/Teams';
import Trivia from './pages/Trivia';
import Venues from './pages/Venues';
import { AuthoringApi } from './service/AuthoringApi';
import { NBASeason } from './utils/event/BingoSportRadarUrlConfig';

// forcing a rebuild
const { apiBaseUrl } = config;

// Not different per environment
const googleClientID =
    '294434330448-e70kr55gsl81mdu57423hpkt2ejhtnnb.apps.googleusercontent.com';

const DELETE = 'DELETE';
const POST = 'POST';
const PATCH = 'PATCH';

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.api = new AuthoringApi({
            onError: this.alertOnApiError,
            onStaleToken: this.alertOnStaleToken,
        });
    }

    static propTypes = {
        enqueueSnackbar: PropTypes.func,
    };
    getScheduledGames = (games) => {
        const futureGames = games.filter((game) => game.status === 'scheduled');
        return () => {
            return futureGames.map((game) => {
                return { label: game.id, value: game.id };
            });
        };
    };

    notifyError = (message) => {
        const adjustedMessage = message.replace(
            /Failed to fetch/,
            'Unknown HTTP fetch failure: api server running? CORS enabled?',
        );

        this.props.enqueueSnackbar(adjustedMessage, {
            action: <Button size="small">Dismiss</Button>,
            autoHideDuration: null,
            variant: 'error',
        });
    };

    notifySuccess = (message) => {
        this.props.enqueueSnackbar(message, {
            action: <Button size="small">Dismiss</Button>,
            autoHideDuration: 2000,
            variant: 'success',
        });
    };

    notifyWarning = (message) => {
        this.props.enqueueSnackbar(message, {
            action: <Button size="small">Dismiss</Button>,
            autoHideDuration: 7000,
            variant: 'warning',
        });
    };

    alertOnApiError = (error) => {
        this.notifyError('Request for url failed, ', error);
    };

    alertOnStaleToken = () =>
        this.notifyError(
            'Login Expired: It seems your login has expired.\nClick the login button again to refresh.',
        );

    alertOnUnexpectedError = (status, body) =>
        this.notifyError(
            `Something bad happened.\nThe server status was ${status} and it said "${body}"`,
        );

    apiVerbWithBody = async (url, verb, body) => {
        const response = await fetch(url, {
            ...(body && { body: JSON.stringify(body) }),
            credentials: 'include',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            method: verb,
        });

        if (!response.ok) {
            if (response.status === 401 || response.status === 403) {
                this.alertOnStaleToken();
                throw new Error('Stale Token');
            } else {
                let errorObjectOrString;

                try {
                    errorObjectOrString = await response.json();
                } catch (error) {
                    console.warn(
                        'unable to parse response as json using text',
                        error,
                    );
                    errorObjectOrString = await response.text();
                }

                if (
                    typeof errorObjectOrString === 'object' &&
                    errorObjectOrString.message
                ) {
                    this.notifyError(errorObjectOrString.message);
                } else {
                    this.notifyError(String(errorObjectOrString));
                }

                throw errorObjectOrString;
            }
        } else {
            if (response.status === 204) {
                return {};
            } else {
                try {
                    return await response.json();
                } catch (error) {
                    console.error(
                        "response was okay but didn't parse as json",
                        await response.text(),
                    );
                    this.notifyError('Request for url failed, ', error);
                }
            }
        }
    };

    // Partners CRUD Methods

    refreshPartners = async (callback) => {
        const partners = await this.api.getPartners();
        if (partners) {
            this.setState({ partners }, callback);
        } else {
            callback && callback();
        }
    };

    refreshPartner = async (partnerId) => {
        try {
            const partner = await this.api.getPartner(partnerId);
            this.setState({ partner });
        } catch (e) {
            this.notifyError(e.message);
        }
    };

    createPartner = async (postBody) => {
        const url = `${apiBaseUrl}/partners`;
        await this.apiVerbWithBody(url, POST, postBody);
        await this.refreshPartners();
    };

    updatePartner = async (partnerId, body) => {
        const url = `${apiBaseUrl}/partners/${partnerId}`;
        await this.apiVerbWithBody(url, PATCH, body);
        await this.refreshPartners();
    };

    deletePartner = async ({ id }) => {
        const url = `${apiBaseUrl}/partners/${id}`;
        await this.apiVerbWithBody(url, DELETE);
        await this.refreshPartners();
    };

    getUsers = ({ isPro, partnerId }) => {
        return this.api.getUsers({
            isPro,
            partnerId,
        });
    };

    setProUsers = ({ displayNames, partnerId }) => {
        return this.api.setProUsers({
            displayNames,
            partnerId,
        });
    };

    deleteUser = (params) => {
        return this.api.deleteUser({
            ...params,
        });
    };

    //#region trivia
    refreshTriviaBuckets = async (partnerId) => {
        const { triviaBucketsByPartner = {} } = this.state;
        try {
            const triviaBuckets = await this.api.getTriviaBuckets(partnerId);
            triviaBucketsByPartner[partnerId] = triviaBuckets;
            this.setState({ triviaBucketsByPartner });
        } catch (error) {
            this.notifyError(error.message);
        }
    };

    refreshTriviaQuestions = async (partnerId) => {
        const { triviaQuestionsByPartner = {} } = this.state;
        try {
            const triviaQuestions =
                await this.api.getTriviaQuestions(partnerId);
            triviaQuestionsByPartner[partnerId] = triviaQuestions || [];
            this.setState({ triviaQuestionsByPartner });
        } catch (error) {
            this.notifyError(`Failed to get triviaQuestions: ${error.message}`);
        }
    };

    deleteTriviaQuestions = async ({ ids, partnerId }) => {
        const idsParams = new URLSearchParams(
            ids.map((id) => ['ids', id]),
        ).toString();

        const url = `${apiBaseUrl}/partners/${partnerId}/trivia-questions?${idsParams}`;
        await this.apiVerbWithBody(url, DELETE);
        await this.refreshTriviaQuestions(partnerId);
    };

    batchCreateTriviaQuestions = (postBody) => {
        return this.api.batchCreateTriviaQuestions(postBody);
    };

    buildGetTriviaQuestions = (partnerId) => () => {
        return this.refreshTriviaQuestions(partnerId);
    };

    //#endregion

    //#region progressive polls
    refreshProgressivePolls = async (iterationId) => {
        const { progressivePollsByIteration = {} } = this.state;
        try {
            const progressivePolls =
                await this.api.getProgressivePolls(iterationId);
            progressivePollsByIteration[iterationId] = progressivePolls || [];
            this.setState({ progressivePollsByIteration });
        } catch (error) {
            this.notifyError(
                `Failed to get progressivePolls: ${error.message}`,
            );
        }
    };

    deleteProgressivePolls = async ({ ids, iterationId }) => {
        await this.api.deleteProgressivePolls({ ids, iterationId });
        await this.refreshProgressivePolls(iterationId);
    };

    createProgressivePoll = async (postBody) => {
        await this.api.createProgressivePoll(postBody);
        await this.refreshProgressivePolls(postBody.iterationId);
    };

    batchCreateProgressivePolls = async (postBody) => {
        await this.api.batchCreateProgressivePolls(postBody);
        await this.refreshProgressivePolls(postBody.iterationId);
    };

    buildGetProgressivePolls = (partnerId) => () =>
        this.refreshProgressivePolls(partnerId);

    //#endregion

    //#region bingo squares
    refreshBingoSquaresForEvent = async (eventId) => {
        const { bingoSquaresByEvent = {} } = this.state;
        const bingoSquares = await this.api.getBingoSquaresForEvent(eventId);
        bingoSquaresByEvent[eventId] = bingoSquares;
        this.setState({ bingoSquaresByEvent });
    };

    refreshSportRadarGameIDsForEvent = async () => {
        try {
            const sportRadarGames = await this.api.getSportRadarGames({
                season: 'ALL',
            });

            const sportRadarGameIDs = {};
            Object.values(NBASeason).forEach(
                (season) =>
                    (sportRadarGameIDs[season] = [
                        { label: '', value: '' },
                        ...sportRadarGames[season].map(
                            ({ away, home, id, scheduled }) => ({
                                label: `${new Date(
                                    scheduled,
                                ).toLocaleString()} ${home.name} VS ${
                                    away.name
                                }`,
                                value: id,
                            }),
                        ),
                    ]),
            );

            this.setState({ sportRadarGameIDs });
        } catch (error) {
            this.notifyError(error.message);
        }
    };

    batchCreateBingoSquares = (params) => {
        return this.api.batchCreateBingoSquares(params);
    };

    updateBingoSquare = (params) => {
        return this.api.updateBingoSquare(params);
    };

    //#endregion bingo squares

    //#region venues
    refreshVenues = async (partnerId) => {
        const { venuesByPartner = {} } = this.state;
        try {
            const venues = await this.api.getVenues(partnerId);
            venuesByPartner[partnerId] = venues || [];
            this.setState({ venuesByPartner });
        } catch (error) {
            this.notifyError(`Failed to get venues: ${error.message}`);
        }
    };

    batchCreateVenues = (postBody) => {
        return this.api.batchCreateVenues(postBody);
    };

    buildGetVenues = (partnerId) => () => {
        return this.refreshVenues(partnerId);
    };

    //#endregion

    //#region teams

    refreshTeams = async ({ iterationId, partnerId }) => {
        const { teamsByIteration = {} } = this.state;
        try {
            const teams = await this.api.getTeams({ iterationId, partnerId });
            teamsByIteration[iterationId] = teams || [];
            this.setState({ teamsByIteration });
        } catch (error) {
            this.notifyError(`Failed to get teams: ${error.message}`);
        }
    };

    batchCreateTeams = (postBody) => {
        return this.api.batchCreateTeams(postBody);
    };
    //#endregion

    // Sponsors CRUD Methods

    refreshSponsorsForPartner = async (partnerId, callback) => {
        const { sponsorsByPartner = {} } = this.state;
        try {
            const sponsors = await this.api.getSponsors(partnerId);
            if (sponsors) {
                sponsorsByPartner[partnerId] = sponsors;
                this.setState({ sponsorsByPartner }, callback);
            } else {
                callback && callback();
            }
        } catch (error) {
            this.notifyError(`Failed to get sponsors: ${error.message}`);
        }
    };

    createSponsor = async (partnerId, body) => {
        await this.api.createSponsor(partnerId, body);
        await this.refreshSponsorsForPartner(partnerId);
    };

    updateSponsor = async (partnerId, sponsorId, body) => {
        await this.api.updateSponsor(sponsorId, body);
        await this.refreshSponsorsForPartner(partnerId);
    };

    deleteSponsor = async (partnerId, sponsorId) => {
        await this.api.deleteSponsor(sponsorId);
        await this.refreshSponsorsForPartner(partnerId);
    };

    saveSponsorshipUnit = async (partnerId, sponsorId, unit) => {
        await this.api.saveSponsorshipUnit(sponsorId, unit);
        await this.refreshSponsorsForPartner(partnerId);
    };

    deleteSponsorshipUnit = async (partnerId, sponsorId, unitId) => {
        await this.api.deleteSponsorshipUnit(sponsorId, unitId);
        await this.refreshSponsorsForPartner(partnerId);
    };

    createOffer = async (postBody) => {
        const { partner } = this.state;
        const { sponsorId } = postBody; // Partial<OfferDto>
        await this.api.createOffer(sponsorId, postBody);
        await this.refreshSponsorsForPartner(partner.id);
    };

    updateOffer = async (offerId, body) => {
        const { partner } = this.state;
        const { sponsorId } = body; // Partial<OfferDto>
        await this.api.updateOffer(sponsorId, offerId, body);
        await this.refreshSponsorsForPartner(partner.id);
    };

    deleteOffer = async ({ id: offerId, parentId: sponsorId }) => {
        const { partner } = this.state;
        await this.api.deleteOffer(sponsorId, offerId);
        await this.refreshSponsorsForPartner(partner.id);
    };

    createOfferCodes = async (sponsorId, offerId, codes) => {
        await this.api.createOfferCodes(sponsorId, offerId, codes);
        await this.refreshCodesForOffer(sponsorId, offerId);
    };

    refreshCodesForOffer = async (sponsorId, offerId, callback) => {
        const { codesByOffer = {} } = this.state;
        const codes = await this.api.getOfferCodes(sponsorId, offerId);
        if (codes) {
            codesByOffer[offerId] = codes;
            this.setState({ codesByOffer }, callback);
        } else {
            callback && callback();
        }
    };

    // Categories CRUD Methods
    refreshCategories = async (partnerId, callback) => {
        const { categoriesByPartner = {} } = this.state;
        const categories = await this.api.getCategories(partnerId);
        if (categories) {
            categoriesByPartner[partnerId] = categories;
            this.setState({ categoriesByPartner }, callback);
        } else {
            callback && callback();
        }
    };

    refreshCategory = async (categoryId) => {
        const category = await this.api.getCategory(categoryId);
        if (category) {
            this.setState({ category });
        }
    };

    createCategory = async (postBody) => {
        const url = `${apiBaseUrl}/categories`;
        await this.apiVerbWithBody(url, POST, postBody);
        await this.refreshCategories();
    };

    updateCategory = async (categoryId, body) => {
        const url = `${apiBaseUrl}/categories/${categoryId}`;
        await this.apiVerbWithBody(url, PATCH, body);
        await this.refreshCategories();
    };

    deleteCategory = async ({ id }) => {
        const url = `${apiBaseUrl}/categories/${id}`;
        await this.apiVerbWithBody(url, DELETE);
        await this.refreshCategories();
    };

    // Iteration CRUD Methods

    refreshIterations = async (categoryId, callback) => {
        const { iterationsByCategory = {} } = this.state;
        const iterations = await this.api.getIterations(categoryId);
        if (iterations) {
            iterationsByCategory[categoryId] = iterations;
            this.setState({ iterationsByCategory }, callback);
        } else {
            callback && callback();
        }
    };

    refreshIteration = async (iterationId) => {
        const iteration = await this.api.getIteration(iterationId);
        if (iteration) {
            this.setState({ iteration });
        }
    };

    createIteration = async (postBody) => {
        const url = `${apiBaseUrl}/iterations`;
        await this.apiVerbWithBody(url, POST, postBody);
        await this.refreshIterations(postBody.categoryId);
    };

    updateIteration = async (iterationId, body) => {
        const url = `${apiBaseUrl}/iterations/${iterationId}`;
        await this.apiVerbWithBody(url, PATCH, body);
        await this.refreshIterations(body.categoryId);
    };

    deleteIteration = async ({ id, parentId }) => {
        const url = `${apiBaseUrl}/iterations/${id}`;
        await this.apiVerbWithBody(url, DELETE);
        await this.refreshIterations(parentId);
    };

    // Event CRUD Methods

    refreshEventsForIteration = async (iterationId, callback) => {
        const { eventsByIteration = {} } = this.state;
        const events = await this.api.getEventsForIteration(iterationId);
        if (events) {
            eventsByIteration[iterationId] = events;
            this.setState({ eventsByIteration }, callback);
        } else {
            callback && callback();
        }
    };

    // Event CRUD Methods

    refreshEvent = async (eventId) => {
        const event = await this.api.getEvent(eventId);
        if (event) {
            this.setState({ event });
        }
    };

    createEvent = async (postBody) => {
        await this.api.createEvent(postBody);
        await this.refreshEventsForIteration(postBody.iterationId);
    };

    updateEvent = async (eventId, body) => {
        const { event, partner } = this.state;
        const updatedEvent = await this.api.updateEvent(eventId, body);
        const { hostName, properties } = partner;
        const { fbAccessToken } = properties;
        try {
            await this.api.recrawlEvent({
                accessToken: fbAccessToken,
                eventUrl: hostName,
            });
        } catch (error) {
            console.warn(`Could not recrawl facebook OG Tags: ${error}`);
        }

        if (event && event.id === updatedEvent.id) {
            this.setState({ event: updatedEvent });
        }
        await this.refreshEventsForIteration(updatedEvent.iterationId);
    };

    finalizeEvent = async (event) => {
        const { partner } = this.state;

        const now = new Date();
        let endDate = event.endDate;

        if (!endDate || new Date(endDate).getTime() > now.getTime()) {
            endDate = now.toISOString();
        }

        const updatedEvent = await this.api.finalizeEvent(event.id, {
            endDate,
        });

        const { hostName, properties } = partner;
        const { fbAccessToken } = properties;
        try {
            await this.api.recrawlEvent({
                accessToken: fbAccessToken,
                eventUrl: hostName,
            });
        } catch (error) {
            console.warn(`Could not recrawl facebook OG Tags: ${error}`);
        }

        if (event && event.id === updatedEvent.id) {
            this.setState({ event: updatedEvent });
        }
        await this.refreshEventsForIteration(updatedEvent.iterationId);
    };

    deleteEvent = async ({ id, parentId }) => {
        const url = `${apiBaseUrl}/events/${id}`;
        await this.apiVerbWithBody(url, DELETE);
        await this.refreshEventsForIteration(parentId);
    };

    duplicateEvent = async ({ eventId, parentId }) => {
        const url = `${apiBaseUrl}/events/${eventId}/duplicate`;
        await this.apiVerbWithBody(url, POST, {});
        await this.refreshEventsForIteration(parentId);
    };

    // Prediction CRUD Methods

    refreshPredictionsForEvent = async (eventId, callback) => {
        const { predictionsByEvent = {} } = this.state;
        const predictions = await this.api.getPredictionsForEvent(eventId);
        if (predictions) {
            predictionsByEvent[eventId] = predictions;
            this.setState({ predictionsByEvent }, callback);
        } else {
            callback && callback();
        }
    };

    createPrediction = async (postBody) => {
        const { eventId } = postBody;
        const url = `${apiBaseUrl}/events/${eventId}/predictions`;
        await this.apiVerbWithBody(url, POST, postBody);
        await this.refreshPredictionsForEvent(eventId);
    };

    batchCreatePredictions = async (postBody) => {
        const { eventId } = postBody;
        await this.api.batchCreatePredictions(postBody);
        await this.refreshPredictionsForEvent(eventId);
    };

    deletePrediction = async (
        { id, parentId: eventId },
        forceDelete = false,
    ) => {
        const url = `${apiBaseUrl}/events/${eventId}/predictions/${id}?force-delete=${forceDelete}`;
        await this.apiVerbWithBody(url, DELETE);
        await this.refreshPredictionsForEvent(eventId);
    };

    deletePredictions = async (
        { ids, parentId: eventId },
        forceDelete = false,
    ) => {
        const idsParams = new URLSearchParams(
            ids.map((id) => ['ids', id]),
        ).toString();

        const url = `${apiBaseUrl}/events/${eventId}/predictions?${idsParams}&force-delete=${forceDelete}`;
        await this.apiVerbWithBody(url, DELETE);
        await this.refreshPredictionsForEvent(eventId);
    };

    // This version is used by AnswerPredictions because it explicitly passes in the eventId.
    updatePredictionWithEvent = async (eventId, predictionId, prediction) => {
        const { predictionsByEvent = {} } = this.state;
        const url = `${apiBaseUrl}/events/${eventId}/predictions/${predictionId}`;
        const predictions = await this.apiVerbWithBody(url, PATCH, prediction);
        if (predictions) {
            predictionsByEvent[eventId] = predictions;
            this.setState({ predictionsByEvent });
        }

        return predictions;
    };

    // This version is passed into CrudPage, because that path doesn't explicitly pass in the eventId
    // to the update function.
    updatePrediction = async (predictionId, prediction) => {
        const { eventId } = prediction;
        return await this.updatePredictionWithEvent(
            eventId,
            predictionId,
            prediction,
        );
    };

    batchUnlockPredictions = async (eventId, bodyData) => {
        const { predictionsByEvent = {} } = this.state;
        const url = `${apiBaseUrl}/events/${eventId}/predictions/unlock`;
        const predictions = await this.apiVerbWithBody(url, POST, bodyData);

        if (predictions) {
            predictionsByEvent[eventId] = predictions;
            this.setState({ predictionsByEvent });
        }
    };

    batchEditPredictions = async (eventId, bodyData) => {
        const { predictionsByEvent = {} } = this.state;
        const url = `${apiBaseUrl}/events/${eventId}/predictions`;
        const response = await this.apiVerbWithBody(url, PATCH, bodyData);

        if (response) {
            predictionsByEvent[eventId] = response;
            this.setState({ predictionsByEvent });
        }
    };

    pullPredictionDataFromFeed = async ({
        eventId,
        feedProvider,
        feedSport,
        groupId,
        id,
        template,
    }) => {
        const data = await this.api.pullPredictionDataFromFeed(
            eventId,
            feedProvider,
            id,
            feedSport,
            template,
            groupId,
        );
        return data;
    };

    sendSmsAlert = async ({
        eventId,
        filter,
        messages,
        partnerId,
        smsTestPhoneNumber,
    }) => {
        const url = `${apiBaseUrl}/partners/${partnerId}/sms`;
        const bodyData = {
            eventId,
            filter,
            messages,
            smsTestPhoneNumber,
        };
        await this.apiVerbWithBody(url, POST, bodyData);
    };

    manualLockPredictions = async (eventId, bodyData) => {
        const { predictionsByEvent = {} } = this.state;

        const lockedPreictions = await this.api.manualLockPredictions(
            eventId,
            bodyData,
        );
        predictionsByEvent[eventId].map(
            (pr) =>
                lockedPreictions.find((lockedPr) => lockedPr.id === pr.id) ||
                pr,
        );
        this.setState({ predictionsByEvent });
    };

    setAnswers = async ({ answerMilestone, answers, callback, eventId }) => {
        const url = `${apiBaseUrl}/events/${eventId}/answers`;

        const predictions = Object.keys(answers).map((predictionId) => {
            const options = Object.keys(
                answers[predictionId].options || {},
            ).map((optionId) => ({
                ...answers[predictionId].options[optionId],
                id: optionId,
            }));
            return {
                ...answers[predictionId],
                id: predictionId,
                ...(options && options.length ? { options } : undefined),
            };
        });

        const response = await this.apiVerbWithBody(url, POST, {
            answerMilestone,
            predictions,
        });

        await this.refreshPredictionsForEvent(eventId, callback);
        return response;
    };

    formatDate = (date) => {
        return date
            ? moment(date)
                  .tz(moment.tz.guess()) // Browser's timezone
                  .format('dddd M/D/Y h:mma z')
            : 'Date not set';
    };

    renderPartners = () => {
        const { isAuthenticated, partners } = this.state;

        if (!isAuthenticated) {
            return null;
        }

        if (!partners) {
            this.refreshPartners();
            return null;
        }
        return (
            <Partners
                createRecord={this.createPartner}
                deleteRecord={this.deletePartner}
                deleteUser={this.deleteUser}
                getUsers={this.getUsers}
                notifyError={this.notifyError}
                notifySuccess={this.notifySuccess}
                notifyWarning={this.notifyWarning}
                partners={partners}
                refreshData={this.refreshPartners}
                setProUsers={this.setProUsers}
                updateRecord={this.updatePartner}
            />
        );
    };

    buildGetCategories = (partnerId) => (callback) =>
        this.refreshCategories(partnerId, callback);

    renderCategories = () => {
        const {
            categoriesByPartner = {},
            isAuthenticated,
            partner,
        } = this.state;
        if (!isAuthenticated) {
            return null;
        }

        const queryParams = queryString.parse(window.location.search) || {};
        const { partner: partnerId } = queryParams;

        if (!partnerId) {
            return <Redirect to="/partners" />;
        }

        const categories = categoriesByPartner[partnerId];
        const refreshData = this.buildGetCategories(partnerId);

        if (!categories) {
            this.refreshCategories(partnerId);
            return null;
        } else if (!partner || partnerId !== partner.id) {
            this.refreshPartner(partnerId);
            return null;
        }
        return (
            <Categories
                categories={categories}
                createRecord={this.createCategory}
                deleteRecord={this.deleteCategory}
                formatDate={this.formatDate}
                notifyError={this.notifyError}
                notifySuccess={this.notifySuccess}
                partner={partner}
                refreshData={refreshData}
                updateRecord={this.updateCategory}
            />
        );
    };

    buildGetIterations = (categoryId) => (callback) =>
        this.refreshIterations(categoryId, callback);

    renderIterations = () => {
        const {
            category,
            isAuthenticated,
            iterationsByCategory = {},
            partner,
        } = this.state;
        if (!isAuthenticated) {
            return null;
        }

        const queryParams = queryString.parse(window.location.search) || {};
        const { category: categoryId } = queryParams;

        if (!categoryId) {
            return <Redirect to="/categories" />;
        }

        const iterations = iterationsByCategory[categoryId];
        const refreshData = this.buildGetIterations(categoryId);

        if (!iterations) {
            this.refreshIterations(categoryId);
            return null;
        } else if (!category || categoryId !== category.id) {
            this.refreshCategory(categoryId);
            return null;
        } else if (!partner) {
            this.refreshPartner(category.partnerId);
            return null;
        }

        return (
            <Iterations
                category={category}
                createRecord={this.createIteration}
                deleteRecord={this.deleteIteration}
                formatDate={this.formatDate}
                iterations={iterations}
                notifyError={this.notifyError}
                notifySuccess={this.notifySuccess}
                partner={partner}
                refreshData={refreshData}
                updateRecord={this.updateIteration}
            />
        );
    };

    buildGetEvents = (iterationId) => (callback) =>
        this.refreshEventsForIteration(iterationId, callback);

    renderEvents = () => {
        const {
            category,
            eventsByIteration = {},
            isAuthenticated,
            iteration,
            partner,
            sponsorsByPartner = {},
            sportRadarGameIDs = {},
            triviaBucketsByPartner = {},
        } = this.state;
        if (!isAuthenticated) {
            return null;
        }
        const queryParams = queryString.parse(window.location.search) || {};
        const { iteration: iterationId } = queryParams;

        if (!iterationId) {
            return <Redirect to="/categories" />;
        }

        const refreshData = this.buildGetEvents(iterationId);

        const events = eventsByIteration[iterationId];

        if (!events) {
            this.refreshEventsForIteration(iterationId);
            this.refreshSportRadarGameIDsForEvent();
            return null;
        } else if (!iteration || iterationId !== iteration.id) {
            this.refreshIteration(iterationId);
            return null;
        } else if (!category) {
            this.refreshCategory(iteration.categoryId);
            return null;
        } else if (!partner) {
            this.refreshPartner(category.partnerId);
            return null;
        } else if (!triviaBucketsByPartner[partner.id]) {
            this.refreshTriviaBuckets(partner.id);
            return null;
        } else if (!sponsorsByPartner || !sponsorsByPartner[partner.id]) {
            this.refreshSponsorsForPartner(partner.id);
        }

        return (
            <Events
                category={category}
                createRecord={this.createEvent}
                deleteRecord={this.deleteEvent}
                duplicateEvent={this.duplicateEvent}
                events={events}
                formatDate={this.formatDate}
                iteration={iteration}
                notifyError={this.notifyError}
                notifySuccess={this.notifySuccess}
                notifyWarning={this.notifyWarning}
                partner={partner}
                refreshData={refreshData}
                sponsors={partner && sponsorsByPartner[partner.id]}
                triviaBuckets={triviaBucketsByPartner[partner.id]}
                updateRecord={this.updateEvent}
                sportRadarGameIDs={sportRadarGameIDs}
            />
        );
    };

    buildGetPredictions = (eventId) => async (callback) => {
        await this.refreshEvent(eventId);
        this.refreshPredictionsForEvent(eventId, callback);
    };

    renderPredictions = () => {
        const {
            category,
            event,
            isAuthenticated,
            iteration,
            partner,
            predictionsByEvent = {},
            sponsorsByPartner = {},
        } = this.state;
        if (!isAuthenticated) {
            return null;
        }

        const queryParams = queryString.parse(window.location.search) || {};
        const { event: eventId } = queryParams;

        if (!eventId) {
            return <Redirect to="/categories" />;
        }

        const predictions = predictionsByEvent[eventId];

        if (!predictions) {
            this.refreshPredictionsForEvent(eventId);
            return null;
        } else if (!event || eventId !== event.id) {
            this.refreshEvent(eventId);
            return null;
        } else if (!iteration || event.iterationId !== iteration.id) {
            this.refreshIteration(event.iterationId);
            return null;
        } else if (!category) {
            this.refreshCategory(iteration.categoryId);
            return null;
        } else if (!partner) {
            this.refreshPartner(category.partnerId);
            return null;
        } else if (!sponsorsByPartner || !sponsorsByPartner[partner.id]) {
            this.refreshSponsorsForPartner(partner.id);
        }

        return (
            <Predictions
                batchCreatePredictions={this.batchCreatePredictions}
                batchManualLockPredictions={this.manualLockPredictions}
                batchUnlockPredictions={this.batchUnlockPredictions}
                category={category}
                createRecord={this.createPrediction}
                deleteRecord={this.deletePrediction}
                deleteRecords={this.deletePredictions}
                event={event}
                formatDate={this.formatDate}
                iteration={iteration}
                notifyError={this.notifyError}
                notifySuccess={this.notifySuccess}
                notifyWarning={this.notifyWarning}
                partner={partner}
                predictions={predictions}
                pullPredictionDataFromFeed={this.pullPredictionDataFromFeed}
                refreshData={this.buildGetPredictions(eventId)}
                sponsors={sponsorsByPartner && sponsorsByPartner[partner.id]}
                updateRecord={this.updatePrediction}
            />
        );
    };

    renderNoChildEntriesEvent = () => {
        const {
            category,
            event,
            isAuthenticated,
            iteration,
            partner,
            sponsorsByPartner = {},
        } = this.state;

        if (!isAuthenticated) {
            return null;
        }

        const queryParams = queryString.parse(window.location.search) || {};
        const { event: eventId } = queryParams;

        if (!eventId) {
            return <Redirect to="/categories" />;
        }

        if (!event || eventId !== event.id) {
            this.refreshEvent(eventId);
            return null;
        } else if (!iteration || event.iterationId !== iteration.id) {
            this.refreshIteration(event.iterationId);
            return null;
        } else if (!category) {
            this.refreshCategory(iteration.categoryId);
            return null;
        } else if (!partner) {
            this.refreshPartner(category.partnerId);
            return null;
        } else if (!sponsorsByPartner || !sponsorsByPartner[partner.id]) {
            this.refreshSponsorsForPartner(partner.id);
        }

        return (
            <NoChildEntriesEvent
                category={category}
                iteration={iteration}
                event={event}
                finalizeEvent={this.finalizeEvent}
                refreshEventsForIteration={this.refreshEventsForIteration}
                notifyError={this.notifyError}
                notifySuccess={this.notifySuccess}
            />
        );
    };

    renderBingoSquares = () => {
        const {
            bingoSquaresByEvent = {},
            category,
            event,
            isAuthenticated,
            iteration,
            partner,
            sponsorsByPartner = {},
        } = this.state;
        if (!isAuthenticated) {
            return null;
        }

        const queryParams = queryString.parse(window.location.search) || {};
        const { event: eventId } = queryParams;

        if (!eventId) {
            return <Redirect to="/categories" />;
        }

        const bingoSquares = bingoSquaresByEvent[eventId];

        if (!bingoSquares) {
            this.refreshBingoSquaresForEvent(eventId);
            return null;
        } else if (!event || eventId !== event.id) {
            this.refreshEvent(eventId);
            return null;
        } else if (!iteration || event.iterationId !== iteration.id) {
            this.refreshIteration(event.iterationId);
            return null;
        } else if (!category) {
            this.refreshCategory(iteration.categoryId);
            return null;
        } else if (!partner) {
            this.refreshPartner(category.partnerId);
            return null;
        } else if (!sponsorsByPartner || !sponsorsByPartner[partner.id]) {
            this.refreshSponsorsForPartner(partner.id);
        }

        return (
            <BingoSquares
                category={category}
                iteration={iteration}
                event={event}
                bingoSquares={bingoSquares}
                updateBingoSquare={this.updateBingoSquare}
                batchCreateBingoSquares={this.batchCreateBingoSquares}
                refreshBingoSquares={this.refreshBingoSquaresForEvent}
                notifyError={this.notifyError}
                notifySuccess={this.notifySuccess}
                notifyWarning={this.notifyWarning}
                finalizeEvent={this.finalizeEvent}
                refreshEventsForIteration={this.refreshEventsForIteration}
            />
        );
    };

    renderAnswerPredictions = () => {
        const {
            category,
            event,
            eventGameStats,
            isAuthenticated,
            iteration,
            partner,
            predictionsByEvent = {},
        } = this.state;
        if (!isAuthenticated) {
            return null;
        }

        const queryParams = queryString.parse(window.location.search) || {};
        const { event: eventId } = queryParams;

        if (!eventId) {
            return <Redirect to="/categories" />;
        }

        const predictions = predictionsByEvent[eventId];

        if (!predictions) {
            this.refreshPredictionsForEvent(eventId);
            return null;
        } else if (!event || eventId !== event.id) {
            this.refreshEvent(eventId);
            return null;
        } else if (!iteration || event.iterationId !== iteration.id) {
            this.refreshIteration(event.iterationId);
            return null;
        } else if (!category) {
            this.refreshCategory(iteration.categoryId);
            return null;
        } else if (!partner) {
            this.refreshPartner(category.partnerId);
        }

        return (
            <AnswerPredictions
                batchEditPredictions={this.batchEditPredictions}
                batchManualLockPredictions={this.manualLockPredictions}
                batchUnlockPredictions={this.batchUnlockPredictions}
                category={category}
                deletePrediction={this.deletePrediction}
                event={event}
                eventGameStats={eventGameStats}
                finalizeEvent={this.finalizeEvent}
                iteration={iteration}
                notifyError={this.notifyError}
                notifySuccess={this.notifySuccess}
                notifyWarning={this.notifyWarning}
                partner={partner}
                predictions={predictions}
                refreshData={this.buildGetPredictions(eventId)}
                sendSmsAlert={this.sendSmsAlert}
                setAnswers={this.setAnswers}
                updatePredictionWithEvent={this.updatePredictionWithEvent}
            />
        );
    };

    renderVenues = () => {
        const { partner, venuesByPartner = {} } = this.state;
        const queryParams = queryString.parse(window.location.search) || {};
        const { partner: partnerId } = queryParams;

        if (!partnerId) {
            return <Redirect to="/partners" />;
        }

        const venues = venuesByPartner[partnerId];

        if (!partner) {
            this.refreshPartner(partnerId);
            return null;
        }

        if (!venues) {
            this.refreshVenues(partnerId);
            return null;
        }

        return (
            <Venues
                batchCreateVenues={this.batchCreateVenues}
                notifyError={this.notifyError}
                notifySuccess={this.notifySuccess}
                partner={partner}
                refreshData={this.buildGetVenues(partnerId)}
                venues={venues}
            />
        );
    };

    renderProgressivePolls = () => {
        const {
            category,
            iteration,
            partner,
            progressivePollsByIteration = {},
        } = this.state;

        const queryParams = queryString.parse(window.location.search) || {};
        const { iteration: iterationId } = queryParams;

        if (!iterationId) {
            return <Redirect to="/partners" />;
        }

        if (!iteration || iterationId !== iteration.id) {
            this.refreshIteration(iterationId);
            return null;
        } else if (!category) {
            this.refreshCategory(iteration.categoryId);
            return null;
        } else if (!partner) {
            this.refreshPartner(category.partnerId);
            return null;
        }

        if (!progressivePollsByIteration[iterationId]) {
            this.refreshProgressivePolls(iterationId);
            return null;
        }

        return (
            <ProgressivePolls
                partner={partner}
                category={category}
                iteration={iteration}
                notifyError={this.notifyError}
                notifySuccess={this.notifySuccess}
                onRowsDelete={this.deleteProgressivePolls}
                onSubmit={this.createProgressivePoll}
                onBatchSubmit={this.batchCreateProgressivePolls}
                progressivePolls={progressivePollsByIteration[iterationId]}
                refreshData={this.buildGetProgressivePolls(iterationId)}
            />
        );
    };

    renderTeams = () => {
        const {
            category,
            iteration,
            partner,
            teamsByIteration = {},
        } = this.state;

        const queryParams = queryString.parse(window.location.search) || {};
        const { iteration: iterationId } = queryParams;

        if (!iterationId) {
            return <Redirect to="/partners" />;
        }

        if (!iteration || iterationId !== iteration.id) {
            this.refreshIteration(iterationId);
            return null;
        } else if (!category) {
            this.refreshCategory(iteration.categoryId);
            return null;
        } else if (!partner) {
            this.refreshPartner(category.partnerId);
            return null;
        }

        const teams = teamsByIteration[iterationId];
        if (!teams) {
            this.refreshTeams({ iterationId, partnerId: partner.id });
            return null;
        }

        return (
            <Teams
                partner={partner}
                teams={teams}
                category={category}
                iteration={iteration}
                notifyError={this.notifyError}
                notifySuccess={this.notifySuccess}
                batchCreateTeams={this.batchCreateTeams}
                progressivePolls={teamsByIteration[iterationId]}
                refreshData={() => {
                    this.refreshTeams({ iterationId, partnerId: partner.id });
                }}
            />
        );
    };

    renderTrivia = () => {
        const {
            isAuthenticated,
            partner,
            triviaBucketsByPartner = {},
            triviaQuestionsByPartner = {},
        } = this.state;
        if (!isAuthenticated) {
            return null;
        }
        const queryParams = queryString.parse(window.location.search) || {};
        const { partner: partnerId } = queryParams;

        if (!partnerId) {
            return <Redirect to="/partners" />;
        }

        const triviaQuestions = triviaQuestionsByPartner[partnerId];
        const triviaBuckets = triviaBucketsByPartner[partnerId];

        if (!partner) {
            this.refreshPartner(partnerId);
            return null;
        }
        if (!triviaBuckets) {
            this.refreshTriviaBuckets(partnerId);
            return null;
        }
        if (!triviaQuestions) {
            this.refreshTriviaQuestions(partnerId);
            return null;
        }

        return (
            <Trivia
                batchCreateTriviaQuestions={this.batchCreateTriviaQuestions}
                notifyError={this.notifyError}
                notifySuccess={this.notifySuccess}
                onRowsDelete={this.deleteTriviaQuestions}
                partner={partner}
                questions={triviaQuestionsByPartner[partnerId]}
                refreshData={this.buildGetTriviaQuestions(partnerId)}
                triviaBuckets={triviaBuckets}
            />
        );
    };

    renderSponsors = (routeProps) => {
        const { isAuthenticated, partner, sponsorsByPartner = {} } = this.state;
        if (!isAuthenticated) {
            return null;
        }

        const queryParams = queryString.parse(window.location.search) || {};
        const { partner: partnerId } = queryParams;

        if (!partnerId) {
            return <Redirect to="/categories" />;
        }

        const sponsors = sponsorsByPartner[partnerId];

        if (!sponsors) {
            this.refreshSponsorsForPartner(partnerId);
            return null;
        } else if (!partner) {
            console.log('refreshing partner');
            this.refreshPartner(partnerId);
            return null;
        }
        return (
            <Sponsors
                history={routeProps.history}
                partner={partner}
                sponsors={sponsors}
            />
        );
    };

    buildGetSponsors = (partnerId) => (callback) => {
        this.refreshSponsorsForPartner(partnerId, callback);
    };

    buildGetCodes = (sponsorId, offerId) => (callback) => {
        this.refreshCodesForOffer(sponsorId, offerId, callback);
    };

    renderOffers = () => {
        const { isAuthenticated, partner, sponsorsByPartner = {} } = this.state;
        if (!isAuthenticated) {
            return null;
        }

        const queryParams = queryString.parse(window.location.search) || {};
        const { partner: partnerId, sponsor: sponsorId } = queryParams;

        if (!partnerId || !sponsorId) {
            return <Redirect to="/categories" />;
        }

        const sponsors = sponsorsByPartner[partnerId];
        if (!sponsors) {
            this.refreshSponsorsForPartner(partnerId);
            return null;
        }
        if (!partner) {
            this.refreshPartner(partnerId);
            return null;
        }

        const sponsor = sponsors.find((s) => s.id === sponsorId);
        // Assume sponsor was found

        return (
            <Offers
                createRecord={this.createOffer}
                deleteRecord={this.deleteOffer}
                partner={partner}
                refreshData={this.buildGetSponsors(partnerId)}
                sponsor={sponsor}
                updateRecord={this.updateOffer}
            />
        );
    };

    loadExternalScript(scriptSrc) {
        const script = document.createElement('script');
        script.src = scriptSrc;
        document.body.appendChild(script);
    }

    componentDidMount() {
        this.loadExternalScript('https://apis.google.com/js/api.js');
    }

    renderCodes = () => {
        const {
            codesByOffer = {},
            isAuthenticated,
            partner,
            sponsorsByPartner = {},
        } = this.state;
        if (!isAuthenticated) {
            return null;
        }

        const queryParams = queryString.parse(window.location.search) || {};
        const {
            offer: offerId,
            partner: partnerId,
            sponsor: sponsorId,
        } = queryParams;

        if (!offerId) {
            return <Redirect to="/categories" />;
        }

        const sponsors = sponsorsByPartner[partnerId];
        if (!sponsors) {
            this.refreshSponsorsForPartner(partnerId);
            return null;
        }
        if (!partner) {
            this.refreshPartner(partnerId);
            return null;
        }

        const sponsor = sponsors.find((s) => s.id === sponsorId);
        // Assume sponsor was found

        const offer = sponsor.offers.find((o) => o.id === offerId);
        if (!offer) {
            // TODO: warning?
            return null;
        }

        const codes = codesByOffer[offer.id];
        if (!codes) {
            this.refreshCodesForOffer(sponsor.id, offer.id);
            return null;
        }

        return (
            <Codes
                codes={codes}
                offer={offer}
                partner={partner}
                refreshData={this.buildGetCodes(sponsor.id, offer.id)}
                sponsor={sponsor}
            />
        );
    };

    setIsAuthenticated = (isAuthenticated) => {
        this.setState({ isAuthenticated });
    };

    render() {
        if (window.location.pathname === '/') {
            window.location.pathname = '/partners';
        }

        return (
            <AppContext.Provider
                value={{
                    notifyError: this.notifyError,
                    notifySuccess: this.notifySuccess,
                }}
            >
                <ApiContext.Provider
                    value={{
                        createOfferCodes: this.createOfferCodes,
                        createSponsor: this.createSponsor,
                        deleteSponsor: this.deleteSponsor,
                        deleteSponsorshipUnit: this.deleteSponsorshipUnit,
                        saveSponsorshipUnit: this.saveSponsorshipUnit,
                        updateSponsor: this.updateSponsor,
                    }}
                >
                    <GoogleOAuthProvider clientId={googleClientID}>
                        <CssBaseline />
                        <BrowserRouter>
                            <div>
                                <AppBar
                                    position="static"
                                    style={{ backgroundColor: '#4154af' }}
                                >
                                    <Toolbar>
                                        <Link
                                            style={{ textDecoration: 'none' }}
                                            to="/partners"
                                        >
                                            <Typography
                                                noWrap
                                                style={{ color: 'white' }}
                                                variant="h6"
                                            >
                                                Megaphone Authoring
                                            </Typography>
                                        </Link>
                                        <div className="grow" />
                                        <GoogleAuth
                                            api={this.api}
                                            notifyError={this.notifyError}
                                            setIsAuthenticated={
                                                this.setIsAuthenticated
                                            }
                                        />
                                    </Toolbar>
                                </AppBar>

                                <Route
                                    component={this.renderPartners}
                                    path="/partners"
                                />
                                <Route
                                    component={this.renderCategories}
                                    path="/categories"
                                />
                                <Route
                                    component={this.renderIterations}
                                    path="/iterations"
                                />
                                <Route
                                    component={this.renderEvents}
                                    path="/events"
                                />
                                <Route
                                    component={this.renderPredictions}
                                    path="/predictions"
                                />
                                <Route
                                    component={this.renderNoChildEntriesEvent}
                                    path="/trivia-event"
                                />
                                <Route
                                    component={this.renderNoChildEntriesEvent}
                                    path="/igame-event"
                                />
                                <Route
                                    component={this.renderBingoSquares}
                                    path="/bingo-squares"
                                />
                                <Route
                                    component={this.renderAnswerPredictions}
                                    path="/answer-predictions"
                                />
                                <Route
                                    path="/sponsors"
                                    render={this.renderSponsors}
                                />
                                <Route
                                    path="/trivia"
                                    render={this.renderTrivia}
                                />
                                <Route
                                    path="/venues"
                                    render={this.renderVenues}
                                />
                                <Route
                                    path="/progressive-polls"
                                    render={this.renderProgressivePolls}
                                />
                                <Route
                                    path="/teams"
                                    render={this.renderTeams}
                                />
                                <Route
                                    component={this.renderOffers}
                                    path="/offers"
                                />
                                <Route
                                    component={this.renderCodes}
                                    path="/codes"
                                />
                            </div>
                        </BrowserRouter>
                    </GoogleOAuthProvider>
                </ApiContext.Provider>
            </AppContext.Provider>
        );
    }
}

export default withSnackbar(App);
