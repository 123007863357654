import { Breadcrumbs, Typography } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
    breadcrumbs: BreadcrumbData[];
}

interface BreadcrumbData {
    link?: string;
    text: string;
}

export default function BreadcrumbBar(props: Props) {
    const { breadcrumbs } = props;
    return (
        <Breadcrumbs maxItems={5} separator="›">
            {breadcrumbs.map((breadCrumb) => {
                if (breadCrumb.link) {
                    return (
                        <Link key={breadCrumb.link} to={breadCrumb.link}>
                            {breadCrumb.text}
                        </Link>
                    );
                } else {
                    return (
                        <Typography color="textPrimary" key={breadCrumb.text}>
                            {breadCrumb.text}
                        </Typography>
                    );
                }
            })}
        </Breadcrumbs>
    );
}
