export const BINGO_SCORECARD_SIZE = {
    '5x5': 5,
    '3x3': 3,
} as const;

export type ScorecardSize =
    (typeof BINGO_SCORECARD_SIZE)[keyof typeof BINGO_SCORECARD_SIZE];
export type ScorecardSizeLabel = keyof typeof BINGO_SCORECARD_SIZE;

export const bingoScorecardSizeOptions: Array<{
    value: ScorecardSize;
    label: ScorecardSizeLabel;
}> = (
    Object.entries(BINGO_SCORECARD_SIZE) as Array<
        [ScorecardSizeLabel, ScorecardSize]
    >
).map(([key, value]) => ({ label: key, value }));
