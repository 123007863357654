/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { TimePicker } from '@material-ui/pickers';
import React from 'react';
import { ValidatorComponent } from 'react-material-ui-form-validator';

class ValidatedTimePicker extends ValidatorComponent {
    assignInputRef = (r) => {
        this.input = r;
    };

    render() {
        const {
            error,
            errorMessages,
            helperText,
            requiredError,
            validatorListener,
            validators,
            ...rest
        } = this.props;

        const { isValid } = this.state;

        return (
            <TimePicker
                {...rest}
                error={!isValid || error}
                helperText={(!isValid && this.getErrorMessage()) || helperText}
                mask={[/\d/, /\d/, ':', /\d/, /\d/, ' ', /a|p/i, 'M']}
                ref={this.assignInputRef}
            />
        );
    }
}

export default ValidatedTimePicker;
