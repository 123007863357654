import '../styles/App.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import CheckIcon from '@material-ui/icons/Check';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import Widgets from '@material-ui/icons/Widgets';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { withRouter } from 'react-router-dom';
import titleCase from 'title-case';
import {
    PREDICTION_EVENT_STATES,
    PREDICTION_TYPES,
} from 'traceme-shared/lib/constants';
import { isMultipleChoice } from 'traceme-shared/lib/util';
import BatchUnlockModal from '../components/BatchUnlockModal';
import CrudPage from '../components/CrudPage';
import FeedPrediction from '../components/FeedPredictions/FeedPrediction';
import ManualLockModal from '../components/ManualLockModal';
import SheetImportExportDialog from '../components/SheetImportExportDialog';
import { PAGE_LABEL } from '../service/Constants';
import { SponsorshipUnitType } from '../service/Dto';
import GoogleSheetsApi from '../service/GoogleSheetsApi';
import { groupUnitsByTypeForSponsors } from '../utils/sponsors';
import predictionTableTransformer from '../utils/tables/predictionTableTransformer';
import {
    determineEventState,
    determinePredictionStatus,
    localizeTimeOnly,
    predictionInputFields,
    predictionTranslationInputFields,
    preformPredictionRenderTransform,
    presubmitPredictionTransform,
    unlockTimeSecondsToNiceString,
} from '../utils/util';

class Predictions extends Component {
    static propTypes = {
        batchCreatePredictions: PropTypes.func.isRequired,
        batchManualLockPredictions: PropTypes.func.isRequired,
        batchUnlockPredictions: PropTypes.func.isRequired,
        category: PropTypes.object.isRequired,
        createRecord: PropTypes.func.isRequired,
        deleteRecord: PropTypes.func.isRequired,
        deleteRecords: PropTypes.func.isRequired,
        event: PropTypes.object.isRequired,
        formatDate: PropTypes.func.isRequired,
        iteration: PropTypes.object.isRequired,
        partner: PropTypes.object.isRequired,
        predictions: PropTypes.arrayOf(PropTypes.object).isRequired,
        pullPredictionDataFromFeed: PropTypes.func.isRequired,
        refreshData: PropTypes.func.isRequired,
        sponsors: PropTypes.arrayOf(PropTypes.object),
        updateRecord: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        const { event } = props;
        const { finalized } = event || {};

        const immediateCreate = window.location.search.includes('create');
        const immediateEdit = window.location.search.includes('edit');
        const queryParams = queryString.parse(window.location.search) || {};
        const { edit: predictionIdToEdit } = queryParams;

        this.state = {
            finalized,
            immediateCreate,
            immediateEdit,
            isFeedFromPredictionCreate: false,
            isImportingExporting: false,
            predictionIdToEdit,
            predictionInputPreset: [],
            showBatchUnlockModal: false,
            showFeedAddModal: false,
            showImportExportModal: false,
            showManualLockModal: false,
            sponsorshipUnitsByType: {},
        };
    }

    componentDidMount() {
        this.updateSponsorshipUnits(this.props.sponsors);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.sponsors !== this.props.sponsors) {
            this.updateSponsorshipUnits(this.props.sponsors);
        }
    }

    updateSponsorshipUnits = (sponsors) => {
        this.setState({
            sponsorshipUnitsByType: groupUnitsByTypeForSponsors(sponsors),
        });
    };

    static renderOptionPreview(multilineOptions) {
        return (
            <List dense={true}>
                {multilineOptions.split('\n').map((option, index) => (
                    <ListItem key={index}>{`${index + 1}. ${option}`}</ListItem>
                ))}
            </List>
        );
    }

    // based on prediction figure out if we should show multiline input or not
    static useMultilineOptions(obj) {
        // templated questions should never show multiline input
        if (
            obj.reportingStatType &&
            obj.reportingStatType !== 'manuallyEntered'
        ) {
            return false;
        }

        // if its brand new
        if (!obj.id) {
            return true;
        }

        return false;
    }

    static predictionAnswered(prediction) {
        if (isMultipleChoice(prediction, true)) {
            if (prediction.options.find((o) => o.correct)) {
                return 'yes';
            } else {
                return 'no';
            }
        } else {
            if (prediction.options.every((o) => !!o.answer)) {
                return 'yes';
            } else {
                return 'no';
            }
        }
    }

    eventHasStarted = () => {
        const { event } = this.props;
        const state = determineEventState(event);
        return (
            state === PREDICTION_EVENT_STATES.live ||
            state === PREDICTION_EVENT_STATES.pending ||
            state === PREDICTION_EVENT_STATES.final
        );
    };

    getTableColumns = () => {
        const eventHasStarted = this.eventHasStarted();
        const columns = [
            {
                label: 'Prediction ID',
                name: 'id',
                options: {
                    display: 'false',
                    filter: false,
                },
            },
            {
                label: 'Firestore Location',
                name: 'firestoreLocation',
                options: {
                    display: 'false',
                    filter: false,
                    sort: false,
                },
            },
            {
                label: '#',
                name: 'number',
                options: {
                    filter: false,
                },
            },
            {
                generateFunc: (prediction) =>
                    determinePredictionStatus(prediction),
                label: 'Status',
                name: 'status',
                options: {
                    display: eventHasStarted,
                },
            },
            {
                label: 'Revealed',
                name: 'visible',
                options: {
                    customBodyRender: (value) => {
                        return value === true ? 'Revealed' : 'Unreleased';
                    },
                    display: false,
                    hint: 'Whether prediction has been revealed',
                },
            },
            {
                label: 'Question',
                name: 'text',
                options: {
                    filter: false,
                },
            },
            {
                label: 'Lock Description',
                name: 'lockDescription',
                options: {
                    filter: true,
                },
            },
            {
                label: 'Sub-text',
                name: 'detailsText',
                options: {
                    display: false,
                    filter: false,
                },
            },
            {
                generateFunc: (prediction) => {
                    let displayString = '';
                    prediction.options.forEach((option) => {
                        displayString += `<${option.text}> `;
                    });
                    return displayString;
                },
                label: 'Choices',
                name: 'optionCount',
                options: {
                    display: false,
                    filter: true,
                },
            },
            {
                label: 'Type',
                name: 'type',
                options: {
                    customBodyRender: (type) => {
                        switch (type) {
                            case PREDICTION_TYPES.multipleChoice:
                                return 'MC';
                            // TODO: what is this doing?
                            case PREDICTION_TYPES.bettingSimulation:
                                return 'BET';
                            case PREDICTION_TYPES.fillInTheBlank:
                                return 'FITB';
                            case PREDICTION_TYPES.poll:
                                return 'Poll';
                            default:
                                // some mysterious question type??
                                return '';
                        }
                    },
                    display: false,
                },
            },
            {
                label: 'Release Milestone',
                name: 'releaseMilestone',
                options: {
                    filter: true,
                },
            },
            {
                label: 'Release Time',
                name: 'releaseTime',
                options: {
                    customBodyRender: (value) => {
                        return value
                            ? value.substr(0, 40) +
                                  (value.length > 40 ? '...' : '')
                            : '';
                    },

                    filter: false,
                },
            },
            {
                label: 'Answer Milestone',
                name: 'answerMilestone',
                options: {
                    filter: true,
                },
            },
            {
                label: 'Answer Time',
                name: 'answerTime',
                options: {
                    customBodyRender: (value) => {
                        return value
                            ? value.substr(0, 40) +
                                  (value.length > 40 ? '...' : '')
                            : '';
                    },

                    filter: false,
                },
            },
            {
                label: 'Unlocked Time',
                name: 'timeIntervalSeconds',
                options: {
                    customBodyRender: (value) => {
                        return unlockTimeSecondsToNiceString(value);
                    },
                    hint: 'How long players have to answer',
                },
            },
            {
                label: 'Lock Date',
                name: 'lockDate',
                options: {
                    customBodyRender: (date) => localizeTimeOnly(date),
                    display: false,
                    filter: false,
                },
            },
            {
                label: 'Notes',
                name: 'notes',
                options: {
                    customBodyRender: (value) => {
                        return value
                            ? value.substr(0, 40) +
                                  (value.length > 40 ? '...' : '')
                            : '';
                    },
                    display: false,
                    filter: false,
                },
            },
            {
                label: 'Points',
                name: 'totalPointValue',
                options: {
                    customBodyRender: (totalPointValue) =>
                        numeral(totalPointValue).format('0a'),
                    display: true,
                    filter: true,
                },
            },
            {
                generateFunc: Predictions.predictionAnswered,
                label: 'Answered',
                name: 'answered',
                options: {
                    display: false,
                },
            },
            {
                label: 'Players',
                name: 'totalUsersCount',
                options: {
                    display: false,
                    filter: false,
                },
            },
        ];
        return columns;
    };

    displayBatchRevealConfirmation = (predictionsToUnlock) => {
        const longestBatchUnlockTime = predictionsToUnlock.reduce(
            (longest, current) => {
                if (current.timeIntervalSeconds > longest) {
                    longest = current.timeIntervalSeconds;
                }
                return longest;
            },
            predictionsToUnlock[0].timeIntervalSeconds,
        );

        this.setState({
            longestBatchUnlockTime,
            predictionsToUnlock,
            showBatchUnlockModal: true,
        });
    };

    static getDerivedStateFromProps(nextProps, state) {
        const { predictions } = nextProps;

        if (!state.originals) {
            state.originals = {};
        }

        predictions.forEach((prediction) => {
            if (!state[prediction.id]) {
                state[prediction.id] = prediction.text;
            }
            if (!state.originals[prediction.id]) {
                state.originals[prediction.id] = prediction.text;
            }
        });
        return state;
    }

    regeneratePredictions = (template) => async () => {
        const { category, event, generatePredictions, refreshData } =
            this.props;
        const { type } = category;
        const { id: eventId } = event;
        await generatePredictions(eventId, type, template);
        refreshData();
    };

    confirmRegeneratePredictions = () => {
        const { event, notifyWarning, templates } = this.props;
        const { type } = event;
        if (type !== 'NBA') {
            return notifyWarning(
                'This Event was authored as a manually verified popup',
            );
        }
        confirmAlert({
            buttons: templates
                .map((template) => {
                    return {
                        label: template,
                        onClick: this.regeneratePredictions(template),
                    };
                })
                .concat([
                    {
                        label: 'Random',
                        onClick: this.regeneratePredictions(),
                    },
                    {
                        label: 'Cancel',
                        onClick: () => {},
                    },
                ]),
            message: 'Generate Prediction Questions for this Event',
            title: 'Generate Questions',
        });
    };

    updatePrediction = (predictionId, text) => {
        this.setState({ [predictionId]: text });
    };

    // If create happened from answering page, we want to go back to the milestone we were initially at.
    getMilestoneQueryParam = () => {
        const query = window.location.search;
        const parsedQuery = queryString.parse(query);
        const milestoneParam =
            parsedQuery['milestone'] !== undefined
                ? parsedQuery['milestone']
                : 0;
        return parseInt(milestoneParam, 10);
    };

    navigateToAnswerPredictions = () => {
        const { event, history } = this.props;
        const milestone = this.getMilestoneQueryParam();
        history.push(
            `/answer-predictions?event=${event.id}&milestone=${milestone}`,
        );
    };

    setRowProps = (row) => {
        const { category } = this.props;
        const { eventMilestones } = category;
        let textColor = '#000000';
        let textWeight = 'normal';

        // de-emphasize answered questions to make table easier to scan
        if (row.answered.toLowerCase() === 'yes') {
            textColor = '#616161';
            textWeight = '100';
        }

        if (!eventMilestones) {
            return {
                style: {
                    backgroundColor: '#ffffff',
                    color: textColor,
                    fontWeight: textWeight,
                },
            };
        }

        // Zebra stripe the table with alternating colors
        const bgColor =
            eventMilestones.indexOf(row.releaseMilestone) % 2 === 0
                ? '#f2f2f2'
                : '#ffffff';

        return {
            style: {
                backgroundColor: bgColor,
                color: textColor,
                fontWeight: textWeight,
            },
        };
    };

    handleCloseUnlockModal = () => {
        this.setState({
            showBatchUnlockModal: false,
        });
    };

    handleBatchUnlockResponse = (success, error) => {
        const { notifyError, notifySuccess, refreshData } = this.props;

        if (success) {
            notifySuccess('Successfully Batch Unlocked');
        } else {
            notifyError(`Failed to batch unlock ${error.message || error}`);
        }
        refreshData();
        this.setState({
            showBatchUnlockModal: false,
        });
    };

    renderBatchUnlockModal = () => {
        const { batchUnlockPredictions, event } = this.props;
        const { longestBatchUnlockTime, predictionsToUnlock } = this.state;
        return (
            <BatchUnlockModal
                batchUnlockPredictions={batchUnlockPredictions}
                eventId={event.id}
                handleBatchUnlockResponse={this.handleBatchUnlockResponse}
                handleCloseUnlockModal={this.handleCloseUnlockModal}
                longestBatchUnlockTime={longestBatchUnlockTime}
                predictions={predictionsToUnlock}
            />
        );
    };

    renderManualLockModal = () => {
        const { batchManualLockPredictions, event } = this.props;
        const { predictionsToLock } = this.state;
        const predictionsIdsToLock = predictionsToLock.map(
            (prediction) => prediction.id,
        );
        return (
            <ManualLockModal
                batchManualLockPredictions={batchManualLockPredictions}
                eventId={event.id}
                handleCloseLockModal={this.handleCloseManualLockModal}
                handleManualLockResponse={this.handleBatchLockResponse}
                predictionIdsToLock={predictionsIdsToLock}
            />
        );
    };

    handleCloseManualLockModal = () => {
        this.setState({
            showManualLockModal: false,
        });
    };

    handleBatchLockResponse = async (success, error) => {
        const { notifyError, notifySuccess, refreshData } = this.props;

        if (success) {
            notifySuccess('Successfully Locked');
        } else {
            notifyError(`Failed to manual lock ${error.message || error}`);
        }
        refreshData();
        this.setState({
            showManualLockModal: false,
        });
    };

    displayManualLockModal = (predictionsToLock) => {
        this.setState({
            predictionsToLock,
            showManualLockModal: true,
        });
    };

    exportGoogleSheet = async (googleSheetId) => {
        const { notifyError, notifySuccess, predictions, refreshData } =
            this.props;
        if (predictions.length <= 0) {
            notifyError('There are no predictions to export!');
            return;
        }

        this.setState({
            isImportingExporting: true,
        });

        const gapi = new GoogleSheetsApi();
        await gapi.initialize();

        const table = predictionTableTransformer.toTable(predictions);

        try {
            await gapi.writeToSheet(googleSheetId, table);
            notifySuccess('Succesfully Exported Predictions');
        } catch (error) {
            notifyError(`Error exporting predictions: ${error.status}`);
        }
        await refreshData();
        this.setState({
            isImportingExporting: false,
            showImportExportModal: false,
        });
    };

    importGoogleSheet = async (googleSheetId) => {
        const { notifyError, notifySuccess, predictions, refreshData } =
            this.props;
        if (predictions.length > 0) {
            notifyError('Please delete existing predictions first');
            return;
        }

        this.setState({
            isImportingExporting: true,
        });

        const gapi = new GoogleSheetsApi();
        await gapi.initialize();

        try {
            const response = await gapi.readSheet(googleSheetId);

            const questionsToCreate =
                predictionTableTransformer.fromTable(response);
            await this.batchCreateQuestions(questionsToCreate);
            notifySuccess('Succesfully Imported Predictions');
        } catch (error) {
            const { body } = error;
            if (body) {
                const bodyObj = JSON.parse(body);
                notifyError(
                    `Error importing predictions: ${bodyObj.error.message}`,
                );
            } else {
                notifyError(this.createErrorMessage(error));
            }
        }
        refreshData();
        this.setState({
            isImportingExporting: false,
            showImportExportModal: false,
        });
    };

    batchCreateQuestions = async (predictions) => {
        const { batchCreatePredictions, event } = this.props;
        const bodyData = { eventId: event.id, predictions };
        await batchCreatePredictions(bodyData);
    };

    handleCloseImportExport = () => {
        this.setState({
            showImportExportModal: false,
        });
    };
    handleCloseFeedAdd = () => {
        this.setState({
            showFeedAddModal: false,
        });
    };

    mapFeedDataToPredictionCRUDForm = (apiData) => {
        return Object.keys(apiData).map((key) => {
            return {
                defaultValue: apiData[key],
                name: key,
            };
        });
    };

    handleFeedDataPull = async (props) => {
        try {
            const data = await this.props.pullPredictionDataFromFeed(props);
            const fields = this.mapFeedDataToPredictionCRUDForm(data);
            this.setState({
                immediateCreate: true,
                isFeedFromPredictionCreate: true,
                predictionInputPreset: fields,
                showFeedAddModal: false,
            });
        } catch (error) {
            const { notifyError } = this.props;
            notifyError(error.message);
        }
    };

    removeForceFormOpenEmptyPreset = () => {
        this.setState({
            immediateCreate: false,
            isFeedFromPredictionCreate: false,
            predictionInputPreset: [],
        });
    };

    createErrorMessage = (validationError) => {
        const { dataPath, message, status } = validationError;
        const [questionNum, fieldName] = dataPath
            ? dataPath.split('/').filter((str) => str !== '')
            : [];

        return `Error importing predictions:
                ${questionNum ? `Question ${Number(questionNum) + 1} - ` : ''}
                ${fieldName ? `'${fieldName}'` : ''}
                ${message || status}`;
    };
    render() {
        const { category, event, formatDate, iteration, partner, predictions } =
            this.props;
        const {
            immediateCreate,
            immediateEdit,
            isFeedFromPredictionCreate,
            isImportingExporting,
            predictionIdToEdit,
            showBatchUnlockModal,
            showFeedAddModal,
            showImportExportModal,
            showManualLockModal,
            sponsorshipUnitsByType,
        } = this.state;
        const { firebaseProjectId } = category;
        const { name, startDate } = event;

        const date = formatDate(startDate);
        const state = determineEventState(event);

        const headline = `${name} (${titleCase(state)})`;
        const subheadline = `${date}`;

        const formCloseCallback =
            (immediateCreate || immediateEdit) && !isFeedFromPredictionCreate
                ? this.navigateToAnswerPredictions
                : undefined;

        const breadCrumbs = [
            { link: '/partners', text: 'Partners' },
            {
                link: `/categories?partner=${category.partnerId}`,
                text: `${category.partnerId}'s Categories`,
            },
            {
                link: `/iterations?category=${category.id}`,
                text: `${category.name}'s Iterations`,
            },
            {
                link: `/events?iteration=${iteration.id}`,
                text: `${iteration.name}'s Events`,
            },
            { link: '', text: `${event.name}'s Predictions` },
        ];

        const secondaryActions = [
            {
                icon: <KeyboardArrowUp />,
                name: 'Import / Export',
                onClick: () => {
                    this.setState({ showImportExportModal: true });
                },
            },
            {
                icon: <Widgets />,
                name: 'Add a prediction from feed',
                onClick: () => {
                    this.setState({ showFeedAddModal: true });
                },
            },
        ];

        // Adds a second FAB button for this frequently-used action.
        const alternativeActions = [
            {
                icon: <CheckIcon />,
                name: 'Answer',
                onClick: this.navigateToAnswerPredictions,
            },
        ];

        const inputFields = predictionInputFields({
            category,
            dataPreset: this.state.predictionInputPreset,
            event,
            partner,
            predictions,
            sponsorshipUnits:
                sponsorshipUnitsByType[SponsorshipUnitType.PREDICTION],
        });

        const translationInputFields = predictionTranslationInputFields({
            partner,
        });

        const tableColumns = this.getTableColumns();
        return (
            <div>
                <CrudPage
                    {...this.props}
                    alternativeFabActions={alternativeActions}
                    breadCrumbs={breadCrumbs}
                    data={predictions}
                    displayBatchRevealConfirmation={
                        this.displayBatchRevealConfirmation
                    }
                    displayManualLockModal={this.displayManualLockModal}
                    firebaseProjectId={firebaseProjectId}
                    formCloseCallback={formCloseCallback}
                    headline={headline}
                    immediateCreate={immediateCreate}
                    immediateEdit={immediateEdit}
                    inputFields={inputFields}
                    label={PAGE_LABEL.prediction}
                    parentId={event.id}
                    predictionIdToEdit={predictionIdToEdit}
                    preformRenderTransform={preformPredictionRenderTransform}
                    presubmitTransform={presubmitPredictionTransform}
                    removeForceFormOpenEmptyPreset={
                        this.removeForceFormOpenEmptyPreset
                    }
                    secondaryActions={secondaryActions}
                    setRowProps={this.setRowProps}
                    subheadline={subheadline}
                    supportedLanguages={
                        partner.properties.supportedLanguages || []
                    }
                    tableColumns={tableColumns}
                    translationInputFields={translationInputFields}
                />
                {showBatchUnlockModal && this.renderBatchUnlockModal()}
                {showManualLockModal && this.renderManualLockModal()}
                {showImportExportModal && (
                    <SheetImportExportDialog
                        isImportingExporting={isImportingExporting}
                        onClose={this.handleCloseImportExport}
                        onExport={this.exportGoogleSheet}
                        onImport={this.importGoogleSheet}
                    />
                )}
                {showFeedAddModal && (
                    <FeedPrediction
                        eventId={event.id}
                        handleClose={this.handleCloseFeedAdd}
                        handleFeedDataPull={this.handleFeedDataPull}
                        pullPredictionDataFromFeed={
                            this.props.pullPredictionDataFromFeed
                        }
                    />
                )}
            </div>
        );
    }
}

export default withRouter(Predictions);
