/**
 * Combine two arrays with possible different length.
 * The result is an array of type A | C which length is equal to the destinationArr length
 * as it is the main one
 *
 * @param destinationArr A[]
 * @param sourceArr B[]
 * @param combineElements (A, B) => C
 * @param areConnected (A, B) => boolean
 * @returns (A | C)[]
 */
export function attachArrays<A, B, C = A>(
    destinationArr: A[],
    sourceArr: B[],
    combineElements: (a: A, b: B) => C,
    areConnected: (a: A, b: B) => boolean,
): Array<A | C> {
    const sourceArrCopy = [...sourceArr];

    return destinationArr.map((destinationEl) => {
        const correspondingSourceElIndex = sourceArrCopy.findIndex((sourceEl) =>
            areConnected(destinationEl, sourceEl),
        );
        if (correspondingSourceElIndex !== -1) {
            return combineElements(
                destinationEl,
                sourceArrCopy.splice(correspondingSourceElIndex, 1)[0],
            );
        }
        return destinationEl;
    });
}

/**
 * Combine two arrays with the same length into one.
 *
 * @param arr1 A[]
 * @param arr2 B[]
 * @param combineElements (A, B) => C
 * @returns C[]
 */
export function combineArrays<A, B, C>(
    arr1: A[],
    arr2: B[],
    combineElements: (a: A, b: B) => C,
): C[] {
    return arr1.map((a1, i) => combineElements(a1, arr2[i]));
}

export function isEmpty(obj: Object) {
    return Object.keys(obj).length === 0;
}

export const allCellsBeforeColumnAreEmpty =
    (currentColumn: number) =>
    (row: string[]): boolean =>
        row.slice(0, currentColumn).every((cell: string) => cell === '');
