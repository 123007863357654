import {
    Button,
    FormControl,
    Grid,
    IconButton,
    Input,
    InputLabel,
    makeStyles,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import UndoIcon from '@material-ui/icons/Undo';
import React, { ChangeEvent, useContext, useEffect, useReducer } from 'react';
import AceEditor from 'react-ace';
import AppContext from '../../contexts/AppContext';
import {
    OfferDto,
    SponsorshipUnitDto,
    SponsorshipUnitType,
} from '../../service/Dto';
import {
    EventSponsorshipUnitTemplateId,
    PredictionSponsorshipUnitTemplateId,
} from '../../service/SponsorshipTypes';

export enum ModeName {
    'create',
    'edit',
}

type ModeInfo =
    | { name: ModeName.create; unitType: SponsorshipUnitType }
    | {
          name: ModeName.edit;
          savedUnit: SponsorshipUnitDto;
          onDeleteClick: (unit: SponsorshipUnitDto) => void | Promise<void>;
      };

interface Props {
    mode: ModeInfo;
    offers: OfferDto[];
    onSaveClick: (unit: Partial<SponsorshipUnitDto>) => void | Promise<void>;
}

const useStyles = makeStyles((theme: any) => ({
    actionsColumn: {
        alignItems: 'flex-start',
        display: 'flex' as 'flex',
        flexDirection: 'row' as 'row',
    },
    inputField: {
        marginBottom: theme.spacing(2),
    },
    inputLabel: {
        marginTop: theme.spacing(2),
    },
    leftColumn: {
        display: 'flex' as 'flex',
        flexDirection: 'column' as 'column',
    },
    propertiesColumn: {
        display: 'flex' as 'flex',
        flexDirection: 'column' as 'column',
    },
    root: {},
    saveButton: {
        marginTop: theme.spacing(2),
    },
}));

interface State {
    dirty: boolean;
    editedUnit: Partial<SponsorshipUnitDto>;
    templateProperties: string;
}

type Action =
    | { type: 'updateName'; value: string }
    | { type: 'updateBonusDescription'; value: string }
    | { type: 'updateOfferId'; value: string }
    | { type: 'updateTemplateId'; value: string }
    | { type: 'updateTemplateProperties'; value: string }
    | { type: 'onSaved'; savedUnit: SponsorshipUnitDto }
    | { type: 'reset'; savedUnit?: SponsorshipUnitDto };

function reducer(state: State, action: Action): State {
    switch (action.type) {
        case 'updateName':
            state.dirty = true;
            state.editedUnit.name = action.value;
            return { ...state };
        case 'updateBonusDescription':
            state.dirty = true;
            state.editedUnit.bonusDescription = action.value;
            return { ...state };
        case 'updateOfferId':
            state.dirty = true;
            state.editedUnit.offerId = action.value;
            return { ...state };
        case 'updateTemplateId':
            state.dirty = true;
            state.editedUnit.templateId = action.value;
            return { ...state };
        case 'updateTemplateProperties':
            return {
                ...state,
                dirty: true,
                templateProperties: action.value,
            };
        case 'onSaved':
        case 'reset':
            return {
                dirty: false,
                editedUnit: action.savedUnit || {},
                templateProperties: action.savedUnit
                    ? JSON.stringify(
                          action.savedUnit.properties,
                          null,
                          TAB_SIZE,
                      )
                    : EMPTY_TEMPLATE_PROPERTIES,
            };
        default:
            return state;
    }
}

const TAB_SIZE = 2;
const EMPTY_TEMPLATE_PROPERTIES = '{}';

// MenuItems cannot have a value of null, but we need to set the offer id
// to null when nothing is selected.
const NULL_HACK = '';

export default function CreateEditSponsorshipUnit(props: Props) {
    const { mode, offers, onSaveClick } = props;
    const appContext = useContext(AppContext);
    const classes = useStyles();

    // TODO: we should  refactor it? EventSponsorshipUnit can only be with "event" templateId
    const isPredictionUnit =
        (mode.name === ModeName.create &&
            mode.unitType === SponsorshipUnitType.PREDICTION) ||
        (mode.name === ModeName.edit &&
            mode.savedUnit.type === SponsorshipUnitType.PREDICTION);

    const [state, dispatch] = useReducer(reducer, {
        dirty: false,
        editedUnit:
            mode.name === ModeName.edit
                ? mode.savedUnit
                : isPredictionUnit
                  ? {
                        templateId:
                            PredictionSponsorshipUnitTemplateId.DOUBLE_POINT_POWERUP,
                    }
                  : { templateId: EventSponsorshipUnitTemplateId.EVENT },
        templateProperties:
            mode.name === ModeName.edit && mode.savedUnit
                ? JSON.stringify(mode.savedUnit.properties, null, TAB_SIZE)
                : EMPTY_TEMPLATE_PROPERTIES,
    });
    const { editedUnit, templateProperties } = state;

    useEffect(() => {
        if (mode.name !== ModeName.edit) {
            return;
        }
        dispatch({ savedUnit: mode.savedUnit, type: 'onSaved' });
    }, [mode]);

    function onNameChange(event: ChangeEvent<HTMLInputElement>) {
        dispatch({ type: 'updateName', value: event.target.value });
    }
    function onBonusDescriptionChange(event: ChangeEvent<HTMLInputElement>) {
        dispatch({ type: 'updateBonusDescription', value: event.target.value });
    }
    function onOfferIdChange(event: unknown) {
        const e = event as ChangeEvent<HTMLInputElement>;
        dispatch({ type: 'updateOfferId', value: e.target.value });
    }
    function onTemplatePropertiesChange(value: string) {
        dispatch({ type: 'updateTemplateProperties', value });
    }

    function onTemplateIdChange(event: React.ChangeEvent<{ value: unknown }>) {
        dispatch({
            type: 'updateTemplateId',
            value: event.target.value as string,
        });
    }

    function onUndoButtonClick() {
        dispatch({
            savedUnit: mode.name === ModeName.edit ? mode.savedUnit : undefined,
            type: 'reset',
        });
    }
    function onDeleteButtonClick() {
        if (mode.name !== ModeName.edit) {
            return;
        }
        mode.onDeleteClick(mode.savedUnit);
    }

    async function onSaveButtonClick() {
        try {
            const properties = JSON.parse(templateProperties);
            const unitToSave = {
                ...editedUnit,
                properties,
            };

            if (unitToSave.offerId === NULL_HACK) {
                unitToSave.offerId = null;
            }

            // On create, inject the unit type
            if (mode.name === ModeName.create) {
                unitToSave.type = mode.unitType;
            }

            await onSaveClick(unitToSave);
        } catch (error) {
            appContext.notifyError((error as Error).message);
        }
    }

    return (
        <Grid className={classes.root} container={true} spacing={2}>
            <Grid className={classes.leftColumn} item={true}>
                <>
                    <TextField
                        className={classes.inputField}
                        label="Name"
                        onChange={onNameChange}
                        value={editedUnit.name}
                    />
                    <FormControl>
                        <InputLabel>Template Id</InputLabel>
                        <Select
                            onChange={onTemplateIdChange}
                            value={editedUnit.templateId}
                        >
                            {Object.values(
                                isPredictionUnit
                                    ? PredictionSponsorshipUnitTemplateId
                                    : EventSponsorshipUnitTemplateId,
                            ).map((templateId) => (
                                <MenuItem key={templateId} value={templateId}>
                                    {templateId}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {/* TODO: too many conditions in a too general component */}
                    {(isPredictionUnit &&
                        state.editedUnit.templateId ===
                            PredictionSponsorshipUnitTemplateId.PRIZE_REDEMPTION) ||
                        (!isPredictionUnit &&
                            state.editedUnit.templateId ===
                                EventSponsorshipUnitTemplateId.EVENT && (
                                <>
                                    <Typography variant="caption">
                                        Template properties
                                    </Typography>
                                    <AceEditor
                                        fontSize={14}
                                        highlightActiveLine={true}
                                        maxLines={20}
                                        minLines={4}
                                        mode="json"
                                        name="blah2"
                                        onChange={onTemplatePropertiesChange}
                                        placeholder="Properties"
                                        setOptions={{
                                            showLineNumbers: true,
                                            useSoftTabs: true,
                                        }}
                                        showGutter={true}
                                        showPrintMargin={true}
                                        tabSize={TAB_SIZE}
                                        value={templateProperties}
                                    />
                                </>
                            ))}
                    {isPredictionUnit &&
                        state.editedUnit.templateId ===
                            PredictionSponsorshipUnitTemplateId.PRIZE_REDEMPTION && (
                            <>
                                <TextField
                                    className={classes.inputField}
                                    label="Bonus description"
                                    onChange={onBonusDescriptionChange}
                                    value={editedUnit.bonusDescription}
                                />
                                <InputLabel
                                    className={classes.inputLabel}
                                    htmlFor="offer-id"
                                    shrink={true}
                                >
                                    Offer ID
                                </InputLabel>
                                <Select
                                    input={
                                        <Input id="offer-id" name="offerId" />
                                    }
                                    onChange={onOfferIdChange}
                                    value={state.editedUnit.offerId || ''}
                                >
                                    {[
                                        <MenuItem
                                            key="empty"
                                            value={NULL_HACK}
                                        />,
                                    ].concat(
                                        offers.map((offer) => (
                                            <MenuItem
                                                key={offer.id}
                                                value={offer.id}
                                            >
                                                {offer.name}
                                            </MenuItem>
                                        )),
                                    )}
                                </Select>
                            </>
                        )}
                    <Button
                        className={classes.saveButton}
                        color="primary"
                        disabled={!state.dirty}
                        onClick={onSaveButtonClick}
                        variant="contained"
                    >
                        Save
                    </Button>
                </>
            </Grid>
            {mode.name === ModeName.edit && (
                <Grid className={classes.actionsColumn} item={true} xs={1}>
                    <IconButton aria-label="Undo" onClick={onUndoButtonClick}>
                        <UndoIcon />
                    </IconButton>
                    <IconButton
                        aria-label="Delete"
                        onClick={onDeleteButtonClick}
                    >
                        <DeleteIcon />
                    </IconButton>
                </Grid>
            )}
        </Grid>
    );
}
