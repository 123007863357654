export const BINGO_SPORTRADAR_LEAGUE = {
    NBA: 'nba',
} as const;

export type SportRadarLeague =
    (typeof BINGO_SPORTRADAR_LEAGUE)[keyof typeof BINGO_SPORTRADAR_LEAGUE];
export type SportRadarLeagueLabel = keyof typeof BINGO_SPORTRADAR_LEAGUE;

export const sportRadarLeagueOptions: Array<{
    value: SportRadarLeague;
    label: SportRadarLeagueLabel;
}> = (
    Object.entries(BINGO_SPORTRADAR_LEAGUE) as Array<
        [SportRadarLeagueLabel, SportRadarLeague]
    >
).map(([key, value]) => ({ label: key, value }));

export const NBASeason = {
    'Preseason (PRE)': 'PRE',
    'Regular Season (REG)': 'REG',
    'Play in Tournament (PIT)': 'PIT',
    'In-Season Tournament (IST)': 'IST',
    'Postseason (PST)': 'PST',
} as const;

export type SportRadarNBASeason = (typeof NBASeason)[keyof typeof NBASeason];
export type SportRadarNBASeasonLabel = keyof typeof NBASeason;

export const bingoSportRadarNBASeasonOptions: Array<{
    value: SportRadarNBASeason;
    label: SportRadarNBASeasonLabel;
}> = (
    Object.entries(NBASeason) as Array<
        [SportRadarNBASeasonLabel, SportRadarNBASeason]
    >
).map(([key, value]) => ({ label: key, value }));
