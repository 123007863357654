import { SponsorDto, SponsorshipUnitDto } from 'src/service/Dto';

export interface SponsorshipUnitsByType {
    [unitType: string /* SponsorshipUnitType */]: SponsorshipUnitDto[];
}

export function groupUnitsByTypeForSponsor(
    sponsor: SponsorDto,
): SponsorshipUnitsByType | undefined {
    if (!sponsor.units) {
        return;
    }
    const unitsByType: SponsorshipUnitsByType = {};
    sponsor.units.forEach((unit) => {
        if (!unitsByType[unit.type]) {
            unitsByType[unit.type] = [];
        }
        unitsByType[unit.type].push(unit);
    });
    return unitsByType;
}

export function groupUnitsByTypeForSponsors(
    sponsors?: SponsorDto[],
): SponsorshipUnitsByType {
    const sponsorshipUnitsByType: SponsorshipUnitsByType = {};
    if (sponsors) {
        sponsors.forEach((sponsor) => {
            const unitsByTypeForSponsor = groupUnitsByTypeForSponsor(sponsor);
            if (!unitsByTypeForSponsor) {
                return;
            }
            Object.keys(unitsByTypeForSponsor).forEach((unitType) => {
                if (!sponsorshipUnitsByType[unitType]) {
                    sponsorshipUnitsByType[unitType] = [];
                }
                sponsorshipUnitsByType[unitType] = [
                    ...sponsorshipUnitsByType[unitType],
                    ...unitsByTypeForSponsor[unitType],
                ];
            });
        });
    }
    return sponsorshipUnitsByType;
}
