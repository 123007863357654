import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import React, { PureComponent } from 'react';

interface Props {
    onClose: () => void;
    subtitle: JSX.Element;
    title: string;
}

class AuthoringModal extends PureComponent<Props> {
    public render() {
        const { onClose, subtitle, title } = this.props;

        return (
            <Dialog
                aria-labelledby="form-dialog-title"
                onClose={onClose}
                open={true}
            >
                <DialogTitle id="form-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    {subtitle}
                    {this.props.children}
                </DialogContent>
            </Dialog>
        );
    }
}

export default AuthoringModal;
