import styled from '@emotion/styled';
import {
    Button,
    DialogActions,
    FormControlLabel,
    LinearProgress,
    Radio,
    RadioGroup,
    Typography,
} from '@material-ui/core';
import { StyleRules, withStyles } from '@material-ui/core/styles';
import React, { PureComponent } from 'react';
import { Prediction } from '../service/AuthoringApi';
import { unlockTimeSecondsToNiceString } from '../utils/util';
import AuthoringModal from './AuthoringModal';

interface Props {
    batchUnlockPredictions: (eventId: string, bodyData: any) => void;
    classes: any;
    eventId: string;
    handleBatchUnlockResponse: (success: boolean, error: any | null) => void;
    handleCloseUnlockModal: () => void;
    longestBatchUnlockTime?: number;
    predictions: Prediction[];
}

interface State {
    batchingPredictionsInProgress: boolean;
    unlockTimeType: string;
}

export enum UnlockTimeTypes {
    custom = 'custom',
    longest = 'longest',
}

interface BatchUnlockBody {
    predictionIds: string[];
    timeIntervalSeconds?: number;
}

class BatchUnlockModal extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            batchingPredictionsInProgress: false,
            unlockTimeType: UnlockTimeTypes.custom,
        };
    }

    private handleSubmit = async () => {
        const {
            batchUnlockPredictions,
            eventId,
            handleBatchUnlockResponse,
            longestBatchUnlockTime,
            predictions,
        } = this.props;
        const { unlockTimeType } = this.state;

        const predictionIds: string[] = [];
        predictions.forEach((prediction) => {
            if (prediction.id) {
                predictionIds.push(prediction.id);
            }
        });

        const bodyData: BatchUnlockBody = {
            predictionIds,
        };

        if (unlockTimeType === UnlockTimeTypes.longest) {
            bodyData.timeIntervalSeconds = longestBatchUnlockTime;
        }

        this.setState({ batchingPredictionsInProgress: true });
        try {
            await batchUnlockPredictions(eventId, bodyData);
            handleBatchUnlockResponse(true, null);
        } catch (error) {
            handleBatchUnlockResponse(false, error);
        }
    };

    private handleChangeUnlockTimeType = (event: any) => {
        this.setState({
            unlockTimeType: event.target.value,
        });
    };

    private getSubtitleNode = (): JSX.Element => {
        const { predictions } = this.props;
        const numberUnlocks = predictions ? predictions.length : 0;
        return (
            <PredictionContainer>
                <Typography variant="subtitle1">
                    Are you sure you want to unlock and make visible{' '}
                    <b>{numberUnlocks}</b>{' '}
                    {numberUnlocks > 1 ? 'questions' : 'question'}
                </Typography>
                {predictions.map((prediction: Prediction) => (
                    <Typography
                        color="textSecondary"
                        key={prediction.text}
                        variant="caption"
                    >
                        {prediction.number}. {prediction.text}
                        <br />
                    </Typography>
                ))}
            </PredictionContainer>
        );
    };

    public render() {
        const { handleCloseUnlockModal, longestBatchUnlockTime, predictions } =
            this.props;
        const { batchingPredictionsInProgress } = this.state;

        const unlockTimeString = longestBatchUnlockTime
            ? unlockTimeSecondsToNiceString(longestBatchUnlockTime)
            : '';

        const numberUnlocks = predictions ? predictions.length : 0;

        const subtitle = this.getSubtitleNode();

        return (
            <AuthoringModal
                onClose={handleCloseUnlockModal}
                subtitle={subtitle}
                title="Batch Reveal"
            >
                {longestBatchUnlockTime && (
                    <DialogActions>
                        <RadioGroup
                            aria-label="position"
                            name="position"
                            onChange={this.handleChangeUnlockTimeType}
                            row={true}
                            value={this.state.unlockTimeType}
                        >
                            <FormControlLabel
                                control={<Radio color="primary" />}
                                label={
                                    longestBatchUnlockTime
                                        ? `Unlock with ${unlockTimeString} unlock time for all questions`
                                        : ''
                                }
                                labelPlacement="end"
                                value={UnlockTimeTypes.longest}
                            />
                            <FormControlLabel
                                control={<Radio color="primary" />}
                                label="Unlock questions with their own unlock times"
                                labelPlacement="end"
                                value={UnlockTimeTypes.custom}
                            />
                        </RadioGroup>
                    </DialogActions>
                )}
                <DialogActions>
                    <Button
                        color="secondary"
                        disabled={batchingPredictionsInProgress}
                        onClick={handleCloseUnlockModal}
                        variant="contained"
                    >
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        disabled={batchingPredictionsInProgress}
                        onClick={this.handleSubmit}
                        variant="contained"
                    >
                        {`Reveal ${numberUnlocks} questions`}
                    </Button>
                </DialogActions>
                {batchingPredictionsInProgress && <LinearProgress />}
            </AuthoringModal>
        );
    }
}

const PredictionContainer = styled.div`
    border-left: 3px solid #ddd;
    padding: 10px 20px;
    margin: 15px 0px;
`;

const styles = () => ({}) as StyleRules;

export default withStyles(styles)(BatchUnlockModal);
