export const PREDICTION_AD_PLACEMENT = {
    Above: 'above',
    Below: 'below',
    Inline: 'inline',
} as const;

export type PredictionAdPlacement =
    (typeof PREDICTION_AD_PLACEMENT)[keyof typeof PREDICTION_AD_PLACEMENT];
export type PredictionAdPlacementLabel = keyof typeof PREDICTION_AD_PLACEMENT;

export const predictionAdPlacementOptions: Array<{
    value: PredictionAdPlacement;
    label: PredictionAdPlacementLabel;
}> = (
    Object.entries(PREDICTION_AD_PLACEMENT) as Array<
        [PredictionAdPlacementLabel, PredictionAdPlacement]
    >
).map(([key, value]) => ({ label: key, value }));
