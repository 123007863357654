/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { DateTimePicker } from '@material-ui/pickers';
import React from 'react';
import { ValidatorComponent } from 'react-material-ui-form-validator';
import { timeFormatFunc } from '../utils/util';

class ValidatedDateTimePicker extends ValidatorComponent {
    assignInputRef = (r) => {
        this.input = r;
    };

    render() {
        const {
            error,
            errorMessages,
            helperText,
            requiredError,
            validatorListener,
            validators,
            ...rest
        } = this.props;

        const { isValid } = this.state;

        return (
            <DateTimePicker
                {...rest}
                error={!isValid || error}
                helperText={(!isValid && this.getErrorMessage()) || helperText}
                labelFunc={timeFormatFunc}
                ref={this.assignInputRef}
            />
        );
    }
}

export default ValidatedDateTimePicker;
