export const AD_SIZING_POLICY = {
    'CMS - "Read More" Button': 'read more',
    'CMS - Full Height': 'auto',
    'Images and Videos': 'preserve aspect ratio',
    'Other Content, fixed height': 'cut off',
} as const;

export type AdSizingPolicy =
    (typeof AD_SIZING_POLICY)[keyof typeof AD_SIZING_POLICY];
export type AdSizingPolicyLabel = keyof typeof AD_SIZING_POLICY;

export const AdSizingPolicyOptions: Array<{
    value: AdSizingPolicy;
    label: AdSizingPolicyLabel;
}> = (
    Object.entries(AD_SIZING_POLICY) as Array<
        [AdSizingPolicyLabel, AdSizingPolicy]
    >
).map(([key, value]) => ({ label: key, value }));
