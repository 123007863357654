import styled from '@emotion/styled';
import { Checkbox } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionActions from '@material-ui/core/AccordionActions';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import UndoIcon from '@material-ui/icons/Undo';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import WarningIcon from '@material-ui/icons/Warning';
import classNames from 'classnames';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import Countdown from 'react-countdown-now';
import { PREDICTION_TYPES } from 'traceme-shared/lib/constants';
import { isMultipleChoice } from 'traceme-shared/lib/util';
import ManualLockModal from '../../components/ManualLockModal';
import { LOCK_TYPES } from '../../service/Dto';
import {
    determinePredictionLockState,
    unlockTimeSecondsToNiceString,
} from '../../utils/util';
import AuthoringModal from '../AuthoringModal';
import TabPanel from '../TabPanel';
import RevealPredictionModal from './RevealPredictionModal';

const styles = (theme) => ({
    answerCount: {
        color: '#808080',
        display: 'inline',
        marginLeft: `${theme.spacing()}px`,
    },
    bottomButtonContainer: {
        justifyContent: 'space-between',
    },
    column: {
        flexBasis: '80%',
    },
    group: {
        margin: `${theme.spacing()}px 0`,
    },
    inline: {
        display: 'inline',
    },
    manualLockBtn: {
        backgroundColor: '#f44336',
        color: '#ffffff',
        marginRight: '10px',
    },
    rightColumn: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginLeft: 'auto',
    },
    rowAnswered: {
        color: '#909090',
        fontWeight: 400,
    },
    rowUnanswered: {
        fontWeight: 500,
    },
    textField: {
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
        width: 200,
    },
});

const extractFitbPendingAnswer = (pendingAnswer, optionId) => {
    if (pendingAnswer) {
        const pendingAnswerOptions = pendingAnswer.options;
        if (pendingAnswerOptions && pendingAnswerOptions[optionId]) {
            return pendingAnswerOptions[optionId].answer;
        }
    }
};

const extractServerAnsweredOptionId = (prediction) => {
    const serverCorrectOption = prediction.options.find(
        (option) => option.correct,
    );
    if (serverCorrectOption) {
        return serverCorrectOption.id;
    }
};

const calcOptionPercent = (option, options) => {
    const total = options.reduce(
        (accumulator, option) => accumulator + option.count,
        0,
    );

    return Math.floor((option.count * 100) / total);
};

class PredictionRow extends Component {
    static propTypes = {
        addToSetAnswerBody: PropTypes.func.isRequired,
        batchEditPredictions: PropTypes.func.isRequired,
        batchManualLockPredictions: PropTypes.func,
        batchUnlockPredictions: PropTypes.func.isRequired,
        classes: PropTypes.object.isRequired,
        currentMilestone: PropTypes.string,
        event: PropTypes.object.isRequired,
        eventMilestones: PropTypes.array,
        giveExpandControlToRow: PropTypes.func.isRequired,
        handleBatchUnlockResponse: PropTypes.func.isRequired,
        manuallyCollapse: PropTypes.bool,
        notifyError: PropTypes.func.isRequired,
        notifySuccess: PropTypes.func.isRequired,
        pendingAnswer: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object,
        ]),
        prediction: PropTypes.object.isRequired,
        predictionIsChecked: PropTypes.bool,
        refreshData: PropTypes.func.isRequired,
        showEditForm: PropTypes.func.isRequired,
        updatePredictionWithEvent: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            checkedPrediction: false,
            disableManualLock: false,
            expanded: false,
            forceDeleteInProgress: false,
            showForceDeleteModal: false,
            showManualLockModal: false,
            showRevealModal: false,
            tabIndex: 0,
        };
    }

    shouldComponentUpdate(nextProps) {
        if (
            this.props.manuallyCollapse === null &&
            nextProps.manuallyCollapse &&
            this.state.expanded
        ) {
            this.setState({ expanded: false });
        }
        if (this.props.predictionIsChecked || nextProps.predictionIsChecked) {
            if (
                this.props.predictionIsChecked &&
                nextProps.predictionIsChecked === undefined
            ) {
                this.setState({ checkedPrediction: false });
            }
        }
        return true;
    }

    resetPrediction = () => {
        const { addToSetAnswerBody, prediction } = this.props;
        if (isMultipleChoice(prediction)) {
            addToSetAnswerBody({
                answerExplanation: prediction.answerExplanation,
                optionId: extractServerAnsweredOptionId(prediction),
                predictionId: prediction.id,
                reset: true,
                type: prediction.type,
            });
        } else {
            prediction.options.forEach((option) => {
                addToSetAnswerBody({
                    answerExplanation: prediction.answerExplanation,
                    optionId: option.id,
                    predictionId: prediction.id,
                    reset: true,
                    type: PREDICTION_TYPES.fillInTheBlank,
                    value: option.answer,
                });
            });
        }
    };

    handleMultipleChoiceChange = (event) => {
        const { addToSetAnswerBody, prediction } = this.props;
        const selectedOptionId = event.target.value;
        addToSetAnswerBody({
            optionId: selectedOptionId,
            predictionId: prediction.id,
            type: prediction.type,
        });
    };

    handleAnswerExplanationChange = (event) => {
        const { addToSetAnswerBody, prediction } = this.props;
        const value = event.target.value;
        addToSetAnswerBody({
            answerExplanation: value,
            predictionId: prediction.id,
            type: prediction.type,
        });
    };

    handleAnswerExplanationtranslationChange = (event, languageCodeId) => {
        const { addToSetAnswerTranslationBody, prediction } = this.props;
        const value = event.target.value;
        addToSetAnswerTranslationBody({
            answerExplanationTranslation: {
                answerExplanation: value,
                languageCodeId,
            },
            predictionId: prediction.id,
            type: prediction.type,
        });
    };

    handleFillInTheBlankChange = (event) => {
        const { addToSetAnswerBody, prediction } = this.props;

        const optionId = event.target.name;
        const value = event.target.value;
        addToSetAnswerBody({
            optionId,
            predictionId: prediction.id,
            type: PREDICTION_TYPES.fillInTheBlank,
            value,
        });
    };

    setPredictionHidden = () => {
        const { prediction, refreshData, updatePredictionWithEvent } =
            this.props;
        const { eventId, id: predictionId, text } = prediction;
        confirmAlert({
            buttons: [
                {
                    label: 'YES',
                    onClick: async () => {
                        await updatePredictionWithEvent(eventId, predictionId, {
                            ...prediction,
                            visible: false,
                        });
                        refreshData();
                    },
                },
                {
                    label: 'NO',
                    onClick: () => {},
                },
            ],
            message: `Hide "${text}" from Players?`,
            title: 'Make Hidden',
        });
    };

    handleCloseBatchManualLockModal = () => {
        this.setState({ showManualLockModal: false });
    };

    handleBatchLockResponse = async (success, error) => {
        const { notifyError, notifySuccess, refreshData } = this.props;

        if (success) {
            notifySuccess('Successfully Locked');
        } else {
            notifyError(`Failed to manual lock ${error.message || error}`);
        }
        refreshData();

        this.setState({
            checkedPrediction: false,
            showManualLockModal: false,
        });
    };

    handleBatchLock = () => {
        this.setState({ showManualLockModal: true });
    };

    renderManualLockModal = () => {
        const { batchManualLockPredictions, event, prediction } = this.props;
        const { id: predictionId } = prediction;

        // ManualLockModal expects an array of prediction Ids
        const predictionIdsToLock = [predictionId];

        return (
            <ManualLockModal
                batchManualLockPredictions={batchManualLockPredictions}
                eventId={event.id}
                handleCloseLockModal={this.handleCloseBatchManualLockModal}
                handleManualLockResponse={this.handleBatchLockResponse}
                predictionIdsToLock={predictionIdsToLock}
            />
        );
    };

    handleCloseRevealModal = () => {
        this.setState({
            showRevealModal: false,
        });
    };

    renderRevealModal = () => {
        const {
            batchUnlockPredictions,
            currentMilestone,
            eventMilestones,
            handleBatchUnlockResponse,
            prediction,
            updatePredictionWithEvent,
        } = this.props;
        return (
            <RevealPredictionModal
                batchUnlockPredictions={batchUnlockPredictions}
                currentMilestone={currentMilestone}
                editPrediction={this.editPrediction}
                eventMilestones={eventMilestones}
                handleCloseModal={this.handleCloseRevealModal}
                handleRevealPredictionResponse={handleBatchUnlockResponse}
                prediction={prediction}
                updatePredictionWithEvent={updatePredictionWithEvent}
            />
        );
    };

    setPredictionVisible = () => {
        this.setState({ showRevealModal: true });
    };

    predictionLock = async () => {
        const { refreshData } = this.props;
        // Callback for countdown component to force render()
        // Need to wait for the prediction state
        // to be reliably "locked"
        await setTimeout(() => {
            refreshData();
            this.setState({ locked: Date() });
        }, 1500);
    };

    renderCountdown = ({ hours, minutes, seconds }) => {
        const hourString = hours > 0 ? `${hours}:` : '';
        return `${hourString}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    renderAnswerLabel = (text, detailString) => {
        const { classes } = this.props;

        return (
            <React.Fragment>
                <Typography className={classes.inline}>{text}</Typography>
                {detailString && (
                    <Typography className={classes.answerCount}>
                        {detailString}
                    </Typography>
                )}
            </React.Fragment>
        );
    };

    editPrediction = () => {
        const { prediction, showEditForm } = this.props;
        showEditForm(prediction);
    };

    handleClickForceDelete = () => {
        this.setState({
            showForceDeleteModal: true,
        });
    };

    renderBottomButtons = (predictionState, modified) => {
        const { classes } = this.props;
        const { disableManualLock } = this.state;
        if (predictionState === 'locked') {
            return (
                <div>
                    <IconButton
                        aria-label="Delete"
                        onClick={this.handleClickForceDelete}
                    >
                        <DeleteIcon />
                    </IconButton>
                    <IconButton
                        aria-label="Edit"
                        className={classes.editIcon}
                        onClick={this.editPrediction}
                    >
                        <EditIcon />
                    </IconButton>
                </div>
            );
        }

        return (
            <>
                <div>
                    <IconButton
                        aria-label="Edit"
                        className={classes.editIcon}
                        onClick={this.editPrediction}
                    >
                        <EditIcon />
                    </IconButton>
                </div>
                <div>
                    {predictionState === 'hidden' && (
                        <Button
                            color="primary"
                            onClick={this.setPredictionVisible}
                            size="small"
                            variant="contained"
                        >
                            Reveal
                        </Button>
                    )}

                    {predictionState === 'unlocked' && !modified && (
                        <Button
                            className={classes.manualLockBtn}
                            color="default"
                            disabled={disableManualLock}
                            onClick={this.handleBatchLock}
                            size="small"
                            variant="contained"
                        >
                            Manual Lock
                        </Button>
                    )}
                    {predictionState !== 'hidden' && !modified && (
                        <Button
                            color="secondary"
                            onClick={this.setPredictionHidden}
                            size="small"
                            variant="contained"
                        >
                            Hide
                        </Button>
                    )}
                </div>
            </>
        );
    };

    renderSummaryIcons = (predictionState, serverAnswer, modified) => {
        const { classes, prediction } = this.props;
        const { lockType, type } = prediction;
        switch (predictionState) {
            case 'hidden':
                return (
                    <Typography className={classes.secondaryHeading}>
                        <Tooltip title="Prediction Hidden">
                            <VisibilityOffIcon color="disabled" />
                        </Tooltip>
                    </Typography>
                );
            case 'locked':
                return (
                    <Typography className={classes.secondaryHeading}>
                        {(serverAnswer || type === PREDICTION_TYPES.poll) &&
                            !modified && (
                                <Tooltip title="Prediction Answered">
                                    <CheckIcon color="primary" />
                                </Tooltip>
                            )}

                        {modified && (
                            <Tooltip title="Prediction has local edits">
                                <CloudUploadIcon color="action" />
                            </Tooltip>
                        )}

                        {serverAnswer && modified && (
                            <Tooltip title="Modifies a previously recorded answer">
                                <WarningIcon color="error" />
                            </Tooltip>
                        )}
                    </Typography>
                );
            case 'unlocked':
                if (lockType === LOCK_TYPES.MANUAL) {
                    return (
                        <Typography className={classes.secondaryHeading}>
                            Manual Lock
                        </Typography>
                    );
                } else {
                    return (
                        <Typography className={classes.secondaryHeading}>
                            <Countdown
                                date={prediction.lockDate}
                                onComplete={this.predictionLock}
                                renderer={this.renderCountdown}
                            />
                            <Tooltip title="Prediction Unlocked">
                                <LockOpenIcon color="action" />
                            </Tooltip>
                        </Typography>
                    );
                }
            default:
                return;
        }
    };

    handleExpandChange = (e, expanded) => {
        const { giveExpandControlToRow } = this.props;
        this.setState({ expanded: expanded });
        giveExpandControlToRow();
    };

    renderDetailText = () => {
        const { prediction } = this.props;

        const {
            answerMilestone,
            answerTime,
            lockType,
            releaseMilestone,
            releaseTime,
            timeIntervalSeconds,
            totalPointValue,
            type,
        } = prediction;

        const unlockDuration =
            unlockTimeSecondsToNiceString(timeIntervalSeconds);

        const predictionState = determinePredictionLockState(prediction);
        const predictionUnlockDuration = `Unlock Duration: ${unlockDuration}`;
        const pointValue = `Points: ${numeral(totalPointValue).format('0a')}`;
        const lockTypeValue = `Lock Type: ${lockType.toLowerCase()}`;

        const answerMilestoneText = releaseMilestone
            ? ` (${answerMilestone})`
            : '';
        const releaseMilestoneText = releaseMilestone
            ? ` (${releaseMilestone})`
            : '';

        const releaseTimeText = releaseTime
            ? `Release: ${releaseTime}${releaseMilestoneText}`
            : '';
        const answerTimeText = answerTime
            ? `Answer: ${answerTime}${answerMilestoneText}`
            : '';
        const answerTimeColor =
            predictionState === 'locked' ? 'primary' : 'textSecondary';
        const releaseTimeColor =
            predictionState === 'hidden' ? 'error' : 'textSecondary';

        const answerTypographyVariant =
            predictionState === 'hidden' ? 'body2' : 'button';
        const releaseTypographyVariant =
            predictionState === 'hidden' ? 'button' : 'body2';

        return (
            <>
                {predictionState !== 'locked' && (
                    <Typography color="textSecondary" variant="body2">
                        {lockTypeValue}
                    </Typography>
                )}
                {predictionState !== 'locked' &&
                    lockType === LOCK_TYPES.AUTOMATIC && (
                        <Typography color="textSecondary" variant="body2">
                            {predictionUnlockDuration}
                        </Typography>
                    )}
                <Typography
                    color={releaseTimeColor}
                    variant={releaseTypographyVariant}
                >
                    {releaseTimeText}
                </Typography>
                <Typography
                    color={answerTimeColor}
                    variant={answerTypographyVariant}
                >
                    {answerTimeText}
                </Typography>
                <Typography color="textSecondary" variant="body2">
                    {pointValue}
                </Typography>
                {type === PREDICTION_TYPES.poll && (
                    <Typography color="textSecondary" variant="body2">
                        {' (POLL)'}
                    </Typography>
                )}
            </>
        );
    };

    handleCloseDeleteModal = () => {
        this.setState({
            forceDeleteInProgress: false,
            showForceDeleteModal: false,
        });
    };

    handleForceDeletePrediction = (e) => {
        e.preventDefault();
        const { deletePrediction, event, prediction } = this.props;
        const { id } = prediction;
        const { id: parentId } = event;
        this.setState({
            forceDeleteInProgress: true,
        });

        deletePrediction({ id, parentId });
    };

    handleNumberPlayersChange = (e) => {
        this.setState({
            numPlayersDeleteConfirmation: e.target.value,
        });
    };

    renderForceDeleteModal = () => {
        const { forceDeleteInProgress, numPlayersDeleteConfirmation } =
            this.state;
        const { classes, prediction } = this.props;
        const { totalUsersCount } = prediction;
        const subtitleElement = (
            <Typography variant="subtitle1">
                Are you SURE you want to delete this prediction?{' '}
                <b>{totalUsersCount}</b> players have already answered this
                question.
            </Typography>
        );
        return (
            <AuthoringModal
                onClose={this.handleCloseDeleteModal}
                subtitle={subtitleElement}
                title="Delete Live Prediction"
            >
                <form
                    className={classes.formControl}
                    onSubmit={this.handleForceDeletePrediction}
                >
                    <DialogActions>
                        <TextField
                            className={classes.textField}
                            error={
                                numPlayersDeleteConfirmation !== totalUsersCount
                            }
                            id="standard-error"
                            label="Number of players"
                            margin="normal"
                            onChange={this.handleNumberPlayersChange}
                            placeholder="Number of players"
                        />
                        <Button
                            color="secondary"
                            disabled={forceDeleteInProgress}
                            onClick={this.handleCloseDeleteModal}
                            variant="contained"
                        >
                            Cancel
                        </Button>
                        <Button
                            color="primary"
                            disabled={
                                forceDeleteInProgress ||
                                numPlayersDeleteConfirmation !== totalUsersCount
                            }
                            type="submit"
                            variant="contained"
                        >
                            Yes
                        </Button>
                    </DialogActions>
                </form>
                {forceDeleteInProgress && <LinearProgress />}
            </AuthoringModal>
        );
    };

    handlePredictionCheck = (name) => (event) => {
        const { handlePredictionCheck, prediction } = this.props;
        const lockState = determinePredictionLockState(prediction);
        const success = handlePredictionCheck(
            name,
            lockState,
            event.target.checked,
        );
        if (success) {
            this.setState({
                checkedPrediction: event.target.checked,
                disableManualLock: event.target.checked,
            });
        }
    };

    handleTabChange = (event, newValue) => {
        this.setState({
            tabIndex: newValue,
        });
    };

    render() {
        const {
            classes,
            manuallyCollapse,
            notifyError,
            pendingAnswer,
            prediction,
        } = this.props;
        const {
            expanded,
            showForceDeleteModal,
            showManualLockModal,
            showRevealModal,
        } = this.state;
        const {
            detailsText,
            id: predictionId,
            notes,
            totalUsersCount,
            type,
        } = prediction;

        const predictionState = determinePredictionLockState(prediction);

        let fillInTheBlankValues, multipleChoiceValue, serverAnswer;
        let atLeastOneFilled, answered;
        const { answerExplanation: pendingAnswerExplanation } =
            pendingAnswer || {};
        const serverAnswerExplanation = prediction.answerExplanation;
        const answerExplanation =
            pendingAnswerExplanation !== undefined
                ? pendingAnswerExplanation
                : serverAnswerExplanation || '';

        let modified = answerExplanation !== (serverAnswerExplanation || '');

        const answerExplanationTranslations =
            prediction.entityTranslations &&
            prediction.entityTranslations.length &&
            prediction.entityTranslations.map((et) => {
                const pendingAnswerExplanationTranslations =
                    pendingAnswer &&
                    pendingAnswer.entityTranslations &&
                    pendingAnswer.entityTranslations.length &&
                    pendingAnswer.entityTranslations.find(
                        (eat) => eat.languageCodeId === et.languageCodeId,
                    );
                const answerExplanation =
                    pendingAnswerExplanationTranslations !== undefined
                        ? pendingAnswerExplanationTranslations.answerExplanation
                        : et.answerExplanation || '';
                return {
                    answerExplanation,
                    languageCodeId: et.languageCodeId,
                };
            });

        const pendingAnswerOptions = (pendingAnswer || {}).options || {};
        if (isMultipleChoice(prediction, true)) {
            serverAnswer = extractServerAnsweredOptionId(prediction);
            const pendingAnswerOptionId = Object.keys(pendingAnswerOptions)
                .length
                ? Object.keys(pendingAnswerOptions)[0]
                : undefined;

            // If multipleChoiceValue is undefined, React throws a warning that the RadioGroup is changing from uncontrolled to controlled.
            // See this: https://stackoverflow.com/questions/44109676/reactjs-warning-textfield-is-changing-an-uncontrolled-input-of-type-text-to-be
            multipleChoiceValue = pendingAnswerOptionId || serverAnswer || '';

            modified =
                modified ||
                ![undefined, serverAnswer].includes(pendingAnswerOptionId);
            answered = !!multipleChoiceValue;
        } else if (prediction.type === PREDICTION_TYPES.fillInTheBlank) {
            const options = prediction.options;
            const serverAnswers = {};
            options.forEach((option) => {
                if (option.answer) {
                    serverAnswers[option.id] = option.answer;
                }
            });
            // just using first one found to indicate if the server had an answer yet
            serverAnswer = Object.values(serverAnswers).length > 0;
            fillInTheBlankValues = [];
            options.forEach((option) => {
                const pendingOption = extractFitbPendingAnswer(
                    pendingAnswer,
                    option.id,
                );
                if (pendingOption === '' || pendingOption) {
                    fillInTheBlankValues[option.id] = pendingOption;
                } else {
                    fillInTheBlankValues[option.id] =
                        serverAnswers[option.id] || '';
                }

                if (fillInTheBlankValues[option.id] !== '') {
                    atLeastOneFilled = true;
                    answered = true;
                }

                if (
                    fillInTheBlankValues[option.id] !==
                    ((serverAnswers || {})[option.id] || '')
                ) {
                    modified = true;
                }
            });
        } else {
            notifyError(
                'Unknown prediction type seen in data from server: ',
                prediction.type,
            );
            return null;
        }

        const disabled =
            predictionState === 'hidden' ||
            (type === PREDICTION_TYPES.poll && answered);

        const showRowAnsweredClass =
            (serverAnswer && !modified) ||
            (prediction.type === PREDICTION_TYPES.poll &&
                predictionState === 'locked');
        const titleClass = classNames({
            [classes.rowAnswered]: showRowAnsweredClass,
            [classes.rowUnanswered]: !showRowAnsweredClass,
            [classes.heading]: true,
        });

        const actuallyExpanded = manuallyCollapse ? false : expanded;

        const translationTabs = [
            {
                label: `Default language: ${prediction.defaultLanguageId}`,
                languageCodeId: prediction.defaultLanguageId,
            },
        ];
        if (
            prediction.entityTranslations &&
            prediction.entityTranslations.length
        ) {
            translationTabs.push(
                ...prediction.entityTranslations.map((et) => ({
                    label: `Translation: ${et.languageCodeId}`,
                    languageCodeId: et.languageCodeId,
                })),
            );
        }

        const tabChange = (el, i) =>
            i === 0
                ? this.handleAnswerExplanationChange
                : (e) =>
                      this.handleAnswerExplanationtranslationChange(
                          e,
                          el.languageCodeId,
                      );

        return (
            <PredictionContainer>
                {(predictionState === 'unlocked' ||
                    predictionState === 'hidden') && (
                    <CheckBoxContainer>
                        <Checkbox
                            checked={this.state.checkedPrediction}
                            onChange={this.handlePredictionCheck(predictionId)}
                            value={predictionId}
                        />
                    </CheckBoxContainer>
                )}
                <Accordion
                    expanded={actuallyExpanded}
                    onChange={this.handleExpandChange}
                    style={{ flexGrow: '1' }}
                >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <div className={classes.column}>
                            <Typography
                                className={titleClass}
                                color={
                                    predictionState === 'unlocked'
                                        ? 'error'
                                        : 'textPrimary'
                                }
                                gutterBottom={true}
                                variant="body1"
                            >
                                {`${prediction.number}. ${prediction.text}`}
                            </Typography>
                            {this.renderDetailText()}
                            {notes && (
                                <Typography
                                    color={answered ? 'textSecondary' : 'error'}
                                    variant="caption"
                                >
                                    {`Notes: ${notes}`}
                                </Typography>
                            )}
                        </div>
                        <div className={classes.rightColumn}>
                            {this.renderSummaryIcons(
                                predictionState,
                                serverAnswer,
                                modified,
                            )}
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div>
                            {expanded && detailsText && (
                                <Typography
                                    color="textSecondary"
                                    variant="body1"
                                >
                                    {detailsText}
                                </Typography>
                            )}
                            {(predictionState === 'locked' ||
                                predictionState === 'unlocked') && (
                                <Typography
                                    component="div"
                                    style={{ marginTop: '5px' }}
                                    variant="caption"
                                >
                                    Total Players: <b>{totalUsersCount}</b>
                                </Typography>
                            )}
                            <FormControl
                                className={classes.formControl}
                                component="fieldset"
                            >
                                {isMultipleChoice(prediction, true) && (
                                    <RadioGroup
                                        className={classes.group}
                                        name={prediction.id}
                                        onChange={
                                            this.handleMultipleChoiceChange
                                        }
                                        value={multipleChoiceValue}
                                    >
                                        {prediction.options.map((option) => {
                                            let countString;
                                            if (option.count) {
                                                const percent =
                                                    calcOptionPercent(
                                                        option,
                                                        prediction.options,
                                                    );
                                                countString = ` (${percent}% / ${option.count} ppl)`;
                                            }

                                            return (
                                                <FormControlLabel
                                                    control={<Radio />}
                                                    disabled={disabled}
                                                    key={`radio-option-${option.id}`}
                                                    label={this.renderAnswerLabel(
                                                        option.text +
                                                            (option.hideOption
                                                                ? ' [hidden]'
                                                                : ''),
                                                        countString,
                                                    )}
                                                    value={option.id}
                                                />
                                            );
                                        })}
                                    </RadioGroup>
                                )}
                                {prediction.type ===
                                    PREDICTION_TYPES.fillInTheBlank &&
                                    prediction.options.map((option) => {
                                        const averageString = option.average
                                            ? `(AVG. ${option.average})`
                                            : '';
                                        return (
                                            <TextField
                                                className={classes.textField}
                                                disabled={disabled}
                                                error={
                                                    fillInTheBlankValues[
                                                        option.id
                                                    ] === '' && atLeastOneFilled
                                                }
                                                helperText={
                                                    fillInTheBlankValues[
                                                        option.id
                                                    ] === '' &&
                                                    atLeastOneFilled &&
                                                    'All options must be filled at once'
                                                }
                                                key={`text-field-${option.id}`}
                                                label={this.renderAnswerLabel(
                                                    option.text,
                                                    averageString,
                                                )}
                                                margin="normal"
                                                multiline
                                                name={option.id}
                                                onChange={
                                                    this
                                                        .handleFillInTheBlankChange
                                                }
                                                value={
                                                    fillInTheBlankValues[
                                                        option.id
                                                    ]
                                                }
                                            />
                                        );
                                    })}
                                {answered && (
                                    <>
                                        <Tabs
                                            indicatorColor="primary"
                                            onChange={this.handleTabChange}
                                            textColor="primary"
                                            value={this.state.tabIndex}
                                        >
                                            {translationTabs.map((el) => {
                                                return (
                                                    <Tab
                                                        key={el.languageCodeId}
                                                        label={
                                                            <div>
                                                                {el.label}
                                                            </div>
                                                        }
                                                    />
                                                );
                                            })}
                                        </Tabs>
                                        {translationTabs.map((el, i) => {
                                            return (
                                                <TabPanel
                                                    index={i}
                                                    key={i}
                                                    value={this.state.tabIndex}
                                                >
                                                    <TextField
                                                        label={`Answer Explanation: ${el.languageCodeId}`}
                                                        margin="normal"
                                                        multiline
                                                        onChange={tabChange(
                                                            el,
                                                            i,
                                                        )}
                                                        value={
                                                            i === 0
                                                                ? answerExplanation
                                                                : answerExplanationTranslations[
                                                                      i - 1
                                                                  ]
                                                                      .answerExplanation
                                                        }
                                                    />
                                                </TabPanel>
                                            );
                                        })}
                                    </>
                                )}
                            </FormControl>
                        </div>
                    </AccordionDetails>
                    {predictionState !== 'locked' && <Divider />}
                    <AccordionActions className={classes.bottomButtonContainer}>
                        {this.renderBottomButtons(predictionState, modified)}
                        {modified && (
                            <IconButton
                                aria-label="Undo"
                                onClick={this.resetPrediction}
                            >
                                <UndoIcon />
                            </IconButton>
                        )}
                    </AccordionActions>
                    {showForceDeleteModal && this.renderForceDeleteModal()}
                    {showRevealModal && this.renderRevealModal()}
                    {showManualLockModal && this.renderManualLockModal()}
                </Accordion>
            </PredictionContainer>
        );
    }
}

const PredictionContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
`;

const CheckBoxContainer = styled.div`
    display: flex;
    justify-content: center;
`;

export default withStyles(styles)(PredictionRow);
