import '../../styles/App.css';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import { SportFeedData } from './FeedPrediction';

interface Props {
    handleChange: (data: SportFeedData) => void;
    feedData: SportFeedData;
}

function Kindred(props: Props) {
    const { feedData, handleChange } = props;

    function changer(key: string) {
        return (event: React.ChangeEvent<{ value: string }>) => {
            handleChange({
                ...feedData,
                [key]: event.target.value,
            });
        };
    }

    return (
        <>
            <FormHelperText>Sports</FormHelperText>
            <FormHelperText>Game group id</FormHelperText>
            <TextField
                fullWidth={true}
                margin="dense"
                onChange={changer('groupId')}
                placeholder="Game group id"
                style={{
                    display: 'block',
                    margin: '0 auto',
                }}
                value={feedData.groupId}
            />
            <FormHelperText>Betoffer id</FormHelperText>
            <TextField
                fullWidth={true}
                margin="dense"
                onChange={changer('id')}
                placeholder="Betoffer Id"
                style={{
                    display: 'block',
                    margin: '0 auto',
                }}
                value={feedData.id}
            />
        </>
    );
}

export default Kindred;
