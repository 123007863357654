import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import CrudPage from '../components/CrudPage';
import config from '../config';
import { PAGE_LABEL } from '../service/Constants';
import { LocaleIds, SponsorshipUnitType } from '../service/Dto';
import {
    AD_SIZING_POLICY,
    AdSizingPolicyOptions,
} from '../utils/ads/adSizingPolicy';
import { AD_CUTOFF_HEIGHT } from '../utils/constants';
import {
    BINGO_SCORECARD_SIZE,
    bingoScorecardSizeOptions,
} from '../utils/event/BingoScorecardSize';
import {
    BINGO_SPORTRADAR_LEAGUE,
    bingoSportRadarNBASeasonOptions,
    NBASeason,
    sportRadarLeagueOptions,
} from '../utils/event/BingoSportRadarUrlConfig';
import { GAME_TYPE, gameTypeOptions } from '../utils/event/GameType';
import {
    IGAME_POINTS_CALC_TYPE,
    igamePointsCalcTypeTypeOptions,
} from '../utils/event/IGamePointsCalcType';
import {
    isNoCountdownTriviaMode,
    isNoPointsTriviaMode,
    TRIVIA_MODE,
    triviaModeOptions,
} from '../utils/event/TriviaMode';
import { groupUnitsByTypeForSponsors } from '../utils/sponsors';
import {
    determineEventState,
    FORM_INPUT_WIDTH,
    localizeDate,
    parseDateString,
    renderPrizeDescription,
    slugify,
} from '../utils/util';

const { predictionsWebRoot } = config;

const isPositiveInteger = (value) => Number.isInteger(value) && value > 0;
const isNonNegativeInteger = (value) => Number.isInteger(value) && value >= 0;

const validateNumberOfTries = ({ fieldErrors, name, newValue }) => {
    if (newValue === 'infinity') {
        return;
    }

    const parsedInt = Number.parseInt(newValue, 10);
    if (Number.isNaN(parsedInt) || parsedInt < 1) {
        fieldErrors[name] = 'should be positive integer or infinity';
    }
};

const validatePointsMultiplier = ({ fieldErrors, name, newValue }) => {
    const parsedFloat = Number.parseFloat(newValue);
    if (Number.isNaN(parsedFloat) || parsedFloat <= 0) {
        fieldErrors[name] = 'should be positive float';
    }
};

const validateBingoGamePoints = ({ fieldErrors, name, newValue }) => {
    const parsedInt = Number.parseInt(newValue, 10);
    if (Number.isNaN(parsedInt) || parsedInt < 0) {
        fieldErrors[name] = 'should be non-negative integer';
    }
};

const validateTriviaAds = ({ fieldErrors, name, newValue }) => {
    if (!newValue) {
        return;
    }
    try {
        JSON.parse(newValue);
    } catch (error) {
        fieldErrors[name] = 'Invalid JSON syntax';
    }
};

function validatePositiveInteger({ fieldErrors, name, newValue }) {
    const newValueInt = Number.parseFloat(newValue, 10);
    if (isPositiveInteger(newValueInt)) {
        return;
    }

    fieldErrors[name] = 'should be positive integer';
}

function validateNonNegativeInteger({ fieldErrors, name, newValue }) {
    const newValueInt = Number.parseFloat(newValue, 10);
    if (isNonNegativeInteger(newValueInt)) {
        return;
    }

    fieldErrors[name] = 'should be positive integer';
}

function validateOptionalNonNegativeInteger({ fieldErrors, name, newValue }) {
    if (!newValue) {
        return;
    }
    validateNonNegativeInteger({ fieldErrors, name, newValue });
}

const validateTriviaPointsPerQuestion = validatePositiveInteger;
const validateTriviaNumberOfQuestions = validatePositiveInteger;
const validateTriviaSecondsPerQuestion = validatePositiveInteger;

const validateTriviaSmsAlertsPosition = validateOptionalNonNegativeInteger;

const validateProgressivePollConfig = ({
    fieldErrors,
    formValues,
    name,
    newValue,
}) => {
    let progressivePollsConfig;

    try {
        progressivePollsConfig = JSON.parse(newValue);
    } catch (error) {
        fieldErrors[name] = 'invalid JSON syntax';
        return;
    }

    if (progressivePollsConfig === null) {
        return;
    }

    if (!isPositiveInteger(progressivePollsConfig.pointsPerPoll)) {
        fieldErrors[name] = 'pointsPerPoll should be positive integer';
        return;
    }

    const { errorMessage, isValidPollPlace } =
        formValues.type === GAME_TYPE['Trivia Game']
            ? {
                  isValidPollPlace: isNonNegativeInteger,
                  errorMessage:
                      'desiredPollPlaces should be a non-empty array of non-negative integers',
              }
            : {
                  isValidPollPlace: isPositiveInteger,
                  errorMessage:
                      'desiredPollPlaces should be a non-empty array of positive integers',
              };

    if (
        !Array.isArray(progressivePollsConfig.desiredPollPlaces) ||
        !progressivePollsConfig.desiredPollPlaces ||
        !progressivePollsConfig.desiredPollPlaces.length ||
        progressivePollsConfig.desiredPollPlaces.some(
            (el) => !isValidPollPlace(el),
        )
    ) {
        fieldErrors[name] = errorMessage;
    }
};

const validateUISettings = ({ fieldErrors, name, newValue }) => {
    const uiKeysRequired = ['backgroundColor', 'textColor'];
    try {
        const ui = JSON.parse(newValue);

        if (ui === null) {
            return;
        }

        uiKeysRequired.some((key) => {
            if (!ui[key]) {
                fieldErrors[name] = `Fields ${uiKeysRequired.join(
                    ', ',
                )} are required`;
            }
        });
    } catch (error) {
        fieldErrors[name] = 'Invalid JSON syntax';
    }
};

const validateActualStartDate = ({
    fieldErrors,
    formValues,
    name,
    newValue,
}) => {
    if (formValues.type !== GAME_TYPE['Prediction Game']) {
        return;
    }

    const startDateIsAfterActualStartDateErrorText =
        '"Actual Start Date" shouldn\'t start before "Start Date"';

    if (name === 'actualStartDate') {
        const startDate = formValues.startDate;

        fieldErrors.startDate = undefined;

        if (!startDate) {
            fieldErrors[name] = '"Start Date" should be set first';
            return;
        }

        if (newValue.isBefore(startDate)) {
            fieldErrors[name] = startDateIsAfterActualStartDateErrorText;
        }
        return;
    }

    if (name === 'startDate') {
        const actualStartDate = formValues.actualStartDate;

        fieldErrors.actualStartDate = undefined;

        if (!actualStartDate) {
            return;
        }

        if (actualStartDate.isBefore(newValue)) {
            fieldErrors[name] = startDateIsAfterActualStartDateErrorText;
        }
    }
};

const updateBingoGameIDsFormValues = ({ formValues }) => {
    formValues.gameId = '';
};

const validateSocialShare = ({ fieldErrors, name, newValue }) => {
    const socialShareKeys = ['title', 'description', 'imageUrl'];

    if (!newValue) {
        return;
    }

    try {
        const socialShare = JSON.parse(newValue);

        if (
            (Object.keys(socialShare).length === socialShareKeys.length &&
                !socialShareKeys.every((key) => key in socialShare)) ||
            Object.keys(socialShare).length > socialShareKeys.length
        ) {
            fieldErrors[name] = `only ${socialShareKeys.join(
                ', ',
            )} fields are allowed`;
            return;
        }

        if (
            Object.values(socialShare).some(
                (value) => typeof value !== 'string',
            )
        ) {
            fieldErrors[name] = `fields ${Object.keys(socialShare).join(
                ', ',
            )} must be strings`;
            return;
        }

        if (Object.values(socialShare).some((value) => value === '')) {
            fieldErrors[name] = `fields ${Object.keys(socialShare).join(
                ', ',
            )} must be non-empty strings`;
        }
    } catch (error) {
        fieldErrors[name] = 'Invalid JSON syntax';
    }
};

class Events extends Component {
    static propTypes = {
        category: PropTypes.object.isRequired,
        createRecord: PropTypes.func.isRequired,
        deleteRecord: PropTypes.func.isRequired,
        duplicateEvent: PropTypes.func.isRequired,
        events: PropTypes.arrayOf(PropTypes.object).isRequired,
        formatDate: PropTypes.func.isRequired,
        iteration: PropTypes.object.isRequired,
        partner: PropTypes.object.isRequired,
        refreshData: PropTypes.func.isRequired,
        sponsors: PropTypes.arrayOf(PropTypes.object),
        sportRadarGameIDs: PropTypes.object.isRequired,
        triviaBuckets: PropTypes.arrayOf(PropTypes.string),
    };

    updateDefaultShareName = ({ formValues, newValue, previousValue }) => {
        const expectedDefaultShareName = `${previousValue}`;

        // determine if share name was already modified, if so don't update it magically
        if (formValues.shareName === expectedDefaultShareName) {
            formValues.shareName = `${newValue}`;
        }
    };

    updateSlug = ({ formValues, newValue }) => {
        const { iteration } = this.props;
        const { startDate: iterationStartDate } = iteration;

        //  only auto update if event isn't visible yet.
        if (
            !formValues.id ||
            !iterationStartDate ||
            parseDateString(iterationStartDate).isAfter(moment())
        ) {
            formValues.slug = slugify(newValue);
        }
    };

    inputFields() {
        const {
            category,
            iteration,
            partner,
            sponsors,
            sportRadarGameIDs,
            triviaBuckets,
        } = this.props;

        const eventSponsorshipUnits =
            groupUnitsByTypeForSponsors(sponsors)[SponsorshipUnitType.EVENT] ||
            [];

        return [
            {
                name: 'DEFAULT LANGUAGE',
                type: 'heading',
            },
            {
                defaultValue:
                    (partner &&
                        partner.properties &&
                        partner.properties.supportedLanguages &&
                        partner.properties.supportedLanguages[0]) ||
                    LocaleIds[0],
                label: 'Default Language',
                name: 'defaultLanguageId',
                options:
                    partner &&
                    partner.properties &&
                    partner.properties.supportedLanguages
                        ? partner.properties.supportedLanguages.map((sl) => ({
                              label: sl,
                              value: sl,
                          }))
                        : [
                              {
                                  label: LocaleIds[0],
                                  value: LocaleIds[0],
                              },
                          ],
                props: {
                    formwidthpercent: FORM_INPUT_WIDTH.third,
                },
                required: true,
                type: 'languageSelect',
            },
            {
                name: 'id',
                type: 'hidden',
            },
            {
                defaultValue: iteration.id,
                label: 'Iteration Id',
                name: 'iterationId',
                required: true,
                type: 'hidden',
            },
            {
                label: 'Name',
                name: 'name',
                onChange: this.updateDefaultShareName,
                props: {
                    autoFocus: true,
                },
                required: true,
                type: 'text',
            },
            {
                label: 'Short Name',
                name: 'shortName',
                onChange: this.updateSlug,
                required: true,
                type: 'text',
            },
            {
                helperTextFunc: (name, value) => {
                    if (value) {
                        // prettier-ignore
                        return `${predictionsWebRoot}/${category.slug}/${iteration.slug}/${slugify(value)}`;
                    }
                },
                label: 'Slug',
                name: 'slug',
                required: true,
                type: 'text',
            },
            {
                label: 'Description (Not currently used)',
                name: 'description',
                required: false,
                type: 'hidden',
            },
            {
                label: 'Share Name',
                name: 'shareName',
                props: {
                    helperText:
                        'Name used in messages when shared through social and SMS',
                },
                required: true,
                type: 'text',
            },
            {
                defaultValue: false,
                label: 'Single event category',
                name: 'categoryPrimary',
                props: {
                    color: 'primary',
                },
                type: 'boolean',
            },
            {
                defaultValue: false,
                label: 'Multi Time Zone',
                name: 'multiTimeZone',
                props: {
                    color: 'primary',
                },
                type: 'boolean',
            },
            {
                defaultValue: true,
                label: 'Visible in app',
                name: 'visible',
                props: {
                    color: 'primary',
                },
                type: 'boolean',
            },
            {
                defaultValue: '',
                helperTextFunc: (name, value) => {
                    if (value.trim() === '') {
                        return '1 per line';
                    } else {
                        return renderPrizeDescription(value);
                    }
                },
                label: 'Prizes',
                name: 'prizes',
                props: {
                    multiline: true,
                },
                required: false,
                type: 'text',
            },
            {
                label: 'Prize Details Markdown',
                name: 'prizeDetailsMarkdown',
                props: {
                    multiline: true,
                },
                required: true,
                shouldDisplay: (obj) => obj.prizes.length > 0,
                type: 'text',
            },
            {
                defaultValue: null,
                label: 'Start Date',
                name: 'startDate',
                props: {
                    helperText: 'When event starts',
                    InputLabelProps: { shrink: true },
                },
                onChange: validateActualStartDate,
                required: false,
                type: 'datetime-local',
            },
            {
                label: 'Event Image URL',
                name: 'eventImageUrl',
                required: false,
                type: 'text',
            },
            {
                label: 'Sponsorship Unit',
                name: 'sponsorshipUnitId',
                options: [{ label: null, value: null }].concat(
                    eventSponsorshipUnits.map((unit) => ({
                        label: unit.name,
                        value: unit.id,
                    })),
                ),
                required: false,
                type: 'select',
            },
            {
                defaultValue: false,
                label: 'Omit the event leaderboard points in parent iteration leaderboard',
                name: 'omitFromIterationLeaderboards',
                props: {
                    color: 'primary',
                },
                type: 'boolean',
            },
            {
                label: 'UI Settings',
                name: 'ui',
                type: 'json',
                defaultValue: null,
                helperTextFunc: () =>
                    `{
                        "backgroundColor": "#ff2315", 
                        "backgroundImgUrl": "https://www.hdsfoundation.org/wp-content/uploads/dark-blue-background-hd-wallpaper-12829.jpg", 
                        "textColor": "#ff5a3c"
                    }`,
                required: false,
                onChange: validateUISettings,
            },
            {
                helperTextFunc: () =>
                    `{
                    "title": "Play Prediction Games",
                    "description": "Official free-to-play game. Predict the action for a chance to win prizes!",
                    "imageUrl": "https://www.example.com/images/image1.jpg"
                }`,
                label: 'Social Share',
                name: 'socialShare',
                onChange: validateSocialShare,
                required: false,
                type: 'json',
            },
            {
                defaultValue: GAME_TYPE['Prediction Game'],
                label: 'Game Type',
                name: 'type',
                options: gameTypeOptions,
                required: true,
                type: 'select',
            },
            {
                name: 'Trivia Game',
                shouldDisplay: (obj) => obj.type === GAME_TYPE['Trivia Game'],
                type: 'heading',
            },
            {
                defaultValue: null,
                label: 'Trivia bucket',
                name: 'triviaBucketId',
                options: triviaBuckets.map((id) => ({
                    label: id,
                    value: id,
                })),
                required: false,
                shouldDisplay: (obj) => obj.type === GAME_TYPE['Trivia Game'],
                type: 'select',
            },
            {
                defaultValue: TRIVIA_MODE['Points Countdown'],
                label: 'Trivia Mode',
                name: 'triviaMode',
                options: triviaModeOptions,
                required: true,
                shouldDisplay: (obj) => obj.type === GAME_TYPE['Trivia Game'],
                type: 'select',
            },
            {
                defaultValue: 10,
                label: 'Number of Questions',
                name: 'triviaNumberOfQuestions',
                shouldDisplay: (obj) => obj.type === GAME_TYPE['Trivia Game'],
                onChange: validateTriviaNumberOfQuestions,
                type: 'number',
            },
            {
                defaultValue: 1000,
                label: 'Points per Question',
                name: 'triviaPointsPerQuestion',
                shouldDisplay: (obj) =>
                    obj.type === GAME_TYPE['Trivia Game'] &&
                    !isNoPointsTriviaMode(obj.triviaMode),
                onChange: validateTriviaPointsPerQuestion,
                type: 'number',
            },
            {
                defaultValue: 20,
                label: 'Seconds per Question',
                name: 'triviaSecondsPerQuestion',
                shouldDisplay: (obj) =>
                    obj.type === GAME_TYPE['Trivia Game'] &&
                    !isNoCountdownTriviaMode(obj.triviaMode),
                onChange: validateTriviaSecondsPerQuestion,
                type: 'number',
            },
            {
                defaultValue: undefined,
                helperTextFunc: () =>
                    '{"1": {"EN": {"duration": 6,"headline": "...", "disclaimer": "...", "iframeHtml": "<iframe width=..."}}}',
                label: 'Ad Interstitials',
                name: 'triviaAds',
                onChange: validateTriviaAds,
                required: false,
                shouldDisplay: (obj) => obj.type === GAME_TYPE['Trivia Game'],
                type: 'json',
            },
            {
                defaultValue: undefined,
                label: 'SMS Alerts Interstitial Position',
                name: 'triviaSmsAlertsPosition',
                onChange: validateTriviaSmsAlertsPosition,
                required: false,
                shouldDisplay: (obj) => obj.type === GAME_TYPE['Trivia Game'],
                type: 'number',
            },
            {
                name: 'Prediction Game',
                shouldDisplay: (obj) =>
                    obj.type === GAME_TYPE['Prediction Game'],
                type: 'heading',
            },
            {
                defaultValue: null,
                helperTextFunc: () =>
                    '{"pointsPerPoll": 1000, "desiredPollPlaces":[2,4]}',
                label: 'Progressive Polls Config',
                name: 'progressivePollsConfig',
                onChange: validateProgressivePollConfig,
                required: false,
                shouldDisplay: (obj) =>
                    obj.type === GAME_TYPE['Prediction Game'] ||
                    (obj.type === GAME_TYPE['Trivia Game'] &&
                        !isNoPointsTriviaMode(obj.triviaMode)),
                type: 'json',
            },
            {
                name: 'IGame',
                shouldDisplay: (obj) => obj.type === GAME_TYPE['IGame'],
                type: 'heading',
            },
            {
                defaultValue: null,
                label: 'Game Iframe Url',
                name: 'igameIframeUrl',
                required: true,
                shouldDisplay: (obj) => obj.type === GAME_TYPE['IGame'],
                type: 'text',
            },
            {
                defaultValue: {},
                label: 'Query Parameters Mapping',
                name: 'igameIframeUrlQueryParamsMapping',
                required: true,
                shouldDisplay: (obj) => obj.type === GAME_TYPE['IGame'],
                type: 'igameIframeUrlQueryParamsMapping',
            },
            {
                defaultValue: 1,
                label: 'Number of Tries',
                name: 'igameNumberOfTries',
                onChange: validateNumberOfTries,
                required: true,
                shouldDisplay: (obj) => obj.type === GAME_TYPE['IGame'],
                props: {
                    helperText: '1, 2, 3...infinity',
                },
                type: 'text',
            },
            {
                label: 'Rules Markdown',
                name: 'igameRulesMarkdown',
                props: {
                    multiline: true,
                },
                required: false,
                shouldDisplay: (obj) => obj.type === GAME_TYPE['IGame'],
                type: 'text',
            },
            {
                defaultValue: false,
                label: 'The igame iframe fill the screen',
                name: 'igameFillTheScreen',
                props: {
                    color: 'primary',
                },
                shouldDisplay: (obj) => obj.type === GAME_TYPE['IGame'],
                type: 'boolean',
            },
            {
                label: 'The igame iframe css width',
                name: 'igameWidth',
                required: false,
                shouldDisplay: (obj) =>
                    obj.type === GAME_TYPE['IGame'] && !obj.igameFillTheScreen,
                props: {
                    helperText: '100%, 540px',
                },
                type: 'text',
            },
            {
                label: 'The igame iframe css height',
                name: 'igameHeight',
                required: false,
                shouldDisplay: (obj) =>
                    obj.type === GAME_TYPE['IGame'] && !obj.igameFillTheScreen,
                props: {
                    helperText: '100%, 540px',
                },
                type: 'text',
            },
            {
                defaultValue: IGAME_POINTS_CALC_TYPE.NO_POINTS,
                label: 'Points Calculation Type',
                name: 'igamePointsCalcType',
                options: igamePointsCalcTypeTypeOptions,
                required: true,
                shouldDisplay: (obj) => obj.type === GAME_TYPE['IGame'],
                type: 'select',
            },
            {
                defaultValue: 1,
                label: 'Points Multiplier',
                name: 'igamePointsMultiplier',
                onChange: validatePointsMultiplier,
                required: true,
                shouldDisplay: (obj) =>
                    obj.type === GAME_TYPE['IGame'] &&
                    obj.igamePointsCalcType !==
                        IGAME_POINTS_CALC_TYPE.NO_POINTS,
                props: {
                    helperText: '1, 2, 3...',
                },
                type: 'text',
            },
            {
                name: 'Bingo Game',
                shouldDisplay: (obj) => obj.type === GAME_TYPE['Bingo Game'],
                type: 'heading',
            },
            {
                defaultValue: 0,
                label: 'Sportradar game reference',
                name: 'sportRadarReferenceReadOnly',
                required: false,
                shouldDisplay: (obj) =>
                    obj.type === GAME_TYPE['Bingo Game'] &&
                    obj.sportRadarReferenceReadOnly,
                type: 'text',
            },
            {
                defaultValue: BINGO_SPORTRADAR_LEAGUE['NBA'],
                label: 'SportRadar League',
                name: 'sportRadarLeague',
                options: sportRadarLeagueOptions,
                required: true,
                shouldDisplay: (obj) =>
                    !obj.sportRadarReferenceReadOnly &&
                    obj.type === GAME_TYPE['Bingo Game'],
                type: 'select',
            },
            {
                defaultValue: NBASeason['Regular Season (REG)'],
                label: 'SportRadar NBA Season',
                name: 'bingoSportRadarSeason',
                options: bingoSportRadarNBASeasonOptions,
                onChange: updateBingoGameIDsFormValues,
                required: true,
                shouldDisplay: (obj) =>
                    !obj.sportRadarReferenceReadOnly &&
                    obj.type === GAME_TYPE['Bingo Game'] &&
                    obj.sportRadarLeague === BINGO_SPORTRADAR_LEAGUE['NBA'],
                type: 'select',
            },
            {
                defaultValue: '',
                label: 'SportRadar GameId',
                name: 'gameId',
                options: (obj) => sportRadarGameIDs[obj.bingoSportRadarSeason],
                required: true,
                shouldDisplay: (obj) =>
                    !obj.sportRadarReferenceReadOnly &&
                    obj.type === GAME_TYPE['Bingo Game'] &&
                    obj.sportRadarLeague === BINGO_SPORTRADAR_LEAGUE['NBA'],
                type: 'select',
            },
            {
                defaultValue: null,
                label: 'Actual Start Date',
                name: 'actualStartDate',
                onChange: validateActualStartDate,
                props: {
                    helperText: 'When match starts',
                    InputLabelProps: { shrink: true },
                },
                required: false,
                shouldDisplay: (obj) =>
                    obj.type === GAME_TYPE['Prediction Game'] ||
                    obj.type === GAME_TYPE['Bingo Game'],
                type: 'datetime-local',
            },
            {
                defaultValue: BINGO_SCORECARD_SIZE['5x5'],
                label: 'Scorecard Size',
                name: 'bingoScorecardSize',
                options: bingoScorecardSizeOptions,
                required: true,
                shouldDisplay: (obj) => obj.type === GAME_TYPE['Bingo Game'],
                type: 'select',
            },
            {
                defaultValue: 0,
                label: 'Points per Bingo',
                name: 'bingoPointsPerBingo',
                onChange: validateBingoGamePoints,
                required: true,
                shouldDisplay: (obj) => obj.type === GAME_TYPE['Bingo Game'],
                type: 'text',
            },
            {
                defaultValue: 0,
                label: 'Points for Participation',
                name: 'bingoPointsForParticipation',
                onChange: validateBingoGamePoints,
                required: true,
                shouldDisplay: (obj) => obj.type === GAME_TYPE['Bingo Game'],
                type: 'text',
            },
            {
                name: 'Ad',
                type: 'heading',
            },
            {
                defaultValue: false,
                label: 'Show Ad',
                name: 'adEnabled',
                props: {
                    color: 'primary',
                },
                type: 'boolean',
            },
            {
                label: 'Iframe HTML',
                name: 'adIframeHtml',
                props: {
                    multiline: true,
                },
                shouldDisplay: (obj) => obj.adEnabled,
                type: 'text',
            },
            {
                defaultValue: AD_SIZING_POLICY['Other Content, fixed height'],
                label: 'Sizing Policy',
                name: 'adSizingPolicy',
                options: AdSizingPolicyOptions,
                shouldDisplay: (obj) => obj.adEnabled,
                type: 'select',
            },
            {
                defaultValue: AD_CUTOFF_HEIGHT,
                label: 'Cut-off Height',
                name: 'adCutOffHeight',
                shouldDisplay: (obj) =>
                    obj.adEnabled &&
                    (obj.adSizingPolicy ===
                        AD_SIZING_POLICY['Other Content, fixed height'] ||
                        obj.adSizingPolicy ===
                            AD_SIZING_POLICY['CMS - "Read More" Button']),
                type: 'number',
            },
            {
                label: 'Headline',
                name: 'adHeadline',
                shouldDisplay: (obj) => obj.adEnabled,
                type: 'text',
            },
            {
                label: 'Disclaimer',
                name: 'adDisclaimer',
                props: {
                    multiline: true,
                },
                shouldDisplay: (obj) => obj.adEnabled,
                type: 'text',
            },
            {
                defaultValue: false,
                label: 'Pin Ad',
                name: 'adPinned',
                props: {
                    color: 'primary',
                },
                shouldDisplay: (obj) =>
                    obj.adEnabled &&
                    obj.adSizingPolicy ===
                        AD_SIZING_POLICY['Images and Videos'],
                type: 'boolean',
            },
        ];
    }

    translationInputFields() {
        const { partner, sponsors } = this.props;
        const eventSponsorshipUnits =
            groupUnitsByTypeForSponsors(sponsors)[SponsorshipUnitType.EVENT] ||
            [];

        return [
            {
                defaultValue: false,
                label: 'Language',
                name: 'translation.languageCodeId',
                options:
                    partner &&
                    partner.properties &&
                    partner.properties.supportedLanguages
                        ? partner.properties.supportedLanguages.map((sl) => ({
                              label: sl,
                              value: sl,
                          }))
                        : [],
                props: {
                    formwidthpercent: FORM_INPUT_WIDTH.third,
                },
                required: true,
                type: 'languageSelect',
            },
            {
                label: 'Short Name',
                name: 'translation.shortName',
                required: false,
                type: 'text',
            },
            {
                label: 'Share Name',
                name: 'translation.shareName',
                props: {
                    helperText:
                        'Name used in messages when shared through social and SMS',
                },
                required: false,
                type: 'text',
            },
            {
                defaultValue: '',
                helperTextFunc: (name, value) => {
                    if (value && value.trim() === '') {
                        return '1 per line';
                    } else {
                        return renderPrizeDescription(value);
                    }
                },
                label: 'Prizes',
                name: 'translation.prizes',
                props: {
                    multiline: true,
                },
                required: false,
                type: 'text',
            },
            {
                label: 'Prize Details Markdown',
                name: 'translation.prizeDetailsMarkdown',
                props: {
                    multiline: true,
                },
                required: false,
                shouldDisplay: (obj) => obj.prizes.length > 0,
                type: 'text',
            },
            {
                label: 'Sponsorship Unit',
                name: 'translation.sponsorshipUnitId',
                options: [{ label: null, value: null }].concat(
                    eventSponsorshipUnits.map((unit) => ({
                        label: unit.name,
                        value: unit.id,
                    })),
                ),
                required: false,
                type: 'select',
            },
            {
                label: 'Event Image URL',
                name: 'translation.eventImageUrl',
                required: false,
                type: 'text',
            },
            {
                label: 'Iframe HTML',
                name: 'translation.adIframeHtml',
                props: {
                    multiline: true,
                },
                shouldDisplay: (obj) => obj.adEnabled,
                type: 'text',
            },
            {
                label: 'Headline',
                name: 'translation.adHeadline',
                shouldDisplay: (obj) => obj.adEnabled,
                type: 'text',
            },
            {
                label: 'Disclaimer',
                name: 'translation.adDisclaimer',
                props: {
                    multiline: true,
                },
                shouldDisplay: (obj) => obj.adEnabled,
                type: 'text',
            },
            {
                label: 'Rules Markdown',
                name: 'translation.igamerulesMarkdown',
                props: {
                    multiline: true,
                },
                required: false,
                shouldDisplay: (obj) => obj.type === GAME_TYPE['IGame'],
                type: 'text',
            },
            {
                helperTextFunc: () =>
                    `{
                    "title": "Play Prediction Games",
                    "description": "Official free-to-play game. Predict the action for a chance to win prizes!",
                    "imageUrl": "https://www.example.com/images/image1.jpg"
                }`,
                label: 'Social Share',
                name: 'translation.socialShare',
                onChange: validateSocialShare,
                required: false,
                type: 'json',
            },
        ];
    }

    static tableColumns = [
        {
            label: 'Event ID',
            name: 'id',
            options: {
                display: false,
                filter: false,
            },
        },
        {
            label: 'Firestore Location',
            name: 'firestoreLocation',
            options: {
                display: false,
                filter: false,
                sort: false,
            },
        },
        {
            label: 'Name',
            name: 'name',
            options: {
                filter: false,
            },
        },
        {
            label: 'Game Type',
            name: 'type',
            options: {
                filter: true,
            },
        },
        {
            label: 'Short Name',
            name: 'shortName',
            options: {
                display: false,
                filter: false,
            },
        },
        {
            label: 'Share Name',
            name: 'shareName',
            options: {
                display: false,
                filter: false,
            },
        },
        {
            label: 'Category Primary',
            name: 'categoryPrimary',
            options: {
                customBodyRender: (value) => {
                    return value === true ? 'Yes' : '';
                },
                display: true,
            },
            props: {
                color: 'primary',
            },
        },
        {
            label: 'Multi Time Zone',
            name: 'multiTimeZone',
            options: {
                display: false,
            },
            props: {
                color: 'primary',
            },
        },
        {
            generateFunc: determineEventState,
            label: 'State',
            name: 'state',
        },
        {
            label: 'Visible',
            name: 'visible',
            options: {
                customBodyRender: (value) => {
                    return value === true ? 'Visible' : 'Hidden';
                },
                hint: 'Whether event is visible to players in app, regardless of start and end dates',
            },
        },
        {
            label: 'Start Date',
            name: 'startDate',
            options: {
                customBodyRender: localizeDate,
                filter: false,
                hint: 'When event starts',
            },
        },
        {
            label: 'End Date',
            name: 'endDate',
            options: {
                customBodyRender: localizeDate,
                display: false,
                filter: false,
            },
        },
        {
            label: 'Actual Start Date',
            name: 'actualStartDate',
            options: {
                customBodyRender: localizeDate,
                filter: false,
                hint: 'When match starts',
            },
        },
        {
            label: 'Players',
            name: 'playerCount',
            options: {
                filter: false,
            },
        },
    ];

    presubmitTransform(formState) {
        const transformedState = { ...formState };

        //#region trivia
        if (transformedState.type === GAME_TYPE['Trivia Game']) {
            transformedState.triviaBucketId =
                transformedState.triviaBucketId || '';

            transformedState.trivia = {
                mode: transformedState.triviaMode,
                numberOfQuestions: Number.parseInt(
                    transformedState.triviaNumberOfQuestions,
                    10,
                ),
                ads: transformedState.triviaAds
                    ? JSON.parse(transformedState.triviaAds)
                    : undefined,
                smsAlertsPosition: transformedState.triviaSmsAlertsPosition
                    ? Number.parseInt(
                          transformedState.triviaSmsAlertsPosition,
                          10,
                      )
                    : undefined,
            };
            if (!isNoCountdownTriviaMode(transformedState.triviaMode)) {
                transformedState.trivia.secondsPerQuestion = Number.parseInt(
                    transformedState.triviaSecondsPerQuestion,
                    10,
                );
            }
            if (!isNoPointsTriviaMode(transformedState.triviaMode)) {
                transformedState.trivia.pointsPerQuestion = Number.parseInt(
                    transformedState.triviaPointsPerQuestion,
                    10,
                );
            }
        } else {
            delete transformedState.triviaBucketId;
        }

        delete transformedState.triviaMode;
        delete transformedState.triviaNumberOfQuestions;
        delete transformedState.triviaPointsPerQuestion;
        delete transformedState.triviaSecondsPerQuestion;
        delete transformedState.triviaAds;
        delete transformedState.triviaSmsAlertsPosition;

        //#endregion trivia

        //#region igame
        const {
            igameFillTheScreen,
            igameHeight,
            igameIframeUrl,
            igameIframeUrlQueryParamsMapping,
            igameNumberOfTries,
            igamePointsCalcType,
            igamePointsMultiplier,
            igameRulesMarkdown,
            igameWidth,
        } = transformedState;

        if (transformedState.type === GAME_TYPE['IGame']) {
            transformedState.igame = {
                iframeUrl: igameIframeUrl,
                iframeUrlQueryParamsMapping: igameIframeUrlQueryParamsMapping,
                ...(igameRulesMarkdown
                    ? { rulesMarkdown: igameRulesMarkdown }
                    : {}),
                // natural number
                numberOfTries:
                    igameNumberOfTries === 'infinity'
                        ? igameNumberOfTries
                        : Number.parseInt(igameNumberOfTries, 10),
                pointsCalcType: igamePointsCalcType,
                ...(igamePointsCalcType !== IGAME_POINTS_CALC_TYPE.NO_POINTS
                    ? {
                          pointsMultiplier: Number.parseFloat(
                              igamePointsMultiplier,
                          ),
                      }
                    : {}),

                ...{
                    iframeDimensions: igameFillTheScreen
                        ? 'fillTheScreen'
                        : {
                              ...(igameWidth
                                  ? {
                                        width: igameWidth,
                                    }
                                  : {}),
                              ...(igameHeight
                                  ? {
                                        height: igameHeight,
                                    }
                                  : {}),
                          },
                },
            };
        }

        // transform ui
        transformedState.ui =
            typeof transformedState.ui === 'string'
                ? JSON.parse(transformedState.ui)
                : transformedState.ui;

        delete transformedState.igameIframeUrl;
        delete transformedState.igameIframeUrlQueryParamsMapping;
        delete transformedState.igameRulesMarkdown;
        delete transformedState.igameNumberOfTries;
        delete transformedState.igamePointsCalcType;
        delete transformedState.igamePointsMultiplier;
        delete transformedState.igameHeight;
        delete transformedState.igameWidth;
        delete transformedState.igameFillTheScreen;
        //#endregion igame

        //#region progressive polls
        if (transformedState.type === GAME_TYPE['Prediction Game']) {
            transformedState.progressivePolls = JSON.parse(
                transformedState.progressivePollsConfig,
            );
        } else if (
            transformedState.type === GAME_TYPE['Trivia Game'] &&
            !isNoPointsTriviaMode(transformedState.trivia.mode)
        ) {
            transformedState.trivia.progressivePollsConfig =
                JSON.parse(transformedState.progressivePollsConfig) ||
                undefined;
        }
        delete transformedState.progressivePollsConfig;
        //#endregion progressive polls

        //#region bingo game
        const {
            bingoPointsForParticipation,
            bingoPointsPerBingo,
            bingoScorecardSize,
            gameId,
            sportRadarLeague,
        } = transformedState;

        const bingoGameId = gameId;
        if (transformedState.type === GAME_TYPE['Bingo Game']) {
            transformedState.bingo = {
                scorecardSize: bingoScorecardSize,
                pointsPerBingo: Number.parseInt(bingoPointsPerBingo, 10),
                pointsForParticipation: Number.parseInt(
                    bingoPointsForParticipation,
                    10,
                ),

                sportRadarLeague: sportRadarLeague,
                gameId: bingoGameId,
            };
        }

        delete transformedState.bingoScorecardSize;
        delete transformedState.bingoPointsPerBingo;
        delete transformedState.bingoPointsForParticipation;

        delete transformedState.sportRadarLeague;
        delete transformedState.gameId;

        //#endregion bingo game

        if (
            transformedState.type !== GAME_TYPE['Prediction Game'] &&
            transformedState.type !== GAME_TYPE['Bingo Game']
        ) {
            delete transformedState.actualStartDate;
        }

        // filter out an empty lines
        transformedState.prizes = (formState.prizes || '')
            .split('\n')
            .map((prize) => prize.trim())
            .filter((prize) => prize);

        if (transformedState.prizes.length === 0) {
            transformedState.prizeDetailsMarkdown = '';
        }
        const uuidLength = 36;

        transformedState.socialShare =
            typeof transformedState.socialShare === 'string'
                ? JSON.parse(transformedState.socialShare)
                : transformedState.socialShare;

        // translation stuff that should be in FormBuilder, not in presubmitTransform of every entity
        const translationEntities = Object.keys(formState).filter((key) =>
            key.startsWith('translation.languageCodeId.'),
        );
        if (translationEntities && translationEntities.length) {
            transformedState.entityTranslations = translationEntities.map(
                (te) => {
                    return {
                        languageCodeId: formState[te],
                        languageIdentity: te.slice(-uuidLength),
                    };
                },
            );

            const translationPrizesMultiline = Object.keys(formState).filter(
                (key) => key.startsWith('translation.prizes'),
            );
            if (
                translationPrizesMultiline &&
                translationPrizesMultiline.length
            ) {
                translationPrizesMultiline.forEach((tpmlKey) => {
                    const translationPrizeMultiline = formState[tpmlKey];
                    const translationEntityIndex =
                        transformedState.entityTranslations.findIndex(
                            (et) =>
                                et.languageIdentity ===
                                tpmlKey.slice(-uuidLength),
                        );
                    transformedState.entityTranslations[
                        translationEntityIndex
                    ] = {
                        ...transformedState.entityTranslations[
                            translationEntityIndex
                        ],
                        prizes: (translationPrizeMultiline || '')
                            .split('\n')
                            .map((prize) => prize.trim())
                            .filter((prize) => prize),
                    };
                    delete formState[tpmlKey];
                    delete transformedState[tpmlKey];
                });
            }

            const translationIgameKeys = Object.keys(formState).filter((key) =>
                key.startsWith('translation.igame'),
            );

            if (translationIgameKeys && translationIgameKeys.length) {
                translationIgameKeys.forEach((translationIGameKey) => {
                    const translationIgameFieldValue =
                        formState[translationIGameKey];

                    if (translationIgameFieldValue?.trim()) {
                        const translationEntityIndex =
                            transformedState.entityTranslations.findIndex(
                                (et) =>
                                    et.languageIdentity ===
                                    translationIGameKey.slice(-uuidLength),
                            );

                        const languageIdentity =
                            transformedState.entityTranslations[
                                translationEntityIndex
                            ].languageIdentity;

                        const regex = new RegExp(
                            `igame(.*?).${languageIdentity}`,
                            'gms',
                        );
                        const translationIgameFieldName =
                            regex.exec(translationIGameKey)[1];

                        transformedState.entityTranslations[
                            translationEntityIndex
                        ] = {
                            ...transformedState.entityTranslations[
                                translationEntityIndex
                            ],

                            igame: {
                                ...('igame' in
                                    transformedState.entityTranslations[
                                        translationEntityIndex
                                    ] &&
                                    transformedState.entityTranslations[
                                        translationEntityIndex
                                    ].igame),

                                [`${translationIgameFieldName}`]:
                                    translationIgameFieldValue,
                            },
                        };
                    }

                    delete formState[translationIGameKey];
                    delete transformedState[translationIGameKey];
                });
            }

            const EventAdTranslationFieldNames = [
                'translation.adHeadline',
                'translation.adDisclaimer',
                'translation.adIframeHtml',
            ];

            const translationEventAdFields = Object.keys(formState).filter(
                (key) =>
                    EventAdTranslationFieldNames.some((fieldName) =>
                        key.startsWith(fieldName),
                    ),
            );

            if (translationEventAdFields && translationEventAdFields.length) {
                translationEventAdFields.forEach((teafKey) => {
                    const translationEventAdField = formState[teafKey];

                    if (!translationEventAdField) {
                        delete formState[teafKey];
                        delete transformedState[teafKey];
                    }
                });
            }

            const translationSocialShareKeys = Object.keys(formState).filter(
                (key) => key.startsWith('translation.socialShare'),
            );

            if (
                translationSocialShareKeys &&
                translationSocialShareKeys.length
            ) {
                translationSocialShareKeys.forEach(
                    (translationSocialShareKey) => {
                        const translationSocialShare =
                            formState[translationSocialShareKey];
                        const translationEntityIndex =
                            transformedState.entityTranslations.findIndex(
                                (et) =>
                                    et.languageIdentity ===
                                    translationSocialShareKey.slice(
                                        -uuidLength,
                                    ),
                            );

                        const socialShare =
                            typeof translationSocialShare === 'string'
                                ? JSON.parse(translationSocialShare)
                                : translationSocialShare;

                        transformedState.entityTranslations[
                            translationEntityIndex
                        ] = {
                            ...transformedState.entityTranslations[
                                translationEntityIndex
                            ],
                            ...(socialShare
                                ? {
                                      socialShare,
                                  }
                                : {}),
                        };
                        delete formState[translationSocialShareKey];
                        delete transformedState[translationSocialShareKey];
                    },
                );
            }
        }

        if (formState.slug) {
            transformedState.slug = slugify(formState.slug);
        }

        return transformedState;
    }

    preformRenderTransform(event) {
        const result = { ...event };
        result.prizes = (event.prizes || []).join('\n');

        //#region trivia
        if (result.type === GAME_TYPE['Trivia Game']) {
            const { trivia } = result;
            result.triviaMode = trivia.mode;
            result.triviaPointsPerQuestion = trivia.pointsPerQuestion;
            result.triviaNumberOfQuestions = trivia.numberOfQuestions;
            result.triviaSecondsPerQuestion = trivia.secondsPerQuestion;

            result.triviaAds = JSON.stringify(result.trivia?.ads, null, 2);
            result.triviaSmsAlertsPosition = trivia.smsAlertsPosition;
        } else {
            result.triviaMode = TRIVIA_MODE['Countdown Decreasing Points'];
            result.triviaPointsPerQuestion = 1000;
            result.triviaNumberOfQuestions = 10;
            result.triviaSecondsPerQuestion = 20;

            result.triviaAds = undefined;
            result.triviaSmsAlertsPosition = undefined;
        }
        //#endregion trivia

        //#region igame
        if (result.type === GAME_TYPE['IGame']) {
            result.igameIframeUrl = result.igame.iframeUrl;
            result.igameIframeUrlQueryParamsMapping =
                result.igame.iframeUrlQueryParamsMapping || {};
            result.igameRulesMarkdown = result.igame.rulesMarkdown || null;
            result.igameNumberOfTries = result.igame.numberOfTries;
            result.igamePointsCalcType = result.igame.pointsCalcType;
            result.igamePointsMultiplier =
                result.igame.pointsCalcType !== IGAME_POINTS_CALC_TYPE.NO_POINTS
                    ? result.igame.pointsMultiplier
                    : null;
            result.igameFillTheScreen =
                result.igame.iframeDimensions === 'fillTheScreen';
            result.igameHeight = result.igame.iframeDimensions
                ? result.igame.iframeDimensions.height
                : '';
            result.igameWidth = result.igame.iframeDimensions
                ? result.igame.iframeDimensions.width
                : '';
        } else {
            // default values
            result.igameIframeUrl = null;
            result.igameIframeUrlQueryParamsMapping = {};
            result.igameRulesMarkdown = null;
            result.igameNumberOfTries = 1;
            result.igamePointsCalcType = IGAME_POINTS_CALC_TYPE.NO_POINTS;
            result.igamePointsMultiplier = 1;
        }
        //#endregion igame

        //#region progressive polls
        if (result.type === GAME_TYPE['Prediction Game']) {
            result.progressivePollsConfig = JSON.stringify(
                result.progressivePolls,
            );
        } else if (
            result.type === GAME_TYPE['Trivia Game'] &&
            !isNoPointsTriviaMode(result.trivia.mode)
        ) {
            result.progressivePollsConfig = result.trivia.progressivePollsConfig
                ? JSON.stringify(result.trivia.progressivePollsConfig)
                : null;
        }
        //#endregion progressive polls

        //#region bingo
        if (result.type === GAME_TYPE['Bingo Game']) {
            result.bingoPointsForParticipation =
                result.bingo.pointsForParticipation;
            result.bingoPointsPerBingo = result.bingo.pointsPerBingo;
            result.bingoScorecardSize = result.bingo.scorecardSize;

            result.sportRadarReferenceReadOnly = `League: ${result.bingo.sportRadarLeague.toUpperCase()}, game id:${
                result.bingo.gameId
            }`;
            result.sportRadarLeague = result.bingo.sportRadarLeague;
            result.gameId = result.bingo.gameId;
        } else {
            // default values
            result.bingoPointsForParticipation = 0;
            result.bingoPointsPerBingo = 0;
            result.bingoScorecardSize = 5;
        }
        //#endregion bingo

        if (event.entityTranslations) {
            result.entityTranslations = event.entityTranslations.map(
                ({ sponsorship, ...et }) => {
                    const igamerulesMarkdown = et.igame?.rulesMarkdown;

                    delete et.igame;

                    if (sponsorship) {
                        return {
                            ...et,
                            sponsorshipUnitId: sponsorship.id,
                            igamerulesMarkdown,
                            // TODO: why is it not here but in the FormBuilder initialFormState ?
                            // prizes: (et.prizes || []).join('\n'),
                        };
                    }
                    return {
                        ...et,
                        igamerulesMarkdown,
                    };
                },
            );
        }

        return result;
    }

    onRowClick = (eventId) => {
        const { events, history } = this.props;

        const foundEvent = events.find((event) => event.id === eventId);

        if (!foundEvent) {
            return;
        }

        switch (foundEvent.type) {
            case 'PREDICTION':
                history.push(`/predictions?event=${eventId}`);
                return;
            case 'TRIVIA':
                history.push(`/trivia-event?event=${eventId}`);
                return;
            case 'IGAME':
                history.push(`/igame-event?event=${eventId}`);
                return;
            case 'BINGO':
                history.push(`/bingo-squares?event=${eventId}`);
                return;
            default:
                console.error(`Unhandled event type (${foundEvent.type})`);
        }
    };

    setRowProps = (row) => {
        if (row.categoryPrimary.toLowerCase() === 'yes') {
            return {
                style: { color: '#c30000' },
            };
        } else if (row.state.toLowerCase() === 'final') {
            return {
                style: { color: '#616161' },
            };
        } else if (
            row.visible.toLowerCase() === 'hidden' ||
            row.visible.toLowerCase() === 'false'
        ) {
            return {
                style: { color: '#949494' },
            };
        } else {
            return {
                style: {},
            };
        }
    };

    render() {
        const { category, events, iteration, partner, refreshData } =
            this.props;
        const { firebaseProjectId } = category;
        const { name } = iteration;
        const headline = `${category.name}: ${name} Events`;
        const breadCrumbs = [
            { link: '/partners', text: 'Partners' },
            {
                link: `/categories?partner=${category.partnerId}`,
                text: `${category.partnerId}'s Categories`,
            },
            {
                link: `/iterations?category=${category.id}`,
                text: `${category.name}'s Iterations`,
            },
            { link: '', text: `${iteration.name}'s events` },
        ];

        return (
            <CrudPage
                {...this.props}
                breadCrumbs={breadCrumbs}
                onRowClick={this.onRowClick}
                data={events}
                firebaseProjectId={firebaseProjectId}
                headline={headline}
                inputFields={this.inputFields()}
                label={PAGE_LABEL.event}
                muiDataTableOptions={{
                    sortOrder: {
                        direction: 'desc',
                        name: 'startDate',
                    },
                }}
                parentId={iteration.id}
                preformRenderTransform={this.preformRenderTransform}
                presubmitTransform={this.presubmitTransform}
                refreshData={refreshData}
                setRowProps={this.setRowProps}
                supportedLanguages={partner.properties.supportedLanguages || []}
                tableColumns={Events.tableColumns}
                translationInputFields={this.translationInputFields()}
            />
        );
    }
}

export default withRouter(Events);
