import Paper from '@material-ui/core/Paper';
import {
    createTheme,
    MuiThemeProvider,
    withStyles,
} from '@material-ui/core/styles';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import MuiDataTable from 'mui-datatables';
import React, { useState } from 'react';
import ActionButton from '../../components/ActionButton';
import BreadcrumbBar from '../../components/BreadcrumbBar';
import { PageHeadline } from '../../components/CrudPage';
import SheetImportExportDialog from '../../components/SheetImportExportDialog';
import { IterationDto, PartnerDto, Team, TeamCreate } from '../../service/Dto';
import GoogleSheetsApi, {
    GoogleSheetsApiError,
} from '../../service/GoogleSheetsApi';
import teamTableTransformer from '../../utils/tables/teamTableTransformer';
import { shouldIgnoreMuiTableRowClick } from '../../utils/tables/util';

const styles = (theme: any) => ({
    paper: {
        padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
    },
});

type Props = {
    partner: PartnerDto;
    iteration: IterationDto;
    teams: Team[];
    batchCreateTeams: ({
        iterationId,
        partnerId,
        teams,
    }: {
        partnerId: string;
        iterationId: string;
        teams: TeamCreate[];
    }) => Promise<void>;
    refreshData: () => Promise<void>;
    notifyError: (message: string) => void;
    notifySuccess: (message: string) => void;

    classes: any;
};

const tableColumns = [
    {
        label: 'Team ID',
        name: 'id',
        options: {
            display: false,
            filter: false,
        },
    },
    {
        label: 'Name',
        name: 'name',
        options: {
            display: true,
            filter: false,
            sort: true,
        },
    },
    {
        label: 'Image Url',
        name: 'imageUrl',
        options: {
            customBodyRender: (value: string | null, tableMeta: any) => {
                return (
                    value && (
                        <img
                            alt={`image for ${tableMeta.rowData[1]}`}
                            height={50}
                            src={value}
                        />
                    )
                );
            },
            display: true,
            filter: false,
            sort: true,
        },
    },
];

const getMuiTheme = () => {
    return createTheme({
        overrides: {
            MuiSvgIcon: {
                fontSizeSmall: {
                    fontSize: '13px',
                    verticalAlign: 'middle',
                },
            },
        },
    });
};

const Teams = ({
    batchCreateTeams,
    classes,
    iteration,
    notifyError,
    notifySuccess,
    partner,
    refreshData,
    teams,
}: Props) => {
    const [showImportExportModal, setShowImportExportModal] = useState(false);
    const [isImportingExporting, setIsImportingExporting] = useState(false);

    const handleExport = async (googleSheetId: string) => {
        setIsImportingExporting(true);

        const gapi = new GoogleSheetsApi();
        await gapi.initialize();

        const table = teamTableTransformer.toTable(teams);

        try {
            await gapi.writeToSheet(googleSheetId, table);
            notifySuccess('Succesfully exported teams');
        } catch (error) {
            if (error instanceof GoogleSheetsApiError) {
                notifyError(`Error exporting teams: ${error.status}`);
            } else {
                notifyError(`Error teams: ${error}`);
            }
        }

        setIsImportingExporting(false);
        setShowImportExportModal(false);
    };

    const handleImport = async (googleSheetId: string) => {
        setIsImportingExporting(true);

        const gapi = new GoogleSheetsApi();
        await gapi.initialize();

        try {
            const response = await gapi.readSheet(googleSheetId);

            const teamsToCreate = teamTableTransformer.fromTable(response);

            await batchCreateTeams({
                iterationId: iteration.id,
                partnerId: partner.id,
                teams: teamsToCreate,
            });
            notifySuccess('Succesfully imported teams');
        } catch (error) {
            if (error instanceof GoogleSheetsApiError) {
                notifyError(`Error importing teams: ${error.status}`);
            } else if (error instanceof Error) {
                const message = `Error importing teams: ${error.message}`;
                notifyError(message);
            }
        }
        await refreshData();
        setIsImportingExporting(false);
        setShowImportExportModal(false);
    };

    const breadCrumbs = [
        { link: '/partners', text: 'Partners' },
        { link: '', text: `${partner.id}'s teams` },
    ];

    const options = {
        filterType: 'dropdown',
        onRowClick: (__: string[], _: any, event: any) => {
            if (shouldIgnoreMuiTableRowClick(event)) {
                return;
            }
        },
        resizableColumns: true,
        responsive: 'simple',
        rowsPerPage: 50,
        rowsPerPageOptions: [10, 20, 30, 40, 50, 75, 100],
    };

    return (
        <div>
            <div>
                <Paper className={classes.paper}>
                    <BreadcrumbBar breadcrumbs={breadCrumbs} />
                    <PageHeadline headline={`${partner.id}: Teams`} />
                </Paper>
            </div>
            <MuiThemeProvider theme={getMuiTheme()}>
                <MuiDataTable
                    columns={tableColumns}
                    data={teams}
                    options={options}
                />
            </MuiThemeProvider>
            <ActionButton
                actions={[
                    {
                        icon: <KeyboardArrowUp />,
                        name: 'Import / Export teams',
                        onClick: () => {
                            setShowImportExportModal(true);
                        },
                    },
                ]}
            />
            {showImportExportModal && (
                <SheetImportExportDialog
                    helperText="Make sure you do not import the teams you already imported!"
                    isImportingExporting={isImportingExporting}
                    onClose={() => setShowImportExportModal(false)}
                    onExport={handleExport}
                    onImport={handleImport}
                />
            )}
        </div>
    );
};

export default withStyles(styles)(Teams);
