import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import { DateTimePicker } from '@material-ui/pickers';
import React, { useState } from 'react';
import {
    BingoSquare,
    BingoSquareUpdateRequest,
    IsoDateString,
} from '../../service/Dto';
import { timeFormatFunc } from '../../utils/util';

type Props = {
    bingoSquare: BingoSquare;
    refreshBingoSquares: (eventId: string) => void;
    updateBingoSquare: (params: {
        id: string;
        eventId: string;
        body: BingoSquareUpdateRequest;
    }) => Promise<void>;
    onClose: () => void;
    notifyError: (message: string) => void;
    notifySuccess: (message: string) => void;
    notifyWarning: (message: string) => void;
    classes: any;
};

type EditState = {
    text: string;
    imageUrl: string;
    occurredDate: IsoDateString | null;
};

const getPatchRequestBody = (
    initial: BingoSquare,
    updatedSquare: EditState,
): BingoSquareUpdateRequest | null => {
    const updatedRequest: BingoSquareUpdateRequest = {};

    if (updatedSquare.text !== initial.text) {
        updatedRequest.text = updatedSquare.text;
    }

    if (updatedSquare.imageUrl !== initial.imageUrl) {
        updatedRequest.imageUrl = updatedSquare.imageUrl;
    }

    if (
        updatedSquare.occurredDate !== initial.occurredDate &&
        updatedSquare.occurredDate !== null
    ) {
        updatedRequest.occurredDate = updatedSquare.occurredDate;
    }

    return Object.keys(updatedRequest).length ? updatedRequest : null;
};

const EditBingoSquareDialogForm = ({
    bingoSquare,
    classes,
    notifyError,
    notifySuccess,
    notifyWarning,
    onClose,
    refreshBingoSquares,
    updateBingoSquare,
}: Props) => {
    const [state, setState] = useState<EditState>(bingoSquare);
    const [isUpdating, setIsUpdating] = useState(false);

    const handleUpdate = async () => {
        const body = getPatchRequestBody(bingoSquare, state);

        if (!body) {
            notifyWarning('Nothing changed.');
            return;
        }

        setIsUpdating(true);

        try {
            await updateBingoSquare({
                id: bingoSquare.id,
                eventId: bingoSquare.eventId,
                body,
            });
        } catch (error) {
            if (error instanceof Error) {
                notifyError(error.message);
            } else {
                notifyError('Unknown error updating Bingo Square');
            }
            setIsUpdating(false);
            return;
        }

        notifySuccess('Successfully updated Bingo Square!');
        setIsUpdating(false);

        refreshBingoSquares(bingoSquare.eventId);
        onClose();
    };

    const alreadyOccurred = bingoSquare.occurredDate !== null;

    return (
        <Dialog open={true} fullWidth onClose={onClose}>
            <DialogTitle id="edit-bingo-square">Edit Bingo Square</DialogTitle>
            <CloseIcon
                className={classes.closeImportExport}
                onClick={onClose}
            />
            <DialogContent>
                <TextField
                    margin="dense"
                    fullWidth
                    // as there is no check on group duplicates
                    label="Text"
                    name="text"
                    value={state.text}
                    onChange={(event) => {
                        const text = event.target.value;
                        setState((prevState) => ({
                            ...prevState,
                            text,
                        }));
                    }}
                />
                <TextField
                    margin="dense"
                    fullWidth
                    // as there is no check on group duplicates
                    label="Image URL"
                    name="imageUrl"
                    value={state.imageUrl}
                    onChange={(event) => {
                        const imageUrl = event.target.value;
                        setState((prevState) => ({
                            ...prevState,
                            imageUrl,
                        }));
                    }}
                />
                <DateTimePicker
                    margin="dense"
                    fullWidth
                    labelFunc={timeFormatFunc}
                    label="Occurred Date"
                    value={state.occurredDate}
                    helperText={
                        alreadyOccurred
                            ? 'Already occurred. Editing is disabled'
                            : 'Mark as occurred'
                    }
                    disabled={alreadyOccurred}
                    name="ocurredDate"
                    onChange={(occurredDate) => {
                        setState((prevState) => ({
                            ...prevState,
                            occurredDate: occurredDate
                                ? occurredDate.toISOString()
                                : null,
                        }));
                    }}
                />
                <DialogActions style={{ marginTop: '25px' }}>
                    <Button
                        color="primary"
                        disabled={isUpdating}
                        onClick={handleUpdate}
                        variant="contained"
                    >
                        Submit
                    </Button>
                </DialogActions>
                {isUpdating && <LinearProgress />}
            </DialogContent>
        </Dialog>
    );
};

const styles = () => ({
    closeImportExport: {
        cursor: 'pointer',
        position: 'absolute' as 'absolute',
        right: '10px',
        top: '10px',
    },
});

export default withStyles(styles)(EditBingoSquareDialogForm);
