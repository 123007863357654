export const NBA_GAME_TIME_REGEX_STRING: string =
    '^(1[0-1]|0?[0-9]):[0-5]{1}[0-9]{1}$';

export const NBA_GAME_TIME_REGEX: RegExp =
    /^(1[0-1]|0?[0-9]):[0-5]{1}[0-9]{1}$/;

export const NBA_PERIODS = ['1Q', '2Q', '3Q', '4Q'];

export const PAGE_LABEL = {
    category: 'Category',
    code: 'Code',
    event: 'Event',
    iteration: 'Iteration',
    offer: 'Offer',
    partner: 'Partner',
    prediction: 'Prediction',
};

// TODO: Move these crud page definitions somewhere more appropriate.

interface CrudPageBreadcrumb {
    link?: string;
    text: string;
}

// NOTE: The following is probably incomplete.
export interface CrudPageInputField {
    name: string;
    label?: string;
    type: 'hidden' | 'text' | 'select' | 'datetime-local';
    options?: Array<{
        label: string;
        value: any;
    }>;
    props?: {
        autoFocus?: boolean;
        multiline?: boolean;
    };
    required?: boolean;
    shouldDisplay?: boolean | ((a: any) => boolean);
    defaultValue?: any;
}

interface CrudPageRowStyle {
    style: any;
}

// NOTE: The following is probably incomplete.
export interface CrudPageTableColumn<T> {
    label: string;
    name?: string;
    generateFunc?: (value: T) => string;
    options?: {
        customBodyRender?: (value: T) => string;
        display?: boolean;
        filter?: boolean;
        hint?: string;
        sort?: boolean;
    };
    props?: {
        color: string;
    };
}

// NOTE: The following is incomplete.
export interface CrudPageProps<T> {
    breadCrumbs: CrudPageBreadcrumb[];
    // either onRowClick or buildChildLink
    onRowClick?: (id: string) => string;
    buildChildLink?: (id: string) => string;

    createRecord?: (formState: Partial<T>) => Promise<void>;
    deleteRecord: (params: { id: string; parentId: string }) => Promise<void>;
    data: T[];
    headline: string;
    inputFields: CrudPageInputField[];
    label: string;
    notifyError: (message: string) => void;
    notifySuccess: (message: string) => void;
    parentId: string; // Used for deleteRecord
    presubmitTransform?: (formState: Partial<T>) => Partial<T>;
    refreshData: (callback: () => void) => void;
    secondaryActions?: Array<{
        name: string;
        onClick: () => void;
        icon: JSX.Element;
    }>;
    setRowProps?: (row: T) => CrudPageRowStyle;
    tableColumns?: Array<CrudPageTableColumn<any>>;
    updateRecord: (id: string, formState: Partial<T>) => Promise<void>;
    supportedLanguages: string[];
}
