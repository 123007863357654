import { Box, Typography } from '@material-ui/core';
import React from 'react';

function TabPanel(props) {
    const { children, index, value, ...other } = props;

    return (
        <div
            aria-labelledby={`simple-tab-${index}`}
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            role="tabpanel"
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component="div">{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export default TabPanel;
