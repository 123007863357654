export const parseDecimalInt = (value: string): number =>
    Number.parseInt(value, 10);

export const shouldIgnoreMuiTableRowClick = (event: any) => {
    // There is a bug in mui-datatables which prevents us from getting the
    // column which was clicked so we can ignore it if we have buttons in
    // that column. Following is a workaround for figuring out which clicks
    // to ignore.
    // https://github.com/gregnb/mui-datatables/issues/311
    if (event && event.target) {
        if (event.target.getAttribute('name')) {
            const name = event.target.getAttribute('name');
            if (name === 'edit' || name === 'firestore') {
                return true;
            }
        }
        if (['path', 'BUTTON', 'IMG'].includes(event.target.tagName)) {
            return true;
        }

        if (['checkbox', 'button'].includes(event.target.type)) {
            return true;
        }
    }

    return false;
};
