export interface Config {
    apiBaseUrl: string;
    predictionsWebRoot: string;
    sponsorBaseUrl: string;
}

const {
    NODE_ENV,
    REACT_APP_API_BASE_URL,
    REACT_APP_PREDICTIONS_WEB_ROOT,
    REACT_APP_SPONSOR_BASE_URL,
} = process.env;

if (
    [REACT_APP_API_BASE_URL, REACT_APP_PREDICTIONS_WEB_ROOT].some(
        (variable) => typeof variable !== 'string',
    )
) {
    throw new Error(
        'Invalid config. Some of required env variables are not present.',
    );
}

const config: Config = {
    apiBaseUrl: `${REACT_APP_API_BASE_URL!}${
        NODE_ENV === 'production' ? '/authoring' : ''
    }`,
    predictionsWebRoot: REACT_APP_PREDICTIONS_WEB_ROOT!,
    sponsorBaseUrl:
        NODE_ENV === 'production'
            ? `${REACT_APP_API_BASE_URL!}/sponsorship`
            : REACT_APP_SPONSOR_BASE_URL!,
};

export default config;
