import styled from '@emotion/styled';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core';
import { StyleRules, withStyles } from '@material-ui/core/styles';
import React, { PureComponent } from 'react';
import { NBAGameStatistics } from '../../service/AuthoringApi';

interface Props {
    classes: any;
    gameStats: NBAGameStatistics;
}

interface State {
    refreshing: boolean;
}

const STAT_NAME_MAP: { [key: string]: string } = {
    biggestLead: 'Biggest Lead',
    defensiveRebounds: 'Defensive Rebounds',
    fastBreakPts: 'Fast Break Points',
    fieldGoalsMade: 'Field Goals',
    fieldGoalsPct: 'Field Goal %',
    foulouts: 'Foul Outs',
    freeThrowsMade: 'Free Throws',
    freeThrowsPct: 'Free Throw %',
    largestScoringRun: 'Largest Scoring Run',
    offensiveRebounds: 'Offensive Rebounds',
    pointsInPaint: 'Points in Paint',
    pointsOffTurnovers: 'Points Off Turnovers',
    threePointsMade: 'Three Pointers',
    threePointsPct: 'Three point %',
};

const MADE_TO_ATTEMPTED_MAP: { [key: string]: string } = {
    fieldGoalsMade: 'fieldGoalsAttempted',
    freeThrowsMade: 'freeThrowsAttempted',
    threePointsMade: 'threePointsAttempted',
};

const capitalize = (s: string) => {
    return s.charAt(0).toUpperCase() + s.slice(1);
};

const DASH = '—';

class NBAStatsTable extends PureComponent<Props, State> {
    private renderTeamNameRow = () => {
        const { classes, gameStats } = this.props;
        const { away: awayTeam, home: homeTeam } = gameStats;
        const { alias: awayAbbrev } = awayTeam;
        const { alias: homeAbbrev } = homeTeam;
        const blazersAreHome = homeAbbrev === 'POR';
        return (
            <TableRow className={classes.tableRow}>
                <TableCell className={classes.bodyCell} />
                <TableCell className={classes.bodyCell}>
                    <StatBox
                        awayStat={awayAbbrev}
                        blazersHome={blazersAreHome}
                        homeStat={homeAbbrev}
                    />
                </TableCell>
                <TableCell className={classes.bodyCell}>
                    <StatBox
                        awayStat={awayAbbrev}
                        blazersHome={blazersAreHome}
                        homeStat={homeAbbrev}
                    />
                </TableCell>
                <TableCell className={classes.bodyCell}>
                    <StatBox
                        awayStat={awayAbbrev}
                        blazersHome={blazersAreHome}
                        homeStat={homeAbbrev}
                    />
                </TableCell>
                <TableCell className={classes.bodyCell}>
                    <StatBox
                        awayStat={awayAbbrev}
                        blazersHome={blazersAreHome}
                        homeStat={homeAbbrev}
                    />
                </TableCell>
                <TableCell className={classes.bodyCell}>
                    <StatBox
                        awayStat={awayAbbrev}
                        blazersHome={blazersAreHome}
                        homeStat={homeAbbrev}
                    />
                </TableCell>
            </TableRow>
        );
    };

    private renderScoreAndUpdated = () => {
        const { classes, gameStats } = this.props;
        const {
            away: awayTeam,
            clock,
            home: homeTeam,
            leadChanges,
            quarter,
        } = gameStats;
        const { alias: awayAbbrev, points: awayPoints } = awayTeam;
        const { alias: homeAbbrev, points: homePoints } = homeTeam;

        const score = `${awayAbbrev}: ${awayPoints} ${homeAbbrev}: ${homePoints}`;
        const lastUpdated = clock ? `Q${quarter} @ ${clock}` : 'Never';
        const leadChangeText = `Lead Changes: ${leadChanges}`;
        return (
            <ScoreAndUpdateContainer>
                <Typography className={classes.lastUpdated} variant="caption">
                    {score}
                </Typography>
                <Typography className={classes.lastUpdated} variant="caption">
                    {leadChangeText}
                </Typography>
                <Typography className={classes.lastUpdated} variant="caption">
                    Last Updated: {lastUpdated}
                </Typography>
            </ScoreAndUpdateContainer>
        );
    };

    private isPercentBased = (key: string): boolean => {
        return (
            key === 'fieldGoalsPct' ||
            key === 'freeThrowsPct' ||
            key === 'threePointsPct'
        );
    };

    private getCleanedStats = (statsToClean: any): any[] => {
        const { periods } = statsToClean;
        const cleanedStats: any[] = [];
        Object.keys(statsToClean).forEach((key: string) => {
            if (
                key === 'fieldGoalsAttempted' ||
                key === 'periods' ||
                key === 'freeThrowsAttempted' ||
                key === 'threePointsAttempted' ||
                key === 'twoPointsAttempted' ||
                key === 'twoPointsMade' ||
                key === 'twoPointsPct' ||
                key === 'flagrantFouls' ||
                key === 'technicalFouls'
            ) {
                return;
            } else {
                const name = STAT_NAME_MAP[key] || capitalize(key);
                let Q1Stat;
                let Q2Stat;
                let Q3Stat;
                let Q4Stat;
                let TOTAL;
                if (key === 'foulouts' || key === 'largestScoringRun') {
                    Q1Stat = DASH;
                    Q2Stat = DASH;
                    Q3Stat = DASH;
                    Q4Stat = DASH;
                    TOTAL = statsToClean[key];
                } else if (
                    key === 'fieldGoalsMade' ||
                    key === 'threePointsMade' ||
                    key === 'freeThrowsMade'
                ) {
                    const attemptedKey: any = MADE_TO_ATTEMPTED_MAP[key];
                    Q1Stat =
                        periods.length > 0
                            ? `${periods[0][key]} - ${periods[0][attemptedKey]} `
                            : DASH;
                    Q2Stat =
                        periods.length > 1
                            ? `${periods[1][key]} - ${periods[1][attemptedKey]} `
                            : DASH;
                    Q3Stat =
                        periods.length > 2
                            ? `${periods[2][key]} - ${periods[2][attemptedKey]} `
                            : DASH;
                    Q4Stat =
                        periods.length > 3
                            ? `${periods[3][key]} - ${periods[3][attemptedKey]} `
                            : DASH;
                    TOTAL = `${statsToClean[key]} - ${statsToClean[attemptedKey]} `;
                } else {
                    const suffix = this.isPercentBased(key) ? '%' : '';
                    Q1Stat =
                        periods.length > 0
                            ? `${periods[0][key]}${suffix}`
                            : DASH;
                    Q2Stat =
                        periods.length > 1
                            ? `${periods[1][key]}${suffix}`
                            : DASH;
                    Q3Stat =
                        periods.length > 2
                            ? `${periods[2][key]}${suffix}`
                            : DASH;
                    Q4Stat =
                        periods.length > 3
                            ? `${periods[3][key]}${suffix}`
                            : DASH;
                    TOTAL = `${statsToClean[key]}${suffix}`;
                }
                const statObject = {
                    name,
                    Q1: Q1Stat,
                    Q2: Q2Stat,
                    Q3: Q3Stat,
                    Q4: Q4Stat,
                    TOTAL,
                };
                cleanedStats.push(statObject);
            }
        });

        return cleanedStats;
    };

    public render() {
        const { classes, gameStats } = this.props;
        // If gameStats doesn't have the proper fields, don't try to render the table.
        if (!gameStats.away) {
            return null;
        }
        const { away: awayTeam, home: homeTeam } = gameStats;
        const { alias: awayAbbrev, statistics: awayFullStats } = awayTeam;
        const { alias: homeAbbrev, statistics: homeFullStats } = homeTeam;

        const gameTitle = `${awayAbbrev} v ${homeAbbrev}`;

        const awayTeamStats = this.getCleanedStats(awayFullStats);
        const homeTeamStats = this.getCleanedStats(homeFullStats);
        const combinedStats = awayTeamStats.map((stat, i) => {
            return { away: stat, home: homeTeamStats[i], name: stat.name };
        });

        const blazersAreHome = homeTeam.alias === 'POR';

        return (
            <Paper
                className={classes.root}
                style={{ marginTop: '20px', padding: '10px' }}
            >
                <Typography className={classes.gameTitle} variant="h6">
                    {gameTitle}
                </Typography>
                {this.renderScoreAndUpdated()}
                <Table
                    className={classes.table}
                    style={{ border: '1px solid rgba(0,0,0,0.5)' }}
                >
                    <TableHead>
                        <TableRow className={classes.headRow}>
                            <TableCell
                                className={classes.headCell}
                                style={{ textAlign: 'left' }}
                            >
                                STAT NAME
                            </TableCell>
                            <TableCell className={classes.headCell}>
                                Q1
                            </TableCell>
                            <TableCell className={classes.headCell}>
                                Q2
                            </TableCell>
                            <TableCell className={classes.headCell}>
                                Q3
                            </TableCell>
                            <TableCell className={classes.headCell}>
                                Q4
                            </TableCell>
                            <TableCell className={classes.headCell}>
                                TOTAL
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.renderTeamNameRow()}
                        {combinedStats.map((stat, index) => {
                            return (
                                <TableRow
                                    className={classes.tableRow}
                                    key={stat.name}
                                    style={{
                                        backgroundColor:
                                            index % 2 !== 0
                                                ? 'rgba(0,0,0,0.1)'
                                                : '#fff',
                                    }}
                                >
                                    <TableCell
                                        className={classes.bodyCell}
                                        style={{ textAlign: 'left' }}
                                    >
                                        {stat.name}
                                    </TableCell>
                                    <TableCell className={classes.bodyCell}>
                                        <StatBox
                                            awayStat={stat.away.Q1}
                                            blazersHome={blazersAreHome}
                                            homeStat={stat.home.Q1}
                                        />
                                    </TableCell>
                                    <TableCell className={classes.bodyCell}>
                                        <StatBox
                                            awayStat={stat.away.Q2}
                                            blazersHome={blazersAreHome}
                                            homeStat={stat.home.Q2}
                                        />
                                    </TableCell>
                                    <TableCell className={classes.bodyCell}>
                                        <StatBox
                                            awayStat={stat.away.Q3}
                                            blazersHome={blazersAreHome}
                                            homeStat={stat.home.Q3}
                                        />
                                    </TableCell>
                                    <TableCell className={classes.bodyCell}>
                                        <StatBox
                                            awayStat={stat.away.Q4}
                                            blazersHome={blazersAreHome}
                                            homeStat={stat.home.Q4}
                                        />
                                    </TableCell>
                                    <TableCell className={classes.bodyCell}>
                                        <StatBox
                                            awayStat={stat.away.TOTAL}
                                            blazersHome={blazersAreHome}
                                            homeStat={stat.home.TOTAL}
                                        />
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                        {this.renderTeamNameRow()}
                    </TableBody>
                </Table>
                {this.renderScoreAndUpdated()}
            </Paper>
        );
    }
}

const StatBox = (props: {
    awayStat: string;
    homeStat: string;
    blazersHome: boolean;
}) => (
    <StatContainer>
        <StatValue fontWeight={props.blazersHome ? 'normal' : 'bold'}>
            {props.awayStat}
        </StatValue>
        <StatValue fontWeight={props.blazersHome ? 'bold' : 'normal'}>
            {props.homeStat}
        </StatValue>
    </StatContainer>
);

const ScoreAndUpdateContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const StatContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const StatValue = styled.p<{ fontWeight?: string }>`
    display: flex;
    font-weight: ${(props) => props.fontWeight};
    text-align: center;
    margin: 0px;
`;

const styles = () =>
    ({
        bodyCell: {
            borderBottom: 'none',
            borderRight: '1px solid rgba(0,0,0,0.5)',
            padding: '0px 12px',
            textAlign: 'center',
        },
        gameTitle: {
            marginBottom: '10px',
            textAlign: 'center',
        },
        headCell: {
            borderBottom: 'none',
            borderRight: '1px solid rgba(0,0,0,0.5)',
            color: '#000',
            padding: '0px 24px',
            textAlign: 'center',
        },
        headRow: {
            backgroundColor: 'rgba(0,0,0,0.2)',
            height: '35px',
        },
        lastUpdated: {
            textAlign: 'right',
        },
        tableRow: {
            backgroundColor: 'rgba(0,0,0,0.1)',
            height: '30px',
        },
    }) as StyleRules;

export default withStyles(styles)(NBAStatsTable);
