import Cookie from 'js-cookie';
import {
    EntityTableExport,
    EntityTableImport,
} from 'src/utils/tables/EntityTableTransformer';

declare global {
    interface Window {
        gapi: any;
        google: any;
    }
}

export interface SheetResult {
    values: string[][];
    range: string;
    status: number;
    body: string;
    statusText?: string;
}

export class GoogleSheetsApiError extends Error {
    public status: number;

    constructor({ message, status }: { status: number; message?: string }) {
        super(message);
        this.status = status;
    }
}
export default class GoogleSheetsApi {
    private spreadsheets: undefined | any = undefined;

    public initialize = async () => {
        // load client api into window
        await new Promise((resolve, reject) => {
            window.gapi.load('client:auth2', {
                callback: resolve,
                onerror: reject,
            });
        });

        const access_token = Cookie.get('access_token');
        window.gapi.auth.setToken({ access_token });

        // load sheets api into window.
        await new Promise((resolve, reject) => {
            window.gapi.client
                .load(
                    'https://sheets.googleapis.com/$discovery/rest?version=v4',
                )
                .then(resolve, reject);
        });

        this.spreadsheets = window.gapi.client.sheets.spreadsheets;
    };

    private readTab = async (
        spreadsheetId: string,
        range: string,
        tabTitle: string,
    ) => {
        if (!this.spreadsheets) {
            throw new Error('Sheets API not yet initialized');
        }
        return this.spreadsheets.values.get({
            range: `${tabTitle}!${range}`,
            spreadsheetId,
        });
    };

    public readSheet = async (spreadsheetId: string) => {
        const range = 'A:AZ' as const;
        if (!this.spreadsheets) {
            throw new Error('Sheets API not yet initialized');
        }
        const getSheets = async () => {
            const result = await this.spreadsheets.get({
                spreadsheetId,
            });
            return result.result.sheets.map(
                (sheet: any) => sheet.properties.title,
            );
        };

        const sheetTitles = await getSheets(); //as lang code ids
        const promises = sheetTitles.map((code: string) => {
            return this.readTab(spreadsheetId, range, code);
        });
        try {
            const data: { result: SheetResult }[] = await Promise.all(promises);
            const response: EntityTableImport = sheetTitles.map(
                (code: string, i: number) => {
                    return {
                        languageId: code,
                        values: data[i].result.values || [],
                    };
                },
            );
            return response;
        } catch (error) {
            const err = error as any;
            throw new GoogleSheetsApiError({ status: err.status });
        }
    };

    private clearSheetAndTabs = async (spreadsheetId: string) => {
        const { result } = await this.spreadsheets.get({
            fields: 'sheets(properties(sheetId))',
            spreadsheetId: spreadsheetId,
        });

        const requests = result.sheets.reduce(
            (
                ar: any[],
                {
                    properties: { sheetId },
                }: { properties: { sheetId: string } },
                i: number,
            ) => {
                if (i > 0) {
                    ar.push({ deleteSheet: { sheetId } });
                }
                return ar;
            },
            [],
        );
        if (requests && requests.length) {
            const deleteSpreadsheetsRequest = {
                resource: { requests },
                spreadsheetId: spreadsheetId,
            };
            //deletes all tabs
            await this.spreadsheets.batchUpdate(deleteSpreadsheetsRequest);
        }
        //clears the first tab as it cant be deleted
        await this.spreadsheets.values.clear({
            range: 'A1:Z',
            spreadsheetId,
        });
    };

    public writeToSheet = async (
        spreadsheetId: string,
        table: EntityTableExport,
    ) => {
        const range = 'A1';
        const [defaultTab, ...additionalTabsValues] = table;
        if (!this.spreadsheets) {
            throw new Error('Sheets API not yet initialized');
        }

        await this.clearSheetAndTabs(spreadsheetId);

        const tabTitleUpdateRequests = [];
        tabTitleUpdateRequests.push({
            updateSheetProperties: {
                fields: 'title',
                properties: {
                    sheetId: 0,
                    title: defaultTab.languageId,
                },
            },
        });

        additionalTabsValues.forEach((el) => {
            tabTitleUpdateRequests.push({
                addSheet: {
                    properties: {
                        title: el.languageId,
                    },
                },
            });
        });

        await this.spreadsheets.batchUpdate({
            resource: {
                requests: tabTitleUpdateRequests,
            },
            spreadsheetId: spreadsheetId,
        });

        const promises: Promise<unknown>[] = [
            this.spreadsheets.values.update({
                range,
                resource: {
                    values: defaultTab.values,
                },
                spreadsheetId,
                valueInputOption: 'USER_ENTERED',
            }),
        ];

        additionalTabsValues.forEach((el) => {
            promises.push(
                this.spreadsheets.values.update({
                    range: `${el.languageId}!${range}`,
                    resource: {
                        values: el.values,
                    },
                    spreadsheetId,
                    valueInputOption: 'USER_ENTERED',
                }),
            );
        });

        return await Promise.all(promises);
    };
}
