import moment from 'moment-timezone';
import numeral from 'numeral';
import React from 'react';
import importedSlugify from 'slugify';
import {
    PREDICTION_EVENT_STATES,
    PREDICTION_TYPES,
} from 'traceme-shared/lib/constants';
import { FeedProviders } from '../components/FeedPredictions/FeedPrediction';
import { SportRadarSportTypes } from '../components/FeedPredictions/SportRadar';
import Predictions from '../pages/Predictions';
import { LocaleIds, LOCK_TYPES } from '../service/Dto';
import { AD_SIZING_POLICY, AdSizingPolicyOptions } from './ads/adSizingPolicy';
import {
    PREDICTION_AD_PLACEMENT,
    predictionAdPlacementOptions,
} from './ads/predictionAdPlacement';
import {
    PREDICTION_AD_RENDER_TIME,
    predictionAdRenderTimeOptions,
} from './ads/predictionAdRenderTime';
import { AD_CUTOFF_HEIGHT } from './constants';

// import { NBA_PERIODS } from './service/Constants';
const timeFormat = 'ddd M/D hh:mm A z';
const UNSPECIFIED = 'Unspecified'; // Special token for when an input value is unspecified.

export const FORM_INPUT_WIDTH = {
    half: 'half',
    third: 'third',
};

// prettier-ignore
export const predictionPointValues = [
    0,
    10,
    25,
    50,
    100,
    500,
    1000,
    5000,
    10000,
    15000,
    20000,
    25000,
    30000,
    35000,
    40000,
    45000,
    50000,
];

export const determineEventState = ({
    endDate,
    finalized,
    startDate,
    visible,
}) => {
    const now = moment();

    // If event isn't visible, this hidden state trumps everything else
    if (!visible) {
        return 'hidden';
    }

    if (startDate === '' || !startDate) {
        return 'unscheduled';
    }

    if (endDate && now.isAfter(moment(endDate))) {
        if (!finalized) {
            return PREDICTION_EVENT_STATES.pending;
        }
        return PREDICTION_EVENT_STATES.final;
    }

    return now.isBefore(moment(startDate))
        ? 'future'
        : PREDICTION_EVENT_STATES.live;
};

export const determinePredictionLockState = (prediction) => {
    const { lockDate, visible } = prediction;
    if (!visible) {
        return 'hidden';
    }

    const now = moment();

    if (lockDate && now.isSameOrAfter(moment(lockDate))) {
        return 'locked';
    }
    return 'unlocked';
};

export const determinePredictionStatus = (prediction) => {
    const isAnswered = Predictions.predictionAnswered(prediction) === 'yes';
    const isVisible = prediction.visible;
    const isLocked = moment(prediction.lockDate).isBefore(
        moment.utc().format(),
    );

    if (isVisible && !isLocked && (prediction.type === 'POLL' || !isAnswered)) {
        return 'Live';
    } else if (isVisible && isLocked && isAnswered) {
        return 'Answered';
    } else if (isVisible && isLocked && !isAnswered) {
        return 'Pending';
    } else if (!isVisible) {
        return 'Hidden';
    } else {
        return '';
    }
};

export const localizeDate = (date) =>
    date ? moment(date).tz(moment.tz.guess()).format(timeFormat) : '';

export const localizeTimeOnly = (date) =>
    date ? moment(date).tz(moment.tz.guess()).format('hh:mm A z') : '';

// parse a zulu time string or one formatted with timezone in it
export const parseDateString = (dateString) => {
    if (dateString.match(/\dZ/i)) {
        return moment(dateString);
    } else {
        return moment(dateString, timeFormat);
    }
};

export const parseTimeOfDay = (moment) => {
    return moment.format('HH:mm');
};

/**
 * get a moment for the next time a specific time of day will occur
 */
export const nextTimeMoment = (timeOfDay, timeZone) => {
    const [hours, minutes] = timeOfDay.split(':');

    const todayAtTime = moment().tz(timeZone);
    todayAtTime.hours(hours);
    todayAtTime.minutes(minutes);

    if (todayAtTime.isBefore(moment())) {
        todayAtTime.add(1, 'day');
        return todayAtTime;
    } else {
        return todayAtTime;
    }
};

export const stringifyBoolean = (boolean) => `${boolean}`;

export const slugify = (string) =>
    importedSlugify(string, {
        lower: true,
        remove: /[^A-Za-z0-9-_\s]/g,
    });

export const renderUrlPreviewHelper = (name, value) => {
    if (value && value.startsWith('http')) {
        return (
            <a href={value} rel="noopener noreferrer" target="_blank">
                Preview
            </a>
        );
    }
};

export const presubmitPredictionTransform = (formState) => {
    const originalOptions = formState.options;
    const transformedState = { ...formState, options: [] };
    const { optionsCurrentOdds, optionsMultiline } = formState;
    if (optionsMultiline) {
        transformedState.options = optionsMultiline
            .split('\n')
            .map((option, index) => {
                const trimmedOption = option.trim();
                return {
                    answeringText: trimmedOption,
                    number: index + 1,
                    text: trimmedOption,
                };
            });
        delete transformedState.optionsMultiline;
    } else {
        originalOptions.forEach((originalOption, index) => {
            const field = `option${index + 1}`;
            transformedState.options.push({
                ...originalOption,
                number: index + 1,
                text: transformedState[field],
            });
            delete transformedState[field];
        });
    }
    if (optionsCurrentOdds) {
        transformedState.optionsCurrentOdds = optionsCurrentOdds.split('\n');
    }
    const uuidLength = 36;
    const translationEntities = Object.keys(formState).filter((key) =>
        key.startsWith('translation.languageCodeId.'),
    );
    if (translationEntities && translationEntities.length) {
        transformedState.entityTranslations = translationEntities.map((te) => {
            return {
                languageCodeId: formState[te],
                languageIdentity: te.slice(-uuidLength),
            };
        });

        const translationOptionsMultilines = Object.keys(formState).filter(
            (key) => key.startsWith('translationOptionsMultiline'),
        );
        if (
            translationOptionsMultilines &&
            translationOptionsMultilines.length
        ) {
            translationOptionsMultilines.forEach((key) => {
                const translationEntityIndex =
                    transformedState.entityTranslations.findIndex(
                        (et) => et.languageIdentity === key.slice(-uuidLength),
                    );
                transformedState.entityTranslations[translationEntityIndex] = {
                    ...transformedState.entityTranslations[
                        translationEntityIndex
                    ],
                    options: formState[key].split('\n').map((option, index) => {
                        const trimmedOption = option.trim();
                        return {
                            answeringText: trimmedOption,
                            number: index + 1,
                            text: trimmedOption,
                        };
                    }),
                };
                delete formState[key];
                delete transformedState[key];
            });
        }
        transformedState.entityTranslations =
            transformedState.entityTranslations.map((et) => {
                if (
                    transformedState[
                        `translation.options.${et.languageIdentity}`
                    ]
                ) {
                    et.options = [
                        ...transformedState[
                            `translation.options.${et.languageIdentity}`
                        ],
                    ];
                    delete formState[
                        `translation.options.${et.languageIdentity}`
                    ];
                    delete transformedState[
                        `translation.options.${et.languageIdentity}`
                    ];
                }
                const optionUpdate = et.options.map((opt, i) => {
                    const optionUpdateKey = `translation.option${i + 1}.${
                        et.languageIdentity
                    }`;
                    const translatedOptionUpdate =
                        transformedState[optionUpdateKey];
                    if (translatedOptionUpdate) {
                        delete formState[optionUpdateKey];
                        delete transformedState[optionUpdateKey];
                        return {
                            ...opt,
                            answeringText: translatedOptionUpdate,
                            text: translatedOptionUpdate,
                        };
                    } else {
                        return opt;
                    }
                });
                return { ...et, options: optionUpdate };
            });
    }

    // Before we submit to the server, change all UNSPECIFIED values to empty-string.
    if (formState.releaseMilestone === UNSPECIFIED) {
        transformedState.releaseMilestone = '';
    }
    if (formState.answerMilestone === UNSPECIFIED) {
        transformedState.answerMilestone = '';
    }

    if (transformedState.type !== PREDICTION_TYPES.multipleChoice) {
        delete transformedState.allOrNothing;
        delete transformedState.hideOptionMetrics;
    }

    return transformedState;
};

export const preformPredictionRenderTransform = (prediction) => {
    const transformedState = { ...prediction };

    // Transform empty values to UNSPECIFIED.
    transformedState.releaseMilestone =
        prediction.releaseMilestone || UNSPECIFIED;
    transformedState.answerMilestone =
        prediction.answerMilestone || UNSPECIFIED;

    if (Predictions.useMultilineOptions(prediction)) {
        transformedState.optionsMultiline = prediction.options
            .map((o) => o.text)
            .join('\n');
    } else {
        prediction.options.forEach((option, index) => {
            transformedState[`option${index + 1}`] = option.text;
        });
    }
    if (prediction.optionsCurrentOdds) {
        try {
            transformedState.optionsCurrentOdds = JSON.parse(
                prediction.optionsCurrentOdds,
            ).join('\n');
        } catch (error) {
            transformedState.optionsCurrentOdds = prediction.optionsCurrentOdds;
        }
    }

    return transformedState;
};

const oddTypes = [
    {
        label: 'Moneyline',
        value: 'MONEY_LINE',
    },
    {
        label: 'Over/Under',
        value: 'OVER_UNDER',
    },
];

const getLockMilestoneForFeed = ({
    autoLockMilestone,
    feedProviderId,
    feedSport,
}) => {
    switch (feedProviderId) {
        case FeedProviders.kindred:
            return [
                {
                    label: 'Feed-driven betting close',
                    value: 'Feed-driven betting close',
                },
            ];
        case FeedProviders.sportRadar:
            switch (feedSport) {
                case SportRadarSportTypes.NHL:
                    return [
                        {
                            label: autoLockMilestone,
                            value: autoLockMilestone,
                        },
                    ];
                case SportRadarSportTypes.NBA:
                    return [
                        {
                            label: autoLockMilestone,
                            value: autoLockMilestone,
                        },
                    ];
                case SportRadarSportTypes.NFL:
                    return [
                        {
                            label: autoLockMilestone,
                            value: autoLockMilestone,
                        },
                    ];
                default:
                    return [
                        {
                            label: 'Feed-driven game START',
                            value: 'Feed-driven game START',
                        },
                        {
                            label: 'Feed-driven game END',
                            value: 'Feed-driven game END',
                        },
                    ];
            }
        default:
            return [
                {
                    label: 'Feed-driven game START',
                    value: 'Feed-driven game START',
                },
                {
                    label: 'Feed-driven game END',
                    value: 'Feed-driven game END',
                },
            ];
    }
};
const getAnswerMilestoneForFeed = ({
    answerMilestone,
    feedProviderId,
    feedSport,
}) => {
    switch (feedProviderId) {
        case FeedProviders.kindred:
            return [
                {
                    label: 'Feed-driven betting results',
                    value: 'Feed-driven betting results',
                },
            ];
        case FeedProviders.sportRadar:
            switch (feedSport) {
                case SportRadarSportTypes.NHL:
                    return [
                        {
                            label: answerMilestone,
                            value: answerMilestone,
                        },
                    ];
                case SportRadarSportTypes.NBA:
                    return [
                        {
                            label: answerMilestone,
                            value: answerMilestone,
                        },
                    ];
                case SportRadarSportTypes.NFL:
                    return [
                        {
                            label: answerMilestone,
                            value: answerMilestone,
                        },
                    ];
                default:
                    return [
                        {
                            label: 'Feed-driven game START',
                            value: 'Feed-driven game START',
                        },
                        {
                            label: 'Feed-driven game END',
                            value: 'Feed-driven game END',
                        },
                    ];
            }
        default:
            return [
                {
                    label: 'Feed-driven game START',
                    value: 'Feed-driven game START',
                },
                {
                    label: 'Feed-driven game END',
                    value: 'Feed-driven game END',
                },
            ];
    }
};

export const predictionInputFields = ({
    category,
    dataPreset,
    event,
    partner,
    predictions,
    sponsorshipUnits,
}) => {
    const fields = [
        {
            defaultValue: event.id,
            name: 'eventId',
            type: 'hidden',
        },
        {
            defaultValue: 'manuallyEntered',
            name: 'reportingStatType',
            type: 'hidden',
        },
        {
            name: 'answeringReferences',
            type: 'hidden',
        },
        {
            defaultValue: false,
            label: 'Reveal to players',
            name: 'visible',
            props: {
                color: 'primary',
            },
            shouldDisplay: (obj) => obj.visible,
            type: 'boolean',
        },
        {
            name: 'DEFAULT LANGUAGE',
            type: 'heading',
        },
        {
            defaultValue:
                (partner &&
                    partner.properties &&
                    partner.properties.supportedLanguages &&
                    partner.properties.supportedLanguages[0]) ||
                LocaleIds[0],
            label: 'Default Language',
            name: 'defaultLanguageId',
            options:
                partner &&
                partner.properties &&
                partner.properties.supportedLanguages
                    ? [
                          {
                              label: partner.properties.supportedLanguages[0],
                              value: partner.properties.supportedLanguages[0],
                          },
                      ]
                    : [
                          {
                              label: LocaleIds[0],
                              value: LocaleIds[0],
                          },
                      ],
            props: {
                formwidthpercent: FORM_INPUT_WIDTH.third,
            },
            required: true,
            type: 'languageSelect',
        },
        {
            name: 'Feed prediction data',
            shouldDisplay: (obj) => Boolean(obj.feedProviderId),
            type: 'heading',
        },
        {
            label: 'Feed ID',
            name: 'feedProviderId',
            props: {
                disabled: true,
                inputProps: {
                    maxLength: 20,
                    spellCheck: false,
                },
                multiline: false,
            },
            required: (obj) => Boolean(obj.feedProviderId),
            shouldDisplay: (obj) => Boolean(obj.feedProviderId),
            type: 'text',
        },
        {
            label: 'Feed Sports',
            name: 'feedSport',
            props: {
                disabled: true,
                inputProps: {
                    maxLength: 20,
                    spellCheck: false,
                },
                multiline: false,
            },
            required: (obj) => Boolean(obj.feedProviderId),
            shouldDisplay: (obj) =>
                obj.feedProviderId === FeedProviders.sportRadar,
            type: 'text',
        },
        {
            defaultValue: '',
            helperTextFunc: () =>
                'Please, dont change the meaning of the question, options as they are going to be used for synchronization and auto-answering',
            label: 'Odds ID',
            name: 'feedOddsId',
            props: {
                disabled: true,
                inputProps: {
                    maxLength: 64,
                    spellCheck: false,
                },
                multiline: false,
            },
            required: (obj) => Boolean(obj.feedProviderId),
            shouldDisplay: (obj) =>
                Boolean(obj.feedProviderId) &&
                obj.feedProviderId === FeedProviders.sportRadar,
            type: 'text',
        },
        {
            defaultValue: '',

            helperTextFunc: () => 'Feed event is scheduled on the date above',
            name: 'feedScheduled',
            props: {
                disabled: true,
            },
            required: (obj) => Boolean(obj.feedProviderId),
            shouldDisplay: (obj) => Boolean(obj.feedProviderId),
            type: 'text',
        },
        {
            defaultValue: '',
            helperTextFunc: () =>
                'This field is inherited from selected template on previous modal window and cant be changed here.',
            label: 'Prediction feed template.',
            name: 'feedTemplate',
            props: {
                disabled: true,
            },
            required: (obj) => Boolean(obj.feedProviderId),
            shouldDisplay: (obj) =>
                obj.feedProviderId === FeedProviders.sportRadar,
            type: 'text',
        },
        {
            label: (obj) =>
                `Enable predictions auto-lock ${
                    obj.feedProviderId === !FeedProviders.kindred
                        ? 'and -answer'
                        : ''
                } using the feed data`,
            name: 'feedAutoProcess',
            shouldDisplay: (obj) => Boolean(obj.feedProviderId),
            type: 'boolean',
        },
        {
            defaultValue: oddTypes[0].value,
            label: 'Betting simulation type',
            name: 'oddType',
            options: oddTypes,
            required: false,
            shouldDisplay: (obj) =>
                obj.feedProviderId === FeedProviders.kindred &&
                obj.type === PREDICTION_TYPES.bettingSimulation,
            type: 'select',
        },
        {
            name: 'QUESTION INFO',
            type: 'heading',
        },
        {
            defaultValue: predictions.length + 1,
            label: 'Question Order',
            name: 'number',
            options: sequence(1, predictions.length + 1).map((value) => ({
                label: `${value}`,
                value,
            })),
            props: {
                formwidthpercent: FORM_INPUT_WIDTH.third,
            },
            type: 'select',
        },
        {
            defaultValue: PREDICTION_TYPES.multipleChoice,
            label: 'Type',
            name: 'type',
            options: (obj) => {
                if (obj.feedProviderId === FeedProviders.kindred) {
                    return [
                        {
                            label: 'Multiple Choice',
                            value: PREDICTION_TYPES.multipleChoice,
                        },
                        {
                            label: 'Bet Simulation',
                            value: PREDICTION_TYPES.bettingSimulation,
                        },
                    ];
                }
                if (obj.feedProviderId === FeedProviders.sportRadar) {
                    return [
                        {
                            label: 'Multiple Choice',
                            value: PREDICTION_TYPES.multipleChoice,
                        },
                    ];
                }
                return [
                    {
                        label: 'Multiple Choice',
                        value: PREDICTION_TYPES.multipleChoice,
                    },
                    {
                        label: 'Fill In the Blank',
                        value: PREDICTION_TYPES.fillInTheBlank,
                    },
                    {
                        label: 'Poll',
                        value: PREDICTION_TYPES.poll,
                    },
                ];
            },
            props: {
                formwidthpercent: FORM_INPUT_WIDTH.third,
            },
            required: true,
            type: 'select',
        },
        {
            defaultValue: 10000,
            helperTextFunc: (_, __, obj) =>
                obj.type === PREDICTION_TYPES.bettingSimulation
                    ? 'Base point value. Players will recieve points according to bet odds from API'
                    : null,
            label: 'Point Value',
            name: 'totalPointValue',
            options: predictionPointValues.map((value) => ({
                label: `${value}`,
                value,
            })),
            props: {
                formwidthpercent: FORM_INPUT_WIDTH.third,
            },
            required: true,
            type: 'select',
        },
        {
            defaultValue: null,
            label: 'Correct answer will give a user full amount of the predictions "Point Value"',
            name: 'allOrNothing',
            props: {
                color: 'primary',
            },
            shouldDisplay: (obj) =>
                obj.type === PREDICTION_TYPES.multipleChoice,
            type: 'boolean',
        },
        {
            defaultValue: null,
            label: 'Hide a percentage distribution of participants and points forecast of the predictions options',
            name: 'hideOptionMetrics',
            props: {
                color: 'primary',
            },
            shouldDisplay: (obj) =>
                obj.type === PREDICTION_TYPES.multipleChoice,
            type: 'boolean',
        },

        {
            defaultValue: '',
            helperTextFunc: (name, value, formState) => {
                const { originalText } = formState;
                if (originalText === value) {
                    return;
                }
                return originalText;
            },
            label: 'Question',
            name: 'text',
            props: {
                autoFocus: true,
                inputProps: {
                    maxLength: process.env.QUESTION_MAX_LENGTH || 256,
                    spellCheck: true,
                },
                multiline: true,
            },
            required: true,
            type: 'text',
        },
        {
            defaultValue: '',
            label: 'Question Detail Text',
            name: 'detailsText',
            props: {
                inputProps: {
                    maxLength: 500,
                    spellCheck: true,
                },
                multiline: true,
            },
            type: 'text',
        },
        {
            defaultValue: '',
            helperTextFunc: (name, value) => {
                if (!value || value.trim() === '') {
                    return undefined;
                } else {
                    return Predictions.renderOptionPreview(value);
                }
            },
            label: 'Options',
            name: 'optionsMultiline',
            props: {
                FormHelperTextProps: {
                    component: 'div',
                },
                inputProps: {
                    spellCheck: true,
                },
                multiline: true,
            },
            required: true,
            shouldDisplay: (obj) => Predictions.useMultilineOptions(obj),
            type: 'text',
        },
        {
            helperTextFunc: () =>
                'Current bet odds for options above. Feed driven.',
            label: 'Current odds for options',
            name: 'optionsCurrentOdds',
            props: {
                disabled: true,
                multiline: true,
            },
            required: false,
            shouldDisplay: (obj) =>
                obj.type === PREDICTION_TYPES.bettingSimulation,
            type: 'text',
        },
        ...sequence(1, 15).map((number) => ({
            helperTextFunc: (name, value, formState) => {
                const optionNumber = name.substring('option'.length);
                const option = formState.options[optionNumber - 1];
                if (option.answeringText === (value || '').trim()) {
                    return;
                }
                return option.answeringText;
            },
            label: (formState) =>
                formState.type === PREDICTION_TYPES.fillInTheBlank
                    ? `Input ${number} Text`
                    : `Option ${number}`,
            name: `option${number}`,
            required: true,
            shouldDisplay: (obj) =>
                !Predictions.useMultilineOptions(obj) &&
                obj.options[number - 1],
            type: 'text',
        })),
        {
            name: 'UNLOCK TIME',
            type: 'heading',
        },
        {
            defaultValue: LOCK_TYPES.MANUAL,
            label: 'Lock type',
            name: 'lockType',
            options: [LOCK_TYPES.MANUAL, LOCK_TYPES.AUTOMATIC].map((value) => ({
                label: value,
                value,
            })),
            props: {
                formwidthpercent: FORM_INPUT_WIDTH.third,
            },
            type: 'select',
        },
        {
            label: 'Custom Unlocked Time',
            name: 'useCustomUnlockedTime',
            shouldDisplay: (obj) => obj.lockType !== LOCK_TYPES.MANUAL,
            type: 'boolean',
        },
        {
            defaultValue: 65,
            label: 'Unlocked Time',
            name: 'timeIntervalSeconds',
            options: [60, 65, 90, 120, 150, 180, 900, 1020, 172800].map(
                (value) => {
                    let label = value;
                    if (typeof value === 'number') {
                        label = unlockTimeSecondsToNiceString(value);
                    }
                    return {
                        label,
                        value,
                    };
                },
            ),
            props: {
                color: 'primary',
                formwidthpercent: FORM_INPUT_WIDTH.half,
            },
            required: (obj) => obj.lockType !== LOCK_TYPES.MANUAL,
            shouldDisplay: (obj) =>
                obj.lockType !== LOCK_TYPES.MANUAL &&
                !obj.useCustomUnlockedTime,
            type: 'select',
        },
        {
            helperTextFunc: (name, value) => {
                if (!value) {
                    return undefined;
                } else {
                    return unlockTimeSecondsToNiceString(value);
                }
            },
            label: 'Custom Unlocked Time (seconds)',
            name: 'timeIntervalSeconds',
            props: {
                formwidthpercent: FORM_INPUT_WIDTH.half,
                inputProps: {
                    maxLength: 20,
                },
            },
            required: true,
            shouldDisplay: (obj) =>
                obj.useCustomUnlockedTime &&
                obj.lockType === LOCK_TYPES.AUTOMATIC,
            type: 'number',
        },
        {
            defaultValue: '',
            label: 'Lock Description',
            name: 'lockDescription',
            props: {
                helperText: 'e.g. "Start of quarter", "9:30" (User sees this)',
                inputProps: {
                    maxLength: 120,
                },
            },
            required: (obj) => obj.lockType === LOCK_TYPES.MANUAL,
            shouldDisplay: (obj) => obj.lockType === LOCK_TYPES.MANUAL,
            type: 'text',
        },
        {
            name: 'MILESTONES',
            type: 'heading',
        },
        {
            defaultValue: UNSPECIFIED,
            label: 'Release Milestone',
            name: 'releaseMilestone',
            options: [UNSPECIFIED, ...(category.eventMilestones || [])].map(
                (value) => ({ label: value, value }),
            ),
            required: false,
            type: 'select',
        },
        {
            defaultValue: '',
            label: 'Release Time',
            name: 'releaseTime',
            props: {
                helperText: 'e.g. "Start of quarter", "9:30"',
                inputProps: {
                    maxLength: 100,
                },
            },
            type: 'text',
        },
        {
            defaultValue: UNSPECIFIED,
            label: 'Answer Milestone',
            name: 'answerMilestone',
            options: (obj) =>
                obj &&
                obj.feedProviderId &&
                obj.feedAutoProcess &&
                obj.feedProviderId !== FeedProviders.kindred
                    ? getAnswerMilestoneForFeed(obj)
                    : [UNSPECIFIED, ...(category.eventMilestones || [])].map(
                          (value) => ({ label: value, value }),
                      ),
            required: false,
            type: 'select',
        },
        {
            defaultValue: UNSPECIFIED,
            helperTextFunc: () =>
                'Prediction will be automatically locked when feed provider API will notify milestone hit.',
            label: 'Feed driven auto lock Milestone',
            name: 'autoLockMilestone',
            options: (obj) => getLockMilestoneForFeed(obj),
            required: false,
            shouldDisplay: (obj) =>
                Boolean(obj.feedProviderId && obj.feedAutoProcess),
            type: 'select',
        },
        {
            defaultValue: '',
            label: 'Answer Time',
            name: 'answerTime',
            props: {
                helperText: 'e.g. "5:00", "End of quarter"',
                inputProps: {
                    maxLength: 100,
                },
            },
            type: 'text',
        },
        {
            name: 'Ad',
            type: 'heading',
        },
        {
            defaultValue: false,
            label: 'Show Ad',
            name: 'adEnabled',
            props: {
                color: 'primary',
            },
            type: 'boolean',
        },
        {
            defaultValue: PREDICTION_AD_PLACEMENT['Inline'],
            label: 'Placement',
            name: 'adPlacement',
            options: predictionAdPlacementOptions,
            shouldDisplay: (obj) => obj.adEnabled,
            type: 'select',
        },
        {
            defaultValue: PREDICTION_AD_RENDER_TIME['Right Away'],
            label: 'Render Time',
            name: 'adRenderTime',
            options: predictionAdRenderTimeOptions,
            shouldDisplay: (obj) => obj.adEnabled,
            type: 'select',
        },
        {
            label: 'Iframe HTML',
            name: 'adIframeHtml',
            props: {
                multiline: true,
            },
            shouldDisplay: (obj) => obj.adEnabled,
            type: 'text',
        },

        {
            defaultValue: AD_SIZING_POLICY['Other Content, fixed height'],
            label: 'Sizing Policy',
            name: 'adSizingPolicy',
            options: AdSizingPolicyOptions,
            shouldDisplay: (obj) => obj.adEnabled,
            type: 'select',
        },
        {
            defaultValue: AD_CUTOFF_HEIGHT,
            label: 'Cut-off Height',
            name: 'adCutOffHeight',
            shouldDisplay: (obj) =>
                obj.adEnabled &&
                (obj.adSizingPolicy ===
                    AD_SIZING_POLICY['Other Content, fixed height'] ||
                    obj.adSizingPolicy ===
                        AD_SIZING_POLICY['CMS - "Read More" Button']),
            type: 'number',
        },
        {
            label: 'Headline',
            name: 'adHeadline',
            shouldDisplay: (obj) => obj.adEnabled,
            type: 'text',
        },
        {
            label: 'Disclaimer',
            name: 'adDisclaimer',
            props: {
                multiline: true,
            },
            shouldDisplay: (obj) => obj.adEnabled,
            type: 'text',
        },
        {
            name: 'MISCELLANEOUS',
            type: 'heading',
        },
        {
            label: 'Sponsorship Unit',
            name: 'sponsorshipUnitId',
            options: [{ label: null, value: null }].concat(
                (sponsorshipUnits || []).map((unit) => ({
                    label: unit.name,
                    value: unit.id,
                })),
            ),
            required: false,
            type: 'select',
        },
        {
            label: 'Notes',
            name: 'notes',
            props: {
                inputProps: {
                    maxLength: 500,
                },
            },
            type: 'text',
        },
    ];

    if (dataPreset && dataPreset.length) {
        dataPreset.forEach((preset) => {
            const dataFieldIndex = fields.findIndex(
                (field) => field.name === preset.name,
            );
            fields[dataFieldIndex] = { ...fields[dataFieldIndex], ...preset };
        });
    }
    return fields;
};

export const predictionTranslationInputFields = ({ partner }) => {
    const fields = [
        {
            defaultValue: false,
            label: 'Language',
            name: 'translation.languageCodeId',
            options:
                partner &&
                partner.properties &&
                partner.properties.supportedLanguages
                    ? partner.properties.supportedLanguages.map((sl) => ({
                          label: sl,
                          value: sl,
                      }))
                    : [],
            props: {
                formwidthpercent: FORM_INPUT_WIDTH.third,
            },
            required: true,
            type: 'languageSelect',
        },
        {
            name: 'QUESTION INFO',
            type: 'heading',
        },
        {
            defaultValue: '',
            helperTextFunc: (name, value, formState) => {
                const { originalText } = formState;
                if (originalText === value) {
                    return;
                }
                return originalText;
            },
            label: 'Question',
            name: 'translation.text',
            props: {
                autoFocus: true,
                inputProps: {
                    maxLength: process.env.QUESTION_MAX_LENGTH || 256,
                    spellCheck: true,
                },
                multiline: true,
            },
            required: false,
            type: 'text',
        },
        {
            defaultValue: '',
            label: 'Question Detail Text',
            name: 'translation.detailsText',
            props: {
                inputProps: {
                    maxLength: 500,
                    spellCheck: true,
                },
                multiline: true,
            },
            type: 'text',
        },
        {
            defaultValue: '',
            helperTextFunc: (name, value) => {
                if (!value || value.trim() === '') {
                    return undefined;
                } else {
                    return Predictions.renderOptionPreview(value);
                }
            },
            label: 'Options',
            name: 'translationOptionsMultiline',
            props: {
                FormHelperTextProps: {
                    component: 'div',
                },
                inputProps: {
                    spellCheck: true,
                },
                multiline: true,
            },
            required: false,
            shouldDisplay: (obj) => Predictions.useMultilineOptions(obj),
            type: 'text',
        },
        ...sequence(1, 15).map((number) => ({
            helperTextFunc: (name, value, formState, tabIdentity) => {
                const optionNumber = name.substring(
                    'translation.option'.length,
                    'translation.option'.length + 1,
                );
                const option =
                    formState[`translation.options.${tabIdentity}`][
                        optionNumber - 1
                    ];
                if (option.answeringText === (value || '').trim()) {
                    return;
                }
                return option.answeringText;
            },
            label: (formState) =>
                formState.type === PREDICTION_TYPES.fillInTheBlank
                    ? `Input ${number} Text`
                    : `Option ${number}`,
            name: `translation.option${number}`,
            required: false,
            shouldDisplay: (obj) =>
                !Predictions.useMultilineOptions(obj) &&
                obj.options[number - 1],
            type: 'text',
        })),
        {
            name: 'UNLOCK TIME',
            type: 'heading',
        },
        {
            defaultValue: '',
            label: 'Lock Description',
            name: 'translation.lockDescription',
            props: {
                helperText: 'e.g. "Start of quarter", "9:30" (User sees this)',
                inputProps: {
                    maxLength: 120,
                },
            },
            required: false,
            shouldDisplay: (obj) => obj.lockType === LOCK_TYPES.MANUAL,
            type: 'text',
        },
        {
            name: 'MILESTONES',
            type: 'heading',
        },
        {
            defaultValue: '',
            label: 'Release Milestone. Release Time',
            name: 'translation.releaseTime',
            props: {
                formwidthpercent: FORM_INPUT_WIDTH.half,
                helperText: 'e.g. "Start of quarter", "9:30"',
                inputProps: {
                    maxLength: 100,
                },
            },
            type: 'text',
        },
        {
            defaultValue: '',
            label: 'Answer Milestone. Answer Time',
            name: 'translation.answerTime',
            props: {
                formwidthpercent: FORM_INPUT_WIDTH.half,
                helperText: 'e.g. "5:00", "End of quarter"',
                inputProps: {
                    maxLength: 100,
                },
            },
            type: 'text',
        },

        {
            name: 'Ad',
            shouldDisplay: (obj) => obj.adEnabled,
            type: 'heading',
        },
        {
            label: 'Iframe HTML',
            name: 'translation.adIframeHtml',
            props: {
                multiline: true,
            },
            shouldDisplay: (obj) => obj.adEnabled,
            type: 'text',
        },
        {
            label: 'Headline',
            name: 'translation.adHeadline',
            shouldDisplay: (obj) => obj.adEnabled,
            type: 'text',
        },
        {
            label: 'Disclaimer',
            name: 'translation.adDisclaimer',
            props: {
                multiline: true,
            },
            shouldDisplay: (obj) => obj.adEnabled,
            type: 'text',
        },
    ];
    return fields;
};

/**
 * Turns hard-coded seconds strings into easy to read strings with minutes or hours.
 *
 * @param {number} seconds string divisible by 60
 * @returns {string} returns a string representation of the seconds param.
 */
export const unlockTimeSecondsToNiceString = (seconds) => {
    const timeInMinutes = (seconds / 60).toFixed(1).slice(0, -2);
    const secondsRemainder = seconds % 60;

    if (timeInMinutes < 1) {
        return `${secondsRemainder} s`;
    } else if (timeInMinutes < 60) {
        const minutesString =
            timeInMinutes > 1
                ? `${timeInMinutes} mins`
                : `${timeInMinutes} min`;

        return secondsRemainder !== 0
            ? `${minutesString} ${secondsRemainder}s`
            : minutesString;
    } else {
        const timeInHours = (timeInMinutes / 60).toFixed(1).slice(0, -2);
        const minutesRemainder = timeInMinutes % 60;
        const minutesString =
            minutesRemainder > 1
                ? `${minutesRemainder} mins`
                : `${minutesRemainder} min`;
        return timeInHours > 1
            ? `${timeInHours} hrs ${minutesString}`
            : `${timeInHours} hr ${minutesString}`;
    }
};

function sequence(start, end) {
    const seq = [];

    for (let i = start; i <= end; i++) {
        seq.push(i);
    }

    return seq;
}

export function numberWithCommas(x) {
    const parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
}

export function renderPrizeDescription(prizesString) {
    const prizePlaceDescriptions = [];

    let previousPrize;
    let startingPlace;
    // filter out an empty lines
    prizesString
        .split('\n')
        .map((prize) => prize.trim())
        .filter((prize) => prize)
        .forEach((prize, index) => {
            // translate to 1st, 2nd, 3rd, etc
            const place = numeral(index + 1).format('0o');

            if (previousPrize === prize) {
                prizePlaceDescriptions.pop();
                prizePlaceDescriptions.push(
                    `${startingPlace}-${place}: ${prize}`,
                );
            } else {
                prizePlaceDescriptions.push(`${place}: ${prize}`);
                previousPrize = prize;
                startingPlace = place;
            }
        });

    return prizePlaceDescriptions.join(', ');
}

export const timeFormatFunc = (date) =>
    date ? date.tz(moment.tz.guess()).format('YYYY-MM-DD HH:mm z') : '';
