import { LocaleIds, Venue, VenueCreate } from '../../service/Dto';
import {
    EntityTableExport,
    EntityTableImport,
    EntityTableTransformer,
} from './EntityTableTransformer';
import parseEntity, { ParseConfig } from './parseEntity';

const venueParseConfig: ParseConfig<VenueCreate> = {
    address: {
        column: 1,
    },
    city: {
        column: 3,
    },
    country: {
        column: 5,
    },
    lat: { column: 7 },
    long: { column: 6 },
    name: {
        column: 0,
    },
    state: {
        column: 4,
        fromCell: (cell: string) => (cell === '' ? null : cell),
    },
    zipCode: { column: 2 },
} as const;

const isRowEmpty = (row: string[]) =>
    row.length === 0 || row.every((cell) => cell === '');

const HEADER_ROWS_COUNT = 1;

const firstTabHeadlineRow = [
    'Name',
    'Address',
    'City',
    'Zip Code',
    'Latitude',
    'Longitude',
];

const triviaQuestionTableTransformer: EntityTableTransformer<
    VenueCreate,
    Venue
> = {
    fromTable: (input: EntityTableImport): VenueCreate[] => {
        const venues: VenueCreate[] = [];
        const [firstTab] = input;
        let rowsToParse = firstTab.values
            .slice(HEADER_ROWS_COUNT)
            .filter((row) => !isRowEmpty(row));

        while (rowsToParse.length > 0) {
            const { nextRowsToParse, parsedEntity } = parseEntity<VenueCreate>(
                venueParseConfig,
                rowsToParse,
            );
            venues.push(parsedEntity);
            rowsToParse = nextRowsToParse;
        }

        return venues;
    },
    toTable: (venues: Venue[]) => {
        const result: EntityTableExport = [
            {
                languageId: LocaleIds[0],
                values: [firstTabHeadlineRow],
            },
        ];

        venues.forEach((venue) => {
            const { address, city, lat, long, name, zipCode } = venue;
            result[0].values.push([name, address, city, zipCode, lat, long]);
        });
        return result;
    },
};

export default triviaQuestionTableTransformer;
