import { createContext } from 'react';

interface AppContextProps {
    notifyError: (message: string) => void;
    notifySuccess: (message: string) => void;
}

const dummy = () => {
    throw new Error(
        'Should never be called. Is ApiContext.Provider in the component tree?',
    );
};

const AppContext = createContext<AppContextProps>({
    // createContext requires default values.
    // In practice, these should never actually be called as long as the provider
    // is correctly in the component tree.
    notifyError: dummy,
    notifySuccess: dummy,
});

export default AppContext;
