import {
    Box,
    Button,
    Checkbox,
    DialogActions,
    FormControl,
    FormControlLabel,
    FormHelperText,
    LinearProgress,
    MenuItem,
    Select,
    Tab,
    Tabs,
    TextField,
    Typography,
} from '@material-ui/core';
import { StyleRules, withStyles } from '@material-ui/core/styles';
import React, { PureComponent } from 'react';
import AuthoringModal from './AuthoringModal';
import TabPanel from './TabPanel';

interface Props {
    classes: any;
    eventId: string;
    handleCloseModal: () => void;
    handleSmsAlertResponse: (success: boolean, error: Error | null) => void;
    notifyError: (error: string) => void;
    partner: any;
    partnerId: string;
    partnerSmsProperties: any;
    sendSmsAlert: (params: {
        eventId: string;
        partnerId: string;
        messages: { [key: string]: string | undefined };
        filter: string;
        smsTestPhoneNumber: string | null;
    }) => Promise<void>;
}

interface TranslatableSMSText {
    [key: string]: string | undefined;
}
interface State {
    characterCount: number;
    currentMessages: TranslatableSMSText;
    confirmMessage: string;
    requestInProgress: boolean;
    selectedKey: string;
    smsRecipients: string;
    smsTestPhoneNumber: string | null;
    testSms: boolean;
    tabIndex: number;
}

const MAX_CHAR_COUNT = 160;

const SMS_RECIPIENTS: { [recipientType: string]: string } = {
    ALL_USERS: 'All',
    NOT_PREDICTED_IN_EVENT: 'Has not predicted in event',
    PREDICTED_IN_EVENT: 'Has predicted in event',
};

export class SendSmsModal extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        const messages: TranslatableSMSText = {};
        if (this.props.partner.properties.supportedLanguages) {
            this.props.partner.properties.supportedLanguages.forEach(
                (el: string) => (messages[el] = ''),
            );
        } else {
            messages.EN = '';
        }

        this.state = {
            characterCount: 0,
            confirmMessage: '',
            currentMessages: messages,
            requestInProgress: false,
            selectedKey: '',
            smsRecipients: SMS_RECIPIENTS.all,
            smsTestPhoneNumber: null,
            tabIndex: 0,
            testSms: false,
        };
    }

    private handleMessageSelectChange = (event: any) => {
        const { partnerSmsProperties } = this.props;
        const messageKey = event.target.value;
        const currentMessage = partnerSmsProperties[messageKey];
        const currentMessages: TranslatableSMSText = {};
        if (
            typeof currentMessage === 'string' ||
            currentMessage instanceof String
        ) {
            this.props.partner.properties.supportedLanguages.forEach(
                (el: string) =>
                    (currentMessages[el] = currentMessage as string),
            );
        } else {
            this.props.partner.properties.supportedLanguages.forEach(
                (el: string) =>
                    (currentMessages[el] = currentMessage[el] || ''),
            );
        }
        this.setState({
            characterCount:
                currentMessages[
                    this.props.partner.properties.supportedLanguages[0]
                ]!.length,
            currentMessages,
            selectedKey: messageKey,
        });
    };

    private handleRecipientSelectChange = (event: any) => {
        this.setState({
            smsRecipients: event.target.value,
        });
    };

    private handleTabMessageChange = (tab: string, i: number) => {
        return (event: any) => {
            const message = event.target.value;
            this.setState({
                characterCount:
                    i === 0 ? message.length : this.state.characterCount,
                currentMessages: {
                    ...this.state.currentMessages,
                    [tab]: message,
                },
            });
        };
    };

    private handleConfirmMessageChange = (event: any) => {
        const message = event.target.value;
        this.setState({
            confirmMessage: message,
        });
    };

    private handleTestNumberChange = (event: any) => {
        this.setState({
            smsTestPhoneNumber: event.target.value,
        });
    };

    private handleTestCheck = (event: any) => {
        const checked: boolean = event.target.checked;
        this.setState({
            smsTestPhoneNumber: null,
            testSms: checked,
        });
    };

    private handleTabChange = (_: any, newValue: number) => {
        this.setState({
            tabIndex: newValue,
        });
    };

    private handleSubmit = async () => {
        const { eventId, handleSmsAlertResponse, partnerId, sendSmsAlert } =
            this.props;
        const {
            characterCount,
            confirmMessage,
            currentMessages,
            smsRecipients,
            smsTestPhoneNumber,
            testSms,
        } = this.state;
        if (
            this.props.partner.properties.supportedLanguages
                .map((el: string) => currentMessages[el])
                .some((el: string) => !el || el.length === 0)
        ) {
            this.props.notifyError('SMS translation text cant be blank.');
            return;
        }
        if (
            this.props.partner.properties.supportedLanguages
                .map((el: string) => currentMessages[el])
                .some((message: string) => message.length > MAX_CHAR_COUNT)
        ) {
            this.props.notifyError(
                `Some language SMS text exceeds max char count of ${MAX_CHAR_COUNT} symbols`,
            );
            return;
        }
        if (confirmMessage !== `${characterCount}`) {
            this.props.notifyError(
                'Default language SMS text length is not equal to what you typed in',
            );
            return;
        }

        this.setState({
            requestInProgress: true,
        });

        let testNumber = null;
        if (testSms) {
            testNumber = smsTestPhoneNumber;
        }

        try {
            const recipientType =
                Object.keys(SMS_RECIPIENTS).find(
                    (key: string) => SMS_RECIPIENTS[key] === smsRecipients,
                ) || 'all';

            await sendSmsAlert({
                eventId,
                filter: recipientType,
                messages: currentMessages,
                partnerId,
                smsTestPhoneNumber: testNumber,
            });
            handleSmsAlertResponse(true, null);
        } catch (error) {
            handleSmsAlertResponse(false, error as Error);
        }
    };

    public render() {
        const { classes, handleCloseModal, partnerSmsProperties } = this.props;
        const {
            confirmMessage,
            currentMessages,
            requestInProgress,
            selectedKey,
            smsRecipients,
            testSms,
        } = this.state;

        const subtitle = (
            <Typography variant="subtitle1">
                Are you sure you want to send messages to possibly{' '}
                <b>thousands</b> of people?
            </Typography>
        );
        return (
            <AuthoringModal
                onClose={handleCloseModal}
                subtitle={subtitle}
                title="Send Mass SMS Alert"
            >
                <FormControl>
                    <FormHelperText>SMS Message Keys</FormHelperText>
                    <Select
                        onChange={this.handleMessageSelectChange}
                        value={selectedKey || ''}
                    >
                        {Object.keys(partnerSmsProperties).map((key) => (
                            <MenuItem key={key} value={key}>
                                {key}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl>
                    <FormHelperText>Send SMS to:</FormHelperText>
                    <Select
                        onChange={this.handleRecipientSelectChange}
                        value={smsRecipients || ''}
                    >
                        {Object.keys(SMS_RECIPIENTS).map((key: string) => (
                            <MenuItem
                                key={SMS_RECIPIENTS[key]}
                                value={SMS_RECIPIENTS[key]}
                            >
                                {SMS_RECIPIENTS[key]}
                            </MenuItem>
                        ))}
                    </Select>
                    <Box display="flex" flexDirection="row">
                        <Tabs
                            indicatorColor="primary"
                            onChange={this.handleTabChange}
                            textColor="primary"
                            value={this.state.tabIndex}
                        >
                            {this.props.partner.properties.supportedLanguages.map(
                                (el: string, i: number) => {
                                    const label = `${
                                        i === 0
                                            ? 'Default language: '
                                            : 'Translation: '
                                    }${el}`;
                                    return (
                                        <Tab
                                            key={i}
                                            label={<div>{label}</div>}
                                        />
                                    );
                                },
                            )}
                        </Tabs>
                    </Box>
                    {this.props.partner.properties.supportedLanguages.map(
                        (tab: string, i: number) => {
                            return (
                                <TabPanel
                                    index={i}
                                    key={i}
                                    value={this.state.tabIndex}
                                    width={'100%'}
                                >
                                    <TextField
                                        id="sms-currentMessage"
                                        key={i}
                                        label="SMS Message"
                                        margin="normal"
                                        multiline={true}
                                        onChange={this.handleTabMessageChange(
                                            tab,
                                            i,
                                        )}
                                        required={true}
                                        rows="4"
                                        style={{
                                            width: '100%',
                                        }}
                                        value={currentMessages[tab]}
                                        variant="outlined"
                                    />
                                    <Typography variant="caption">
                                        Character Count:{' '}
                                        <b>
                                            {(currentMessages[tab] &&
                                                currentMessages[tab]!.length) ||
                                                0}
                                        </b>{' '}
                                        / {MAX_CHAR_COUNT}
                                    </Typography>
                                </TabPanel>
                            );
                        },
                    )}
                    <TextField
                        className={classes.textField}
                        id="sms-currentMessage"
                        label="Enter Default Language Message Character Count"
                        margin="normal"
                        onChange={this.handleConfirmMessageChange}
                        required={true}
                        type="number"
                        value={confirmMessage}
                    />
                </FormControl>
                <FormControl>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={testSms}
                                onChange={this.handleTestCheck}
                                value="Test SMS"
                            />
                        }
                        label="TEST SMS"
                        labelPlacement="start"
                    />
                    {testSms && (
                        <TextField
                            className={classes.textField}
                            id="sms-testNumber"
                            label="Test phone Number"
                            margin="normal"
                            onChange={this.handleTestNumberChange}
                            type="phone"
                        />
                    )}
                </FormControl>
                <DialogActions className={classes.bottomWrapper}>
                    <Button
                        color="secondary"
                        disabled={requestInProgress}
                        onClick={handleCloseModal}
                        variant="contained"
                    >
                        Cancel
                    </Button>

                    <Button
                        color="primary"
                        disabled={requestInProgress}
                        onClick={this.handleSubmit}
                        variant="contained"
                    >
                        Send SMS Alert
                    </Button>
                </DialogActions>
                {requestInProgress && <LinearProgress />}
            </AuthoringModal>
        );
    }
}

const styles = () =>
    ({
        bottomWrapper: {
            display: 'flex',
            justifyContent: 'space-between',
        },
    }) as StyleRules;

export default withStyles(styles)(SendSmsModal);
