import React, { ReactNode } from 'react';

type Props = {
    options: string[];
    correctOption: string;
};

const TextOptions = ({ correctOption, options }: Props) => {
    return (
        <>
            {options
                .map<ReactNode>((option) => (
                    <span
                        key={option}
                        style={
                            option === correctOption
                                ? { fontWeight: 'bold' }
                                : {}
                        }
                    >
                        {option}
                    </span>
                ))
                .reduce<ReactNode[] | null>(
                    (acc, cur) => (acc === null ? [cur] : [...acc, ', ', cur]),
                    null,
                )}
        </>
    );
};

export default TextOptions;
